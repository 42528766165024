import React, { Component } from 'react';
import { Button, Container, DialogActions, DialogContent, Grid, Hidden, IconButton, Paper, Typography } from '@material-ui/core';
import { watch } from 'redux-easy/lib/redux-easy';
import { withRouter } from 'react-router-dom';
import { Helmet } from "react-helmet";
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import CloseIcon from '@material-ui/icons/Close';
import './expensesFinal.scss';

class ExpensesFinal extends Component {

    step = 5;

    state = {
        selectedType: this.props.state.data.expensesType,
    }

    getProgressWidth() {
        const w = this.step * 20 + '%';
        return w === '100%' ? '101%' : w;
    }

    componentDidMount = () => {
        this.props.changeWidth()
    }

    handleClose = () => {
        if (this.props.handleClose) {
            this.props.handleClose();
        }
    }

    render() {
        return (

            <Grid className='expenses-final'>
                <MuiDialogTitle disableTypography className="dialog-header">
                    <h2>Cadastro de despesas</h2>
                    <IconButton color="secondary" aria-label="close" className="close-button" onClick={this.handleClose}>
                        <CloseIcon />
                    </IconButton>
                </MuiDialogTitle>
                <Grid container className="progress-floater">
                    <div className="progress-container">
                        <div className="progress" style={{ width: this.getProgressWidth() }}></div>
                    </div>
                </Grid>
                <DialogContent className="dialog-content" >

                    <Grid container item xs={12} direction="column" justify="center" alignItems="center" className="content-holder">
                        <h6>Despesa {this.state.selectedType? 'fixa' : 'variável'}</h6>
                        <h1>cadastrada</h1>
                        <img src="/images/despesas-final.png" width="260px" alt="" />
                        <p>Agora que você já tem suas despesas {this.state.selectedType === 1 ? 'fixas' : 'variáveis'} cadastradas será muito mais fácil ter a visibidade dos seus gastos. Você pode cadastrar novas despesas <span onClick={() => this.props.showContent({ to: 'expenses-panel-select', from: this.props.page, reset: true })}>clicando aqui</span> ou clique em sair para ir ao painel principal.</p>
                    </Grid>

                </DialogContent >
                <DialogActions className="dialog-footer">
                    {/* <Button
                        id="g-pm-start-test"
                        className={`g-pm-start-test`}
                        color="primary"
                        variant="outlined"
                        onClick={() => this.props.showContent({ to: 'expenses-select', from: this.props.page, reset: true, data: {expensesType: this.state.selectedType === 1 ? 2 : 1 }})}
                        style={{ borderRadius: "0px"}}

                    >
                        nova despesa {this.state.selectedType === 1 ? 'variável' : 'fixa' }
                    </Button> */}
                    <Button
                        id="g-pm-start-test"
                        className={`g-pm-start-test`}
                        variant="contained"
                        color="primary"
                        onClick={this.handleClose}
                        style={{ borderRadius: "0px", color: "white", width: "120px" }}
                    >
                        sair
                    </Button>
                </DialogActions>
            </Grid>
        )
    }
}

export default watch(withRouter(ExpensesFinal), { user: '' });