import React, { useEffect, useState } from 'react';
import '../../../../backoffice.scss';
import './styles.scss';

import { Grid, Table, TableBody, TableCell, TableContainer, CircularProgress, TableRow, TableHead, TableSortLabel, FormControl, Select, MenuItem } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

import Pagination from '@material-ui/lab/Pagination';
import PropTypes from 'prop-types';

import Button from '../../button/Button';

import api from "../../../../services/api";
import { Keyboard } from '@material-ui/icons';
const Globals = require("../../../../Globals.json");

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy, searchUser) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}
function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort, headCells } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align='center'
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              <span style={{ color: '#01377D', fontWeight: '1000', fontSize: '16px' }}>{headCell.label}</span>
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden} >
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
    minHeight: 400,
    position: 'relative',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

export default function EnhancedTable() {
  const classes = useStyles();
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('id');
  const [page, setPage] = useState(0);
  const [pagination, setPagination] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [searchUser, setSearchUser] = useState('')
  const [searchCpf, setSearchCpf] = useState('')
  const [searchEmail, setSearchEmail] = useState('')
  const [searchPhone, setSearchPhone] = useState('')
  const [searchStatus, setSearchStatus] = useState('')
  const [searchPerfil, setSearchPerfil] = useState('')
  const [searchGroup, setSearchGroup] = useState('')
  const [searchObjective, setSearchObjective] = useState('')
  const [roles, setRoles] = useState([])
  const [statusList, setStatusList] = useState([])
  const [objective, setObjective] = useState([])
  const [group, setGroup] = useState([])
  const [status, setStatus] = useState([])
  const [perPage, setPerPage] = useState(1)
  const [lastPage, setLastPage] = useState()
  const [isLoading, setIsLoading] = useState(false)
  const [showResult, setShowResult] = useState(false)
  const [headCells] = useState([
    { id: 'username', numeric: false, disablePadding: false, label: 'Nome de Usuário' },
    { id: 'cpf', numeric: false, disablePadding: false, label: 'CPF' },
    { id: 'email', numeric: false, disablePadding: false, label: 'Email' },
    { id: 'phone', numeric: false, disablePadding: false, label: 'Telefone' },
    { id: 'typeAccess', numeric: false, disablePadding: false, label: 'Perfil de acesso' },
    { id: 'typeUser', numeric: false, disablePadding: false, label: 'Tipo de Usuário' },
    { id: 'status', numeric: false, disablePadding: false, label: 'Status' }
  ])
  const [rows, setRows] = useState([])
  const [total, setTotal] = useState(0)

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPerPage(newPage);
    getUsersPerPage(newPage, rowsPerPage, true)
  };

  const handleChangeRowsPerPage = (e) => {
    const pageIndex = pagination;
    setRowsPerPage(e.target.value);
    getUsersPerPage(pageIndex, e.target.value, true)
  };

  const filterBy = () => {
    setPagination(1)
    setPerPage(1)
    getUsersPerPage(1, rowsPerPage, true)
  }

  const isSearchAvailable = () => {

    let cont = 0;

    if (searchUser && searchUser.length >= 5) {
      cont++;
    }
    if (searchCpf && searchCpf.length >= 3) {
      cont++;
    }
    if (searchEmail && searchEmail.length >= 5) {
      cont++;
    }
    if (searchPhone && searchPhone.length >= 6) {
      cont++;
    }
    if (searchStatus) {
      cont++;
    }
    if (searchPerfil) {
      cont++;
    }
    if (searchObjective) {
      cont++;
    }

    return cont >= 3;

  }

  const getUsersPerPage = (page, rows, filterby) => {
    setIsLoading(true)
    api.get(Globals.api.usersPage, {
      params: {
        nome: searchUser ? searchUser : undefined,
        cpf_cnpj: searchCpf ? searchCpf : undefined,
        email: searchEmail ? searchEmail : undefined,
        phone: searchPhone ? searchPhone : undefined,
        type_users_group_id: searchGroup ? searchGroup : undefined,
        type_users_status_id: searchStatus ? searchStatus : undefined,
        role_id: searchPerfil ? searchPerfil : undefined,
        type_interests_category_id: searchObjective ? searchObjective : undefined
      }
    }).then(response => {
      setShowResult(true)
      // const total = response.data.total;
      console.log('rows', response.data)
      setRows(response.data)
      // setTotal(total)
      // setLastPage(response.data.lastPage)
      // setPagination(page)
      // setRowsPerPage(rows)
      setIsLoading(false)
    }).catch(error => {
      console.log(error)
    })
  }

  const getRoles = () => {
    api.get(Globals.api.getRoles, {
      params: {
        "per_page": 1000,
        "page": 1,
      }
    }).then(response => {
      console.log('roles', response.data)
      setRoles(response.data.data)
    }).catch(error => {
      console.log(error)
    })
  }

  const translateStatus = (s_id) => {
    const items = statusList.filter((e) => e.id == s_id);
    return items.length > 0 ? items[0].type : "Status Desconhecido";
  }

  const getObjective = () => {
    api.get(Globals.api.getObjective, {
      params: {
        "per_page": 1000,
        "page": 1,
      }
    })
      .then(response => {
        console.log('objetivos', response.data)
        setObjective(response.data.data)
      }).catch(error => {
        console.log(error)
      })
  }

  const getGroup = () => {
    api.get(Globals.api.getGroup, {
      params: {
        "per_page": 1000,
        "page": 1,
      }
    })
      .then(response => {
        console.log('Grupos', response.data)
        setGroup(response.data.data)
      }).catch(error => {
        console.log(error)
      })
  }

  const filterInput = (e) => {
    if (e.key == "Backspace" || e.key == "Tab") return;
    if ("0123456789".indexOf(e.key) === -1) e.preventDefault();
  }

  const getStatusList = () => {
    api.get(Globals.api.getStatus, {
      params: {
        "per_page": 1000,
        "page": 1,
      }
    }).then(response => {
      console.log('statusList', response.data)
      setStatusList(response.data.data)
    }).catch(error => {
      console.log(error)
    })
  }

  const removeNonNumbers = (str) => {
    console.log("Cleaning::", str);
    return str.replace(/[^0-9]/g, '');
  }

  const joinValues = (arr) => {
    if (arr.length === 1 && arr[0] === null) {
      return '';
    }
    return arr.join(", ");
  }

  useEffect(() => {
    getGroup();
    getRoles();
    getObjective();
    getStatusList();
  }, [])

  // const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  return (
    <Grid className="backoffice-content" style={{ marginBottom: '70px' }}>
      <Grid className="content">
        <Grid className="box" >
          <div className="table_user_contentTexts">
            <span className="table_user_textList">Lista de Usuários cadastrados</span>
            <span className="table_user_textFiltro">Filtros</span>
            <span className="table_user_textFiltroSmall">Preencha ao menos 3 filtros para efetuar uma busca</span>
          </div>

          <div className="user_positionSearch">

            <input
              placeholder="Nome"
              value={searchUser}
              style={{ width: '400px' }}
              onChange={(e) => setSearchUser(e.target.value)}
            />

            <input
              type="number"
              placeholder="CPF"
              value={searchCpf}
              style={{ width: '200px' }}
              onKeyDown={(e) => filterInput(e)}
              onChange={(e) => setSearchCpf(removeNonNumbers(e.target.value))}
            />

            <input
              placeholder="Email"
              value={searchEmail}
              onChange={(e) => setSearchEmail(e.target.value)}
            />

            <input
              type="number"
              placeholder="Telefone"
              style={{ width: '210px' }}
              value={searchPhone}
              onKeyDown={(e) => filterInput(e)}
              onChange={(e) => setSearchPhone(removeNonNumbers(e.target.value))}
            />

          </div>

          <div className="user_positionSearch">

            {/* <select value={searchGroup} onChange={(e) => setSearchGroup(e.target.value)} style={{ width: "190px" }} >
              <option value={''}>Tipo de Usuário</option>
              {group.map((item, index) => (
                <option key={item.id} value={item.id}>{item.description}</option>
              ))}
            </select> */}

            <select value={searchPerfil} onChange={(e) => setSearchPerfil(e.target.value)} style={{ width: "190px" }} >
              <option value={''}>Perfil de Acesso</option>
              {roles.map((item, index) => (
                <option key={item.id} value={item.id}>{item.description}</option>
              ))}
            </select>

            <select value={searchStatus} style={{ width: "200px" }} onChange={(e) => setSearchStatus(e.target.value)} >
              <option value=''>Status</option>
              {statusList.map((item, index) => (
                <option key={item.id} value={item.id}>{item.type}</option>
              ))}
            </select>

            <select value={searchObjective} onChange={(e) => setSearchObjective(e.target.value)} >
              <option value={''}>Objetivo</option>
              {objective.map((item, index) => (
                <option key={item.id} value={item.id}>{item.type}</option>
              ))}
            </select>

            <Button disabled={!isSearchAvailable()}
              icon="none"
              type={!isSearchAvailable() ? 'disabled' : 'darkBlue'}
              textAlign="center"
              height="40px"
              onClick={filterBy}>Buscar</Button>

          </div>

          <TableContainer>
            <Table

              aria-label="enhanced table"
            >
              <EnhancedTableHead

                order={order}
                headCells={headCells}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
              // rowCount={rows.length}
              />
              {isLoading ?

                <TableRow>
                  <TableCell style={{ maxHeight: "40px" }} colSpan="7" align="center" className="user_table_cell" >
                    <CircularProgress />
                  </TableCell>
                </TableRow>

                :
                <TableBody>
                  {showResult && rows.length == 0 &&
                    <TableRow>
                    <TableCell style={{ maxHeight: "40px" }} colSpan="7" align="center" className="user_table_cell" >
                      <div className="table_user_showResult">
                        <span>Não foi possível encontrar nenhum registro dentro do filtro selecionado.</span>
                      </div>
                    </TableCell>
                  </TableRow>
                  }
                  {showResult ?
                    <>
                      {stableSort(rows, getComparator(order, orderBy, searchUser))
                        .map((row, index) => {
                          return (
                            <TableRow
                              hover
                              key={index}
                            >
                              <TableCell style={{ maxHeight: "40px", padding: 8 }} align="center" className="user_table_cell" >
                                <Link style={{ color: '#01377D', textDecoration: 'none' }}
                                  to={Globals.paths.backofficeUsersEdit.replace(':userId', row.id)} >{row.username}</Link>
                              </TableCell>
                              <TableCell style={{ maxHeight: "40px", padding: 8 }} align="center" className="user_table_cell" >
                                <span>{row.cpf_cnpj}</span>
                              </TableCell>
                              <TableCell style={{ maxHeight: "40px", padding: 8 }} align="center" className="user_table_cell" >
                                <span>{row.email}</span>
                              </TableCell>
                              <TableCell style={{ maxHeight: "40px", padding: 8 }} align="center" className="user_table_cell" >
                                <span>{row.number}</span>
                              </TableCell>
                              <TableCell style={{ maxHeight: "40px", padding: 8 }} align="center" className="user_table_cell" >
                                <span>{joinValues(row.roles)}</span>
                              </TableCell>
                              <TableCell style={{ maxHeight: "40px", padding: 8 }} align="center" className="user_table_cell" >
                                <span>{joinValues(row.grupos)}</span>
                              </TableCell>
                              <TableCell style={{ maxHeight: "40px", padding: 8 }} align="center" className="user_table_cell" >
                                <span>
                                  {row.status_description}
                                </span>
                              </TableCell>
                            </TableRow>
                          )
                        })}
                    </> :
                    <TableRow>
                      <TableCell style={{ maxHeight: "40px" }} colSpan="7" align="center" className="user_table_cell" >
                        <div className="table_user_showResult">
                          <span>Insira os dados que deseja buscar para visualizar os usuários.</span>
                        </div>
                      </TableCell>
                    </TableRow>

                  }
                  {/* {emptyRows > 0 && (
                  <TableRow style={{ height: (53) * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )} */}
                </TableBody>
              }
            </Table>
          </TableContainer>

          {/* {showResult &&
          <>
            <div className="user_footer_table">
              <span>Linhas por página:
          <FormControl>
                  <Select
                    value={rowsPerPage}
                    onChange={(e) => handleChangeRowsPerPage(e)}
                  >
                    <MenuItem value={5}>
                      <em>5</em>
                    </MenuItem>
                    <MenuItem value={10}>10</MenuItem>
                  </Select>
                </FormControl>
              </span>

              <Pagination count={lastPage} page={perPage} onChange={handleChangePage} />

              <div className="user_exibitionTable">
                <span>Exibindo 1 - {rowsPerPage} de {total} </span>
              </div>
            </div>
          </>
        } */}
        </ Grid>
      </ Grid>
    </Grid>


  );
}

