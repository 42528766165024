import React from 'react';

import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";
import { withStyles, Breadcrumbs, Typography, Grid } from '@material-ui/core';

import CourseList from '../../components/course/CourseList'

import ArrowRightIcon from '@material-ui/icons/ArrowRight';

const styles = theme => ({

  BreadCrumb:
  {
    color: "#fff",
    marginBottom: "20px",
    fontWeight: "bold",

    [theme.breakpoints.down('xs')]: {
      marginBottom: "10px",
      marginTop: "-14px"
    }
  },
  BreadCrumbLink:
  {
    color: "#fff",
    textTransform: "uppercase",
    textDecoration: "none",
    fontSize: "10pt",

    "@media (max-width: 436px)": {
      fontSize: "11px",
    }
  },
  BreadCrumbLabel:
  {
    color: "#fff",
    textTransform: "uppercase",
    fontSize: "10pt",
  },
  contentContainer:
  {
  },
  topToolbar:
  {
    paddingBottom: "10px",
  },
  topToolbarItem:
  {
    height: "80px",
    border: "#ccc 1px solid",
    borderRadius: "10px",
  },
  feedContainer:
  {
    paddingRight: "10px",
    [theme.breakpoints.down('xs')]: {
      paddingRight: "0px",
    },
  },
  pageItem:
  {
    minHeight: "200px",
    backgroundColor: "#fff",
    border: "#ddd 1px solid",
    marginBottom: "10px",
    boxShadow: "4px 4px 10px #0003",
  },
  trailHeaderImage:
  {
    height: "220px",
    backgroundPosition: "center center",
    backgroundSize: "cover",
  },
  trailHeaderInfo:
  {
    padding: "20px",
    display: "flex",
    flexDirection: "column",
    borderBottom: "#F4F4F4 2px solid",
  },
  trailHeaderInfoLabel:
  {
    fontWeight: "bold",
    fontSize: "10pt",
    color: "#5A5A5A",
  },
  trailHeaderInfoTitle:
  {
    flexGrow: 1,
    fontWeight: "bold",
    fontSize: "15pt",
    color: "#333",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  extraInfo:
  {
    paddingBottom: "20px",
  },
  extraInfoBox:
  {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingTop: "2px",
    paddingBottom: "2px",
    marginRight: "20px",
    color: "#9F9F9F",
    fontWeight: "bold",
    fontSize: "9pt",
  },
  extraInfoBoxProgressBar:
  {
    backgroundColor: "#E6E6E6",
    width: "100px",
    height: "8px",
    marginTop: "7px",
    marginRight: "20px",
    borderRadius: "4px",
  },
  extraInfoBoxProgressBarFill:
  {
    backgroundColor: "#1BB37E",
    width: "30%",
    height: "100%",
    borderRadius: "4px",
  },
  trailDescription:
  {
    padding: "20px",
  },
  trailDescriptionLabel:
  {
    fontWeight: "bold",
    fontSize: "15pt",
    color: "#333",
    marginBottom: "15px",
  },
  trailDescriptionText:
  {
    fontSize: "12pt",
    color: "#666",
  },
  trailStructure:
  {
    padding: "20px",
  },
  trailStructureLabel:
  {
    fontWeight: "bold",
    fontSize: "15pt",
    color: "#333",
    marginBottom: "15px",
  },
  trailButton:
  {
    textDecoration: "none",
    display: "flex",
    flexDirection: "row",
    textTransform: "uppercase",
    flexWrap: "nowrap",
    padding: "5px",
    justifyContent: "center",
    alignItems: "center",
    border: "#0D509B 1px solid",
    backgroundColor: "#fff",
    color: "#0D509B",
    borderRadius: "0px",
    fontSize: "10pt",
    paddingLeft: "10px",

    transition: "color .3s ease-out, border-color .3s ease-out",

    '&:hover': {
      color: "#5DACFC",
      borderColor: "#5DACFC",
      cursor: "pointer",
    }

  },
  trailButtonLabel:
  {
    flexGrow: 1,
    textDecoration: "none",
  },
  trailButtonIcon:
  {
    padding: "0px",
  },
  classList: {
    display: "flex",
    flexDirection: "column",
  },
});

const Globals = require("../../../Globals.json");

class trailDetail extends React.Component {

  state = {
    isLoading: false,
    trailId: null,

    trailName: "Gestão financeira para iniciantes - Como sair das dividas",
    trailDescription: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    trailThumbnail: "https://paginadoestado.com.br/wp-content/uploads/infocoweb/2020/05/10/thumbnail-for-63637.jpg",
    trailData: {},
    classes: [],
  }

  async loadData(trailId) {
    // this.setState({ isLoading: true });

    // const postData = {
    //     "container_id": trailId,
    // };

    // this.setState({ isLoading: true });
    // const response = await api.post('api/v1/module/get_modules', postData);

    // const { container, children } = response.data;
    // console.log(response.data);

    // this.setState({
    //     isLoading: false,
    //     trailName: container.name,
    //     trailDescription: container.description,
    //     trailThumbnail: container.thumbnail,
    //     classes: children,
    // });

  }

  componentDidMount() {
    //console.log( "Teste", this.props );
    //const { trailId } = this.props;
    //this.setState({ trailId });
    //this.loadData(trailId);
  }

  render() {

    const { classes } = this.props;

    return (
      <>
        <Breadcrumbs aria-label="breadcrumb" className={classes.BreadCrumb}>
          <Link color="inherit" to={Globals.paths.trails} className={classes.BreadCrumbLink}>
            Trilha
                    </Link>
          <Typography color="textPrimary" className={classes.BreadCrumbLabel}>{this.state.trailName} </Typography>
        </Breadcrumbs>


        <Helmet>
          <title>Trilha | Meu bolso em dia</title>
        </Helmet>

        <Grid container className={classes.contentContainer}>
          <Grid item container xs={12} sm={8} md={9} direction="column" className={classes.feedContainer}>
            <Grid item container className={classes.pageItem}>
              <Grid item xs={4} className={classes.trailHeaderImage} style={{ backgroundImage: `url(${this.state.trailThumbnail})` }}>
              </Grid>
              <Grid item xs={8} className={classes.trailHeaderInfo}>

                <div className={classes.trailHeaderInfoLabel}>
                  Trilha
                                </div>

                <div xs={12} className={classes.trailHeaderInfoTitle}>
                  {this.state.trailName}
                </div>

                <Grid container direction="row" className={classes.extraInfo}>
                  <div className={classes.extraInfoBoxProgressBar}>
                    <div className={classes.extraInfoBoxProgressBarFill}></div>
                  </div>
                  <div className={classes.extraInfoBox}>Não Iniciado</div>
                </Grid>
                <div xs={12}>

                  <button
                    className={classes.trailButton}
                    onClick={() => console.log('Not implemented yet')}
                  >
                    <div className={classes.trailButtonLabel}>
                      Começar
                                        </div>
                    <ArrowRightIcon className={classes.trailButtonIcon} />
                  </button>
                </div>

              </Grid>
              <Grid item xs={12} className={classes.trailDescription}>
                <div className={classes.trailDescriptionLabel}>
                  Resumo da Trilha
                                </div>
                <div className={classes.trailDescriptionText}>
                  {this.state.trailDescription}
                </div>
              </Grid>

              <Grid item xs={12} className={classes.trailStructure}>

                <div className={classes.trailStructureLabel}>
                  Estrutura da Trilha
                                </div>

                <div className={classes.classList}>
                  <CourseList history={this.props.history} endpoint={Globals.api.getModules} payload={{ type_module_id: "2" }} />
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </>
    )
  }
}
export default withStyles(styles)(trailDetail);

