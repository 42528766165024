import React, { Component } from 'react';

import { Button, CircularProgress, Grid, Box, Hidden } from '@material-ui/core';

import CurrencyBalance from '../../components/progresso/CurrencyBalance';
import CurrentLevel from '../../components/sidebar/CurrentLevel';
import FeedItem from '../../components/feed/FeedItem';
import Gauge from '../../components/gauge';
import InitialModalQuiz from '../../components/quiz/InitialModalQuiz';
import OpenTable from '../../components/shopping/openTable';
import ToolbarItem from '../../components/toolbar/ToolbarItem';
import { withRouter } from 'react-router-dom';
import Btn from '../../components/button/Button'
import Pusher from 'react-pusher';
import { Skeleton } from '@material-ui/lab';
import { watch } from 'redux-easy/lib/redux-easy';
import { Helmet } from "react-helmet";

import AlertErrors from '../../components/alertErrors/alerts';

import "./openShopping.scss"

import api from '../../../services/api';
import { getNextIndexAssessmentDateText } from '../../../services/util';

const Globals = require("../../../Globals.json");
const QuestionarioIndice = require("../../../QuestionarioIndice.json");

class openShopping extends Component {
  state = {
    isLoading: false,
    isModalOpen: false,
    isfb: 0,
    userId: -1,
    lock: false,
    indiceData: {},
    items: [],
    open: false,
    later: false,
    title: "",
    loading: false,
    table: [],
    alertError: '',
    color: "",
    nextIndexAssessmentDateText: '',
  }

  handleModal = async (item) => {
    if (item === 'later') {
      this.setState({ isModalOpen: false, lock: false });
      if (!this.state.later) {
        const postData = {
          type_action_id: 15,
          data_modules_detail_id: 155,
        }
        try {
          await api.post(Globals.api.userActionEvent, postData);
        } catch (error) {
          this.setState({ alertError: error.response.status })
          setTimeout(() => {
            this.setState({ alertError: '' })
          }, 3000)
        }
      }
    }
  }

  closeModal = () => {
    this.setState({ open: false })
  }

  async loadIndiceData () {
    try {
      const response = await api.post(Globals.api.getModules, { container_id: 58 });
      if (response.data.container.user_module_data.isfb) {
        this.setState({ isfb: response.data.container.user_module_data.isfb });
      }
    } catch (error) {
      this.setState({ alertError: error.response.status })
      setTimeout(() => {
        this.setState({ alertError: '' })
      }, 3000)
    }
  }

  async loadDataQuestIndice () {

    this.setState({ isLoading: true });
    const postData = { "container_id": 53 };

    try {
      const response = await api.post(Globals.api.getModules, postData);
      const { container } = response.data;

      this.setState({
        isLoading: false,
        objectiveTitle: container.user_module_data.objectiveTitle,
        items: container.user_module_data.steps || [],
      });
    } catch (error) {
      this.setState({ alertError: error.response.status })
      setTimeout(() => {
        this.setState({ alertError: '' })
      }, 3000)
    }
  }

  handleClick (item) {
    if (item.action === "calc_indice_sfb") {
      this.setState({ open: true });
    }
  }

  async hadleTable (loading) {

    if (loading !== 'loading') {
      this.setState({ loading: true });
    } else {
      this.setState({ loading: false });
    }

    let id = this.props.match.params.shoppingId
    // console.log('Meu id', id)

    try {
      const response = await api.get(Globals.api.getTableRescue);
      // console.log('table', response.data)

      if (id === '460') {
        this.setState({
          title: 'Esmeralda',
          color: "green",
          loading: false,
          table: response.data.filter((i) => i.tabela.id === 460)
        })
      }
      else if (id === '447') {
        this.setState({
          title: 'Safira',
          color: "blue",
          loading: false,
          table: response.data.filter((i) => i.tabela.id === 447)
        })
      }
      else if (id === '471') {
        this.setState({
          title: 'Rubi',
          color: "red",
          loading: false,
          table: response.data.filter((i) => i.tabela.id === 471)
        })
      }
      else if (id === '446') {
        this.setState({
          title: '',
          color: "darkBlue",
          loading: false,
          table: response.data.filter((i) => i.tabela.id === 446)
        })
      }
      else {
        this.setState({
          title: 'Esmeralda',
          color: "green",
          loading: false,
          table: response.data.filter((i) => i.tabela.id === 460)
        })
      }
    } catch (error) {
      this.setState({ alertError: error.response.status })
      setTimeout(() => {
        this.setState({ alertError: '' })
      }, 3000)
    }
  }
  updateTable = () => {
    this.hadleTable('loading')
  }

  async loadData () {
    this.setState({ nextIndexAssessmentDateText: await getNextIndexAssessmentDateText() });
  }

  componentDidMount () {
    this.setState({ userId: this.props.user.profile.id });
    this.setState({ indiceData: QuestionarioIndice });
    this.loadIndiceData();
    this.loadDataQuestIndice();
    this.hadleTable();
    this.loadData();
  }

  render () {
    return (
      <>

        {/* <Pusher
          channel={"notifications." + this.state.userId}
          event="buyItemChanged"
          onUpdate={(e) => this.updateTable()}
        /> */}

        <Grid container>
          <AlertErrors error={this.state.alertError} />

          <Helmet>
            <title>Loja | Meu bolso em dia</title>
          </Helmet>
          <Grid item xs={12} sm={8} md={9} className="classContainer">

            <div className="open_containerCorrency"  >
              <CurrencyBalance text="Utilize pontos para desbloquear vouchers, ferramentas, produtos ou conteúdos." />
            </div>


            {this.state.loading ?

              <Grid style={{ backgroundColor: '#fff' }}>
                <div style={{ fontSize: '28px', fontWeight: '500', marginLeft: '20px', paddingTop: '25px' }}>
                  Tabela de Resgate
                </div>
                <Box pt={0.5} style={{ display: "flex", padding: '20px', backgroundColor: '#fff', justifyContent: 'center', marginTop: '23px' }}>
                  <Skeleton variant="rect" width={152} height={180} style={{ marginLeft: "10px" }} />
                  <Skeleton variant="rect" width={152} height={180} style={{ marginLeft: "10px" }} />
                  <Skeleton variant="rect" width={152} height={180} style={{ marginLeft: "10px" }} />
                  <Skeleton variant="rect" width={152} height={180} style={{ marginLeft: "10px" }} />
                  <Skeleton variant="rect" width={152} height={180} style={{ marginLeft: "10px" }} />
                  <Skeleton variant="rect" width={152} height={180} style={{ marginLeft: "10px" }} />
                  <Skeleton variant="rect" width={152} height={180} style={{ marginLeft: "10px" }} />
                </Box>
              </Grid>
              :
              <>
                <Grid container >
                  <FeedItem expandHeight>
                    <OpenTable color={this.state.color} title={`Tabela de Resgate ${this.state.title}`} children={this.state.table[0]?.children} />
                    <div className="containerBtn">
                      <Btn to={Globals.paths.closedShopping.replace(":shoppingId", "460")} width="130px">VER MENOS</Btn>
                    </div>
                  </FeedItem>
                </Grid>
              </>}
          </Grid>

          <Hidden xsDown>
            <Grid item container sm={4} md={3} direction="column">

              <div className="home-saude-financeira">
                <ToolbarItem tooltip={this.state.nextIndexAssessmentDateText}
                  title={
                    <div className="positionTitle">
                      <span>Índice de Saúde Financeira</span>
                    </div>
                  } color="#45AF98">
                  {this.state.isLoading ? <CircularProgress style={{ marginLeft: '43%' }} /> :
                    <>
                      {
                        this.props.user.profile.isfb === -1 ?
                          <div className="indiceContainer">
                            <span>Descubra qual o seu Índice de Saúde Financeira.</span>
                            {this.state.items.map((item, index) => (
                              <div key={index} >
                                {item.action === "calc_indice_sfb" &&
                                  <Button type={"green"} onClick={() => this.handleClick(item)}>Calcule agora</Button>
                                }
                              </div>
                            ))}
                          </div> :
                          <div className="positionGauge">
                            <Gauge value={this.props.user.profile.isfb} />
                            <span>Esse é o seu Índice de Saúde Financeira atual</span>
                          </div>
                      }
                    </>
                  }
                </ToolbarItem>
              </div>

              <ToolbarItem title="Seu Nível" color="#45AF98">
                <CurrentLevel />
              </ToolbarItem>
            </Grid>
          </Hidden>
          {this.state.open &&
            <InitialModalQuiz onClose={this.closeModal} open={this.state.open} quizData={QuestionarioIndice} />
          }
        </Grid >
      </>

    )
  }

}
export default withRouter(watch((openShopping), { user: '' }));
