import React from "react";
import App from "./App";
import * as Sentry from '@sentry/react';
import { reduxSetup } from 'redux-easy';
import './redux/reducers';

Sentry.init({ dsn: "https://eed76fde2c7c44ca84afae7679849471@o328906.ingest.sentry.io/6343337" });

const initialState = {
  user: {
    profile: {
      user_avatar: '',
    }
  },
  courses: { selectedCourse: {} },
  tour: { show: false },
  isfb: 0,
};

// The specified component is rendered in the element with
// id "root" unless the "target" option is specified.
reduxSetup({ component: <App />, initialState });

