import styled from 'styled-components';

export const Button = styled.button`
  background-color: #004178;
  border: none;
  padding: 12px 25px;
  color: #fff;
  text-transform: uppercase;
  font-family: 'Caros Soft Bold';
  font-size: 14px;
  font-weight: 800;
  cursor: pointer;
  border-radius: 3px;

  &:hover {
    background-color: #012b4e;
  }
`;