import React from 'react';
import './styles/lastTrophy.scss';

import { Grid, Box } from '@material-ui/core';

import AwardIcon from '../../../assets/icon/new-icons/trofeu.svg';
class Profile extends React.Component {
  render () {
    const { quantity } = this.props;
    return (
      <>
        <Grid item xs={8}>
          <Grid container className="last_trophy_pL">
            <div className="last_trophy_container">
              {quantity !== 0 ?
                <div className="last_trophy_text">
                  <span className="last_trophy_txtTitle"> {quantity}</span>
                  <span className="last_trophy_msg">Essa é a minha quantidade de conquistas na plataforma.</span>
                </div>
                : <span style={{ fontSize: "16px", color: "#ccc" }}>
                  Você ainda não recebeu nenhum troféu.
                  </span>
              }
            </div>
          </Grid>
        </Grid>
        <Grid item xs={4}>
          <Grid container>
            <Box>
              <img className="last_trophy_icon_study" alt="study" src={AwardIcon}></img>
            </Box>
          </Grid>
        </Grid>
      </>
    )
  }
}
export default Profile;