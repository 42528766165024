import { Hidden } from '@material-ui/core';
import React from 'react';
import SwipeableViews from 'react-swipeable-views';
import IconCertificate from '../../../assets/icon/new-icons/certificado.svg';
import Globals from '../../../Globals.json';
import api from '../../../services/api';
import AlertErrors from '../../components/alertErrors/alerts';
import Pagination from '../../components/pagination/Pagination';
import ExpandCertificateImg from './ExpandCertificateImg';
import './styles/achivementsList.scss';

class AchivementsList extends React.Component {
  state = {
    certificates: [],
    expandedCertificateImg: false,
    index: 0,
    nameCertificate: '',
    description: '',
    imgExpanded: '',
    courseId: null,
    alertError: '',
  };

  handleChangeIndex = (index) => {
    this.setState({
      index,
    });
  };

  loadData = () => {
    try {
      api.get(Globals.api.userCertificate).then((response) => {
        this.setState({ certificates: response.data });
      });
    } catch (error) {
      this.setState({ alertError: error.response.status });
      setTimeout(() => {
        this.setState({ alertError: '' });
      }, 3000);
    }
  };

  componentDidMount() {
    this.loadData();
  }

  expandImg = (nameCertificate, description, id, imgExpanded) => {
    if (id) {
      this.setState({ courseId: id });
    }
    this.setState({ expandedCertificateImg: true });
    this.setState({ nameCertificate: nameCertificate });
    this.setState({ description: description });
    this.setState({ imgExpanded: imgExpanded });
  };

  render() {
    const trophies = this.props.trophies;
    const certificates = this.props.certificates;
    const { index } = this.state;

    return (
      <>
        {this.state.expandedCertificateImg && (
          <ExpandCertificateImg
            open={this.state.expandedCertificateImg}
            nameCertificate={this.state.nameCertificate}
            description={this.state.description}
            imgExpanded={this.state.imgExpanded}
            courseId={this.state.courseId}
            onClose={() => this.setState({ expandedCertificateImg: false })}
          />
        )}
        <AlertErrors error={this.state.alertError} />

        <Hidden xsDown>
          {trophies && (
            <>
              {trophies.length > 0 ? (
                trophies.map((item, index) => (
                  <div key={index} className="achivement_list_card">
                    <div className="achivement_list_cardPerfil">
                      <img
                        src={item.DataAssetsDetail.image}
                        width="130px"
                        height="130px"
                        alt="img"
                      />
                      <div className="achivement_list_imageShadow"></div>
                    </div>
                    <span className="achivement_list_titleShadow">
                      {item.DataAssetsDetail.name}
                    </span>
                    <span className="achivement_list_subTextShadow">
                      {item.DataAssetsDetail.description}
                    </span>
                  </div>
                ))
              ) : (
                <span className="achivement_list_msg">Você ainda não possuí nenhum troféu...</span>
              )}
            </>
          )}

          {certificates && (
            <>
              {this.state.certificates.length > 0 ? (
                this.state.certificates.map((item, index) => (
                  <div
                    key={index}
                    className="achivement_list_cardCertificate"
                    onClick={() =>
                      this.expandImg(
                        item.DataModulesDetails.name,
                        item.DataModulesDetails.description,
                        item.DataModulesDetails.RefModulesDMD[0].container_id,
                        item.DataModulesDetails.data.image
                      )
                    }
                  >
                    <div className="achivement_list_cardPerfil">
                      <img src={IconCertificate} width="100%" height="100%" alt="img" />
                      <div className="achivement_list_imageShadowCertificate"></div>
                    </div>
                    <span className="achivement_list_titleShadow">
                      {item.DataModulesDetails.name}
                    </span>
                    <span className="achivement_list_subTextShadow">
                      {/* {item.DataAssetsDetail.description} */}
                    </span>
                  </div>
                ))
              ) : (
                <span className="achivement_list_msg">
                  Você ainda não possuí nenhum certificado...
                </span>
              )}
            </>
          )}
        </Hidden>

        <Hidden smUp>
          {trophies && (
            <>
              <SwipeableViews
                index={index}
                onChangeIndex={this.handleChangeIndex}
                style={{ overflow: 'hidden' }}
              >
                {trophies.length > 0 ? (
                  trophies.map((item, index) => (
                    <div key={index} className="achivement_list_card">
                      <div className="achivement_list_cardPerfil">
                        <img
                          src={item.DataAssetsDetail.image}
                          width="100%"
                          height="100%"
                          alt="img"
                        />
                        <div className="achivement_list_imageShadow"></div>
                      </div>
                      <span className="achivement_list_titleShadow">
                        {item.DataAssetsDetail.name}
                      </span>
                      <span className="achivement_list_subTextShadow">
                        {item.DataAssetsDetail.description}
                      </span>
                    </div>
                  ))
                ) : (
                  <span className="achivement_list_msg">
                    Você ainda não possuí nenhum troféu...
                  </span>
                )}
              </SwipeableViews>
              <Pagination
                dots={trophies.length}
                index={index}
                onChangeIndex={this.handleChangeIndex}
              />
            </>
          )}

          {certificates && (
            <>
              <SwipeableViews
                index={index}
                onChangeIndex={this.handleChangeIndex}
                style={{ overflow: 'hidden', width: '100%', maxWidth: '150px' }}
              >
                {this.state.certificates.length > 0 ? (
                  this.state.certificates.map((item, index) => (
                    <div
                      key={index}
                      className="achivement_list_cardCertificate"
                      onClick={() =>
                        this.expandImg(
                          item.DataModulesDetails.name,
                          item.DataModulesDetails.description,
                          item.DataModulesDetails.RefModulesDMD[0].container_id,
                          item.DataModulesDetails.data.image
                        )
                      }
                    >
                      <div className="achivement_list_cardPerfil">
                        <img src={IconCertificate} width="100%" height="100%" alt="img" />
                        <div className="achivement_list_imageShadow"></div>
                      </div>
                      <span className="achivement_list_titleShadow">
                        {item.DataModulesDetails.name}
                      </span>
                    </div>
                  ))
                ) : (
                  <span className="achivement_list_msg">
                    Você ainda não possuí nenhum certificado...
                  </span>
                )}
              </SwipeableViews>

              <Pagination
                dots={this.state.certificates.length}
                index={index}
                onChangeIndex={this.handleChangeIndex}
              />
            </>
          )}
        </Hidden>
      </>
    );
  }
}

export default AchivementsList;
