import { Dialog, DialogContent } from '@material-ui/core';
import React, { Component } from 'react'
import './styles/deleteUserModal.scss'

import Close from '../../../../assets/user/excluir_usuario.svg'

import Button from '../../button/Button'

import api from '../../../../services/api';
import Globals from '../../../../Globals.json';

export class DeleteUserModal extends Component {
  state = {
    success: false,
  }

  handleDeleteUser = () => {
    const userId = this.props.userId
    api.delete(Globals.api.usersPage + `/${userId}`)
      .then(response => {
        console.log(response.data)
        this.setState({ success: true });
      }).catch(error => {
        this.setState({ failed: true });
        console.log(error)
      })
  }

  render() {
    return (
      <Dialog
        open={this.props.open}
        onClose={this.props.onClose}
        maxWidth={"xs"}
        className="modal"
      >
        <DialogContent className="modal_delete_user_container">

          <div className="modal_delete_user_header">
            <button type="button" className="close"
              onClick={this.props.onClose}>&times;
             </button>
          </div>

          <div className="modal_delete_user_close">
            <img src={Close} alt="Excluir" />
          </div>

          <section>
            <span>{this.state.success === false ?
              "Você tem certeza que quer excluir este usuário permanentemente?" :
              "Enviado"}
            </span>
            {/* <p>{this.state.success === false ?
              "Ao confirmar um link de verificação será enviado no e-mail do usuário cadastrado." :
              "Um link de exclusão foi enviado ao email cadastrado do usuário."
            } */}
            <p>{this.state.success === false ?
              "Ao confirmar, o usuário será excluído permanentemente da plataforma. Essa ação não pode ser desfeita. Tem certeza que deseja continuar?" :
              "O usuário foi excluído permanentemente da plataforma."
            }
            </p>
          </section>

          {this.state.success === false ?
            <Button icon='none' type="danger" onClick={() => this.handleDeleteUser()} >CONFIRMAR</Button> :
            <Button to={Globals.paths.backofficeUsers} icon='none' type="danger">VOLTAR</Button>
          }
        </DialogContent>
      </Dialog>
    )
  }
}

export default DeleteUserModal;