import React from 'react';
import './help.scss';

import { Helmet } from "react-helmet";
import { Link } from 'react-router-dom';
import { withStyles, Breadcrumbs, Typography, Grid, Hidden } from '@material-ui/core';

import CurrentStudy from '../../components/sidebar/CurrentStudy';
import FeedItem from '../../components/feed/FeedItem'
import HelpActions from '../../components/help/HelpActions';
import HelpCommom from '../../components/help/HelpCommom';
import ToolbarItem from '../../components/toolbar/ToolbarItem';

const Globals = require("../../../Globals.json");

const styles = theme => ({
  helpItem:
  {
    minHeight: "300px",
    backgroundColor: "#FFF",
    marginBottom: "10px",
    boxShadow: "4px 4px 10px #0003",
    padding: "20px",
    paddingTop: "25px",
  },
  helpTitleBar:
  {
    color: "#2E2E2E",
    fontSize: "28px",
    fontWeight: "bold",
    marginLeft: "20px",
    paddingBottom: "20px",

    [theme.breakpoints.down('xs')]: {
      fontSize: "18px",
    }
  },
  helpContainer:
  {
    width: "100%",
    paddingRight: "10px",
    marginBottom: "60px",

    [theme.breakpoints.down('xs')]: {
      paddingRight: "10px",
    },
  },
  BreadCrumbLink: {
    color: "#fff",
    textTransform: "uppercase",
    textDecoration: "none",
    fontSize: "10pt",

    "@media (max-width: 436px)": {
      fontSize: "11px",
    }
  },
  BreadCrumb:
  {
    color: "#fff",
    marginBottom: "20px",
    fontWeight: "bold",

    [theme.breakpoints.down('xs')]: {
      marginBottom: "10px",
      marginTop: "-14px"
    }
  },
  BreadCrumbLabel:
  {
    color: "#fff",
    textTransform: "uppercase",
    fontSize: "10pt",
  },
  helpSearchInput: {
    float: "right",
    height: "55px",
    width: "40%",
    padding: "0px 10px 0px 10px",
    fontSize: "20px",

    [theme.breakpoints.down('xs')]: {
      marginTop: '10px',
      marginRight: '20px',
      width: '100%',
      height: '35px',
      fontSize: '18px',
    }
  },

  helpContainerHidden: {
    display: "flex",
    flexDirection: "row",
  },

  helpContentHidden: {
    display: "flex",
    borderShadow: "5px 5px 5px #0002",
    maxWidth: "280px",
    minWidth: "280px",
    maxHeight: "170px",
    minHeight: "170px",
    border: "1px solid #0001",
    boxShadow: "5px 5px 4px #0003",
    marginBottom: "15px",
    marginLeft: "20px",

    "&:nth-child(1)": {
      marginLeft: "0px",
    }
  },

  helpContentHiddenQuestions: {
    display: "flex",
    borderShadow: "5px 5px 5px #0002",
    maxWidth: "280px",
    minWidth: "280px",
    maxHeight: "170px",
    minHeight: "170px",
    border: "1px solid #0001",
    boxShadow: "5px 5px 4px #0003",
    marginBottom: "15px",
    marginLeft: "20px",
    overflowX: "auto",
    overflowY: "hidden",
    alignItems: "center",

    "&:nth-child(1)": {
      marginLeft: "0px",
    }
  },

});

class Help extends React.Component {

  state = {
    activePagination: 0,
    activeBtn: 0
  }

  render() {

    return (
      <>
        <Breadcrumbs aria-label="breadcrumb" className="help_breadCrumb">
          <Link color="inherit" to={Globals.paths.courses} className="help_breadCrumbLink">
            Ajuda
            </Link>
          <Typography color="textPrimary" className="help_breadCrumbLabel">{this.state.courseName} </Typography>
        </Breadcrumbs>

        <Helmet>
          <title>Ajuda | Meu bolso em dia</title>
        </Helmet>

        <Grid container >
          <Grid item xs={12} sm={8} md={9} className="helpContainer">

            <Hidden smUp>

              <Grid item xs={12} className="feedLine">
                <FeedItem>
                  <div className="helpContainerHidden">
                    <div className="helpContentHidden">
                      <ToolbarItem color="#013670">
                        <CurrentStudy changeBox={true} description={`Ainda tem dúvidas? Envie sua pergunta para nós.`}
                          btnText={"Perguntar"} />
                      </ToolbarItem>
                    </div>

                    <div className="helpContentHiddenQuestions">
                      <HelpCommom description={"Usando as ferramentas"} />
                      <HelpCommom description={"Gerenciamento da conta"} />
                      <HelpCommom description={"Políticas de privacidade"} />
                      <HelpCommom description={"Solucionar um problema"} />
                      <HelpCommom description={"Vincular contas"} />
                      <HelpCommom description={"Entendendo a pontuação"} />
                      <HelpCommom description={"Certificado e validações"} />
                      <HelpCommom description={"Validade da pontuação"} />
                    </div>

                  </div>
                </FeedItem>
              </Grid>

            </Hidden>

            <Grid item className="helpItem">
              <Grid item xs={12} className="helpTitleBar">
                Central de Ajuda
                <input placeholder={"buscar"} className="helpSearchInput" />
              </Grid>
              <HelpActions />

            </Grid >
          </Grid>


          <Hidden xsDown>
            <Grid item sm={4} md={3} direction="column">
              <ToolbarItem title="Evie sua pergunta" color="#013670">
                <CurrentStudy description={`Ainda tem dúvidas? Envie sua pergunta para nós.`}
                  btnText={"Perguntar"} ></CurrentStudy>
              </ToolbarItem>

              <ToolbarItem title="Perguntas frequentes" color="#013670">
                <HelpCommom description={"Usando as ferramentas"} />
                {/* <HelpCommom description={"Gerenciamento da conta"} />
                <HelpCommom description={"Políticas de privacidade"} />
                <HelpCommom description={"Solucionar um problema"} />
                <HelpCommom description={"Vincular contas"} />
                <HelpCommom description={"Entendendo a pontuação"} />
                <HelpCommom description={"Certificado e validações"} />
                <HelpCommom description={"Validade da pontuação"} /> */}
              </ToolbarItem>
            </Grid>
          </Hidden>

        </Grid >
      </>

    )
  }

}

export default withStyles(styles)(Help);

