import React, { Component } from 'react';
import { CircularProgress, Dialog, Grid } from '@material-ui/core';
import { watch, dispatch } from 'redux-easy/lib/redux-easy';
import { withRouter } from 'react-router-dom';
import { Helmet } from "react-helmet";
import './welcome.scss';
// import Globals from '../../../Globals.json';
import TenantShop from '../TenantShop/TenantShop';
// import IndexForm from './index-form/IndexForm';
// import { enumTypeUserStatuses } from '../../../../services/enums';
// import IndexDetail from './index-detail/IndexDetail';
// import ProfileIndex from './profile-index/ProfileIndex';
// import PersonaList from './persona-list/PersonaList';
// import PersonaDetail from './persona-detail/PersonaDetail';

class IncentiveModal extends Component {


    state = {
        started: false,
        open: false,
        startIndex: 0,
        isLoading: true,
        disableBackdropClick: true,
        disableEscapeKeyDown: true,
        currentPage: '',
        //previousPage: '',
        canStartIndex: false,
        history: [],
        data: null,
    }

    startIndex = () => {
        // const profile = this.props.user.profile;
        // if (profile.type_users_status_id === enumTypeUserStatuses.firstIndexMustBeFilled || profile.canAnswerIndex) {
        //     this.setState({ currentPage: 'index-form' });
        // }
    }

    componentDidUpdated(prevProps) {
        if (prevProps !== this.props) {
            this.loadData()
        }

    }
    componentDidMount = () => {

        if (this.props.open) {
            this.handleClickOpen();
        }
        console.log(this.props)
        this.init();
    }


    init = () => {

        this.setState({ isLoading: true });
        //this.setState({ currentPage: 'profile-index', canStartIndex: false });

        // if (this.props.user && this.props.user.profile) {
        //     if (this.props.user.profile.type_users_status_id === enumTypeUserStatuses.firstIndexMustBeFilled) {
        //         this.setState({ currentPage: 'welcome', canStartIndex: true });
        //     }
        //     else {
        //         this.setState({ currentPage: 'profile-index' });
        //     }

        // }

        this.setState({ isLoading: false });

    }
    loadData() {

        if (this.props.user) {
            this.setState({ isLoading: true });


            this.setState({

                coins: this.props.user.profile.progress.total_moedas,
                isLoading: false,

            });


        } else {
            dispatch('updateUserProfile');
        }
    }

        handleClose = () => {
            this.props.closeIncentiveModal();
        };

        handleClickOpen = () => {
            this.setState({ open: true });
        };

        showContent = (config) => {
            const history = this.state.history;
            if (config.data) {
                this.setState({ currentPage: config.to, history, data: config.data }, () => {
                    history.push(config.from);
                });
            }
            else {
                history.push(config.from);
                this.setState({ currentPage: config.to, history });
            }


        }

        goBack = (reset = false) => {
            if (reset) {
                this.setState({ currentPage: 'profile-index', history: [] });
            }
            else {
                const history = this.state.history;
                const last = history.pop();
                this.setState({ currentPage: last, history });
            }
        }

        setCloseConfig = (cantClose) => {
            this.setState({
                disableBackdropClick: cantClose,
                disableEscapeKeyDown: cantClose
            });
        }

        disableIndex = () => {
            this.setState({ canStartIndex: false });
        }


        render() {

            let html = (
                <Grid item xs={12} className="loading">
                    <div className="loading">
                        <CircularProgress />
                    </div>
                </Grid>
            );

            if (!this.state.isLoading) {

                html = html = <TenantShop {...this.props} handleClose={this.handleClose} showContent={this.showContent} startIndex={this.startIndex} />

            } else {
                html = <CircularProgress />
            }

            return (
                <div>
                    <Helmet>
                        <title>Bem vindo | Meu bolso em dia</title>
                    </Helmet>
                    <Dialog onClose={this.handleClose} disableBackdropClick={this.state.disableBackdropClick} disableEscapeKeyDown={this.state.disableEscapeKeyDown} maxWidth={'md'} className="incentive-dialog" open={this.state.open}>
                        {html}
                    </Dialog>
                </div>
            )
        }
    }


    export default watch(withRouter(IncentiveModal), { user: '' });