import React, { Component } from 'react';
import api from '../../../services/api';
// import Button from '../button/Button';

import { Button } from '@material-ui/core';

import AlertErrors from '../../components/alertErrors/alerts';
import './openTable.scss';
import Globals from '../../../Globals.json';
class openTable extends Component {

  state = {
    id: "",
    open: false,
    produto: "",
    idProduct: "",
    thumbnail: "",
    levelItem: 0,
    message: "",
    msgId: 0,
    value: 0,
    alertError: '',
    btnModal: true
  }

  handleClick = (id, windowState) => {
    if (this.state.id === id && windowState === 'closed') {
      this.setState({ id: "", open: false })
      // this.clearState();
    }
    else if (windowState === 'open') {
      this.setState({ id: id, open: false })
      // this.clearState();
    }
  }

  handlePurchase = (thumbnail, id, name, value, levelItem, windowState) => {
    this.handleClick(id, windowState)
    this.setState({ open: true, produto: name, idProduct: id, thumbnail: thumbnail, value: value, levelItem: levelItem, message: "", btnModal: true})
    // console.log('Produto: ', name, '/id: ', id, 'value', value, 'Lvl: ' , levelItem)
  }

  async checkOut() {
    try {
      const postData = { "data_module_detail_id": this.state.idProduct };
      await api.post(Globals.api.buyStoreItem, postData);
      this.setState({ message: 'Compra realizada com sucesso!', msgId: 0, btnModal: false })
    }
    catch (err) {
      this.setState({ message: err.response.data.message, msgId: 1 })
      setTimeout(() => {
        this.setState({ alertError: '' })
      }, 3000)
    }
  }

  clearState = () => {
    this.setState({
      message: "",
    })
  }

  render() {
    const { color, title } = this.props;

    return (
      <>
        <AlertErrors error={this.state.alertError} />
        <div className="txtTitle">{title}</div>
        <div className="cardContainer">
          {this.props.children && this.props.children.map((item, index) => (
            <div key={index} className="open_table_fragment">
              {item.id === this.state.id ?
                <div className="containerExpand">
                  <div className={`card ${item.usuario_pode_acessar && color}`}>
                    <div className={`card3 ${item.usuario_pode_acessar && color}`} onClick={() => this.handleClick(item.id, 'closed')} >
                      <div className={`perfil ${item.usuario_pode_acessar && color}`}>
                        <img src={item.thumbnail} alt="Imagem Item" width="100%" height="100%" />
                      </div>
                      <span className="titleTableExpaned">{item.name}</span>
                      <span className="coins">
                        {item.data.custo ? `${item.data.custo} PONTOS` : `Level ${item.data.level}`}
                      </span>
                      <span className="description">{item.description}</span>
                    </div>
                    {item.usuario_pode_acessar ?
                      item.item_comprado_pelo_user ?
                        <span className={`txtReward2 && ${color}`}>RESGATADO</span> :
                        <span className={`txtRewardExpanded && ${color}`} onClick={() => this.handlePurchase(item.thumbnail, item.id, item.name, item.data.custo, item.data.level, 'closed')} >RESGATAR</span> :
                      <span className={`subTextExpanded ${item.usuario_pode_acessar && color}`}>Level {item.data.level}</span>
                    }
                  </div>
                </div> :

                item.id === !this.state.id ?
                  <div key={index} className={`card ${item.usuario_pode_acessar && color}`}>
                    <div onClick={() => this.handleClick(item.id, 'open')} className={`card2 ${item.usuario_pode_acessar && color}`} >
                      <div className={`perfil ${item.usuario_pode_acessar && color}`}>
                        <img src={item.thumbnail} alt="Imagem Item" width="100%" height="100%" />
                      </div>
                      <span className="titleTable">{item.name}</span>
                      <span className="coins">
                        {item.data.custo ? `${item.data.custo} PONTOS` : `Level ${item.data.level}`}
                      </span>
                    </div>
                    {item.usuario_pode_acessar ?
                      item.item_comprado_pelo_user ?
                        <span className={`txtReward2`}>RESGATADO</span> :
                        <span className={`txtReward && ${color}`} onClick={() => this.handlePurchase(item.thumbnail, item.id, item.name, item.data.custo, item.data.level, 'closed')}>RESGATAR</span> :
                      <span className={`subText ${item.usuario_pode_acessar && color}`}>Level {item.data.level}</span>
                    }
                  </div> :

                  this.state.open && this.state.idProduct === item.id ?
                    <div className="containerExpand">
                      <div className={`card ${item.usuario_pode_acessar && color && `white ${color}`}`}>
                        <div className={`card3 ${item.usuario_pode_acessar && 'white'}`} onClick={() => this.setState({ open: false })} >
                          <div className={`perfil ${item.usuario_pode_acessar && color}`}>
                            <img src={item.thumbnail} alt="Imagem Item" width="100%" height="100%" />
                          </div>
                          <span className="description2">Você deseja resgatar <b>{item.name}</b> por {item.data.custo} pontos?</span>
                        </div>
                        <div className="cardMessage">
                          {this.state.msgId === 0 ?
                            <div className="messagePurchase">{this.state.message} </div> :
                            <div className="messageError">{this.state.message}. </div>}
                        </div>
                        <div className="containerBtn">
                          {this.state.btnModal ? 
                            <>
                              <Button className="btn cancelar" onClick={() => this.setState({ open: false, message: "" })}>Cancelar</Button>
                              <Button className="btn" onClick={() => this.checkOut()}>Resgatar</Button>
                            </> :
                            <div className="containerBtnCenter" >
                              <Button className="btn cancelar" onClick={() => this.setState({ open: false, message: "", btnModal: true })}>Voltar</Button>  
                            </div>
                            }
                        </div>
                      </div>
                    </div> :

                    <div key={index} className={`card ${item.usuario_pode_acessar && color}`}>
                      <div onClick={() => this.handleClick(item.id, 'open')} className={`card2 ${item.usuario_pode_acessar && color}`} >
                        <div className={`perfil ${item.usuario_pode_acessar && color}`}>
                          <img src={item.thumbnail} alt="Imagem Item" width="100%" height="100%" />
                        </div>
                        <span className="titleTable">{item.name}</span>
                        <span className="coins">
                          {item.data.custo ? `${item.data.custo} Pontos` : `Level ${item.data.level}`}
                        </span>
                      </div>
                      {item.usuario_pode_acessar ?
                        item.item_comprado_pelo_user ?
                          <span className={`txtReward2`}>RESGATADO</span> :
                          <span className={`txtReward && ${color}`} onClick={() => this.handlePurchase(item.thumbnail, item.id, item.name, item.data.custo, item.data.level, 'closed')}>RESGATAR</span> :
                        <span className={`subText ${item.usuario_pode_acessar && color}`}>Level {item.data.level}</span>
                      }
                    </div>}
            </div>
          ))}
        </div>
      </>
    )
  }
}
export default openTable;
