import React from 'react';
import { useMediaQuery } from 'react-responsive';
import Button from '../Button';
import MainText from '../MainText';
import * as Styled from './Hero.styles';

function Hero({ openRegisterDialog }) {
  const isIpadPro = useMediaQuery({ minWidth: 960, maxWidth: 1024 });
  
  return (
    <Styled.Hero>
      <div className="inner-wrapper">
        <figure className="featured">
          <img className="featured__image" src="./images/hero/featured.png" alt="Inicie sua jornada" />
          <img className="featured__shield featured__shield--super-small" src="./images/hero/shield.png" alt="" />
          <img className="featured__shield featured__shield--small" src="./images/hero/shield.png" alt="" />
          <img className="featured__shield featured__shield--regular" src="./images/hero/shield.png" alt="" />
          <img className="featured__shield featured__shield--big" src="./images/hero/shield.png" alt="" />
          <Styled.Logo variant="left" />
          <Styled.Logo variant="right" />
        </figure>
        <MainText size={isIpadPro ? 300 : 420}>
          <h2>Você no controle da sua vida financeira.</h2>
          <p>
            Aprenda gratuitamente como se organizar, sair das dívidas e juntar dinheiro para viver com mais
            tranquilidade.
          </p>
          <Button onAction={openRegisterDialog}>Comece já</Button>
        </MainText>
      </div>
    </Styled.Hero>
  );
}

export default Hero;
