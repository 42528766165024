import React from 'react';
import './styles/helpCommom.scss';

import { Link } from 'react-router-dom';

class HelpCommom extends React.Component {
  render() {
    return (
      <>
        <Link to={"/ajuda"} className="commom_containerQuests" >
          <span className="commom_description">{this.props.description}</span>
        </Link>
      </>
    )
  }

}

export default HelpCommom;

