import React, { useState, useRef, Fragment } from "react";

import PropTypes from "prop-types";
import classNames from "classnames";
import { withRouter } from "react-router-dom";
import {
  TextField,
  Button,
  Checkbox,
  Typography,
  FormControlLabel,
  withStyles,
} from "@material-ui/core";
import { dispatchSet, watch } from 'redux-easy/lib/redux-easy';

//import AlertErrors from '../../../logged_in/components/alertErrors/alerts';
import FormDialog from "../../../shared/components/FormDialog";
import ButtonCircularProgress from "../../../shared/components/ButtonCircularProgress";
import VisibilityPasswordTextField from "../../../shared/components/VisibilityPasswordTextField";
import api from "../../../services/api";
import { isAuthenticated, login, getStatus } from "../../../services/auth";
import { enumTypeUserStatuses } from "../../../services/enums";
import { Alert } from "@material-ui/lab";

let email = null;
let password = null;

const styles = (theme) => ({
  forgotPassword: {
    marginTop: theme.spacing(2),
    color: theme.palette.primary.main,
    cursor: "pointer",
    "&:enabled:hover": {
      color: theme.palette.primary.dark,
    },
    "&:enabled:focus": {
      color: theme.palette.primary.dark,
    },
  },
  disabledText: {
    cursor: "auto",
    color: theme.palette.text.disabled,
  },
  formControlLabel: {
    marginRight: 0,
  },
  message: {
    textAlign: "center",
    marginBottom: "5px",
  }
});

const Globals = require("../../../Globals.json");

function LoginDialog(props) {
  const {
    setStatus,
    message,
    history,
    classes,
    onClose,
    openChangePasswordDialog,
    status,
    emailVerified
    // user,
  } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const loginEmail = useRef();
  const loginPassword = useRef();
  const [fieldsValidation, setFieldsValidation] = useState({});

  const handleSignIn = async e => {
    setIsLoading(true)
    if (!email || !password) {
      this.setState({ error: "Preencha e-mail e senha para continuar!" });
    } else {
      try {
        const response = await api.post(Globals.api.login, { email, password });
        login(response.data.data.token);
        loadData()
      } catch (err) {

        const fieldsValidation = {};

        err.response.data.forEach(error => {
          fieldsValidation[error.field] = error.message;
        });

        setIsLoading(false);
        setFieldsValidation(fieldsValidation);
        setTimeout(() => {
          setFieldsValidation({})
        }, 3500)
      }
    }
  };

  const showLoading = () => {
    setIsLoading(true)
  }

  const hideLoading = () => {
    setIsLoading(false)
  }

  const loadData = async () => {

    if (isAuthenticated()) {


      const response = await api.get(Globals.api.selfInfo);
      dispatchSet("user.profile", response.data);

      const status = response.data.type_users_status_id;

      switch (status) {
        case enumTypeUserStatuses.active: {
          history.push(Globals.paths.home);
          break;
        }
        case enumTypeUserStatuses.firstIndexMustBeFilled: {
          history.push(Globals.paths.home);
          break;
        }
        case enumTypeUserStatuses.forwardToBlog: {
          history.push(Globals.paths.forwardToBlog);
          break;
        }
        case enumTypeUserStatuses.notVerified: {
          history.push(Globals.paths.confirmEmail);
          break;
        }
        case enumTypeUserStatuses.validatingIndebtProfile: {
          history.push(Globals.paths.validatingIndebtProfile);
          break;
        }
        default: {
          break;
        }
      }

    }

    setIsLoading(false);
  }

  return (
    <Fragment>
      {/* <AlertErrors error={alertError} /> */}
      <FormDialog
        open
        onClose={onClose}
        loading={isLoading}
        onFormSubmit={(e) => {
          e.preventDefault();
          handleSignIn();
        }}
        hideBackdrop
        headline="Login"
        content={
          <Fragment>

            {/* {emailVerified && (
              <Alert severity="info">Seu e-mail foi validado com sucesso!</Alert>
            )} */}
            <div className={classes.message}>{message}</div>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              label="Usuário / E-mail"
              inputRef={loginEmail}
              autoFocus
              autoComplete="off"
              type="email"
              onChange={e => email = e.target.value}
              error={!!fieldsValidation.uid || !!fieldsValidation.password || !!fieldsValidation.email}
              helperText={fieldsValidation.uid || fieldsValidation.password || fieldsValidation.email}
              FormHelperTextProps={{ error: true }}
            />
            <VisibilityPasswordTextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              label="Senha"
              inputRef={loginPassword}
              autoComplete="off"
              onChange={e => password = e.target.value}
              error={!!fieldsValidation.uid || !!fieldsValidation.password}
              helperText={fieldsValidation.uid || fieldsValidation.password}
              FormHelperTextProps={{ error: true }}
              onVisibilityChange={setIsPasswordVisible}
              isVisible={isPasswordVisible}
            />

          </Fragment>
        }
        actions={
          <Fragment>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              disabled={isLoading}
              size="large"
            >
              {isLoading ? <ButtonCircularProgress /> : "Entrar"}
            </Button>
            <Typography
              align="center"
              className={classNames(
                classes.forgotPassword,
                isLoading ? classes.disabledText : null
              )}
              color="primary"
              onClick={isLoading ? null : openChangePasswordDialog}
              tabIndex={0}
              role="button"
              onKeyDown={(event) => {
                // For screenreaders listen to space and enter events
                if (
                  (!isLoading && event.keyCode === 13) ||
                  event.keyCode === 32
                ) {
                  openChangePasswordDialog();
                }
              }}
            >
              Esqueceu a Senha?
            </Typography>
          </Fragment>
        }
      />
    </Fragment>
  );
}

LoginDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  setStatus: PropTypes.func.isRequired,
  openChangePasswordDialog: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  status: PropTypes.string,
};

export default withRouter(withStyles(styles)(watch((LoginDialog), { user: '' })));
