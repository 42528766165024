
import React, { Component } from 'react';
import './styles/feed.scss';

class FeedItem extends Component {

  render() {

    const { title, subtitle, overflowHidden, expandHeight } = this.props;

    const customStyle = {};
    customStyle.flexGrow = this.props.grow || 1;
    if (this.props.noPadding) {
      customStyle.padding = "0px";
    }
    if (this.props.morePadding) {
      customStyle.padding = "20px 35px 20px 35px";
    }
    if (this.props.color) {
      customStyle.borderRight = `4px solid ${this.props.color}`
    }

    return (

      <div className={overflowHidden === true ? "feed_item_overflowHidden" : "feed_item_feedItem"} style={customStyle}>
        { (title) &&
          <div className="feed_item_titleBar">
            <div className="feed_item_title">
              {title}
            </div>
            {subtitle &&
              <div className="feed_item_subtitle">
                {subtitle}
              </div>
            }
          </div>
        }
        <div className={expandHeight ? "feed_item_content" : "feed_item_contentFixed"} >
          {this.props.children}
        </div>
      </div>
    )
  }

}

export default FeedItem;

