import React from 'react';

//Componentes
// import api from '../../../services/api';

import { withStyles, LinearProgress, Backdrop, CircularProgress } from '@material-ui/core';

import ArticleList from '../article/ArticleList';
import Button from '../button/Button';
// import ResultQuiz from './resultQuiz';
// import InitialModalQuiz from './InitialModalQuiz';

const styles = theme => ({
  container: {
    display: "flex",
    alignItems: "baseline",
    width: "90%",
    justifyContent: "center",
    flexDirection: "column",
    backgroundColor: "#FFF",
    height: "auto",
    top: "0px",
  },

  contentBtn: {
    width: "100%",
    display: "flex",
    marginTop: "90px",
    justifyContent: "flex-end",
    padding: "0px 50px 0px 50px",

    [theme.breakpoints.down('xs')]: {
      padding: "0px 5px 0px 5px",
      marginTop: "20px",
    }
  },

  header: {
    color: "#FFF",
    width: "100%",
    display: "flex",
    position: "relative",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: "#F86D49",
    height: "70px",
    padding: "10px",
  },

  titleTest: {
    fontWeight: "400",

    [theme.breakpoints.down('xs')]: {
      fontSize: "14px",
    }
  },

  textWin: {
    fontSize: "20px",
    fontWeight: "bold",
    marginRight: "70px",
    textAlign: "center",

    [theme.breakpoints.down('xs')]: {
      fontSize: "14px",
      marginRight: "0px",
      textAlign: "center",
    }
  },

  linearProgress: {
    width: "100% !important",
  },

  contentDescription: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "10px 40px 0px 40px",
    textAlign: "center",
    fontSize: "20px",
    width: "100%",

    [theme.breakpoints.down('xs')]: {
      fontSize: "18px",
    }
  },

  fontContentDescription: {
    fontSize: "20px",

    [theme.breakpoints.down('xs')]: {
      fontSize: "16px",
    }
  },

  containerQuestions: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%"
  },

  contentAsk: {
    width: "90%",
    display: "flex",
    flexDirection: "column",
    overflowX: "auto",
    marginTop: "20px",

    [theme.breakpoints.down('xs')]: {
      width: "95%",
      marginTop: "10px",
    }
  },

  resps: {
    display: "flex",
    alignItems: "center",
    paddingLeft: "5px",
    paddingRight: "5px",
    border: "2px solid #D3D3D3",
    borderRadius: "3px",
    width: "100%",
    height: "50px",
    cursor: "pointer",
    marginTop: "20px",
    fontWeight: "500",
    fontSize: "16px",
  },

  respsActive: {
    display: "flex",
    alignItems: "center",
    paddingLeft: "5px",
    paddingRight: "5px",
    border: "2px solid #80D9BD",
    color: "#80D9BD",
    borderRadius: "3px",
    width: "100%",
    height: "50px",
    cursor: "pointer",
    marginTop: "20px",
    fontWeight: "500",
    fontSize: "16px",
  },
  close: {
    position: "absolute",
    top: "-10px",
    right: "0",
    color: "#FFF",
    background: "none",
    border: "none",
    fontSize: "30px",
    cursor: "pointer",
    zIndex: "9999",
  },

  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
});


class FinalModal extends React.Component {

  state = {
    currentQuestion: null,
    currentIndex: -1,
    isLoading: false,
    selection: [],
    questions: [],
    recomendations: [],
    open: true,
    refazer: false,
    finalMessage: "",
    profile: {}
  }

  async loadData() {

    const response = this.props.dados.data

    this.setState({ isLoading: this.state.isLoading + 1 });

    // const postData = {
    //   "container_id": 19,
    // };

    const profileLS = localStorage.getItem("quizProfile");

    const profile = Object.keys(response).find(p => p === profileLS)

    this.setState({ profile: response[profile]})

    this.setState({
      isLoading: false,
      recomendations: response.recomendations || [],
      finalMessage: response.finalMessage,
    });

    if (this.state.questions.length > 0) {
      this.setState({ currentQuestion: this.state.questions[0], currentIndex: 0 });
    }

  }

  componentDidMount() {
    this.loadData();
  }

  render() {

    const { classes } = this.props;

    return (
      <>
        {this.state.isLoading ?
          <Backdrop className={classes.backdrop} open={this.state.isLoading}>
            <CircularProgress color="inherit" />
          </Backdrop> :
          <div className={classes.container}>
            <div className={classes.header}>
              <h3 className={classes.titleTest}>TESTE DE PERFIL - QUAL O SEU ÍNDICE FINANCEIRO?</h3>
              <h3 className={classes.textWin}>VOCÊ GANHOU 200 PONTOS</h3>
            </div>
            <div className={classes.linearProgress}>
              <LinearProgress {...this.props} className={{ colorPrimary: classes.colorPrimary, barColorPrimary: classes.barColorPrimary }}
                variant="determinate" value={(this.state.currentIndex) / this.state.questions.length * 100} />
            </div>

            {/* <div className={classes.contentDescription}>
              <p>
                {this.state.profile.finalMessage && this.state.profile.finalMessage}
              </p>
              <span className={classes.fontContentDescription}>Veja o material que selecionamos para você:</span>
            </div>

            <div className={classes.containerQuestions} >
              <div className={classes.contentAsk}>
                <ArticleList history={this.props.history} endpoint={`api/v1/recommendation/recommended_module`} payload={{ module_type: "10" }} />
              </div>
            </div> */}

            <div className={classes.contentBtn}>
              {/* <Button className={classes.quizButtonReturn} onClick={this.props.againQuiz} >
                Refazer Teste
                </Button> */}
              <Button minWidth="120px" type="darkBlue" onClick={this.props.closeFinalModal}>
                Finalizar
              </Button>
            </div>
          </div>
        }
        {/* {this.state.endQuestion &&
          <ResultQuiz
            description={"Seus pontos foram somados a sua pontuação geral"}
            coins={"200"}
          />
        } */}
      </>

    )
  }
}
export default withStyles(styles)(FinalModal);