import styled, { css } from 'styled-components';
import { withTheme } from "@material-ui/core/styles";

export const JourneySteps = withTheme(styled('article')`
  align-items: center;
  display: flex;
  height: ${({ imgSize }) => `${imgSize[1]}px`};
  max-width: 1440px;
  margin: auto;
  position: relative;
  
  ${(props) => {
    if (props.inverted) {
      return css`
        flex-direction: row-reverse;
      `;
    }
  }};

  /* MOBILE */
  @media(max-width: ${({ theme }) => `${theme.breakpoints.values.sm}px`}) {
    flex-direction: column-reverse;
    height: initial;
    width: 100vw;
    padding: 0;
  }

  /* TABLET */
  @media only screen and (
    min-width: ${({ theme }) => `${theme.breakpoints.values.sm}px`}
  ) and (max-width: ${({ theme }) => `${theme.breakpoints.values.md}px`}) {
    flex-direction: column-reverse;
    height: initial;
    width: 100vw;
    padding: 0;
  }

  &:first-of-type {
    margin-top: 30px;
  }

  .content {
    background-color: #F1EFE3;
    box-sizing: border-box;
    ${(props) => props.inverted 
      ? css`padding: 60px 150px 60px 60px;` 
      : css`padding: 60px 60px 60px 150px;`
    };
    width: ${props => props.shrink ? props.shrink : '51%'};

    /* MOBILE */
    @media(max-width: ${({ theme }) => `${theme.breakpoints.values.sm}px`}) {
      width: 100vw;
      padding: 40px;
    }

    /* TABLET */
    @media only screen and (
      min-width: ${({ theme }) => `${theme.breakpoints.values.sm}px`}
    ) and (max-width: ${({ theme }) => `${theme.breakpoints.values.md}px`}) {
      width: 100vw;
      padding: 40px 100px;
    }

    h3, p {
      margin: 0;
      margin-bottom: 30px;
    }

    h3 {
      font-size: 36px;
      font-family: 'Caros Soft Bold';
      line-height: 40px;
      color: #004178;

      &.thin {
        font-family: 'Caros Soft Light';
        font-size: 32px;

        @media(max-width: ${({ theme }) => `${theme.breakpoints.values.sm}px`}) {
          font-size: 26px;
          line-height: 30px;
        } 

        strong {
          display: block;
          font-family: 'Caros Soft ExtraBold';
          font-size: 36px;

          @media(max-width: ${({ theme }) => `${theme.breakpoints.values.sm}px`}) {
            font-size: 28px;
          } 
        }
      }
    }

    p {
      padding-right: 30px;
      font-size: 20px;
      margin: 0;

      @media(max-width: ${({ theme }) => `${theme.breakpoints.values.sm}px`}) {
        font-size: 18px;
      }
    }

    button {
      margin-top: 30px;
      margin-bottom: 15px;
    }
  }

  > img {
    height: auto;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    max-width: ${({ imgSize }) => `${imgSize[0]}px`};
    width: 55vw;
    ${(props) => props.inverted ? css`left: 0` : css`right: 0`};

    @media(max-width: ${({ theme }) => `${theme.breakpoints.values.sm}px`}) {
      height: auto;
      width: 100vw;
      position: initial;
      top: initial;
      left: initial;
      transform: initial;
      padding: 20px;
    }

    /* TABLET */
    @media only screen and (
      min-width: ${({ theme }) => `${theme.breakpoints.values.sm}px`}
    ) and (max-width: ${({ theme }) => `${theme.breakpoints.values.md}px`}) {
      height: auto;
      width: 100vw;
      position: initial;
      top: initial;
      left: initial;
      transform: initial;
      padding: 20px;
    }
  }

  .fader {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    ${(props) => props.inverted ? css`left: 0` : css`right: 0`};
    max-width: ${({ imgSize }) => `${imgSize[0]}px`};
    height: ${({ imgSize }) => `${imgSize[1]}px`};
    width: 52vw;

    @media(max-width: ${({ theme }) => `${theme.breakpoints.values.sm}px`}) {
      position: static;
      top: initial;
      transform: none;
      width: 100vw;
      height: ${({ imgSize }) => `${imgSize[1] - 200}px`};
      margin-top: 40px;
    }

    /* TABLET */
    @media only screen and (
      min-width: ${({ theme }) => `${theme.breakpoints.values.sm}px`}
    ) and (max-width: ${({ theme }) => `${theme.breakpoints.values.md}px`}) {
      position: static;
      top: initial;
      transform: none;
      width: 100vw;
      height: ${({ imgSize }) => `${imgSize[1] - 50}px`};
      margin-top: 40px;
    }

    img {
      max-width: ${({ imgSize }) => `${imgSize[0]}px`};
      max-height: ${({ imgSize }) => `${imgSize[1]}px`};
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      opacity: 0;
      transition: all .5s ease-in-out;
      width: 50vw;

      @media(max-width: ${({ theme }) => `${theme.breakpoints.values.sm}px`}) {
        top: 20px;
        left: 0;
        transform: none;
        width: 90vw;
        left: 50%;
        transform: translateX(-50%);
        max-height: ${({ imgSize }) => `${imgSize[1]}px`};
      }

      /* TABLET */
      @media only screen and (
        min-width: ${({ theme }) => `${theme.breakpoints.values.sm}px`}
      ) and (max-width: ${({ theme }) => `${theme.breakpoints.values.md}px`}) {
        top: 20px;
        left: 0;
        transform: none;
        width: 90vw;
        left: 50%;
        transform: translateX(-50%);
        max-height: ${({ imgSize }) => `${imgSize[1]}px`};
      }

      &.isActive {
        opacity: 1;
      }
    }
  }
`);