import { dispatch } from 'redux-easy/lib/redux-easy';
import { isAuthenticated } from '../../../../services/auth';
import { enumTypeUserStatuses } from '../../../../services/enums';
import { useHistory } from "react-router";

export default function buildMenu({
  history,
  user,
  openRegisterDialog,
  openLoginDialog,
  openValidatingIndebtProfile,
  openForwardToBlog,
}) {
  const myMenu = [{
    name: 'Conheça a Febraban',
    href: 'https://portal.febraban.org.br/',
  }, {
    name: 'Blog',
    href: 'https://meubolsoemdia.com.br/',
  }];
  myMenu.push({
    name: "FAQ",
    onClick: () => {
      history.push('/faq');
    }, 
  });
  if (!isAuthenticated()) {
    myMenu.push({
      name: 'Cadastrar',
      onClick: openRegisterDialog,
    });
    myMenu.push({
      name: 'Entrar',
      onClick: openLoginDialog,
      customClass: ['button'],
    });
  } else {
    if (user.profile?.type_users_status_id) {
      const userProfile = user.profile;

      if (userProfile.type_users_status_id === enumTypeUserStatuses.validatingIndebtProfile) {
        openValidatingIndebtProfile();
      }

      myMenu.push({
        name:
          userProfile.type_users_status_id !== enumTypeUserStatuses.active
            ? 'Olá, ' + userProfile.username
            : 'Acessar meu espaço',
        onClick: () => {
          if (userProfile.type_users_status_id === enumTypeUserStatuses.forwardToBlog) {
            openForwardToBlog();
          } else if (
            userProfile.type_users_status_id === enumTypeUserStatuses.active ||
            userProfile.type_users_status_id === enumTypeUserStatuses.firstIndexMustBeFilled
          ) {
            history.push('/home');
          }
        },
        customClass: ['button'],
      });
    } else {
      dispatch('updateUserProfile');
    }
  }

  return myMenu;
}
