import React from 'react';
import './styles/toolItem.scss';

import { Hidden } from '@material-ui/core';
import { Link } from 'react-router-dom';

import Pagination from "../../components/pagination/Pagination";
import SwipeableViews from "react-swipeable-views";

class ObjectiveItem extends React.Component {

  state = {
    tools: [
      {
        toolThumbnail: "https://pefmbddiag.blob.core.windows.net/cdn-pi/imagens/praia.jpg",
        title: "Planejamento de viagem", link: "/ferramentas/planejamento", active: true
      },

      // {
      //   toolThumbnail: "https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcQxPVrs5QgB9sH8rynbTCv1p2XKPODBMPtK4Q&usqp=CAU",
      //   title: "Mapeamento de despesas", link: "/ferramentas/planejamento", active: false
      // },

      // {
      //   toolThumbnail: "https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcT37h2_Jfp-PvGBO90pHnO509mZGNvro-qJhQ&usqp=CAU",
      //   title: "Simulador de investimentos", link: "/ferramentas/planejamento", active: false
      // },

      // {
      //   toolThumbnail: "https://cdn4.ecycle.com.br/cache/images/materias/Nomundo/50-650-tecnologia750.jpg",
      //   title: "Plano econômico Express", link: "/ferramentas/planejamento", active: false
      // },

      // {
      //   toolThumbnail: "https://tutano.trampos.co/wp-content/uploads/2017/08/2017-08-21_calculadora-cpm.jpg",
      //   title: "Calculadora de juros", link: "/ferramentas/planejamento", active: false
      // },

      // {
      //   toolThumbnail: "https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcSuOP9TNNe6WH7xJCCv-aOv9xVV3CfqJJNR2A&usqp=CAU",
      //   title: "Meu dia-a-dia", link: "/ferramentas/planejamento", active: false
      // },

    ],
    index: 0
  }
  handleChangeIndex = (index) => {
    this.setState({
      index
    });
  };

  render() {

    const { index } = this.state;

    return (
      <>
        <Hidden xsDown>
          <div className="tool_item_containerXsDown">
            {this.state.tools.map((item, index) => (
              <div className="tool_item_root" key={index} >
                <div className="tool_item_itemBox">
                  <div className="tool_item_toolImage" style={{ backgroundImage: `url(${item.toolThumbnail})` }}>
                    {item.active ?
                      <Link to={item.link} className="tool_item_link">
                        <div className="tool_item_toolContainerText">
                          <p className="tool_item_toolText">{item.title}</p>
                          <p className="tool_item_descriptionText">{item.description}</p>
                        </div>
                      </Link> :
                      <div className="tool_item_link" style={{ cursor: 'not-allowed' }}>
                        <div className="tool_item_toolContainerText">
                          <p className="tool_item_toolText">{item.title}</p>
                          <p className="tool_item_descriptionText">{item.description}</p>
                        </div>
                      </div>
                    }
                  </div>
                </div>
              </div>
            ))}
          </div>
        </Hidden>

        <Hidden smUp>
          <div className="tool_item_container">
            <SwipeableViews index={index} onChangeIndex={this.handleChangeIndex} slideStyle={{ overflow: 'visible' }} >
              {this.state.tools.map((item, index) => (
                <div key={index}>
                  {item.active ?
                    <Link to={item.link} className="tool_item_link" key={index}>
                      <div className="tool_item_root" >
                        <div className="tool_item_itemBox">
                          <div className="tool_item_toolImage" style={{ backgroundImage: `url(${item.toolThumbnail})` }}>
                            <div className="tool_item_toolContainerText">
                              <p className="tool_item_toolText">{item.title}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link> :
                    <div className="tool_item_link" >
                      <div className="tool_item_root" >
                        <div className="tool_item_itemBox">
                          <div className="tool_item_toolImage" style={{ backgroundImage: `url(${item.toolThumbnail})`, cursor: 'not-allowed'}}>
                            <div className="tool_item_toolContainerText">
                              <p className="tool_item_toolText">{item.title}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  }
                </div>
              ))}
            </SwipeableViews>
          </div>
          <Pagination
            dots={this.state.tools.length}
            index={index}
            onChangeIndex={this.handleChangeIndex}
          />
        </Hidden>
      </>
    )
  }
}

export default ObjectiveItem;
