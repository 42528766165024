import React, { Component } from 'react';
import './closedShopping.scss';

import { Helmet } from "react-helmet";
import { Button, CircularProgress, Grid, Hidden, Box } from '@material-ui/core';

import AlertErrors from '../../components/alertErrors/alerts';
import CurrencyBalance from '../../components/progresso/CurrencyBalance';
import CurrentLevel from '../../components/sidebar/CurrentLevel';
import FeedItem from '../../components/feed/FeedItem';
import Gauge from '../../components/gauge';
import InitialModalQuiz from '../../components/quiz/InitialModalQuiz';
import OpenTable from '../../components/shopping/openTable';
import ToolbarItem from '../../components/toolbar/ToolbarItem';
import Btn from '../../components/button/Button'
import Pusher from 'react-pusher';
import { watch } from 'redux-easy/lib/redux-easy';

import { Skeleton } from '@material-ui/lab';

import api from '../../../services/api';
import { getNextIndexAssessmentDateText } from '../../../services/util';

const Globals = require("../../../Globals.json");
const QuestionarioIndice = require("../../../QuestionarioIndice.json");
class openShopping extends Component {
  state = {
    isLoading: false,
    loading: false,
    isModalOpen: false,
    isfb: 0,
    lock: false,
    indiceData: {},
    items: [],
    open: false,
    later: false,
    tableEsmeralda: [],
    tableSafira: [],
    tableRubi: [],
    tableLoja: [],
    alertError: '',
    userId: -1,
    //forasd
  }

  handleModal = async (item) => {
    try {
      if (item === 'later') {
        this.setState({ isModalOpen: false, lock: false });
        if (!this.state.later) {
          const postData = {
            type_action_id: 15,
            data_modules_detail_id: 155,
          }
          await api.post(Globals.api.userActionEvent, postData);
        }
      }
    } catch (error) {
      this.setState({ alertError: error.response.status })
      setTimeout(() => {
        this.setState({ alertError: '' })
      }, 3000)
    }
  }

  closeModal = () => {
    this.setState({ open: false })
  }

  async loadIndiceData () {
    try {
      const response = await api.post(Globals.api.getModules, { container_id: 58 });
      if (response.data.container.user_module_data.isfb) {
        this.setState({ isfb: response.data.container.user_module_data.isfb });
      }
    } catch (error) {
      this.setState({ alertError: error.response.status })
      setTimeout(() => {
        this.setState({ alertError: '' })
      }, 3000)
    }
  }

  async loadDataQuestIndice () {
    try {

      this.setState({ isLoading: true });
      const postData = { "container_id": 53 };

      const response = await api.post(Globals.api.getModules, postData);
      const { container } = response.data;

      this.setState({
        isLoading: false,
        objectiveTitle: container.user_module_data.objectiveTitle,
        items: container.user_module_data.steps || [],
      });
    } catch (error) {
      this.setState({ alertError: error.response.status })
      setTimeout(() => {
        this.setState({ alertError: '' })
      }, 3000)
    }
  }

  handleClick (item) {
    if (item.action === "calc_indice_sfb") {
      this.setState({ open: true });
    }
  }

  async handleTables (loading) {
    try {

      let tableEsm = []
      let tableSaf = []
      let tableRub = []
      let tableLoja = []

      if (loading !== 'loading') {
        this.setState({ loading: true });
      } else {
        this.setState({ loading: false });
      }

      const response = await api.get(Globals.api.getTableRescue);
      // console.log('Tabelas aqui', response.data)

      tableLoja = response.data.filter((i) => i.tabela.id === 446);
      tableEsm = response.data.filter((i) => i.tabela.id === 460);
      tableSaf = response.data.filter((i) => i.tabela.id === 447);
      tableRub = response.data.filter((i) => i.tabela.id === 471);

      this.setState({
        loading: false,
        tableLoja: tableLoja[0]?.children.slice(0, 8),
        tableEsmeralda: tableEsm[0]?.children.slice(0, 8),
        tableSafira: tableSaf[0]?.children.slice(0, 8),
        tableRubi: tableRub[0]?.children.slice(0, 8),
      });
    } catch (error) {
      this.setState({ alertError: error.response.status })
      setTimeout(() => {
        this.setState({ alertError: '' })
      }, 3000)
    }
  }

  updateTable = () => {
    this.handleTables('loading')
  }

  loadData = async () => {
    this.setState({ nextIndexAssessmentDateText: await getNextIndexAssessmentDateText() });
  }

  componentDidMount () {
    this.setState({ userId: this.props.user.profile.id });
    this.setState({ indiceData: QuestionarioIndice });
    this.loadIndiceData()
    this.loadDataQuestIndice()
    this.handleTables()
  }

  render () {
    return (<></>);
    return (
      <>

        {/* <Pusher
          channel={"notifications." + this.state.userId}
          event="buyItemChanged"
          onUpdate={(e) => this.updateTable()}
        /> */}

        <Grid container>
          <Helmet>
            <title>Loja | Meu bolso em dia</title>
          </Helmet>
          <Grid item xs={12} sm={8} md={9} className="classContainer">
            <AlertErrors error={this.state.alertError} />

            <Grid className="closed_containerCorrency" >
              <CurrencyBalance text="Utilize pontos para desbloquear vouchers, ferramentas, produtos ou conteúdos." />
            </Grid>

            {this.state.loading ?
              <Grid style={{ backgroundColor: '#fff' }}>
                <div style={{ fontSize: '28px', fontWeight: '500', marginLeft: '20px', paddingTop: '25px' }}>
                  Tabela de Resgate
              </div>
                <Box pt={0.5} style={{ display: "flex", padding: '20px', backgroundColor: '#fff', justifyContent: 'center', marginTop: '23px' }}>
                  <Skeleton variant="rect" width={152} height={180} style={{ marginLeft: "10px" }} />
                  <Skeleton variant="rect" width={152} height={180} style={{ marginLeft: "10px" }} />
                  <Skeleton variant="rect" width={152} height={180} style={{ marginLeft: "10px" }} />
                  <Skeleton variant="rect" width={152} height={180} style={{ marginLeft: "10px" }} />
                  <Skeleton variant="rect" width={152} height={180} style={{ marginLeft: "10px" }} />
                  <Skeleton variant="rect" width={152} height={180} style={{ marginLeft: "10px" }} />
                  <Skeleton variant="rect" width={152} height={180} style={{ marginLeft: "10px" }} />
                </Box>
              </Grid> :
              <>

                <Grid container >
                  <AlertErrors error={this.state.alertError} />
                  <FeedItem expandHeight>
                    <OpenTable color="darkBlue" title="Tabela de Resgate" children={this.state.tableLoja} />
                    <div className="containerBtn">
                      <Btn to={Globals.paths.openShopping.replace(":shoppingId", "446")} width="130px">VER MAIS</Btn>
                    </div>
                  </FeedItem>
                </Grid>

                <Grid container >
                  <AlertErrors error={this.state.alertError} />
                  <FeedItem expandHeight>
                    <OpenTable color="green" title="Tabela de Resgate Esmeralda" children={this.state.tableEsmeralda} />
                    <div className="containerBtn">
                      <Btn to={Globals.paths.openShopping.replace(":shoppingId", "460")} width="130px">VER MAIS</Btn>
                    </div>
                  </FeedItem>
                </Grid>

                <Grid container >
                  <AlertErrors error={this.state.alertError} />
                  <FeedItem expandHeight>
                    <OpenTable color="blue" title="Tabela de Resgate Safira" children={this.state.tableSafira} />
                    <div className="containerBtn">
                      <Btn to={Globals.paths.openShopping.replace(":shoppingId", "447")} width="130px">VER MAIS</Btn>
                    </div>
                  </FeedItem>
                </Grid>

                <Grid container >
                  <AlertErrors error={this.state.alertError} />
                  <FeedItem expandHeight>
                    <OpenTable color="red" title="Tabela de Resgate Rubi" children={this.state.tableRubi} />
                    <div className="containerBtn">
                      <Btn to={Globals.paths.openShopping.replace(":shoppingId", "471")} width="130px">VER MAIS</Btn>
                    </div>
                  </FeedItem>
                </Grid>
              </>
            }
          </Grid>

          <Hidden xsDown>
            <Grid item container sm={4} md={3} direction="column">

              <div className="home-saude-financeira">
                <ToolbarItem tooltip={this.state.nextIndexAssessmentDateText} title={
                  <div className="positionTitle">
                    <span>Índice de Saúde Financeira</span>
                  </div>
                } color="#45AF98">
                  {this.state.isLoading ? <CircularProgress style={{ marginLeft: '43%' }} /> :
                    <>
                      {
                        this.props.user.profile.isfb === -1 ?
                          <div className="indiceContainer">
                            <span>Descubra qual o seu Índice de Saúde Financeira.</span>
                            {this.state.items.map((item, index) => (
                              <div key={index} >
                                {item.action === "calc_indice_sfb" &&
                                  <Button type={"green"} onClick={() => this.handleClick(item)}>Calcule agora</Button>
                                }
                              </div>
                            ))}
                          </div> :
                          <div className="positionGauge">
                            <Gauge value={this.props.user.profile.isfb} />
                            <span>Esse é o seu Índice de Saúde Financeira atual</span>
                          </div>
                      }
                    </>
                  }
                </ToolbarItem>
              </div>

              <ToolbarItem title="Seu Nível" color="#45AF98">
                <CurrentLevel />
              </ToolbarItem>
            </Grid>
          </Hidden>
          {this.state.open &&
            <InitialModalQuiz onClose={this.closeModal} open={this.state.open} quizData={QuestionarioIndice} />
          }
        </Grid >
      </>

    )
  }

}

export default watch((openShopping), { user: '' });
