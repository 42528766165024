import React from 'react';
import './typeProfile.scss';

import Button from '../../../../components/button/Button';
import NavigationAppBar from '../../AppBar/NavigationAppBar';
import Switch from '@material-ui/core/Switch';

import { withRouter } from 'react-router-dom';


const Globals = require("../../../../../Globals.json");
class Home extends React.Component {

  state = {
    menuActive: 0,
    userActive: false,
    userAcess: '',
    userType: '',
    userName: '',
    userPhone: '',
    userCpf: '',
    userEmail: '',
    UserEnd: '',
    UserCep: '',
    UserCid: '',
    UserEst: '',
    userPass: '',
    userRepeatPass: '',
  }


  handleDeleteUser() {
    console.log('Deletando user...')
  }

  getSelection(id) {
    this.setState({ menuActive: id })
  }

  handleSave() {
    console.log('Enviado dados..')
    console.log(
      ' Nome: ', this.state.userName,
      ' Email: ', this.state.userEmail,
      ' Pass: ', this.state.userPass,
      ' RepetPass: ', this.state.userRepeatPass,
      ' userActive: ', this.state.userActive,
      ' CPF: ', this.state.userCpf,
      ' tel: ', this.state.userPhone,
      ' obj: ', this.state.userObj,
      ' userControlPanel: ', this.state.userControlPanel,
      ' userControlPanel2: ', this.state.userControlPanel2,
    )
  }

  handleCancel() {
    console.log('Cancelando alterações user...')
  }

  handleSearch() {
    const userId = this.props.match.params.userId
    console.log('Página carregada', userId)
  }
  componentDidMount() {
    this.handleSearch()
  }
  render() {

    return (
      <>
        <NavigationAppBar text="Editar User" />
        <div className="containerEditUser">
          <div className="ContentContainerProfile">

            <div className="profileHeader">
              <div>Cadastro de tipo de Usuário</div>
              <div className={`switchProfile ${!this.state.userActive && 'red'}`}>
                {this.state.userActive}
                {this.state.userActive ? "Ativo" : "Desativo"}
                <Switch
                  color="secondary"
                  checked={this.state.userActive}
                  // onChange={(e) => this.handleUserActive(e)}
                  onChange={e => this.setState({ userActive: e.target.checked })}
                  name="checkedaks"
                />
              </div>
            </div>

            <div className="profileBox1Type">
              Nome do tipo de Usuário
              <input
                variant="outlined"
                value={this.state.userName}
                margin="dense"
                placeholder="Digite o nome do perfil"
                autoFocus
                type="text"
                autoComplete="off"
                onChange={e => this.setState({ userName: e.target.value })}
                className="profileInput"
              />
            </div>

            <div className="profileBox1Type">
              Descrição do tipo de usuário
                <textarea
                type='text'
                className="perfilInputText2"
                placeholder="Escrever mensagem"
              />
            </div>

            <div className="editContainerBtnsType">
              <Button
                icon="none"
                width="150px"
                type="ligthDark"
                height="36px"
                textAlign="center"
                right="20px"
                to={Globals.paths.backofficeUsers}>
                cancelar
                </Button>

              <Button

                width="150px"
                type="darkBlue"
                textAlign="center"
                onClick={() => this.handleSave()}>
                cadastrar
                </Button>
            </div>
          </div>
        </div>
      </>
    )
  }
}
export default withRouter(Home);
