import React from 'react';
import './styles/currentStudy.scss';

import { Grid, Box } from '@material-ui/core';

import StudyIcon from '../../../assets/icon/caderno.png';

import Button from '../button/Button';

export default function CurrentStudy ({ description, btnText }) {
  return (
    <>
      <Grid item xs={8}>
        <Grid container className="current_stydy_pL">
          <ul className="current_stydy_ul_study">
            <li className="current_study_f14">
              {description ?
                <label>
                  {description}
                </label> :
                <label>
                  Comece seus estudos e ganhe <br /> <b>+3.000 pontos.</b>
                </label>
              }
            </li>
          </ul>
          <Grid className="current_stydy_mt">
            {btnText ?
              <Button width='135px' >
                {btnText}
              </Button>
              :
              <Button width='135px' >
                VER MAIS
              </Button>
            }
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={4}>
        <Grid container>
          <Box>
            <img className="current_stydy_icon_study" alt="study" src={StudyIcon} />
          </Box>
        </Grid>
      </Grid>

    </>
  );

}
