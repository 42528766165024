import React from 'react';
import './styles/missionCustomItem.scss';

class MissionCustomItem extends React.Component {
  render() {

    const { text } = this.props;

    return (
      <>
        <div className="mission_cust_container">
          <div className="mission_cust_itemLeft">
            <input className="mission_cust_checkBox" type="checkbox" name="alternative" id="checkbox" />
            <span className="mission_cust_text">{text}</span>
          </div>

          <div className="mission_cust_itemRight">
            <button className="mission_cust_buttonBlue"> EDITAR </button>
            <button className="mission_cust_buttonOrange"> EXCLUIR  </button>
          </div>
        </div>
      </>
    )
  }
}

export default MissionCustomItem;
