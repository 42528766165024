import React, { Component } from 'react';
import './styles.scss';

import { Alert } from '@material-ui/lab';

export default class Alerts extends Component {
  render () {
    return (
      <div className={`alert_error ${this.props.side ? 'side' : this.props.noneWidth && 'noneWidth'}`}>
        {
          this.props.error === 400 ? <Alert className={`functions_alerts ${this.props.noneWidth && 'noneWidth'}`} severity="error">Falha na requisição 400</Alert> :
            this.props.error === 404 ? <Alert className={`functions_alerts ${this.props.noneWidth && 'noneWidth'}`} severity="error">Falha na requisição 404</Alert> :
              this.props.error === 422 ? <Alert className={`functions_alerts ${this.props.noneWidth && 'noneWidth'}`} severity="error">Falha na requisição 422</Alert> :
                this.props.error === 500 && <Alert className={`functions_alerts ${this.props.noneWidth && 'noneWidth'}`} severity="error">Falha no servidor 500</Alert>
        }
      </div>
    )
  }
}