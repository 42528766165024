import React from 'react';
import './mission.scss';

import { Helmet } from "react-helmet";
import { Grid, Hidden } from '@material-ui/core';

import CurrentLevel from '../../components/sidebar/CurrentLevel';
import FeedItem from '../../components/feed/FeedItem';
import LatestAchievements from '../../components/sidebar/LatestAchievements';
import MissionList from '../../components/mission/MissionList';
import PointsBalance from '../../components/sidebar/PointsBalance';
import ToolbarItem from '../../components/toolbar/ToolbarItem';

class Mission extends React.Component {

  state = {
    classTextBtn: 0,
  }

  render () {

    return (
      <>
        <Grid container>

          <Helmet>
            <title>Missões | Meu bolso em dia</title>
          </Helmet>
          <Grid item xs={12} sm={8} md={9} className="classContainer">
            <Grid container >
              <FeedItem expandHeight title="" borderRadius>
                <MissionList />
                {/* <MissionCustom /> */}
              </FeedItem>
            </Grid>

          </Grid>

          {/* <Hidden xsDown>
            <Grid item container sm={4} md={3} direction="column">

              <ToolbarItem title="Estudo atual" color="#49A4F8">
                <PointsBalance coins={this.props.coins}/>
              </ToolbarItem>

            </Grid>
          </Hidden> */}

          <Hidden xsDown>
            <Grid item sm={4} md={3}>

              <ToolbarItem title="Seu Nível" color="#45AF98"
                tooltip="O Nível representa o seu status de usuário na Plataforma. Ele aumenta conforme você vai usando e aprendendo com a Plataforma: cumprir os objetivos e missões da sua jornada de aprendizado 
                e consumir conteúdos e usar ferramentas te dão experiência. Essa experiência se acumula, e quando você atinge determinada quantidade, passa de nível. Níveis mais altos concedem benefícios e 
                vantagens exclusivas para os usuários." >
                <CurrentLevel />
              </ToolbarItem>

              <ToolbarItem title="Saldo de Pontos" color="#45AF98"
                tooltip="Os Pontos representam o reconhecimento do seu engajamento no uso da Plataforma. Praticamente qualquer atividade da Plataforma é 
                recompensada com uma quantidade de pontos ao ser completada. Junte os pontos e as troque por benefícios e conteúdos exclusivos da Plataforma." >
                <PointsBalance coins={this.props.coins} ></PointsBalance>
              </ToolbarItem>

              <ToolbarItem title="Últimas Conquistas" color="#45AF98">
                <LatestAchievements></LatestAchievements>
              </ToolbarItem>

            </Grid>
          </Hidden>

        </Grid >
      </>

    )
  }

}

export default Mission;
