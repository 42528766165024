import React from 'react';
import './quizTest.scss';

import { Link, withRouter } from 'react-router-dom';
import { Helmet } from "react-helmet";
import { Breadcrumbs, Grid, Hidden } from '@material-ui/core';

import Favorite from '../../components/sidebar/Favorite';
import FeedItem from '../../components/../components/feed/FeedItem';
import FeedLine from '../../components/../components/feed/FeedLine';
import ToolbarItem from '../../components/toolbar/ToolbarItem';
import TestQuiz from '../../components/quizTest/testQuiz';

import QuestionarioIndice from "../../../QuestionarioIndice.json";
import Globals from "../../../Globals.json";
import api from '../../../services/api';


class QuizTest extends React.Component {
  state = {
    isLoading: false,
    thumbnail: '',
    name: '',
    id: '',
    description: '',
    quizData: '',
  }

  render() {
    return (
      <>
        <Breadcrumbs aria-label="breadcrumb" className="quiz_breadCrumb">
          <Link color="inherit" to={Globals.paths.quiz} className="quiz_breadCrumbLink">
            Quiz
            </Link>
        </Breadcrumbs>

        <Helmet>
          <title>Quiz | Meu bolso em dia</title>
        </Helmet>

        <Grid container>
          <Grid item xs={12} className="quizClassContainer" style={{ marginBottom: '70px' }}>

            <FeedLine>
              <FeedItem overflowHidden expandHeight noPadding>
                <TestQuiz />
              </FeedItem>
            </FeedLine>

          </Grid>

        </Grid >
      </>
    )
  }
}

export default QuizTest;
