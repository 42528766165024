import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import '../../../backoffice.scss';

import ModuleEditing from '../../components/backoffice/components/ModuleEditing/ModuleEditing';

import Globals from "../../../Globals.json";
import FBBEditorJS from '../../components/FBBEditorJs';


export default function EditArticle() {

  const editorRef = useRef();

  const [editorContent, setEditorContent] = useState({});

  const [container, setContainer] = useState({});
  const [breadcrumbs, setBreadcrumbs] = useState([]);

  const params = useParams();

  console.log('PARAMS::', params);

  const onReceiveData = async (container, children, parent) => {

    setContainer(container);

    let contentJson = null;
    try {
      contentJson = JSON.parse(container.description)
    }
    catch (e) {
      console.log("JSON ERROR::", e);
    }

    console.log("CONTENTJSON::", contentJson);

    setEditorContent(contentJson);

    setBreadcrumbs([
      {
        label: "Artigos",
        link: Globals.paths.backofficeArticles,
      },
      {
        label: container.name
      }
    ]);

  }

  const copyData = () => {

    window.getSelection().removeAllRanges()

    var range = document.createRange()
    range.selectNode(document.getElementById("htmlContainer"));
    window.getSelection().addRange(range)

    document.execCommand('copy')

  }


  const descriptionEditor =
    <>
      <div style={{ marginTop: "20px" }}>Conteúdo</div>
      <div style={{ borderBottom: "#999 1px solid" }}>
        <FBBEditorJS
          data={editorContent}
          onChange={(api, newData) => setEditorContent(newData)}
        />
      </div>
      {/* {!editorContent &&
        <>
          <div id="htmlContainer" dangerouslySetInnerHTML={{ __html: container.description }}></div>
          <button onClick={() => copyData()}>COPY</button>
        </>
      } */}
    </>

  const onBeforeSaveData = (postData) => {
    postData.description = JSON.stringify(editorContent);
  }

  const onNewData = (newData) => {
  }

  return (

    <ModuleEditing
      isNew={params.articleId === "new"}
      moduleId={params.articleId}
      typeModuleId={10}
      title="Detalhe de Artigo"
      onReceiveData={onReceiveData}
      onBeforeSaveData={onBeforeSaveData}
      onNewData={onNewData}
      customDataFields={descriptionEditor}
      hideDescriptionField
      breadcrumbs={breadcrumbs}
    >

    </ModuleEditing>

  );
}

