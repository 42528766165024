import React from 'react';

import { withStyles, Grid, Box } from '@material-ui/core';

import api from '../../../services/api';
import Button from '../button/Button';
import AlertErrors from '../../components/alertErrors/alerts';
import { TurnedInNot } from '@material-ui/icons';
import { Skeleton } from '@material-ui/lab';
import { getCoinsFromRewards, getCoinsTextFromRewards } from '../../../services/util';

const styles = theme => ({
  quizItemContainer:
  {
    width: "99%",
    boxShadow: "0px 2px 20px rgba(0, 0, 0, 0.08)",
    marginBottom: "15px",
    height: "124px",
    flexWrap: "nowrap",
    display: "flex",
    minWidth: "520px",
  },

  addFavorite: {
    padding: "3px",
    width: "30px",
    height: "30px",
    background: "rgba(0, 0, 0, 0.2)",
    cursor: "pointer",
    position: "relative",
    top: "-123px",
    left: "5px",
    border: "none",

    "&:hover": {
      background: "rgba(0, 0, 0, 0.1)",
    }
  },

  quizImage:
  {
    width: "185px",
    height: "100%",
    position: 'relative',

    [theme.breakpoints.down('xs')]: {
      width: "35%"
    }
  },

  contentCoins: {
    top: "0",
    width: "130px",
    position: "absolute",
    borderTop: "6px solid #FBDBD0",
    borderLeft: "none",
    right: '-50px',
    borderRight: "none",
    borderBottom: "none",
  },

  coins: {
    color: "#9e4326",
    width: "94px",
    height: "30px",
    fontSize: "11px",
    display: "flex",
    background: "#FBDBD0",
    alignItems: "center",
    justifyContent: "center",
    textTransform: "uppercase",
    fontWeight: 'bold',
  },

  imageQuiz: {
    width: "182px",
    height: "124px",
  },
  quizInfoContainer:
  {
    flexGrow: 2,
    height: "100%",

    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
  quizInfoTitle:
  {
    width: "100%",
    padding: "30px 20px 0px 23px",

    fontSize: "18px",
    fontWeight: "bold",
    color: "#2E2E2E",

    // display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    display: '-webkit-box',
    overflow: 'hidden',
    minHeight: '100px',
    whiteSpace: 'normal',
    textOverflow: 'ellipsis',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: '3',

    [theme.breakpoints.down('xs')]: {
      width: "250px",
      height: "40px",
      overflow: "auto",
      fontSize: "12px",
    }
  },
  quizButtonContainer:
  {
    width: "170px",
    padding: "8px",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },

  quizBorderRight:
  {
    backgroundColor: "#FBDBD0",
    width: "5px",
    height: "100%",
  },

  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    overflow: 'auto',
  }

});

const Globals = require("../../../Globals.json");

class QuizTesteList extends React.Component {

  state = {
    open: false,
    openFinalModal: false,
    isLoading: false,
    quiz: [],
    length: 0,
    id: 0,
    results: [],
    questions: [],
    alertError: '',
  }

  async loadData () {
    this.setState({ isLoading: true });
    try {
      const basePayload = this.props.payload || {};
      const postData = { ...basePayload };

      const { endpoint } = this.props;
      const sendMethod = this.props.method || "post";
      const options = {
        method: sendMethod,
        url: endpoint,
      };

      if (sendMethod === "post") {
        options.data = postData;
      }
      else if (sendMethod === "get") {
        options.params = postData;
      }
      const response = await api(options);
      this.setState({ quiz: response.data, isLoading: false });
      this.setState({ length: response.data.length });
    } catch (error) {
      this.setState({ alertError: error.response.status })
      setTimeout(() => {
        this.setState({ alertError: '' })
      }, 3000)
    }
  }

  componentDidMount () {
    this.loadData();
  }

  openFinalModal = () => {
    this.setState({ open: false, openFinalModal: true });
  }

  closeFinalModal = () => {
    this.setState({ openFinalModal: false })
  }

  againQuiz = (id) => {
    this.setState({ id: id, open: true, openFinalModal: false });
  }

  getCoins = (rewards) => {

    // console.log( "GET_COINS:", rewards );
    if (!rewards) return null;
    const coins = rewards.filter((i) => i.asset_id === 1);
    if (coins.length > 0) {
      return coins[0];
    }
    else {
      return null;
    }

  }

  render () {

    const { classes } = this.props;

    return (
      <>
        <AlertErrors error={this.state.alertError} />
        {this.state.isLoading ? <Grid container direction="row">
          <Box pt={0.5} style={{ display: 'flex', flexDirection: "column" }}>
            <div style={{ display: 'flex', flexDirection: "row" }}>
              <Skeleton variant="rect" width={230} height={170} />
              <Skeleton variant="rect" width={"80vw"} height={170} style={{ marginLeft: "10px" }} />
            </div>
            <br />
            <div style={{ display: 'flex', flexDirection: "row" }}>
              <Skeleton variant="rect" width={230} height={170} />
              <Skeleton variant="rect" width={"80vw"} height={170} style={{ marginLeft: "10px" }} />
            </div>
            <br />
            <div style={{ display: 'flex', flexDirection: "row" }}>
              <Skeleton variant="rect" width={230} height={170} />
              <Skeleton variant="rect" width={"80vw"} height={170} style={{ marginLeft: "10px" }} />
            </div>
            <br />
          </Box>
        </Grid> :
          <>
            <Grid container direction="column">
              {this.state.quiz.map((item, index) => (

                <div key={index} className={classes.quizItemContainer}>
                  <div className={classes.quizImage} >
                    <img className={classes.imageQuiz} src={item.thumbnail} alt={item.name} />
                    {getCoinsFromRewards(item.data.rewards) > 0 &&
                      <div className={classes.contentCoins}>
                        <div className={classes.coins} >
                          {getCoinsTextFromRewards(item.data.rewards)}
                        </div>
                      </div>
                    }

                  </div>

                  <div className={classes.quizInfoContainer}>
                    <div className={classes.quizInfoTitle}>
                      {item.name}</div>
                  </div>


                  <div className={classes.quizButtonContainer}>
                    <Button icon="none" minWidth="128px" minHeight="36px" fontSize="12px"
                      to={Globals.paths.quizTest.replace(':quizId', item.id)}>
                      Descobrir
                    </Button>

                  </div>
                  <div className={classes.quizBorderRight}>
                  </div>
                </div>
              ))}

              {/* {this.state.open &&
                <InitialModalQuiz dados={this.state.questions} onClose={() => this.setState({ open: false })} open={this.state.open}
                  endpoint={Globals.api.recommendedModule} payload={{ module_type: "1" }} id={this.state.id} onCloseQuiz={this.openFinalModal} />
              }

              {this.state.openFinalModal &&
                <Modal
                  open={this.state.openFinalModal}
                  onClose={this.closeFinalModal}
                  aria-labelledby="Quiz Final"
                  aria-describedby="Fim do questionário"
                  className={classes.modal}
                >
                  <FinalModal dados={this.state.results} closeFinalModal={this.closeFinalModal} indexId={this.state.id} againQuiz={() => this.againQuiz(this.state.id)} />
                </Modal>
              } */}
            </Grid>

          </>
        }
      </>

    )
  }

}

export default withStyles(styles)(QuizTesteList);

