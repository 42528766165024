import { Checkbox, FormControlLabel, TextField } from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import '../../../backoffice.scss';

import ModuleEditing from '../../components/backoffice/components/ModuleEditing/ModuleEditing';

import Globals from "../../../Globals.json";
import api from '../../../services/api';
import Dialogs from '../../../services/dialogs';


export default function EditClassContentQuiz() {

  const baseOption = { id: -1, text: '', correct: false };
  const baseOptions = [];
  for (let i = 0; i < 5; i++) {
    baseOptions.push({ ...baseOption, id: i + 1 });
  }

  const [container, setContainer] = useState({});
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const [question, setQuestion] = useState('');
  const [options, setOptions] = useState(baseOptions);

  const params = useParams();
  const history = useHistory();

  const onReceiveData = async (container, children, parent) => {

    setContainer(container);

    setQuestion( container.data.question ?? '' );

    const opts = container.data.options ?? baseOptions;

    while( opts.length < 5 )
    {
      opts.push( { ...baseOption, id: opts.length } )
    }

    setOptions( opts );

    const postData = {
      "container_id": params.courseId,
      type_module_status_ids: [0, 1, 2],
    };
    const responseCourse = await api.post(Globals.api.getModules, postData);
    const course = responseCourse.data.container;

    setBreadcrumbs([
      {
        label: "Cursos",
        link: Globals.paths.backofficeCourses,
      },
      {
        label: course.name,
        link: Globals.paths.backofficeEditCourse.replace(":courseId", course.id)
      },
      {
        label: parent.name,
        link: Globals.paths.backofficeEditClass.replace(":courseId", course.id).replace(":classId", parent.id)
      },
      {
        label: container.name
      }
    ]);

  }

  let [, _forceUpdate] = useState();

  const setOptionProp = (item, prop, value) => {
    console.log( "SOP::", value );
    if( prop === "correct" )
    {
      options.forEach( item => { item.correct = false } );
    }
    item[prop] = value;
    _forceUpdate({});
  }

  const custom =
    <div className="quiz-container">
      <div className="quiz-question">
        <TextField
          style={{ width: "700px" }}
          key="txtQuestion"
          label="Pergunta"
          multiline
          rowsMax={4}
          value={question}
          onChange={(e) => setQuestion(e.target.value)}
        />
      </div>
      <div className="quiz-options-container">
        {options.map((item, index) => (
          <div key={index} className="quiz-option">
            <TextField
              style={{ width: "500px" }}
              key="txtQuestion"
              label={`Opção ${index + 1}`}
              value={item.text}
              onChange={(e) => { setOptionProp( item, "text", e.target.value ) }}
              InputProps={{
                style: { color: item.correct ? "#4AAF98" : "#000" }
              }}
                />
            { item.text.trim().length > 0 &&
            <FormControlLabel
              control={<Checkbox checked={item.correct == true} onChange={(e) => { setOptionProp( item, "correct", e.target.checked ) } } />}
              label="Opção correta"
            />
            }
          </div>
        ))}
      </div>
    </div>

  const generateJson = () => {
    const json = {};
    json.question = question;
    json.options = [];
    options.forEach( (item, index) => {
      if( item.text.trim().length > 0 )
      {
        json.options.push( {
          id: index + 1,
          text: item.text,
          correct: item.correct
        })
      }
    })
    return json;
  }

  const onValidate = () => {

    if( question.trim().length === 0 ) {
      Dialogs.show( "Pergunta Necessária", "É necessário digitar uma pergunta válida." )
      return false;
    }

    let countCorrect = 0;
    let countOptions = 0;
    options.forEach( (item, index) => {
      if( item.text.trim().length > 0 )
      {
        countOptions++;
        if( item.correct )
        {
          countCorrect++;
        }
      }
    })

    if( countOptions < 2 ) {
      Dialogs.show( "Opções Inválidas", "São necessárias ao menos 2 opções para a sua pergunta." )
      return false;
    }

    if( countCorrect == 0 ) {
      Dialogs.show( "Opções Inválidas", "Uma das opções precisa ser a opçao correta." )
      return false;
    }

    if( countCorrect > 1 ) {
      Dialogs.show( "Opções Inválidas", "Apenas uma das opções pode ser a opção correta." )
      return false;
    }

    return true;

  }

  const onBeforeSaveData = (postData) => {
    postData.thumbnail = "https://";
    postData.description = "-";
    postData.data = generateJson();
  }

  const onNewData = (newData) => {
  }

  return (

    <ModuleEditing
      isNew={params.contentId === "new"}
      moduleId={params.contentId}
      typeModuleId={29}
      parentId={params.classId}
      title="Detalhe de Quiz pós Aula"
      onReceiveData={onReceiveData}
      onBeforeSaveData={onBeforeSaveData}
      onValidate={onValidate}
      onNewData={onNewData}
      customDataFields={custom}
      hideThumbnailField
      hideDescriptionField
      hideRewards
      breadcrumbs={breadcrumbs}
    >

    </ModuleEditing>

  );
}

