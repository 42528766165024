import React from 'react';
import './styles/listToolFinances.scss';

import { Grid } from '@material-ui/core';

import { Link } from 'react-router-dom';
import { Hidden } from '@material-ui/core';

import AlertErrors from '../../components/alertErrors/alerts';
import Pagination from "../../components/pagination/Pagination";
import SwipeableViews from "react-swipeable-views";

import api from '../../../services/api';

import Imgtest from '../../../../src/assets/image/teste-img-fotografia.png';

const Globals = require("../../../Globals.json");
class ToolFinances extends React.Component {

  state = {
    tools: [
      {
        toolThumbnail: "https://pefmbddiag.blob.core.windows.net/cdn-pi/imagens/praia.jpg",
        favorite: true, title: "Planejamento de viagem", link: "/ferramentas/planejamento",
        description: "Calcule quanto você poupa por mês para chegar ao seu destino.",
      },
      {
        toolThumbnail: Imgtest,
        favorite: true, title: "Fotografia financeira", link: "/ferramentas/fotografia",
        description: "Calcule quanto você gasta e como controlas as suas despesas.",
      },
    ],
    alertError: '',
    index: 0,
  }

  handleChangeIndex = (index) => {
    this.setState({
      index
    });
  };

  addFavorites = () => {
    const postData = {
      type_action_id: 13,
      data_modules_detail_id: this.state.data_modules_detail_id,
      type_module_id: this.state.type_module_id,
    };
    try {
      api.post(Globals.api.userActionEvent, postData)
    } catch (error) {
      this.setState({ alertError: error.response.status })
      setTimeout(() => {
        this.setState({ alertError: '' })
      }, 3000)
    }

  }

  render() {
    const { index } = this.state;
    return (
      <>
        <Hidden xsDown>
          <AlertErrors error={this.state.alertError} />
          
            {this.state.tools.map((item, index) => (
              <Grid item xs={12} className={'tools-container-cards'} key={index}>
                <Link to={item.link} className="list_tool_fin_link">
                  <div className="list_tool_fin_itemBox" style={{ margin: '4px' }}>
                    <div className="list_tool_fin_toolImage" style={{ backgroundImage: `url(${item.toolThumbnail})` }}>
                      <div className="list_tool_fin_toolContainerText">
                        <p className="list_tool_fin_toolText">{item.title}</p>
                        <p className="list_tool_fin_descriptionText">{item.description}</p>
                      </div>
                    </div>
                  </div>
                </Link>
              </Grid>
            ))}
          
        </Hidden>

        <Hidden smUp>
          <AlertErrors error={this.state.alertError} />
          <div className="containerlist_tool_fin_">
            <SwipeableViews index={index} onChangeIndex={this.handleChangeIndex} slideStyle={{ overflow: 'visible' }} >
              {this.state.tools.map((item, index) => (
                <div key={index}>
                  <Link to={item.link} className="list_tool_fin_link" >
                    <div className="list_tool_fin_itemBox">
                      <div className="list_tool_fin_toolImage" style={{ backgroundImage: `url(${item.toolThumbnail})` }}>
                        <div className="list_tool_fin_toolContainerText">
                          <p className="list_tool_fin_toolText">{item.title}</p>
                          <p className="list_tool_fin_descriptionText">{item.description}</p>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              ))}
            </SwipeableViews>
          </div>
          {this.state.tools.length > 1 &&
            <Pagination
              dots={this.state.tools.length}
              index={index}
              onChangeIndex={this.handleChangeIndex}
            />
          }
        </Hidden>

      </>
    )
  }
}

export default ToolFinances;
