import React from 'react';
import '../../../backoffice.scss';
import ModuleListing from '../../components/backoffice/components/ModuleListing/ModuleListing';
const Globals = require("../../../Globals.json");

export default function Quizzes() {

  return (
    <ModuleListing
      title="Lista de Quizzes"
      buttonLabel="Novo Quiz"
      typeModuleId={1}
      fullUrl={Globals.paths.backofficeEditQuiz}
      urlReplacePattern=":quizId" />
  )

}

