import React from 'react';
import './personalReport.scss';

import { withStyles, Grid, Hidden, CircularProgress } from '@material-ui/core';

import api from "../../../services/api";

import AlertErrors from '../../../logged_in/components/alertErrors/alerts';
import Button from '../../../logged_in/components/button/Button';
import Chart from './Chart/Chart';
import FeedItem from '../../../logged_in/components/feed/FeedItem';
import FeedLine from '../../../logged_in/components/feed/FeedLine';
import LastCertificate from '../../../logged_in/components/sidebar/LastCertificate'
import LastTrophy from '../../../logged_in/components/sidebar/LastTrophy';
import Perfil from './Perfil/Perfil';
import PointsBalance from '../../../logged_in/components/sidebar/PointsBalance'
import QrCode from './QrCode/QrCode';
import ToolbarItem from '../../../logged_in/components/toolbar/ToolbarItem';

import Logo from '../../../assets/logo/hyst_new_logo.svg'
import { Dialog, DialogContent } from '@material-ui/core';
import {
  FacebookIcon,
  WhatsappIcon,
  LinkedinIcon,
  TwitterIcon,
  FacebookShareButton,
  WhatsappShareButton,
  LinkedinShareButton,
  TwitterShareButton
} from "react-share";
import { getNextIndexAssessmentDateText } from '../../../services/util';
import SmallProfileGauge from '../../../logged_in/components/profileGauge/SmallProfileGauge';


const Globals = require("../../../Globals.json");

const styles = theme => ({
  "@global": {
    body: {
      overflow: "auto",
    },

    ".MuiGrid-grid-md-3": {
      flexGrow: "0",
      maxWidth: "25%",
      flexBasis: "25%",
      padding: "0px 20px 0px 0px",
      marginTop: "-40px !important",
      zIndex: "1",
    },
  },

  msg: {
    fontSize: "16px",
    fontWeight: "400",
    lineHeight: "20px",
    color: "#ccc",
    paddingLeft: "20px"
  },
  classContainer:
  {
    width: "100%",
    paddingRight: "10px",
    marginBottom: "70px",
    padding: "0 30px 0px 20px",
    marginTop: "-40px",
    zIndex: "1",
  },
  container: {
    display: "flex",
    minHeight: "550px",
    width: "100%",
    marginBottom: "10px",
    [theme.breakpoints.down('xs')]: {
      minHeight: "480px",
    },
  },
  boxReport: {
    display: "flex",
    backgroundColor: "#fff",
    width: "100%",
    height: "100%",
    padding: "4% 2% 2% 2%",
  },
  boxLeft: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-end",
    paddingRight: "108px",
    height: "100%",
    width: "43%",
    [theme.breakpoints.down('xs')]: {
      height: "452px",
    },
    '@media screen and (max-width: 1250px)': {
      padding: "0px",
      justifyContent: "flex-start",
      alignItems: "center",
    },
  },
  boxLeftQrcode: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    height: "100%",
    // width: "40%",
    [theme.breakpoints.down('xs')]: {
      height: "540px",
    },
  },
  boxRight: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    height: "100%",
    width: "55%",
    [theme.breakpoints.down('xs')]: {
    },
  },
  infomationItem: {
    marginBottom: "20px",

  },
  infomation: {
    fontSize: "18px",
    fontWeight: "bold",
    [theme.breakpoints.down('xs')]: {
      fontSize: "14px",
    },
    '@media screen and (max-width: 800px)': {
      fontSize: "16px",
    },
  },
  infomationText: {
    fontSize: "18px",
    [theme.breakpoints.down('xs')]: {
      fontSize: "12px",
    },
    '@media screen and (max-width: 800px)': {
      fontSize: "14px",
    },
  },
  textHeader: {
    fontSize: "12px"
  },
  informationHeader: {
    display: "flex",
    flexDirection: "column",
    marginBottom: "30px",
  },
  titleHeader: {
    margin: "0px",
    fontSize: "26px",
    fontWeight: "700",
    [theme.breakpoints.down('xs')]: {
      fontSize: "16px",
    },
    '@media screen and (max-width: 800px)': {
      fontSize: "20px",
    },
  },
  avatar: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#e6e6e6",
    width: "180px",
    height: "180px",
    borderRadius: "50%",
    border: "0px",
    padding: "0px",
    '@media screen and (max-width: 800px)': {
      width: "140px",
      height: "140px",
    },
    [theme.breakpoints.down('xs')]: {
      width: "120px",
      height: "120px",
    },
  },
  teste: {
    '@media print': {
      paddingTop: '-150px',
    },
  }
});

class PersonalReport extends React.Component {

  state = {
    address: "",
    uuid: "",
    id: "",
    name: "",
    email: "",
    created_at: "",
    country: "",
    state: "",
    cpf: "",
    phone: "",
    zipCode: "",
    user_avatar: '',
    isfb: 0,
    coins: 0,
    trophy: 0,
    totalCertificates: 0,
    isLoading: false,
    landmark: '',
    estado: '',
    open: false,
    width: 0,
    alertError: '',
    nextIndexAssessmentDateText: '',
    indexData: {
      indexScore: 0
    },
    userId: null,
    baseUrl: '',
    errorMessage: ''
  }


  openModal = () => {
    this.setState({ open: true })
  }

  handlePrint = () => {
    window.print();
  }

  async loadData() {

    this.setState({ isLoading: true });
    this.setState({ nextIndexAssessmentDateText: await getNextIndexAssessmentDateText() });
    let getToken = '';
    let hasToken = false;

    try {

      // if (localStorage.getItem("@fbb-Token") !== null) {
      //   hasToken = true
      //   getToken = localStorage.getItem('@fbb-Token');

      //   localStorage.removeItem('@fbb-Token')
      // }

      const userId = this.props.match.params.userId

      const response = await api.get(`${Globals.api.userInfoReport}/${userId}`);

      console.log('responseeeeeeee: ', response.data)

      let fullCpf = response.data.cpf_cnpj;
      let phone = response.data.phone
      let date = response.data.created_at;

      const formattedCpf = fullCpf && fullCpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4")
      const formattedPhone = phone && phone.replace(/(\d{2})/, "$1 ");
      const createdAt = date && date.substring(0, 10).split('-').reverse().join('/');

      this.setState({
        cpf: formattedCpf,
        uuid: response.data.uuid,
        id: response.data.id,
        name: response.data.username,
        email: response.data.email,
        state: response.data.state,
        address: response.data.adress,
        cadIbgeCounty: response.data.cadIbgeCounty,
        cadIbgeState: response.data.cadIbgeState,
        country: response.data.country,
        zipCode: response.data.zip_code,
        desempenho: response.data.desempenho,
        created_at: createdAt,
        phone: formattedPhone,
        landmark: response.data.landmark,
        estado: response.data.state,
        trophy: response.data.total_trofeis,
        isfb: response.data.isfb,
        dataChart: response.data.desempenho.evolucao_grafico,
        coins: response.data.progress.total_moedas,
        totalCertificates: response.data.desempenho.cursos_concluidos,
        isLoading: false,
        user_avatar: response.data.user_avatar,
        userId: response.data.id,
        indexData: response.data.indexData || this.state.indexData,


      });

      // if (hasToken) {
      //   localStorage.setItem('@fbb-Token', getToken)
      // }
    } catch (error) {
      if (hasToken) {
        localStorage.setItem('@fbb-Token', getToken)
      }
      console.log('errorrrrrrr: ', error.response.data)
      if (error.response.data?.message) {
        this.setState({ errorMessage: error.response.data.message });
      } else {
        this.setState({ errorMessage: 'Não foi possível gerar o relatório solicitado.' });
      }

      // this.setState({ alertError: error.response.status })
      // setTimeout(() => {
      //   this.setState({ alertError: '' })
      // }, 3000)
    }
  }

  componentDidMount() {
    const baseUrl = window.location.origin
    this.loadData();
    this.setState({ width: window.innerWidth, height: window.innerHeight, baseUrl: baseUrl });
  }

  // abre pagina com parametro pdf
  gerarPdf = () => {
    window.open(`pdf/${this.state.uuid}`, '_blank', 'width=900', 'height=600', 'overflow=hidden')
  }

  render() {

    const { classes } = this.props;

    return (
      <>
        <AlertErrors error={this.state.alertError} />
        {this.state.isLoading ? <div className="loading">
          {this.state.errorMessage || 'Carregando...'}
        </div> : (
          <Grid container className="containerReport">
            <div className="report-container-report">
              <header>
                <div className="container-990 header-flex" id="divToPrint">
                  <div className='logo-container'>
                    <img src={Logo} alt="Logo" className="logo" />
                  </div>

                  <div className="buttons">
                    <Button width="150px" fontSize="14px" onClick={() => this.gerarPdf()}>GERAR PDF</Button>
                    <div className={`position-btn`}>
                      <Button maxWidth="150px" fontSize="14px" onClick={this.openModal}>Compartilhar</Button>
                      {this.props.pdf}
                    </div>
                  </div>
                </div>
              </header>
            </div>
            <Grid item xs={12} sm={8} md={9} className={classes.classContainer}>

              <FeedLine>
                <FeedItem title="Informações Cadastrais" expandHeight>
                  <Perfil
                    created_at={this.state.created_at}
                    name={this.state.name}
                    phone={this.state.phone}
                    email={this.state.email}
                    cpf={this.state.cpf}
                    zipCode={this.state.zipCode}
                    landmark={this.state.landmark}
                    estado={this.state.estado}
                    cadIbgeCounty={this.state.cadIbgeCounty}
                    cadIbgeState={this.state.cadIbgeState}
                    user_avatar={this.state.user_avatar}
                  />
                </FeedItem>
              </FeedLine>

              <FeedLine>
                <FeedItem title="Evolução - últimos 6 meses" overflowHidden expandHeight>
                  <Chart data={this.state.dataChart} responseChart noMediaQuery="noMediaQuery" />
                </FeedItem>
              </FeedLine>

              <FeedLine>
                <FeedItem expandHeight>
                  <QrCode
                    desempenho={this.state.desempenho}
                    uuid={this.state.uuid}
                    trophy={this.state.trophy}
                  />
                </FeedItem>
              </FeedLine>

            </Grid>

            <Hidden xsDown>
              <Grid item container sm={4} md={3} direction="column" >



                <ToolbarItem title="Saldo de Pontos" color="#45AF98"
                  tooltip="Os Pontos representam o reconhecimento do seu engajamento no uso da Plataforma. Praticamente qualquer atividade da Plataforma é 
                  recompensada com uma quantidade de pontos ao ser completada. Junte os pontos e as troque por benefícios e conteúdos exclusivos da Plataforma." >
                  <PointsBalance coins={this.props.coins} ></PointsBalance>
                </ToolbarItem>

                <ToolbarItem className="toolbar" title="Certificados" color="#45AF98">
                  <LastCertificate quantity={this.state.totalCertificates} />
                </ToolbarItem>

                <ToolbarItem className="toolbar" title="Troféus" color="#45AF98">
                  <LastTrophy quantity={this.state.trophy} />
                </ToolbarItem>

              </Grid>
            </Hidden>

            <Dialog
              open={this.state.open}
              onClose={() => this.setState({ open: false })}
              maxWidth={"xs"}
              className="modal"
            >
              <DialogContent className="form share-box">
                <div className="header">
                  <h2>Compartilhe seu perfil</h2>
                  <button type="button" className="close"
                    onClick={() => this.setState({ open: false })}>&times;
                  </button>
                </div>

                <div className="share">
                  <FacebookShareButton
                    url={this.state.baseUrl + Globals.paths.personalReport.replace(':userId', this.state.uuid)}
                    quote={"Meu Perfil - Meu Bolso em Dia!"}
                    hashtag="#febraban" >
                    <FacebookIcon size={36} />
                  </FacebookShareButton>

                  <WhatsappShareButton
                    url={this.state.baseUrl + Globals.paths.personalReport.replace(':userId', this.state.uuid)}
                    title={"Meu Perfil - Meu Bolso em Dia!"}
                  >
                    <WhatsappIcon size={36} />
                  </WhatsappShareButton>

                  <TwitterShareButton
                    url={this.state.baseUrl + Globals.paths.personalReport.replace(':userId', this.state.uuid)}
                    title={"Meu Perfil - Meu Bolso em Dia!"}
                    hashtag="#febraban"
                  >
                    <TwitterIcon size={36} />
                  </TwitterShareButton>

                  <LinkedinShareButton
                    url={this.state.baseUrl + Globals.paths.personalReport.replace(':userId', this.state.uuid)}
                    title={"Meu Perfil - Meu Bolso em Dia!"}
                  >
                    <LinkedinIcon size={36} />
                  </LinkedinShareButton>
                </div>
              </DialogContent>
            </Dialog>

          </Grid >
        )}
      </>
    )
  }
}

export default withStyles(styles)(PersonalReport);

