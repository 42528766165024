import React, { useEffect, useState } from 'react';
import { Dialog, DialogContent, TextField, CircularProgress } from '@material-ui/core';
import api from "../../../services/api";
import useStyles from './styles';
import Globals from '../../../Globals.json';
import * as Styled from './styles';

function ContactForm({ isOpened, onClose }) {
  const [opened, setOpened] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [step, setStep] = useState(1);
  const [formErrors, setFormErrors] = useState({
    title: false,
    message: false,
  });
  const [formData, setFormData] = useState({
    title: '',
    message: '',
  });
  const classes = useStyles();

  useEffect(() => {
    setOpened(isOpened);
  }, [isOpened]);

  function handleClose() {
    onClose();
    setTimeout(() => {
      setStep(1);
      setFormData({
        title: '',
        message: '',
      });
    }, 1000);
  }

  function sendForm() {
    const errors = {};

    if (formData.title?.trim() === '') {
      errors.title = 'Nos diga qual o título da sua mensagem!';
    }

    if (formData.message?.trim() === '') {
      errors.message = 'A mensagem é obrigatória';
    }

    setFormErrors(errors);
    
    if (!Object.keys(errors).length) {
      setIsLoading(true);
      api
        .post(Globals.api.sendContactForm, formData)
        .catch(() => { /* do nothing */ })
        .finally(() =>
          setTimeout(() => {
            setIsLoading(false);
            setStep(2);
          }, 2000)
        );
    }
  }

  function handleChange(evt) {
    const { name, value } = evt.target;

    setFormErrors({
      title: false,
      message: false,
    });

    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }

  function renderSteps() {
    if (step === 1) {
      return (
        <>
          <h2>Fale Conosco / E-mail</h2>
          <h3>Envie sua mensagem</h3>
          <p>
            Envie sua mensagem por aqui e daremos continuidade via e-mail. Por isso, garanta que o
            seu e-mail cadastrado na plataforma esteja atualizado.
          </p>
          {isLoading ? (
            <div style={{ textAlign: 'center', marginBottom: '20px' }}>
              <CircularProgress />
            </div>
          ) : (
            <form>
              <TextField
                size="small"
                variant="outlined"
                label="Título"
                required
                fullWidth
                style={{ marginBottom: '30px' }}
                onChange={handleChange}
                error={formErrors.title}
                helperText={formErrors.title}
                value={formData.title}
                name="title"
              />
              <TextField
                size="small"
                variant="outlined"
                label="Mensagem"
                required
                fullWidth
                multiline="true"
                rows={4}
                onChange={handleChange}
                error={formErrors.message}
                helperText={formErrors.message}
                value={formData.message}
                name="message"
              />
            </form>
          )}
        </>
      );
    } else if (step === 2) {
      return (
        <>
          <h2>Fale Conosco / E-mail</h2>
          <h3>Obrigado!</h3>
          <p>
            Obrigado por ter nos enviado uma mensagem! Nossa equipe entrará em contato o mais breve
            possível.
          </p>
        </>
      );
    }

    return null;
  }

  return (
    <Dialog
      open={opened}
      onClose={handleClose}
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
      fullWidth={true}
      maxWidth="md"
    >
      <DialogContent spacing={0} className={classes.dialogContent}>
        <Styled.MainContent>
          <aside>
            <figure className="aside-image">
              <img
                src="./images/contact-form-bg.png"
                alt="Entre em contato com o Meu Bolso em Dia"
              />
            </figure>
          </aside>
          <section className="main">
            <h1>Entre em contato com o Meu Bolso em Dia</h1>
            <div className="main__content">
              <div className="steps">{renderSteps()}</div>
            </div>
            <div hidden={isLoading} className="main__actions">
            <button className="main__actions__button" onClick={handleClose}>
              Fechar
            </button>
            {step === 1 && <button className="main__actions__button main__actions__button--blue" onClick={sendForm}>
              Enviar
            </button>}
            </div>
          </section>
        </Styled.MainContent>
      </DialogContent>
    </Dialog>
  );
}

export default ContactForm;
