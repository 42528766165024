
const Dialogs = {};

let setDialogData = () => {};
let dialogData = {};

Dialogs.setDialogSetter = (fn) => setDialogData = fn ;

Dialogs.show = ( title, message, buttons = null ) => {
  if( !setDialogData ) {
    console.error( "dialogs.alert():: setDialogSetter não foi especificado." );
    return;
  }
  dialogData = {
    isOpened: true,
    title: title,
    message: message,
    buttons: buttons ?? [{label: "OK"}],
  }

  setDialogData( dialogData );
}

Dialogs.close = () => {
  if( !setDialogData ) {
    console.error( "dialogs.close():: setDialogSetter não foi especificado." );
    return;
  }
  dialogData = {
    ...dialogData,
    isOpened: false,
  }
  setDialogData( dialogData );
}

export default Dialogs;