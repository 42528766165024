import React from 'react';
import './styles/feed.scss';

import { Grid } from '@material-ui/core';
class FeedLine extends React.Component {
    render() {
        return (

            <Grid item xs={12} className="line_feedLine">
                { this.props.children}
            </Grid>
        )
    }

}

export default FeedLine;

