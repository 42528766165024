import React, { useCallback, useEffect, useState } from 'react';
import TagManager from 'react-gtm-module';
import * as Styled from './styles';

function CookiesWarning() {
  const [showWarning, setShowWarning] = useState(false);
  const tagManagerArgs = { gtmId: 'GTM-MFQKPLB' };
  const tagManagerArgs2 = { gtmId: 'GTM-NWKGCS2' };

  const injectScripts = useCallback(() => {
    setShowWarning(false);
    //TagManager.initialize(tagManagerArgs);
    //TagManager.initialize(tagManagerArgs2);
  }, [tagManagerArgs,tagManagerArgs2]);

  function agreeWithCookies() {
    window.localStorage.setItem('cookiesAgreement', true);
    injectScripts();
  }

  useEffect(() => {
    const userDef = window.localStorage.getItem('cookiesAgreement');

    if (!userDef) {
      setShowWarning(true);
    } else {
      injectScripts();
    }
  }, [injectScripts]);

  if (!showWarning) {
    return null;
  }

  return (
    <Styled.Container>
      <div className="content">
        <div className="innerWrapper">
          <p>
            O MEU BOLSO EM DIA utiliza cookies para auxiliar sua navegação,
            aprimorar o conteúdo do nosso site, direcionar conteúdos de
            marketing, realizar análises estatísticas e conectá-lo com mídias
            sociais, garantindo a melhor experiência possível. Para saber mais
            sobre cookies, acesse nossa Política de Privacidade.{' '}
            <a
              href="https://pefmbddiag.blob.core.windows.net/cdn-pi/termos_consentimento.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              Ver mais.
            </a>
          </p>
          <button type="button" onClick={agreeWithCookies}>
            Ok
          </button>
        </div>
      </div>
    </Styled.Container>
  );
}

export default CookiesWarning;
