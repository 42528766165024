import styled from 'styled-components';
import { withTheme } from "@material-ui/core/styles";
import { ReactComponent as Logo } from '../../../../assets/logo/logo-footer-white.svg';

export const Footer = withTheme(styled('footer')`
  background-color: #004178;
  border-top: 10px solid #4FAF98;
  color: #fff;

  .inner-wrapper {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin: auto;
    max-width: 1440px;
    padding: 63px 73px;

    @media(max-width: ${({ theme }) => `${theme.breakpoints.values.sm}px`}) {
      flex-direction: column;
      align-items: flex-start;
      padding: 40px;
    }

    @media only screen and (
      min-width: ${({ theme }) => `${theme.breakpoints.values.sm}px`}
    ) and (max-width: ${({ theme }) => `${theme.breakpoints.values.md}px`}) {
      padding: 40px;
      flex-wrap: wrap;
    }

    .rights-reserved {
      &__text {
        font-size: 14px;
        margin: 0;

        @media(max-width: ${({ theme }) => `${theme.breakpoints.values.sm}px`}) {
          display: none;
        }

        /* TABLET */
        @media only screen and (
          min-width: ${({ theme }) => `${theme.breakpoints.values.sm}px`}
        ) and (max-width: ${({ theme }) => `${theme.breakpoints.values.md}px`}) {
          display: none;
        }
      }

      &--mobile {
        display: none;
        
        @media(max-width: ${({ theme }) => `${theme.breakpoints.values.sm}px`}) {
          display: block;

          &__text {
            margin-top: 70px;
            font-size: 12px;
            text-align: center;
          }
        }

        @media only screen and (
          min-width: ${({ theme }) => `${theme.breakpoints.values.sm}px`}
        ) and (max-width: ${({ theme }) => `${theme.breakpoints.values.md}px`}) {
          display: block;
          flex-basis: 100%;
          margin-top: 70px;
          text-align: center;
        }
      }
    }

    nav {
      display: flex;
      flex-direction: column;

      @media(max-width: ${({ theme }) => `${theme.breakpoints.values.sm}px`}) {
        padding: 40px 0;
      }

      a {
        color: #fff;
        text-decoration: none;
        font-family: 'Caros Soft Regular';
        font-size: '14px';
        line-height: 17px;
        margin-bottom: 16px;
        min-width: 170px;

        @media(max-width: ${({ theme }) => `${theme.breakpoints.values.sm}px`}) {
          margin-bottom: 7px;
        }

        &:last-child {
          margin-bottom: 0;
        }
        
        &:hover {
          font-family: 'Caros Soft Bold';
        }
      }
    }

    .social-media {
      &__icon {
        align-items: center;
        background-color: rgba(255, 255, 255, 0.1);
        background-position: center;
        background-repeat: no-repeat;
        border-radius: 50%;
        display: inline-flex;
        height: 32px;
        margin-left: 16px;
        justify-content: center;
        overflow: hidden;
        width: 32px;
        transition: background-color .15s ease-in-out;
        text-indent: -1000px;

        @media(max-width: ${({ theme }) => `${theme.breakpoints.values.sm}px`}) {
          margin-left: initial;
          margin-right: 16px;
        }

        &:hover {
          background-color: rgba(255, 255, 255, 0.3);
        }

        &--instagram {
          background-image: url(/images/icons/social-icon-instagram.svg);
          background-size: 17px;
        }
        
        &--facebook {
          background-image: url(/images/icons/social-icon-facebook.svg);
          background-size: 33%;
        }

        &--twitter {
          background-image: url(/images/icons/social-icon-twitter.svg);
          background-position: 8px 9px;
          background-size: 55%;
        }
        
        &--youtube {
          background-image: url(/images/icons/social-icon-youtube.svg);
          background-size: 56%;
        }
      }
    }
  }
`);

export const LogoIcon = withTheme(styled(Logo)`
  width: 190px;
  height: 87px;
  margin-bottom: 40px;

  @media(max-width: ${({ theme }) => `${theme.breakpoints.values.sm}px`}) {
    margin-bottom: 0;
  }

  /* TABLET */
  @media only screen and (
    min-width: ${({ theme }) => `${theme.breakpoints.values.sm}px`}
  ) and (max-width: ${({ theme }) => `${theme.breakpoints.values.md}px`}) {
    margin-bottom: 0;
    margin-right: 30px;
  }
`)