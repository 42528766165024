import { Grid } from '@material-ui/core';
import React from 'react';
import './styles.scss';

import QRCode from 'qrcode.react';

const Globals = require("../../../../Globals.json");



class ProfileQrCode extends React.Component {

  state = {
    baseUrl: ''
  }

  componentDidMount = () => {
    const baseUrl = window.location.origin
    this.setState({ baseUrl: baseUrl });
  }
  
  render() {
    console.log(this.props.desempenho)
    const { noMediaQuery = 'mediaQuery', margin } = this.props;
    return (
      <div className={`container-qr-code ${noMediaQuery}`}>
        {this.props.desempenho !== undefined && (
          <Grid item container xs={12} sm={12} className={`containerQrCode ${this.props.print}`}>
            {/* {this.props.print ? */}
            <div className={'qr-code-content'}>
              <div className={`qr-code ${this.props.print}`}>
                <QRCode
                  value={ this.state.baseUrl + Globals.paths.personalReport.replace(':uuid', this.props.uuid)}
                  size={180}
                  bgColor={'#ffffff'}
                  fgColor={'#000000'}
                  level={'L'}
                  includeMargin={false}
                  renderAs={'svg'}
                />
              </div>
            </div>
            {/* <Grid sm={12} md={5} container
                 direction="column"
                 justify="flex-start"
                 alignItems="flex-end"
                 className="contentImage"
               >
                 <div className={`qr-code ${this.props.print}`}>
                   <QRCode
                     value={`https://pefpi.meubolsoemdia.com.br/relatorio/${this.props.userId}`}
                     size={180}
                     bgColor={"#ffffff"}
                     fgColor={"#000000"}
                     level={"L"}
                     includeMargin={false}
                     renderAs={"svg"}
                   />
                 </div>
               </Grid> */}

            <div direction="column" className="contentList">
              <section
                className={`desempenho-list ${this.props.print}`}
                style={{ marginTop: margin }}
              >
                <h1>Desempenho</h1>

                <ul>
                  <li>
                    <b>{this.props.desempenho.cursos_concluidos}</b> Cursos concluídos{' '}
                  </li>

                  <li>
                    <b>{this.props.desempenho.cursos_em_andamento}</b> Cursos em andamento{' '}
                  </li>

                  <li>
                    <b>{this.props.desempenho.artigos_lidos}</b> Artigos lidos{' '}
                  </li>
                  <li>
                    <b>{this.props.desempenho.missoes_concluidas}</b> Missões concluídas{' '}
                  </li>
                  <li>
                    <b>{this.props.desempenho.mini_quiz_concluidos}</b> Mini Quiz concluídos {' '}
                  </li>

                  {/* {!isNaN(this.props.desempenho.media_aproveitamento) &&
                    <li><b>{this.props.desempenho.media_aproveitamento}</b> Média de aproveitamento de conteúdo </li>
                  }
                  <li><b>{this.props.trophy}</b> Objetivos conquistados</li> */}
                </ul>
              </section>
            </div>
          </Grid>
        )}
      </div>
    );
  }
}
export default ProfileQrCode;
