import React from 'react';
import './styles/currencyBalance.scss';
import { watch } from 'redux-easy/lib/redux-easy';
import { Link } from 'react-router-dom';
import IconGift from '../../../assets/icon/new-icons/presente.svg'

const Globals = require("../../../Globals.json");

class CurrencyBalance extends React.Component {
  handleClick () {

    window.open(`${Globals.paths.closedShopping}`);
  }

  componentDidUpdate (newProps) {
    console.log(newProps.user, this.props.user);
  }

  componentDidMount () {
    console.log(this.props.user);
  }

  render () {
    const { text, link } = this.props
    const coins = this.props.user.profile.progress.total_moedas;



    return (
      <>
        { this.props.link ?

          <Link to={link} className="linkBalance" style={{ display: 'block' }}>
            <div className="contentCoins" >
              <img src={IconGift} alt="Icone" className="imgBalance" />
              <div className="infoBalance">
                <div className="titleBalance">Saldo de pontos</div>
                <div className="textBalance">
                  {text}
                </div>
              </div>
              <div className="coinsBalance">{coins}</div>
            </div>
          </Link> :

          <div className="contentCoins" >
            <img src={IconGift} alt="Icone" className="imgBalance" />
            <div className="infoBalance">
              <div className="titleBalance">Saldo de pontos</div>
              <div className="textBalance">
                {text}
              </div>
            </div>
            <div className="coinsBalance">{coins}</div>
          </div>
        }
      </>
    )
  }
}
export default watch((CurrencyBalance), { user: '' });
