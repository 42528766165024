import React, { useState, useCallback, useEffect } from 'react';
import { Route } from 'react-router-dom';
import * as Styled from './LandingPage.styles';
import MainNav from './MainNav';
import Hero from './Hero';
import Video from './Video';
import Faq from './Faq';
import MainJourney from './MainJourney';
import Journey from './Journey';
import Footer from './Footer';
import Debts from './Debts';
import DialogSelector from '../register_login/DialogSelector';
import RegisterStepper from '../RegisterStepper';
import { isAuthenticated } from '../../../services/auth';
import Globals from '../../../Globals.json';

function LandingPage({
  isValidatingIndebtProfile,
  isForwardToBlog,
  openModalPass,
  openModalLogin,
  emailVerified,
  faq,
  ...props
}) {
  const [dialogOpen, setDialogOpen] = useState(null);
  const [registerOpen, setRegisterOpen] = useState(false);
  const [validatingIndebtProfile, setValidatingIndebtProfile] = useState(false);
  const [forwardToBlog, setForwardToBlog] = useState(false);

  useEffect(() => {
    if (isValidatingIndebtProfile) {
      setValidatingIndebtProfile(!!isValidatingIndebtProfile);
    }

    if (emailVerified) {
      setDialogOpen('login');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openModalPass]);

  const openRegisterDialog = () => {
    //!isAuthenticated() ? setRegisterOpen(true) : props.history.push('/home');
    //para evitar que quando o caminho não é a raiz ocorra erro de history não existir
    !isAuthenticated() ? setRegisterOpen(true) : (props.history ? props.history.push('/home') : window.location.href = Globals.paths.home); 
  };

  const closeRegister = () => {
    setRegisterOpen(false);
  };

  const openForwardToBlog = () => {
    setForwardToBlog(true);
  };

  const openTermsDialog = useCallback(() => {
    setDialogOpen('termsOfService');
  }, [setDialogOpen]);

  const openVideoDialog = useCallback(() => {
    setDialogOpen('video');
  }, [setDialogOpen]);

  const closeDialog = useCallback(() => {
    setDialogOpen(null);
  }, [setDialogOpen]);

  const openLoginDialog = useCallback(() => {
    setDialogOpen('login');
  }, [setDialogOpen]);

  const openChangePasswordDialog = useCallback(() => {
    setDialogOpen('changePassword');
    setRegisterOpen(false);
  }, [setDialogOpen]);

  const openValidatingIndebtProfile = useCallback(() => {
    setValidatingIndebtProfile(true);
  }, [setValidatingIndebtProfile]);

  const getDialog = () => {
    switch (dialogOpen) {
      case 'login':
        return openLoginDialog;
      case 'changePassword':
        return openChangePasswordDialog;
      default:
        return null;
    }
  };

  return (
    <>
      <Styled.LandingPage>
        <MainNav
          {...props}
          openLoginDialog={openLoginDialog}
          openRegisterDialog={openRegisterDialog}
          openForwardToBlog={openForwardToBlog}
          openValidatingIndebtProfile={openValidatingIndebtProfile}
        />
        {faq ? (
            <Faq openRegisterDialog={openRegisterDialog} />
        ) : (
          <>
            <Hero openRegisterDialog={openRegisterDialog} />
            <Video openVideoDialog={openVideoDialog} />
            <MainJourney openRegisterDialog={openRegisterDialog} />
            <Journey openRegisterDialog={openRegisterDialog} />
            <Debts openRegisterDialog={openRegisterDialog} />
          </>
        )}
        <Footer openRegisterDialog={openRegisterDialog} />
      </Styled.LandingPage>

      <DialogSelector
        openLoginDialog={openLoginDialog}
        dialogOpen={dialogOpen}
        emailVerified={!!emailVerified}
        onClose={closeDialog}
        openTermsDialog={openTermsDialog}
        openRegisterDialog={openRegisterDialog}
        openChangePasswordDialog={openChangePasswordDialog}
      />

      {openModalPass ? (
        <Route exact path={Globals.paths.changePassword}>
          <RegisterStepper startStep={openChangePasswordDialog} />
        </Route>
      ) : null}

      {openModalLogin ? (
        <Route exact path={Globals.paths.check}>
          {getDialog()}
        </Route>
      ) : null}

      {registerOpen ? (
        <RegisterStepper
          open={registerOpen}
          startStep={validatingIndebtProfile ? 2 : 1}
          closeFunction={closeRegister}
        />
      ) : (
        validatingIndebtProfile && (
          <RegisterStepper
            open={validatingIndebtProfile}
            startStep={validatingIndebtProfile ? 2 : 1}
            closeFunction={closeRegister}
          />
        )
      )}

      {(!!isForwardToBlog || forwardToBlog) && (
        <RegisterStepper open={true} startStep={4} closeFunction={closeRegister} />
      )}
    </>
  );
}

export default LandingPage;
