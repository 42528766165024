import React from 'react';
import './styles/helpList.scss';

class HelpList extends React.Component {
  render() {
    return (
      <>
        <div className="help_list_containerCard">
          <p className="help_list_title">{this.props.title}</p>
          <p className="help_list_description">{this.props.description}</p>
        </div>
      </>
    )
  }
}

export default HelpList;
