
import React from 'react';

import { withRouter } from 'react-router-dom';
import { login, isAuthenticated } from "../../../../services/auth"
import { withStyles, Grid, TextField } from '@material-ui/core';
import ButtonCircularProgress from "../../../../shared/components/ButtonCircularProgress";
import { dispatchSet } from 'redux-easy/lib/redux-easy';

import Button from '@material-ui/core/Button';
import VisibilityPasswordTextField from '../../../../shared/components/VisibilityPasswordTextField';

import Logo from '../../../../assets/logo/MBED_horizontal_inversao_preferencial.png'
import api from '../../../../services/api';

const styles = theme => ({
  header: {
    display: "flex",
    flexDirection: "column",
    height: "80px",
  },
  passReqText:
  {
    paddingLeft: "5px",
    whiteSpace: "nowrap",
  },
  form: {
    backgroundColor: "#fff",
    height: "508px",
    width: "451px",
    display: "flex",
    flexDirection: "column",
    padding: "35px",
    position: "relative",
    marginTop: "120px",
  },
  container: {
    display: "flex",
    height: "100vh",
    justifyContent: "center",
    backgroundColor: "#d3d3d3",
  },
  headerTitle: {
    fontSize: "20px",
    fontWeight: "bold",
    color: "#004178",
  },
  headerEmail: {
    fontSize: "16px",
    color: "#4EAF98",
  },
  inputs: {
    marginTop: "8px",
    width: "60%",
    height: "40px",
    border: "1px solid #D3D3D3",
    paddingLeft: "5px",
    fontSize: "14px"
  },
  logo: {
    width: "400px",
    marginTop: "-30px",
  },
  textsForm: {
    fontSize: "16px",
    color: "#2E2E2E",
    marginBottom: "8px",
  },
  containerButton: {
    marginTop: "40px",
  },
  borderGreen: {
    width: "20%",
    height: "10px",
    position: "absolute",
    backgroundColor: "#4EAF98",
    bottom: 0,
    right: 0,
  },
  header2:
  {
    backgroundColor: "#004178",
    height: "120px",
    width: "100vw",
    boxSizing: "border-box",
    padding: "0px 5vw 0px 5vw",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  },
  styleButton: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    border: "transparent 1px solid",
    color: "#FFF",
    fontSize: "14px",
    fontWeight: "bold",
    transition: "color .3s ease-out",
    height: "40px",
    cursor: "pointer",
    borderRadius: "0px",
    backgroundColor: "#07487F",
    "&:hover": {
      color: "#FFF",
      backgroundColor: "#07487F"
    }
  },
  arrowIcon: {
    width: "40px",
    height: "40px",
    padding: "0px",
    marginRight: "-5px",
  },
  textHeader: {
    fontSize: "20px",
    color: "#4EAF98",
    fontWeight: "bold",
  },

  textsError: {
    fontSize: "12px",
    color: "red",
  }

});

const Globals = require("../../../../Globals.json");

class ResetPassword extends React.Component {

  state = {
    password: "",
    email: "",
    message: "",
    messageErro: "",
    isLoading: false,
    alertError: '',
    status: "",
    next: false
  }

  handleSignIn = async () => {
    if (this.state.email === "" || this.state.password === "") {
      this.setState({ status: "Preencha e-mail e senha para continuar!" });
    } else {
      try {
        this.setState({ isLoading: true });
        const response = await api.post(Globals.api.login,
          {
            email: this.state.email,
            password: this.state.password
          });
        login(response.data.data.token);
        const user = await api.get(Globals.api.selfInfo);
        dispatchSet("user.profile", user.data);

        this.props.history.push(Globals.paths.backofficeHome)
      } catch (err) {
        if (typeof err.response.data.status !== "undefined") {
          err.response.data.status === 4 && this.setState({ status: "A combinação de e-mail e senha não pode ser reconhecida. Por favor, verifique os dados informados." })
          err.response.data.status === 1 && this.setState({ status: "E-mail não verificado. Por favor, verifique sua caixa de e-mail." })
          err.response.data.status === 3 && this.setState({ status: "E-mail não cadastrado na plataforma." })
        }
        this.setState({ isLoading: false })
        setTimeout(() => {
          this.setState({ isLoading: '', alertError: '' })
        }, 3000)
      }
    }
  };

  componentDidMount = () => {
    if (isAuthenticated()) {
      this.props.history.push(Globals.paths.backofficeHome)
    }
  }

  render() {


    const { classes } = this.props;

    return (
      <React.Fragment>
        <div className={classes.container}>
          <div className={classes.form} >
            <div className={classes.header}>
              <span className={classes.headerTitle} >Login</span>
            </div>
            <span className={classes.textsForm}>Usuário / E-mail</span>
            <TextField
              className={classes.inputField}
              size="small"
              variant="outlined"
              placeholder="Digite seu usuário / e-mail"
              required
              fullWidth
              onChange={(e) => {
                this.setState({ email: e.target.value });
              }}
            />
            <span className={classes.textsError}>{this.state.status}</span>
            <br />
            <span className={classes.textsForm}>Senha*</span>
            <VisibilityPasswordTextField
              className={classes.inputField}
              size="small"
              variant="outlined"
              placeholder="Digite sua senha"
              required
              fullWidth
              onChange={(e) => {
                this.setState({ password: e.target.value });
              }}
              isVisible={this.state.isConfirmPasswordVisible}
              onVisibilityChange={(val) => { this.setState({ isConfirmPasswordVisible: val }) }}
            />
            <span className={classes.textsError}>{this.state.status}</span>

            <div className={classes.containerButton}>
              <Button
                variant="contained"
                className={classes.styleButton}
                onClick={() => this.handleSignIn()}
                disabled={this.state.isLoading}
                type="button"
              >
                {this.state.isLoading ? <ButtonCircularProgress /> : "Entrar"}
              </Button>
            </div>
            <br />
            <span style={{ fontSize: "16px", color: "#f00", marginLeft: "4px" }}>{this.state.messageErro}</span>
            <div className={classes.borderGreen}></div>
          </div>
        </div>
      </React.Fragment >
    )
  }
}
export default withStyles(styles)(withRouter(ResetPassword));
