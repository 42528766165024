import { Dialog, FormControl, InputLabel, Select, Slider, TextField } from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import '../../../backoffice.scss';

import ModuleEditing from '../../components/backoffice/components/ModuleEditing/ModuleEditing';

import Globals from "../../../Globals.json";
import Button from '../../components/button/Button';
import FileUploader from '../../components/backoffice/components/FileUploader/FileUploader';
import QuizResultEditor from '../../components/backoffice/components/Quiz/QuizResultEditor';
import ChildrenListing from '../../components/backoffice/components/Listing/ChildrenListing';
import QuizQuestionEditor from '../../components/backoffice/components/Quiz/QuizQuestionEditor';
import api from '../../../services/api';


export default function EditQuiz() {

  const [typeName, setTypeName] = useState('');
  const [type, setType] = useState('range');

  const [container, setContainer] = useState({});
  const [itemChildren, setItemChildren] = useState([]);

  const [childrenReordered, setChildrenReordered] = useState( false );

  const [breadcrumbs, setBreadcrumbs] = useState([]);

  const [results, setResults] = useState([]);
  const [currentResult, setCurrentResult] = useState(null);
  const [openEditResult, setOpenEditResult] = useState(false);

  const [openEditQuestion, setOpenEditQuestion] = useState(false);
  const [currentQuestion, setCurrentQuestion] = useState(null);

  const params = useParams();
  const history = useHistory();

  const onReceiveData = (container, children) => {

    setContainer(container);
    setItemChildren(children);

    setTypeName(container.data.typeName);
    setType(container.data.type ?? 'range');
    setResults(container.data.results ?? []);

    setBreadcrumbs([
      {
        label: "Quizzes",
        link: Globals.paths.backofficeQuizzes
      },
      {
        label: container.name
      }
    ]);

  }

  const addResult = () => {
    setCurrentResult(null);
    setOpenEditResult(true);
  }

  const editResult = (result) => {
    setCurrentResult(result);
    setOpenEditResult(true);
  }

  const onSaveResult = (resultData) => {

    const resultsArray = [...results];
    console.log(resultsArray);

    if (currentResult) {
      const index = resultsArray.indexOf(currentResult);
      resultsArray.splice(index, 1);
    }

    resultsArray.push(resultData);

    if (type === 'range') {
      resultsArray.sort(sortByRange);
    }
    else {
      resultsArray.sort(sortByTitle);
    }

    console.log(resultsArray);

    setResults(resultsArray);

    setOpenEditResult(false);

  }

  const sortByRange = (a, b) => (a.min || 0) - (b.min || 0);
  const sortByTitle = (a, b) => a.title < b.title ? -1 : 1;

  const onResultClose = () => setOpenEditResult(false);

  const onRemoveResult = () => {
    const index = results.indexOf(currentResult);
    results.splice(index, 1);
    setResults([...results]);
  }

  const onBeforeSaveData = (postData) => {
    postData.data.typeName = typeName;
    postData.data.type = type;
    postData.data.typeName = typeName;
    postData.data.results = results;

    if( childrenReordered )
    {
      const childrenOrder = [];
      itemChildren.forEach( (item, index) => {
        childrenOrder.push( item.id );
      })
      postData.setChildrenOrder = childrenOrder;
    }

  }

  const onQuestionClick = (item) => {

    setCurrentQuestion(item);
    setOpenEditQuestion(true);

  }

  const onCloseQuestion = () => {
    setOpenEditQuestion(false);
  }

  const onAfterQuestionSave = async () => {

    //Update children...
    const postData = {
      type_module_id: 1,
      container_id: container.id,
      type_module_status_ids: [0, 1, 2],
    };
    const { data: { children } } = await api.post(Globals.api.getModules, postData);
    setItemChildren(children);

  }

  const addQuestion = () => {
    setCurrentQuestion(null);
    setOpenEditQuestion(true);
  }

  const onQuestionsReordered = (reorderedList) => {
    //Essa função de reorenação pode ser reutilizada em outros tipos de módulo, 
    //mas tem que ser alterada de acordo com os tipos de item filho que ele possui.
    const oldList = [...itemChildren];
    let newChildrenList = [];
    //Encontra cada item na sequencia ordenada e adiciona na nova lista
    reorderedList.forEach( (item, index) => {
      const oldItem = oldList.find( i => i.id === item.id );
      if( oldItem ) {
        newChildrenList.push( oldItem );
      }
    })
    setItemChildren( newChildrenList );
    setChildrenReordered( true );
  }

  const customDataFields = <div className='quiz-edit-container'>
    <TextField
      key="txtSubtitle"
      label="Subtítulo"
      value={typeName}
      onChange={(e) => setTypeName(e.target.value)}
    />

    <FormControl>
      <InputLabel htmlFor={'quiz_type'}>Tipo do Quiz</InputLabel>
      <Select
        native
        value={type}
        onChange={(e) => setType(e.target.value)}
        inputProps={{
          id: 'quiz_type',
        }}
      >
        <option value='range'>Cálculo por Pontuação</option>
        <option value='scoring'>Cálculo por Peso de Perfil</option>
      </Select>
    </FormControl>


    <div className="inner-title page-title">Resultados</div>
    <div className='quiz-edit-results-container'>
      {results.map((item, index) => (
        <div key={`result-${index}`} className='quiz-edit-result-item' onClick={() => editResult(item)}>
          <img className='quiz-result-item-image' src={item.image} alt={item.title} />
          <div className='quiz-result-item-text'>{item.title}</div>
        </div>
      ))}
      <div className='quiz-edit-result-item' onClick={() => addResult()}>
        <div className='quiz-result-item-plus'><span>+</span></div>
        <div className='quiz-result-item-text'><span>Adicionar resultado</span></div>
      </div>
    </div>

    {openEditResult &&
      <QuizResultEditor
        quizType={type}
        resultData={currentResult}
        open={openEditResult}
        onSave={onSaveResult}
        onClose={onResultClose}
        onRemove={onRemoveResult}
      />
    }

    {container.id &&
      <>
        < ChildrenListing
          label="Perguntas"
          columns={[{ label: "Pergunta", field: "description" }]}
          items={itemChildren}
          onClick={(item) => onQuestionClick(item)}
          headless
          ordenable
          onReorder={(orderedList) => onQuestionsReordered(orderedList) }
        />

        <Button
          style={{ marginTop: "10px" }}
          icon="none"
          type={'darkBlue'}
          textAlign="center"
          height="40px"
          onClick={() => addQuestion()}
        >
          Adicionar Pergunta
        </Button>

        <QuizQuestionEditor
          quizType={type}
          questionId={currentQuestion?.id ?? null}
          parentId={container.id}
          results={results}
          open={openEditQuestion}
          onClose={onCloseQuestion}
          onSave={onAfterQuestionSave}
        />
      </>
    }

  </div>;

  return (
    <>

      <ModuleEditing
        isNew={params.quizId === "new"}
        typeModuleId={1}
        moduleId={params.quizId}
        title="Detalhe de Quiz"
        customDataFields={customDataFields}
        onReceiveData={onReceiveData}
        onBeforeSaveData={onBeforeSaveData}
        breadcrumbs={breadcrumbs}
      >
      </ModuleEditing>
    </>
  );
}

