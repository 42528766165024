
import React from 'react';
import { withStyles, Grid, Paper, Box, Tooltip } from '@material-ui/core';
import './styles.scss';

const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: '#f5f5f9',
        color: '#2E2E2E',
        maxWidth: 220,
        fontSize: "14px",
        border: '1px solid #dadde9',
    },
}))(Tooltip);

class ToolbarItem extends React.Component {

    render() {

        const { title, color, tooltip, print = "none", hiddenTooltip, overflow } = this.props;

        return (

            <Paper className={`paper ${print}`} >
                {tooltip &&
                    <HtmlTooltip title={tooltip} aria-label="indice saude financeira" placement="top">
                        <span className={`positionTooltip ${hiddenTooltip}`}>(?)</span>
                    </HtmlTooltip>
                }
                <Grid container >
                    <Grid item className="grid_text_level" xs={12} style={tooltip ? { paddingTop: "0px" } : {}}>
                        <label className="f20" >
                            {title}
                        </label>
                    </Grid>
                    <div className={`contentChildren ${overflow}`}>
                        {this.props.children}
                    </div>
                    <Grid item xs={12}>
                        {this.props.changeBox === true ?
                            <Box className="otherRectangle" style={{ backgroundColor: color }}></Box> :

                            <Box className="rectangle" style={{ backgroundColor: color }}></Box>
                        }
                    </Grid>
                </Grid>

            </Paper>
        )
    }

}

export default ToolbarItem;

