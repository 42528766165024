import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import {
  Breadcrumbs,
  CircularProgress,
  FormControlLabel,
  Grid,
  Link,
  Switch,
  TextField,
} from '@material-ui/core';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import PropTypes from 'prop-types';
import api from '../../../../../services/api';
import Dialogs from '../../../../../services/dialogs';
import Button from '../../../button/Button';
import DimensionInfluenceInput from '../DimensionInfluenceInput/DimensionInfluenceInput';
import FileUploader from '../FileUploader/FileUploader';
import RewardsInput from '../RewardsInput/RewardsInput';
import Globals from '../../../../../Globals.json';
import '../../../../../backoffice.scss';

function ModuleEditing({
  customDataFields = null,
  typeModuleId,
  parentId = null,
  moduleId,
  title,
  onReceiveData = null,
  onBeforeSaveData = null,
  onAfterSaveData = null,
  onValidate = null,
  onNewData = null,
  onBack = null,
  isNew = false,
  children,
  hideThumbnailField = false,
  hideNameField = false,
  hideDescriptionField = false,
  hideRewards = false,
  hideInfluences = false,
  thumbnailFolder = null,
  breadcrumbs = [],
  noBox = false,
  hideDeletedContent = false,
  preventBackOnSave = false,

}) {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [item, setItem] = useState(null);
  const [parent, setParent] = useState(null);
  const [siblings, setSiblings] = useState(null);
  const [itemThumbnail, setItemThumbnail] = useState(null);
  const [itemName, setItemName] = useState('');
  const [itemDescription, setItemDescription] = useState('');
  const [itemStatus, setItemStatus] = useState('');
  const [, setItemChildren] = useState([]);
  const [influenceTags, setInfluenceTags] = useState(null);
  const [, setItemData] = useState(null);
  const [dataRewards, setDataReward] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const newData = (parent) => {
    setItem({});
    setItemStatus(0);
    setItemThumbnail(null);
    setItemName('');
    setItemDescription('');
    if (onNewData) onNewData(parent);
    setItemChildren([]);
  };

  const loadData = useCallback(async () => {
    setIsLoading(true);
    const type_module_status_ids = []
    
    hideDeletedContent ? type_module_status_ids.push(...[0, 1]) : type_module_status_ids.push(...[0, 1, 2]);    

    let responseParent = null;
    if (parentId) {
      responseParent = await api.post(Globals.api.getModules, {
        container_id: parentId,
        type_module_status_ids,
      });
      setParent(responseParent.data.container);
      setSiblings(responseParent.data.children);
    }

    if (isNew) {
      newData(parentId ? responseParent.data.container : null);
      setIsLoading(false);
      return;
    }
    const postData = {
      type_module_id: typeModuleId,
      container_id: moduleId,
      type_module_status_ids,
    };

    const { data: { container, children } } = await api.post(Globals.api.getModules, postData);

    if (!container) {
      setErrorMessage('Id/Tipo Invalidos: ' + moduleId + '/' + typeModuleId);
      return;
    }
    setItem(container);
    setItemStatus(container.type_module_status_id);
    setItemThumbnail(container.thumbnail);
    setItemName(container.name);
    setItemDescription(container.description);
    setItemData(container.data);
    setDataReward(container.data.rewards ?? []);

    if (onReceiveData)
      await onReceiveData(container, children, responseParent && responseParent.data.container);

    setItemChildren(children);
    setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkLeave = async () => {
    Dialogs.show(
      <div>Confirmação</div>,
      'Tem certeza que deseja sair da edição? Qualquer alteração feita não será salva.',
      [
        {
          label: 'Cancelar',
        },
        {
          label: "Sim",
          onClick: () => ( onBack ?? history.goBack )(),
        },
      ]
    );
  };

  const checkConfirmDelete = async () => {
    Dialogs.show(
      <div>Confirmação de exclusão!</div>,
      'Tem certeza que deseja excluir este módulo?',
      [
        {
          label: 'Cancelar',
        },
        {
          label: "Sim",
          onClick: async () => {
            await deleteQuestions();
            ( onBack ?? history.goBack )();
          }
        },
      ]
    );
  };

  const deleteQuestions = async () => {
    const responseData = await api
      .delete(Globals.api.modulesDetails.replace(':moduleId', item.id))
      .catch((err) => {
        return { error: true, type: 'api', message: err.message };
      });
  }

  const validateAndSave = async () => {
    setIsLoading(true)
    try {
      if (!hideThumbnailField && !itemThumbnail) {
        Dialogs.show(
          'Thumbnail Obrigatório',
          'É necessário que uma imagem para thumbnail seja selecionada.'
        );
        return;
      }
  
      if (!hideNameField && itemName.trim().length === 0) {
        Dialogs.show('Nome Obrigatório', 'É necessário que um nome seja informado.');
        return;
      }
  
      if (!hideDescriptionField && itemDescription.trim().length === 0) {
        Dialogs.show('Descrição Obrigatória', 'É necessário que uma descrição seja informada.');
        return;
      }  
      
  
      if (onValidate && !onValidate(itemStatus)) {
        return;
      }
  
      const ret = await saveData();
      
      if (ret.error) {
        Dialogs.show(
          'Erro',
          <>
            Ocorreu um erro ao salvar as informações.
            <br />
            <b>{ret.message}</b>
          </>
        );
      } else {
        preventBackOnSave ? 
          (Dialogs.show(
            <div>Salvo com Sucesso</div>,
            'O seu story foi salvo com sucesso. Gostaria de voltar ao menu anterior?',
            [
              {
                label: 'Continuar Editando',
              },
              {
                label: "Voltar",
                onClick: () => ( onBack ?? history.goBack )(),
              },
            ]
          )) : 
          ( onBack ?? history.goBack )();
      }
    } catch (error) {
      
    } finally {
      setIsLoading(false)
    }
    
  };

  const saveData = async () => {
    if (isNew) {
      return await saveNew();
    }

    const postData = {
      name: itemName,
      description: itemDescription,
      thumbnail: itemThumbnail,
      type_module_id: item.type_module_id,
      type_module_status_id: itemStatus,
      module_order: '2',
      data: generateDataField(),
      tags: (influenceTags ?? []).map((t) => ({
        data_modules_detail_id: item.id,
        type_relevance_tag_id: t.id,
        value: t.value,
      })),
    };

    if (onBeforeSaveData) onBeforeSaveData(postData);

    const responseData = await api
      .put(Globals.api.modulesDetails.replace(':moduleId', item.id), postData)
      .catch((err) => {
        return { error: true, type: 'api', message: err.message };
      });
    if( onAfterSaveData ) onAfterSaveData( postData, responseData);

    return { error: false };
  };

  const saveNew = async () => {
    const postData = {
      name: itemName,
      description: itemDescription,
      thumbnail: itemThumbnail,
      type_module_id: typeModuleId,
      type_module_status_id: itemStatus,
      module_order: '2',
      data: generateDataField(),
      tags: (influenceTags ?? []).map((t) => ({
        data_modules_detail_id: item.id,
        type_relevance_tag_id: t.id,
        value: t.value,
      })),
    };

    if (parent) {
      postData.container_id = parent.id;
      postData.module_order = siblings.length;
    }

    if (onBeforeSaveData) onBeforeSaveData(postData);

    let responseData;

    await api
      .post(Globals.api.modulesDetails.replace(':moduleId', ''), postData)
      .then((response) => { 
        responseData = response.data;
        setItem({...item, id: responseData.id, type_module_id: responseData.type_module_id })
      })
      .catch((err) => {
        return { error: true, type: 'api', message: err.message };
      });

    if( onAfterSaveData ) onAfterSaveData( postData, responseData );

    return { error: false };
  };

  const generateDataField = () => {
    const retData = {};
    if (!hideRewards && dataRewards) {
      retData.rewards = dataRewards;
    }
    return retData;
  };

  useEffect(() => {
    loadData();
  }, [loadData]);

  if (errorMessage) {
    return <div className="loading">{errorMessage}</div>;
  }

  if (isLoading) {
    return (
      <div className="loading">
        <CircularProgress />
      </div>
    );
  }

  return (
    <div className="backoffice-content">
      {breadcrumbs.length > 0 && (
        <div className="breadcrumb-container">
          <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="Breadcrumb">
            {breadcrumbs.map((bcItem, index) => (
              <div key={index}>
                {bcItem.link ? (
                  <Link key={index} color="inherit" href={bcItem.link}>
                    {bcItem.label}
                  </Link>
                ) : (
                  <span key={index}>{bcItem.label}</span>
                )}
              </div>
            ))}
          </Breadcrumbs>
        </div>
      )}

      <Grid style={{ marginBottom: noBox ? '0px' : '70px' }}>

        <Grid className={ noBox ? '': 'box' } >

          <div className="page-title">
            {title}
          </div>

          <div className="backoffice-fields-container">
            <FormControlLabel
              control={
                <Switch
                  checked={itemStatus === 1}
                  onChange={(e) => {
                    setItemStatus(e.target.checked ? 1 : 0);
                  }}
                />
              }
              label="Modulo Ativo"
            />

            {!hideThumbnailField && (
              <>
                <div className="field-label">Thumbnail</div>
                <FileUploader
                  onChange={(url) => setItemThumbnail(url)}
                  folder={thumbnailFolder}
                  imageUrl={itemThumbnail}
                  maxFileSize={1}
                />
              </>
            )}

            {!hideNameField && (
              <TextField
                key="txtNome"
                label="Nome"
                value={itemName}
                onChange={(e) => setItemName(e.target.value)}
              />
            )}

            {!hideDescriptionField && (
              <TextField
                key="txtDesc"
                label="Descrição"
                multiline
                rowsMax={4}
                value={itemDescription}
                onChange={(e) => setItemDescription(e.target.value)}
              />
            )}

            {customDataFields ?? null}

            {!hideRewards && (
              <>
                <div className="page-title mtop">Recompensa</div>
                <RewardsInput
                  rewardData={dataRewards}
                  onUpdate={(rewards) => {
                    setDataReward(rewards);
                  }}
                />
              </>
            )}
            {!hideInfluences && item && (
              <>
                <div className="page-title mtop">
                  Influência das dimensões do Índice no conteúdo
                </div>
                <DimensionInfluenceInput
                  paramTags={item.tags}
                  updateTags={(tags) => setInfluenceTags(tags)}
                />
              </>
            )}

            {children}

            <div className="buttons-container">
              <Button
                icon="none"
                textAlign="center"
                height="40px"
                right="10px"
                onClick={() => checkLeave()}
              >
                Voltar
              </Button>

              <Button
                icon="none"
                type={'darkBlue'}
                textAlign="center"
                height="40px"
                onClick={() => validateAndSave()}
              >
                Salvar
              </Button>

              {!isNew && itemStatus === 0 && (
                <Button
                  icon="none"
                  type={'danger'}
                  textAlign="center"
                  height="40px"
                  left="10px"
                  onClick={() => checkConfirmDelete()}
                >
                  Excluir
              </Button>
              
              )}

            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

ModuleEditing.propTypes = {
  hideDeletedContent: PropTypes.bool,
  preventBackOnSave: PropTypes.bool,
  customDataFields: PropTypes.node,
  customListing: PropTypes.node,
  moduleId: PropTypes.string,
  typeModuleId: PropTypes.number.isRequired,
  parentId: PropTypes.string,
  title: PropTypes.string.isRequired,
  onReceiveData: PropTypes.func,
  onBeforeSaveData: PropTypes.func,
  onAfterSaveData: PropTypes.func,
  onValidate: PropTypes.func,
  onNewData: PropTypes.func,
  onBack: PropTypes.func,
  isNew: PropTypes.bool,
  hideThumbnailField: PropTypes.bool,
  hideNameField: PropTypes.bool,
  hideDescriptionField: PropTypes.bool,
  hideRewards: PropTypes.bool,
  hideInfluences: PropTypes.bool,
  noBox: PropTypes.bool,
  thumbnailFolder: PropTypes.string,
  breadcrumbs: PropTypes.array,
};

export default ModuleEditing;
