import React, { Component } from 'react';

import { Dialog, DialogContent, IconButton } from '@material-ui/core';
import VisibilityPasswordTextField from "../../../shared/components/VisibilityPasswordTextField";
import './styles/changePassword.scss';

import api from '../../../services/api';
import Alert from '@material-ui/lab/Alert';
import Button from '../button/Button';
import CloseIcon from '@material-ui/icons/Close';
import Festa from '../../../assets/icon/festa.png'
const Globals = require("../../../Globals.json");

class ChangePassword extends Component {

  state = {
    old_password: "",
    password: "",
    password_confirmation: "",
    setIsPasswordVisible: false,
    isOld_passwordVisible: false,
    isPasswordVisible: false,
    isPassword_confirmationVisible: false,
    openAlert: false,
    openAlertError: false,
    msg: ''
  }

  clearState = () => {
    this.setState({ old_password: "", password: "", password_confirmatio: "" })
  }

  changePassword = () => {
    if (this.state.old_password === "" && this.state.password === "" && this.state.password_confirmation === "" ) {
      this.setState({ openAlertError: true })
      this.setState({ msg: "Preencha todos os campos!" })
        setTimeout(() => {
          return this.setState({ openAlertError: false })
        }, 4000)
    } else {
      api.put(Globals.api.changePassword, {
        "old_password": this.state.old_password,
        "password": this.state.password,
        "password_confirmation": this.state.password_confirmation
      }).then(response => {
        this.setState({ openAlert: true })
        this.clearState()
          setTimeout(() => {
            return this.setState({ openAlert: false })
          }, 4000)
      }).catch(error => {
        this.setState({ openAlertError: true })
        this.setState({ msg: "Senha inválida!" })
          setTimeout(() => {
            return this.setState({ openAlertError: false })
          }, 4000)
      })
    }

  }

  onChange = (e) => {
    this.setState({ [e.target.id]: e.target.value })
  }

  render() {

    return (
      <>
        {this.state.openAlert ?
          <Alert icon={<img src={Festa} alt="Imagem de festa" style={{ padding: "0px", width: "20px" }} />} className="customAlert"
            action={<IconButton aria-label="close" color="inherit" size="small"
              onClick={() => { this.setState({ openAlert: false }) }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
            }> Senha alterada com sucesso!</Alert> : this.state.openAlertError &&

          <Alert icon={<CloseIcon style={{ padding: "0px", width: "20px", color: "#2E2E2E" }} />} className="customAlertError"
            action={<IconButton aria-label="close" color="inherit" size="small"
              onClick={() => { this.setState({ openAlert: false }) }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
            }> <span style={{ color: "#fff" }}>{this.state.msg}</span> </Alert>
        }
        <Dialog
          open={this.props.open}
          onClose={this.props.onClose}
          // hideBackdrop
          maxWidth="xs"
          className="modal"
        >

          <DialogContent>
            <div className="header">
              <h2>Alterar Senha</h2>
              <button type="button" className="close"
                onClick={this.props.onClose}>&times;</button>
            </div>
            <br /><br />

            <VisibilityPasswordTextField
              id={"old_password"}
              className="inputField"
              size="small"
              variant="outlined"
              required
              fullWidth
              placeholder="Insira a sua senha antiga"
              autoComplete="off"
              type="password"
              value={this.state.old_password}
              inputProps={{ maxLength: 15 }}
              onChange={(e) => this.onChange(e)}
              onVisibilityChange={() => this.setState({ isOld_passwordVisible: !this.state.isOld_passwordVisible })}
              isVisible={this.state.isOld_passwordVisible}
            />

            <VisibilityPasswordTextField
              id={"password"}
              className="inputField"
              size="small"
              variant="outlined"
              required
              fullWidth
              placeholder="Insira uma nova senha"
              autoComplete="off"
              type="password"
              value={this.state.password}
              inputProps={{ maxLength: 15 }}
              onChange={(e) => this.onChange(e)}
              onVisibilityChange={() => this.setState({ isPasswordVisible: !this.state.isPasswordVisible })}
              isVisible={this.state.isPasswordVisible}
            />

            <VisibilityPasswordTextField
              id={"password_confirmation"}
              className="inputField"
              size="small"
              variant="outlined"
              required
              fullWidth
              placeholder="Repita sua senha"
              autoComplete="off"
              type="password"
              value={this.state.password_confirmation}
              inputProps={{ maxLength: 15 }}
              onChange={(e) => this.onChange(e)}
              onVisibilityChange={() => this.setState({ isPassword_confirmationVisible: !this.state.isPassword_confirmationVisible })}
              isVisible={this.state.isPassword_confirmationVisible}
            />

            <div className="positionBtn" >
              <Button width="90px" textAlign="center" icon="none" onClick={this.changePassword}>Salvar</Button>
            </div>
          </DialogContent>
        </Dialog>
      </>
    )
  }

}

export default ChangePassword;