import React, { Component } from 'react';
import './styles/latestAchievements.scss';

import { Grid, Box } from '@material-ui/core';

import AlertErrors from '../../components/alertErrors/alerts';
import Button from '../button/Button';

import api from "../../../services/api";
import { watch } from 'redux-easy/lib/redux-easy';

const Globals = require("../../../Globals.json");

class LatestAchievements extends Component {

  state = {
    trophies: [],
    alertError: ''
  }

  async loadData() {
    try {
      const response = await api.get(Globals.api.latestAchievements);

      this.setState({ trophies: response.data })
    } catch (error) {
      this.setState({ alertError: error.response.status })
      setTimeout(() => {
        this.setState({ alertError: '' })
      }, 3000)
    }
  }

  componentDidMount() {
    this.loadData();
  }

  render() {
    return (
      <>
        <Grid item xs={8}>
          <Grid container className="latest_achiv_pL">
            <AlertErrors side error={this.state.alertError} />
            <div className="latest_achiv_container">
              {this.state.trophies.length > 0 ?
                <div className="latest_achiv_text">
                  <span className="latest_achiv_txtTitle"> {this.state.trophies[0].DataAssetsDetail.name}</span>
                  <span className="latest_achiv_txtDescription"> {this.state.trophies[0].DataAssetsDetail.description}</span>
                </div>
                : <span style={{ fontSize: "16px", color: "#d3d3d3" }}>
                  Você ainda não recebeu nenhuma conquista
          </span>}

            </div>
            {this.state.trophies.length > 0 &&
              <div className="latest_achiv_buttonContainer">
                {this.props.user && this.props.user.profile && this.props.user.profile.progress && this.props.user.profile.progress.level > 1 &&
                  <Button to={Globals.paths.progresso} minWidth="100%" >
                    VER CONQUISTAS
                </Button>
                }
              </div>
            }
          </Grid>
        </Grid>
        <Grid item xs={4}>
          <Grid container>
            <Box>
              {this.state.trophies.length > 0 &&
                <img className="latest_achiv_icon_study" alt="study" src={this.state.trophies[0].DataAssetsDetail.image}></img>
              }
            </Box>
          </Grid>
        </Grid>
      </>
    )
  }
}

export default watch(LatestAchievements, { user: '' });
