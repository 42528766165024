import React, { Component } from 'react';
import PropTypes from 'prop-types';

function percentageValidation (isRequired) {
  return function (props, propName) {
    const prop = props[propName];
    if (prop == null) {
      if (isRequired) {
        throw new Error('Percentage is a required.');
      }
    } else {
      if (typeof prop !== 'number') {
        return new Error(
          'Invalid percentage. Must be a number between 0 and 100.'
        );
      }
      if (prop < 0 || prop > 100) {
        return new Error(
          'Invalid percentage. Must be a number between 0 and 100.'
        );
      }
    }
  };
}

class SemiCircleProgress extends Component {

  static propTypes = {
    stroke: PropTypes.string,
    strokeWidth: PropTypes.number,
    background: PropTypes.string,
    diameter: PropTypes.number,
    orientation: PropTypes.oneOf(['up', 'down']),
    direction: PropTypes.oneOf(['left', 'right']),
    showValue: PropTypes.bool,
    typeValue: PropTypes.oneOf(['percent', 'value']),
    defs: PropTypes.arrayOf(PropTypes.element),
    percentage: percentageValidation(true),
    cssClass: PropTypes.string,
    cssStyle: PropTypes.object,
  };

  static defaultProps = {
    stroke: '#ececec',
    strokeWidth: 5,
    background: '#ccc',
    diameter: 200,
    orientation: 'up',
    direction: 'right',
    showValue: false,
    typeValue: 'value',
    percentage: 0,
    defs: [],
    cssClass: '',
    cssStyle: {},

  };

  render () {

    const coordinateForCircle = this.props.diameter / 2;
    const radius = (this.props.diameter - 2 * this.props.strokeWidth) / 2;
    const circumference = Math.PI * radius;

    let percentageValue;
    if (this.props.percentage > 100) {
      percentageValue = 100;
    } else if (this.props.percentage < 0) {
      percentageValue = 0;
    } else {
      percentageValue = this.props.percentage;
    }
    const semiCirclePercentage = percentageValue * (circumference / 100);

    let rotation;
    if (this.props.orientation === 'down') {
      if (this.props.direction === 'left') {
        rotation = 'rotate(180deg) rotateY(180deg)';
      } else {
        rotation = 'rotate(180deg)';
      }
    } else {
      if (this.props.direction === 'right') {
        rotation = 'rotateY(180deg)';
      }
    }

    return (
      <div className={`semicircle-container ${this.props.cssClass}`} style={{ position: 'relative', ...this.props.cssStyle }}>
        <svg
          width={this.props.diameter}
          height={this.props.diameter / 2}
          style={{ transform: rotation, overflow: 'hidden' }}
          viewBox={`0 0 ${this.props.diameter} ${this.props.diameter / 2}`} preserveAspectRatio="xMinYMax meet"
        >

          <defs>
            {this.props.defs.length && this.props.defs}
          </defs>
          <circle
            cx={coordinateForCircle}
            cy={coordinateForCircle}
            r={radius}
            fill='transparent'
            stroke={this.props.stroke}
            strokeWidth={this.props.strokeWidth + 6}
            strokeDasharray={`${circumference}`}
            style={{
              strokeDashoffset: circumference
            }}
          />
          <circle
            cx={coordinateForCircle}
            cy={coordinateForCircle}
            r={radius}
            fill='transparent'
            stroke={this.props.background}
            strokeWidth={this.props.strokeWidth}
            strokeDasharray={`${circumference}`}
            style={{
              strokeDashoffset: semiCirclePercentage,
              transition:
                'stroke-dashoffset .3s ease 0s, stroke-dasharray .3s ease 0s, stroke .3s'
            }}
          />
        </svg>
        {this.props.showValue && (
          <span
            className='semicircle-percent-value'
            style={{
              width: '100%',
              left: '0',
              textAlign: 'center',
              bottom: this.props.orientation === 'down' ? 'auto' : '0',
              position: 'absolute'
            }}
          >
            {this.props.typeValue === 'value' ? percentageValue : percentageValue + '%'}
          </span>
        )}
      </div>
    );
  }
}

export default SemiCircleProgress;