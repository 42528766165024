import React from 'react';
import JourneySteps from '../JourneySteps';
import Button from '../Button';
import * as Styled from './Journey.styles';

function Journey({ openRegisterDialog }) {
  const sliderImages = [
    "./images/journey-step-04-01.svg",
    "./images/journey-step-04-02.svg",
    "./images/journey-step-04-03.svg",
  ];

  return (
    <Styled.Journey>
      <div className="header">
        <h1>Inicie sua própria jornada</h1>
        <h2>Confira tudo o que podemos proporcionar para o seu aprendizado.</h2>
      </div>
      <JourneySteps bg="./images/journey-step-01.svg" size={[665, 666]}>
        <h3 className="thin">Descubra o seu <strong>índice de saúde financeira</strong></h3>
        <p>Com o índice você fica sabendo se sua saúde financeira está bem, se pode melhorar ou se precisa de cuidados especiais. É muito simples, rápido e prático.</p>
        <Button onAction={openRegisterDialog}>Calcule seu índice</Button>
      </JourneySteps>
      <JourneySteps bg="./images/journey-step-02.svg" size={[813, 498]} inverted>
        <h3 className="thin">Saiba qual é o seu <strong>ponto de partida</strong></h3>
        <p>Para se organizar financeiramente é preciso foco e disciplina. Cada etapa é importante para guiar seus passos até a conquista dos seus objetivos. Saiba por onde começar e traçar os caminhos certos para alcançar a sua meta.</p>
        <Button onAction={openRegisterDialog}>Comece já</Button>
      </JourneySteps>
      <JourneySteps bg="./images/journey-step-03.svg" size={[896, 549]}>
        <h3 className="thin">Assuma o controle <strong>das suas finanças</strong></h3>
        <p>Cuidar do seu dinheiro pode ser mais fácil, assim como conhecer os seus limites. Basta rever atitudes, começar o seu planejamento e recuperar o controle da sua vida financeira para focar no futuro.</p>
        <Button onAction={openRegisterDialog}>Comece já</Button>
      </JourneySteps>
      <JourneySteps bg={sliderImages} size={[740, 471]} inverted shrink={'49%'}>
        <h3 className="thin">Aprenda como <strong>alcançar seus objetivos</strong></h3>
        <p>Aprender, organizar e colocar em prática! Com uma jornada personalizada você pode incluir este hábito em sua rotina e pensar na realização de sonhos e metas como, um carro novo, o casamento, uma casa maior e até uma aposentadoria confortável.</p>
        <Button onAction={openRegisterDialog}>Comece já</Button>
      </JourneySteps>
    </Styled.Journey>
  )
}

export default Journey;
