import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Paper } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  paper: {
    textAlign: 'center',
    color: theme.palette.text.secondary,
    height: "150px",
    width: "260px",
    cursor: "pointer",
    boxSizing: "border-box",
    transition: "border .3s",
    borderRadius: "0px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    backgroundSize: "cover",
    outlineWidth: "3px",
    outlineStyle: "solid",
    "@media (max-width: 700px)": {
      width: "auto",
      height: "100px"
    }
  },
  cardTitle:
  {
    height: "150px",
    fontSize: "15px",
    fontWeight: "bold",
    color: "#FFFFFF",
    padding: "5px",
    paddingLeft: "15px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    backgroundColor: "#00000099",
    "@media (max-width: 700px)": {
      fontSize: "12px",
      height: "100px",
      padding: "10px"
    }
  },
}));

export default function RegisterStep3Card (props) {

  const classes = useStyles();
  const { title, onSelect, onUnselect, thumbnail } = props;
  const [selected, setSelected] = useState(false);

  const toggleSelection = () => {

    if (selected) {
      onUnselect();
    }
    else {
      onSelect();
    }

    setSelected(!selected);

  }

  return (
    <Paper className={classes.paper} onClick={toggleSelection}
      style={{ background: thumbnail !== null ? `url(${thumbnail})` : '#717171' }}>
      <Grid container direction="column">
        <Grid item className={classes.cardImage}>
        </Grid>
        <Grid item className={classes.cardTitle}>
          {title}
        </Grid>
      </Grid>
    </Paper>
  );
}
