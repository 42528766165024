import React, { useState } from "react";

import PropTypes from "prop-types";
import {
  TextField,
  Dialog,
  DialogContent,
  DialogActions,
  Typography,
  withStyles,
  Accordion,
  AccordionSummary,
  AccordionDetails
} from "@material-ui/core";

import AlertErrors from '../../../logged_in/components/alertErrors/alerts';
import api from "../../../services/api";
import ButtonCircularProgress from "../../../shared/components/ButtonCircularProgress";
import ArrowRightIcon from '@material-ui/icons/ArrowRight';

// import ExpansionPanel from '@material-ui/core/ExpansionPanel';
// import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
// import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
// import { display } from "@material-ui/system";

let email = null;
let cpf_cnpj = null;

const styles = (theme) => ({
  dialogContent: {
    paddingTop: theme.spacing(2),
    borderRadius: "0px",
  },
  dialogActions: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingRight: theme.spacing(2),
    display: "flex",
    justifyContent: "flex-start",
    borderRadius: "0px",
    margin: "10px 0px 30px 0px",
  },
  close: {
    position: "absolute",
    top: "0px",
    right: "0",
    background: "none",
    border: "none",
    fontSize: "30px",
    cursor: "pointer",
    zIndex: "9999",
    fontWeight: "bold",
    color: "#a5a5a5",
    margin: "1px 6px 0px 0px",
  },

  title: {
    color: "#004178",
    fontSize: "26px",
    fontWeight: "bold",
    margin: "15px 0px 20px 0px",
  },

  styleButton: {
    display: "flex",
    flexDirection: "row",
    textTransform: "uppercase",
    flexWrap: "nowrap",
    padding: "5px",
    justifyContent: "space-between",
    alignItems: "center",
    border: "transparent 1px solid",
    color: "#FFF",
    backgroundColor: "var(--darkBlue)",
    fontSize: "14px",
    fontWeight: "bold",
    paddingLeft: "10px",
    transition: "color .3s ease-out",
    width: "150px",
    height: "40px",
    cursor: "pointer",
    marginLeft: "17px",
  },

  colorBottom: {
    position: "absolute",
    right: "0",
    bottom: "0",
    height: "10px",
    width: "90px",
    background: "#4FAF98",
  },

  customInput: {
    height: "40px",
    fontSize: "16px",
    paddingLeft: "10px",
    fontWeight: "bold",
    width: "100%",
    marginBottom: 15
  },
  containerAccordion2:{
    width: 444,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },

  Accordion2Item:{
    width: 400,
  }

});

const Globals = require("../../../Globals.json");
function ChangePassword(props) {
  const { onClose, classes, setLoginStatus } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [alertError, setAlertError] = useState('');
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const sendPasswordEmail = () => {
    setIsLoading(true);
    setTimeout(async () => {
      setLoginStatus("verificationEmailSend");
      // setIsLoading(false);

      if (email === null && cpf_cnpj === null) {
        alert('Você precisa preencher pelo menos um dos campos');
        return false;
      }


      if (email === null) {

        try {

          await api.post(Globals.api.forgotPassword, { cpf_cnpj });
          setSuccess(true);
          setIsLoading(false);
          // alert('Verifique sua caixa de entrada e siga as instruções para resetar a senha.')
          // onClose();
        } catch (err) {
          console.log(err)
          setIsLoading(false);
          setAlertError(err.response.status)
          setTimeout(() => {
            setAlertError('')
          }, 3000)
        }

      } else {

        await api.post(Globals.api.forgotPassword, { email })
          .then(response => {
            setSuccess(true);
          }).catch(err => {
            setErrorMessage(err.response.data.message)
            setIsLoading(false);
          })
      }
    }, 1500);
  };

  return (
    <Dialog
      open
      hideBackdrop
      onClose={onClose}
      disableBackdropClick={isLoading}
      disableEscapeKeyDown={isLoading}
      maxWidth="xs"
    >
      {success === false ?
        <form
          onSubmit={(e) => {
            e.preventDefault();
            sendPasswordEmail();
          }}
        >
          <DialogContent className={classes.dialogContent}>
            <AlertErrors side error={alertError} />
            <button className={classes.close} onClick={onClose} disabled={isLoading}>
              &times;
            </button>
            <Typography className={classes.title}>
              Redefinir senha
          </Typography>
            <Typography paragraph>
              Digite seu endereço de e-mail abaixo e enviaremos instruções sobre
              como redefinir sua senha.
            </Typography>
            <input
              variant="outlined"
              margin="dense"
              placeholder="Digite seu email"
              autoFocus
              type="email"
              autoComplete="off"
              onChange={e => email = e.target.value}
              className={classes.customInput}
            />
            <p style={{ color: 'red' }}>{errorMessage && errorMessage}</p>
          </DialogContent>
          <div className={classes.containerAccordion2}>

          <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')} className={classes.Accordion2Item}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Typography className={classes.heading}>Outras opções</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography paragraph>
                Você também pode usar o CPF para resetar a senha.
          </Typography>
              <TextField
                variant="outlined"
                margin="dense"
                fullWidth
                label="CPF"
                autoFocus
                type="text"
                autoComplete="off"
                onChange={e => cpf_cnpj = e.target.value}
              />
            </AccordionDetails>
          </Accordion>

          </div>

          <DialogActions className={classes.dialogActions}>
            <button
              type="submit"
              variant="contained"
              color="primary"
              disabled={isLoading}
              className={classes.styleButton}
            >
              Redefinir
            {isLoading ? <ButtonCircularProgress /> : <ArrowRightIcon />}
            </button>
            <div className={classes.colorBottom} />
          </DialogActions>

        </form> :
        <DialogContent className={classes.dialogContent}>
          <button className={classes.close} type="button" onClick={onClose} >
            &times;
        </button>
          <Typography className={classes.title}>
            Pronto!
        </Typography>
          <Typography paragraph>
            Verifique sua caixa de entrada e siga as instruções para redefinir sua senha de acesso.
        </Typography>
          <button className={classes.styleButton} type="button" onClick={onClose}
            style={{ backgroundColor: "#FFF", color: "#004178", borderColor: "#004178", margin: "0px 0px 30px 0px" }}>
            Sair <ArrowRightIcon />
          </button>
          <div className={classes.colorBottom} />
        </DialogContent>
      }
    </Dialog>
  );
}

ChangePassword.propTypes = {
  onClose: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  setLoginStatus: PropTypes.func.isRequired,
};

export default withStyles(styles, { withTheme: true })(ChangePassword);
