import {
  Breadcrumbs,
  Grid,
  Hidden,
  Snackbar,
  Typography,
  CircularProgress,
} from '@material-ui/core';
import { GraphicEq, TrackChanges, Videocam } from '@material-ui/icons';
import SubjectIcon from '@material-ui/icons/Subject';
import { Alert } from '@material-ui/lab';
import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import ReactPlayer from 'react-player/vimeo';
import { Link } from 'react-router-dom';
import Globals from '../../../Globals.json';
import api from '../../../services/api';
import AlertErrors from '../../components/alertErrors/alerts';
import Button from '../../components/button/Button';
import ClassLesson from '../../components/class/ClassLesson';
import EditorJsRenderer from '../../components/EditorJSRenderer';
import Storie from '../../components/stories/Stories';
import ToolbarItem from '../../components/toolbar/ToolbarItem';
import './classDetail.scss';

class ClassDetail extends React.Component {
  state = {
    loadingClass: false,
    loadingCourse: false,
    loadingQuiz: false,
    courseId: null,
    classTextBtn: 0,
    courseName: '',
    courseDescription: '',
    courseThumbnail: '',
    courseData: {},
    classId: '',
    className: '',
    classDescription: '',
    classThumbnail: '',
    classData: {},
    classes: [],
    contents: [],
    duration: 0,
    mediaPercentage: 0,
    selectedContent: {},
    openQuestion: false,
    validationQuiz: null,
    validationQuestion: null,
    validationQuestionSelection: null,
    controlerBtn: true,
    alertError: '',
    msgIncorrect: null,
    msgCorrect: null,
    completed: false,
    showCopySuccess: false,
  };

  async loadClassData() {
    try {
      this.setState({ loadingClass: true });

      let validationQuestion = null;
      const postData = {
        container_id: this.props.classId,
      };
      const response = await api.post(Globals.api.getModules, postData);
      const { container, children } = response.data;
      const acceptedClassContentId = [13, 14, 15, 35];
      const validationQuiz = children.find((i) => i.type_module_id === 29);

      if (validationQuiz) {
        validationQuestion = validationQuiz.data;
      }

      this.setState({
        loadingClass: false,
        loadingQuiz: false,
        classId: container.id,
        className: container.name,
        classDescription: container.description,
        classThumbnail: container.thumbnail,
        classData: container.data,
        contents: children.filter((item) => acceptedClassContentId.includes(item.type_module_id)),
        validationQuiz,
        validationQuestion,
      });

      if (children.length > 0) {
        this.setState({ selectedContent: children[0] });
      }
    } catch (error) {
      this.setState({ alertError: error.response.status });
      setTimeout(() => {
        this.setState({ alertError: '' });
      }, 3000);
    }
  }

  async loadCourseData() {
    try {
      this.setState({ loadingCourse: true });

      const postData = {
        container_id: this.props.courseId,
      };
      const response = await api.post(Globals.api.getModules, postData);
      const { container, children } = response.data;

      this.setState({
        loadingCourse: false,
        courseName: container.name,
        courseDescription: container.description,
        courseThumbnail: container.thumbnail,
        classes: children.filter((item) => item.type_module_id === 6),
      });
    } catch (error) {
      this.setState({ alertError: error.response.status });
      setTimeout(() => {
        this.setState({ alertError: '' });
      }, 3000);
    }
  }

  async startClassEvent() {
    try {
      const postData = {
        type_action_id: 4, //inicio de aula
        data_modules_detail_id: this.props.classId,
      };
      await api.post(Globals.api.userActionEvent, postData);
    } catch (error) {
      this.setState({ alertError: error.response.status });
      setTimeout(() => {
        this.setState({ alertError: '' });
      }, 3000);
    }
  }

  async startCourseEvent() {
    try {
      const postData = {
        type_action_id: 1, //inicio de curso
        data_modules_detail_id: this.props.courseId,
        type_module_id: 2,
      };
      await api.post(Globals.api.userActionEvent, postData);
    } catch (error) {
      this.setState({ alertError: error.response.status });
      setTimeout(() => {
        this.setState({ alertError: '' });
      }, 3000);
    }
  }

  getOptionClass = (id) => {
    if (this.state.completed && this.state.validationQuestionSelection === id) {
      if (this.state.msgCorrect) {
        return 'answerCorret';
      } else if (this.state.msgIncorrect) return 'answerCorret false';
    }

    return this.state.validationQuestionSelection === id
      ? 'test_containerAnswerSelected2'
      : 'test_containerAnswer2';
  };

  openQuestion() {
    console.log('AQUI', this.state.validationQuiz.user_module_data)
    if (
      this.state.validationQuiz.user_module_data &&
      this.state.validationQuiz.user_module_data.questionAnswered &&
      this.state.validationQuiz.user_module_data.answerIsCorrect      
    ) {
      this.setState({
        validationQuestionSelection: this.state.validationQuiz.user_module_data.questionAnswered,
        completed: true,
        controlerBtn: false,
        msgCorrect: true,
      });
    }

    if (this.state.validationQuestion) {
      const validationQuestion = { ...this.state.validationQuestion };
      validationQuestion.randomizedAlternatives = this.randomizeArray(validationQuestion.options);
      this.setState({ openQuestion: true, validationQuestion });
    }
  }

  handleSelection(id) {
    this.setState({ validationQuestionSelection: id });
  }

  btnFinishClass() {
    this.setState({
      loadingClass: true,
      loadingQuiz: false,
      controlerBtn: true,
      msgCorrect: null,
      validationQuestionSelection: '',
      openQuestion: false,
    });
    this.finishClass();
  }

  async sendAnswer() {
    if (this.state.loadingQuiz) return;

    this.setState({
      loadingQuiz: true,
    });

    try {
      const postData = {
        type_action_id: 3,
        data_modules_detail_id: +this.props.classId,
        question_answered: this.state.validationQuestionSelection,
      };

      const { data } = await api.post(Globals.api.userActionEvent, postData);
      const { answerIsCorrect } = data;

      if (answerIsCorrect) {
        this.setState({ msgCorrect: true, controlerBtn: false, completed: true, loadingQuiz : false });
      } else {
        this.setState({ msgIncorrect: true, controlerBtn: false, completed: true, loadingQuiz : false });
      }
    } catch (error) {
      this.setState({ alertError: error.response.status });
      setTimeout(() => {
        this.setState({ alertError: '' });
      }, 3000);
    }
  }

  async finishClass() {
    let uri = Globals.paths.courseDetail.replace(':courseId', this.props.courseId);

    try {
      const postData = {
        type_action_id: 27,
        data_modules_detail_id: +this.props.classId,
      };

      if (this.state.selectedContent.id) {
        postData.contentId = this.state.selectedContent.id;
      }

      await api.post(Globals.api.userActionEvent, postData);
      const nextClass = this.getNextClass();

      if (nextClass) {
        uri = Globals.paths.class
          .replace(':courseId', this.props.courseId)
          .replace(':classId', nextClass.id);

        if (nextClass.type_module_id === Globals.types.class) {
          uri = Globals.paths.class
            .replace(':courseId', this.props.courseId)
            .replace(':classId', nextClass.id);
        }
      }

      this.props.history.push(uri);
    } catch (error) {
      this.setState({ alertError: error.response.status });
      setTimeout(() => {
        this.setState({ alertError: '' });
      }, 3000);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.classId !== prevProps.classId || this.props.courseId !== prevProps.courseId) {
      this.fullLoad();
    }
  }

  getCurIndex() {
    let curIndex = -1;

    for (let index = 0; index < this.state.classes.length; index++) {
      if (this.state.classes[index].id === parseInt(this.props.classId)) {
        curIndex = index;
      }
    }

    return curIndex;
  }

  showCopySuccess = () => {
    this.setState({ showCopySuccess: true }, () => {
      console.log(this.state.showCopySuccess);
    });
  };

  getNextClass() {
    let curIndex = this.getCurIndex();
    if (this.state.classes.length >= curIndex + 2) {
      return this.state.classes[curIndex + 1];
    }
  }

  componentDidMount() {
    this.fullLoad();
  }

  fullLoad() {
    this.setState({ selectedContent: {}, mediaPercentage: 0 });
    this.loadCourseData();
    this.loadClassData();
    this.startCourseEvent();
    this.startClassEvent();
  }

  renderContent = (content) => {
    if (content) {
      if (content.type_module_id === Globals.types.content_video) {
        return (
          <>
            <div className="classDetailContentVideo">
              <ReactPlayer
                className="vimeo-player"
                controls={true}
                onProgress={(e) => this.onMediaTimeUpdate(e)}
                onDuration={this.handleDuration}
                url={content.description}
              />
            </div>
          </>
        );
      } else if (content.type_module_id === Globals.types.content_audio) {
        return (
          <video
            id="ctMedia"
            className="classDetailVideoSmUp"
            onTimeUpdate={(e) => this.onMediaTimeUpdate(e)}
            width="100%"
            height="440"
            controls="controls"
          >
            <source src={content.description} />
            Seu navegador não suporta este tipo de mídia.
          </video>
        );
      } else if (content.type_module_id === Globals.types.content_text) {
        let contentHtml = '';
        let objContent = {};

        try {
          objContent = JSON.parse(this.state.selectedContent.description);
        } catch (e) {
          objContent = null;
        }

        if (objContent && objContent.blocks) {
          return (
            <section>
              <EditorJsRenderer data={objContent} />
            </section>
          );
        } else {
          contentHtml = this.state.selectedContent.description.replace(
            /Arial/g,
            'Trebuchet MS !important'
          );
        }

        return (
          <div
            className="classDetailContentText"
            dangerouslySetInnerHTML={{ __html: contentHtml }}
          />
        );
      } else if (content.type_module_id === Globals.types.content_story) {
        const story = {
          id: this.state.selectedContent.id,
          name: this.state.selectedContent.name,
          slides: this.state.selectedContent.data.slides,
        };

        return (
          <Storie
            onCopy={this.showCopySuccess}
            key={`storie-${story.id}`}
            storie={story}
            showCopyButtons={true}
          />
        );
      }
    }
  };

  handleDuration = (duration) => {
    this.setState({ duration });
  };

  onMediaTimeUpdate(e) {
    const media = Math.round(e.playedSeconds);
    var pct = (this.state.duration * 5) / 100;

    if (media >= pct) {
      this.setState({ mediaPercentage: media });
    }
  }

  randomizeArray = (arr) => {
    console.log(arr);
    const newArr = [...arr];
    const ret = [];

    while (newArr.length > 0) {
      ret.push(newArr.splice(Math.floor(Math.random() * newArr.length), 1)[0]);
    }
    return ret;
  };

  reloadClass = () => {
    this.setState({
      openQuestion: false,
      msgIncorrect: null,
      validationQuestionSelection: '',
      controlerBtn: true,
    });
  };

  selectContent = (item) => {
    this.setState({ selectedContent: item });

    try {
      const postData = {
        type_action_id: 29,
        data_modules_detail_id: item.id,
      };

      api.post(Globals.api.userActionEvent, postData);
    } catch (e) {}
  };

  renderClassDetail = () => (
    <Grid item className="classDetailItem">
      <span className="codigo_licao">AULA {this.getCurIndex() + 1}</span>
      <Grid item xs={12} className="titleDetailBar">
        {this.state.className}
      </Grid>

      {this.state.openQuestion ? (
        <div className="classDetailContainerQuestion">
          <span className="classDetaiTextQuestion">{this.state.validationQuestion.question}</span>
          {this.state.validationQuestion.randomizedAlternatives.map((item, index) => (
            <div key={index} className="classDetail_containerAnswer">
              {item.text !== '' && (
                <div
                  key={item.id}
                  className={this.getOptionClass(item.id)}
                  onClick={() => this.handleSelection(item.id)}
                  style={{
                    pointerEvents:
                      this.state.msgCorrect || this.state.msgIncorrect ? 'none' : 'auto',
                    cursor:
                      this.state.msgCorrect || this.state.msgIncorrect ? 'default' : 'pointer',
                  }}
                >
                  <span>{item.text}</span>
                </div>
              )}
            </div>
          ))}
        </div>
      ) : (
        <Grid container item xs={12} className="class-content">
          {this.renderContent(this.state.selectedContent)}
        </Grid>
      )}

      {this.state.msgCorrect === true && (
        <div className="msgCorrect">
          <span>Parabéns, resposta correta!</span>
          <div style={{ width: '190px', marginTop: '10px' }}>
            {(this.state.mediaPercentage >= 0.05 ||
              this.state.selectedContent.type_module_id === Globals.types.content_text ||
              this.state.selectedContent.type_module_id === Globals.types.content_audio ||
              this.state.selectedContent.type_module_id === Globals.types.content_story) && (
              <Button
                className={
                  !!this.getNextClass()
                    ? 'g-c-finish-class-and-go-to-next'
                    : 'g-c-finish-class-and-course'
                }
                type="darkBlue"
                width="160px"
                onClick={() => this.btnFinishClass()}
              >
                Concluir Aula
              </Button>
            )}
          </div>
        </div>
      )}

      {this.state.msgIncorrect === true && (
        <div className="msgCorrect false">
          <span>Resposta incorreta, você deve refazer a aula.</span>
          <div style={{ width: '190px', marginTop: '10px' }}>
            <Button type="darkBlue" width="160px" onClick={() => this.reloadClass()}>
              Refazer aula
            </Button>
          </div>
        </div>
      )}

      {this.state.openQuestion ? (
        this.state.controlerBtn === true && (
          <div className="classContentContinuBtn">
            {this.state.validationQuestionSelection &&
              (this.state.mediaPercentage >= 0.05 ||
                this.state.selectedContent.type_module_id === Globals.types.content_text ||
                this.state.selectedContent.type_module_id === Globals.types.content_audio ||
                this.state.selectedContent.type_module_id === Globals.types.content_story) && (
                <Button
                  type="darkBlue"
                  onClick={() => this.sendAnswer()}
                  style={{ marginRight: '0px' }}
                  icon={this.state.loadingQuiz ? 'none' : null}
                >
                  {this.state.loadingQuiz ? 'Validando resposta... ' : 'Enviar'}
                </Button>
              )}
          </div>
        )
      ) : (
        <>
          <div className="buttonBarContainerClass">
            <div className="classContentsButtonContainer">
              {this.state.contents.map((item, index) => (
                <Fragment key={index}>
                  {item !== this.state.selectedContent && (
                    <div style={{ margin: '10px' }}>
                      <Button
                        icon={
                          item.type_module_id === 13 ? (
                            <Videocam style={{ marginLeft: '10px' }} />
                          ) : item.type_module_id === Globals.types.content_audio ? (
                            <GraphicEq style={{ marginLeft: '10px' }} />
                          ) : item.type_module_id === Globals.types.content_text ? (
                            <SubjectIcon style={{ marginLeft: '10px' }} />
                          ) : (
                            item.type_module_id === Globals.types.content_story && (
                              <TrackChanges style={{ marginLeft: '10px' }} />
                            )
                          )
                        }
                        type="darkBlue"
                        onClick={() => this.selectContent(item)}
                      >
                        {item && item.name}
                      </Button>
                    </div>
                  )}
                </Fragment>
              ))}
            </div>
            {this.state.validationQuiz ? (
              <>
                <div style={{ width: '190px', marginTop: '10px' }}>
                  {(this.state.mediaPercentage >= 0.05 ||
                    this.state.selectedContent.type_module_id === Globals.types.content_text ||
                    this.state.selectedContent.type_module_id === Globals.types.content_audio ||
                    this.state.selectedContent.type_module_id === Globals.types.content_story) && (
                    <Button
                      type="darkBlue"
                      onClick={() => this.openQuestion()}
                      width="170px"
                      style={{ marginRight: '0px' }}
                    >
                      Responder quiz
                    </Button>
                  )}
                </div>
                <br /> <br />
              </>
            ) : (
              <div style={{ width: '190px', marginTop: '10px' }}>
                {(this.state.mediaPercentage >= 0.05 ||
                  this.state.selectedContent.type_module_id === Globals.types.content_text ||
                  this.state.selectedContent.type_module_id === Globals.types.content_audio ||
                  this.state.selectedContent.type_module_id === Globals.types.content_story) && (
                  <Button
                    type="darkBlue"
                    className={
                      !!this.getNextClass()
                        ? 'g-c-finish-class-and-go-to-next'
                        : 'g-c-finish-class-and-course'
                    }
                    onClick={() => this.finishClass()}
                    width="170px"
                    style={{ marginRight: '0px' }}
                  >
                    Concluir Aula
                  </Button>
                )}
              </div>
            )}
          </div>
        </>
      )}
    </Grid>
  );

  render() {
    if (this.state.loadingCourse) {
      return (
        <div className="courseLoaderContainer">
          <CircularProgress />
        </div>
      );
    }

    return (
      <>
        <Breadcrumbs aria-label="breadcrumb" className="class_detail_breadCrumb">
          <Link color="inherit" to={Globals.paths.courses} className="class_detail_breadCrumbLink">
            Cursos
          </Link>
          <Link
            color="inherit"
            to={Globals.paths.courseDetail.replace(':courseId', this.props.courseId)}
            className="class_detail_breadCrumbLink"
          >
            {this.state.courseName}
          </Link>
          <Typography color="textPrimary" className="class_detail_breadCrumbLabel">
            {this.state.className}
          </Typography>
        </Breadcrumbs>

        <Helmet>
          <title>Aula | Meu bolso em dia</title>
        </Helmet>

        <Grid container>
          <Grid item xs={12} sm={8} md={9} className="classDetailContainer">
            <AlertErrors error={this.state.alertError} />
            <Snackbar
              open={this.state.showCopySuccess}
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
              autoHideDuration={2000}
              onClose={() =>
                setTimeout(() => {
                  this.setState({ showCopySuccess: false });
                }, 2000)
              }
            >
              <Alert
                onClose={() => {
                  this.setState({ showCopySuccess: false });
                }}
                severity="info"
              >
                Conteúdo copiado para sua área de transferência.
              </Alert>
            </Snackbar>
            {this.state.loadingClass ? (
              <div className="classLoaderContainer">
                <CircularProgress />
              </div>
            ) : (
              this.renderClassDetail()
            )}
          </Grid>

          <Hidden xsDown>
            <Grid item container sm={4} md={3}>
              <ToolbarItem title="Próximas lições">
                <div className="classContainer_sub">
                  {this.state.classes.map((item) => (
                    <ClassLesson
                      onClick={() =>
                        this.setState({
                          loadingClass: true,
                          loadingQuiz: false,
                          openQuestion: false,
                          msgCorrect: false,
                          msgInvalid: false,
                        })
                      }
                      key={item.id}
                      classData={item}
                      courseId={this.props.courseId}
                      started={item.user_module_data.startedAt != null}
                      done={item.user_module_data.finishedAt != null}
                      current={item.id === this.state.classId}
                    />
                  ))}
                </div>
              </ToolbarItem>
            </Grid>
          </Hidden>
        </Grid>
      </>
    );
  }
}
export default ClassDetail;
