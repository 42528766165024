import React from 'react';
import api from "../../../services/api";
import { Grid } from '@material-ui/core';
import moment from 'moment';
import Logo from '../../../assets/logo/MBED_horizontal_inversao_preferencial.png'
import AlertErrors from '../../../logged_in/components/alertErrors/alerts';
import './publicCertificate.scss';
import CertificateComponent from '../../../logged_in/components/certificate/CertificateComponent';

const Globals = require("../../../Globals.json");

class PublicCertificate extends React.Component {

  state = {
    isLoading: false,
    desempenho: [],
    certificateName: '',
    certificateDescription: '',
    imgCertificate: '',
    certificateDate: '',
    certificateCreatedAt: '',
    courseName: '',
    userName: '',
    alertError: '',
    certificateNotNull: false
  }


  async loadData () {

    this.setState({ isLoading: true });

    let hasToken = false;
    let getToken = '';

    if (localStorage.getItem("@fbb-Token") !== null) {
      hasToken = true
      getToken = localStorage.getItem('@fbb-Token');

      localStorage.removeItem('@fbb-Token')
    }

    const userId = this.props.match.params.userId;
    const certificateId = parseInt(this.props.match.params.certificateId);

    try {
      const response = await api.get(Globals.api.publicCertificate, { params: { userId, certificateId } });
      const certificateUrl = response.data;

      if (certificateUrl) {
        this.setState({
          certificateUrl,
          isLoading: false
        })
      } else {
        this.setState({ certificateNotFound: true })
      }

      if (hasToken) {
        localStorage.setItem('@fbb-Token', getToken)
      }
    } catch (error) {
      this.setState({ alertError: error.response.status })
      if (hasToken) {
        localStorage.setItem('@fbb-Token', getToken)
      }
      setTimeout(() => {
        this.setState({ alertError: '' })
      }, 3000)
    }
  }

  componentDidMount () {
    this.loadData();
  }

  render () {

    if (this.state.certificateNotFinded) {
      return (<div>Certificado não encontrado</div>)
    }

    return (
      <>
        <AlertErrors error={this.state.alertError} />
        {this.state.isLoading ? <div className="loading">Carregando...</div> : (
          <Grid container className="containerReport">
            <div className="report-container-report">
              <header>
                <div className="container-990 header-flex">
                  <img src={Logo} className="logo" alt="Imagem logo" />
                </div>
              </header>
            </div>
            <Grid container className="certificate-container p20">
              <Grid item container xs={12} className="containerGrid">
                <Grid item container justify="center">
                  {this.state.certificateUrl && (
                    <CertificateComponent certificateUrl={this.state.certificateUrl}></CertificateComponent>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid >
        )}
      </>
    )
  }
}
export default PublicCertificate;
