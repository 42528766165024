import React from 'react';
import './styles/nextBenefits.scss';

import { Skeleton } from '@material-ui/lab';
import { Box } from '@material-ui/core';

import AlertErrors from '../../components/alertErrors/alerts';
import api from '../../../services/api';

import imgProgress from '../../../assets/image/imgProgress.png';

const Globals = require("../../../Globals.json");

class NextBenefits extends React.Component {

  state = {
    levelsInfo: [],
    level: "",
    xpCorrent: "",
    xpTotal: "",
    index: 0,
    lengthLevel: 0,
    isLoading: false,
    alertError: '',
  }

  loadData = () => {
    const levels = [];
    this.setState({ isLoading: true })
    try {
      api.get(Globals.api.getBenefits).then(response => {

        Object.keys(response.data).forEach(index => {
          levels.push({ level: index, ...response.data[index] });
        })

        console.log('ual', response.data)
        this.setState({ levelsInfo: levels });
        var ultimo = this.state.levelsInfo[this.state.levelsInfo.length - 1];
        this.setState({ lengthLevel: ultimo, isLoading: false });
      })
    } catch (error) {
      this.setState({ alertError: error.response.status })
      setTimeout(() => {
        this.setState({ alertError: '' })
      }, 3000)
    }
  }

  async loadDataLevels () {

    this.setState({ isLoading: true });

    const response = await api.get(Globals.api.selfInfo);

    this.setState({
      level: response.data.progress.level,
      xpCorrent: response.data.progress.xp,
      xpTotal: response.data.progress.next_level,
    });
  }


  componentDidMount () {
    this.loadData()
    this.loadDataLevels()
  }

  handleChangeIndex = (index) => {
    this.setState({
      index
    });
  };

  render () {

    const { level } = this.state;

    return (
      <>
        {this.state.isLoading ?
          <>
            <Box pt={0.5} style={{ display: "flex" }}>
              <Skeleton variant="rect" width={"300px"} height={"165px"} />
              <Skeleton variant="rect" width={"300px"} height={"165px"} style={{ marginLeft: "15px" }} />
              <Skeleton variant="rect" width={"300px"} height={"165px"} style={{ marginLeft: "15px" }} />
            </Box>
            <AlertErrors error={this.state.alertError} />
          </> :
          <>
            <div className="next_benefit_container">
              {this.state.levelsInfo.map((item, index) => (
                <div key={index} className="next_benefit_itemBox">
                  <div className="next_benefit_header">
                    <img className="imgIcon" src={`${imgProgress}`} alt='img' />
                    <span className="next_benefit_levelText" >
                      {(Number(level) >= Number(this.state.lengthLevel.level) && Number(this.state.lengthLevel.level) === Number(item.level))
                        || Number(level) === Number(item.level) ? "Nível Atual" : `Nível ${item.level}`}
                    </span>
                  </div>
                  <div className="next_benefit_containerInfo" />
                  <div className="next_benefit_description">
                    <>
                      {item.desbloqueia.map((item, index) => (
                        <span className="next_benefit_text" key={index} > <span className="next_benefit_teste3">•</span>{item}</span>

                      ))}
                    </>
                  </div>

                  {/* <div className="next_benefit_container2">
                    <div className="next_benefit_header2">
                      <span className="next_benefit_textContent">Conteúdo liberado para compra</span>
                      <div className="next_benefit_hr"/>
                    </div>
                    <div className="next_benefit_containerInfo" />
                    <div className="next_benefit_description2">
                      <>
                        {item.beneficios.map((item, index) => (
                          <span className="next_benefit_text" key={index} > <span className="next_benefit_teste3">•</span>{item}</span>
                        ))}
                      </>
                    </div>
                  </div> */}
                </div>
              ))}
            </div>
          </>
        }
      </>
    )
  }
}
export default NextBenefits;
