import React, { Component } from 'react';
import { Container, Collapse, DialogContent, Grid, IconButton, CircularProgress, Card, CardContent, Dialog } from '@material-ui/core';
import { dispatch, watch } from 'redux-easy/lib/redux-easy';
import { withRouter } from 'react-router-dom';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import Button from '../../../components/button/Button';
import api from '../../../../services/api';
import Alert from '@material-ui/lab/Alert';

import IconGift from '../../../../assets/icon/new-icons/presente.svg'

import './TenantShop.scss';

const Globals = require('../../../../Globals.json');


class TenantShop extends Component {
	state = {
		coins: '',
		id: '',
		catalog_id: '',
		code: '',
		name: '',
		description: '',
		image_url: '',
		points_needed_for_redemption: '',
		minimum_profile: '',
		catalog: [],
		isLoading: true,
		isLoadingReedem: false,
		redeemOrder: false,
		redeemOrderItem: '',
		redeemedSuccess: false,
		firstLoad: true,
		date: '',
		alertError: '',
		openAlert: false,
		showModalInfo: true,
		redeemedSuccessMsg: ''
	};

	async loadData() {
		this.setState({ isLoading: true });
		if (this.props.clickedTenant) {
			try {
				const catalog = await api.post(Globals.api.activeCatalogItens,
					{
						tenant_id: this.props.clickedTenant.id,
						include_redeemed: true
					});

				this.setState({
					catalog: catalog.data,
				});
			} catch (error) {
				console.error(error);
			}
		}

		if (this.props.user) {
			this.setState({
				coins: this.props.user.profile.progress.total_moedas,
			});

		} else {
			dispatch('updateUserProfile');
		}
		this.setState({ isLoading: false, firstLoad: false });

	}
	async redeenItem() {
		this.setState({ isLoadingReedem: true });
		try {

			await api.post(Globals.api.redeemItem.replace(":catalog_item_id", this.state.redeemOrderItem.id)).then((res) => {
				this.props.loadDataParent();
				this.loadData();
				this.getNewDate(res.data.created_at)
				this.setState({ redeemedSuccess: true, redeemedSuccessMsg: res.data?.msg || '' });
			})
		} catch (error) {
			this.setState({ loading: false, openAlert: true });

			if (error.response?.data?.msg && typeof (error.response.data?.msg) === 'string' ) {

				this.setState({ alertError: error.response.data.msg });

			} else if (error.response?.data?.msg?.error?.message && typeof (error.response.data?.msg?.error?.message) === 'string') {

				this.setState({ alertError: error.response.data.msg.error.message });

			} else {
				this.setState({ alertError: 'Não foi possível resgatar o item, por favor, entre em contato com a instituição.' });

			}
			console.error(error);
		} finally {
			this.setState({ isLoadingReedem: false });
		}
	}

	componentDidMount = () => {
		this.loadData();

	}
	componentDidUpdated(prevProps) {
		if (prevProps.user !== this.props.user) {
			this.loadData();
		}

	}

	handleClose = () => {
		this.setState({ firstLoad: true });
		if (this.props.handleClose) {
			this.props.handleClose();
		}
	}
	handlePreRedeem = (item) => {
		this.setState({
			redeemOrder: true,
			redeemOrderItem: item
		});

	}

	handleBackButton = () => {
		this.setState({
			redeemOrder: false,
			redeemOrderItem: '',
			redeemedSuccessMsg: '',
			redeemedSuccess: false,
			openAlert: false,
		});
	}

	getNewDate = (value) => {
		console.log(value)
		const date = value.substring(0, 10).split('-').reverse().join('/')
		this.setState({ date: date });
	}
	handleCloseModalInfo = () => {
		this.setState({ showModalInfo: false });
	}

	render() {
		const coins = this.props.user.profile.progress.total_moedas;
		const { name, logo_url } = this.props.clickedTenant;
		return (
			<div className="container-tenant-shop-modal">
				<MuiDialogTitle disableTypography className="dialog-header">
					<IconButton color="secondary" aria-label="close" className="close-button" onClick={this.handleClose}>
						<CloseIcon />
					</IconButton>
				</MuiDialogTitle>
				<DialogContent className="dialog-content">
					<Grid container direction="column" justify="flex-start" alignItems="center" className="dialog-container-tenant">

						{this.state.isLoading && this.state.firstLoad ? (
							<Grid container item xs={12} justify="center" alignItems="center" className="loading-container">
								<CircularProgress size={40} />
							</Grid>
						) : (

							this.state.redeemOrder ? (
								<Container className="item-shop-container" container direction="column" justify="flex-start">

									<Grid container className="item-container">
										<Grid container xs={12} className="item-content">
											<div className="image-container">
												<img src={this.state.redeemOrderItem.image_url} alt={this.state.redeemOrderItem.name} />
											</div>
											{this.state.redeemedSuccess ? (
												<div className="redeemed-container">
													<div className="info-container">
														<div className="top-info">
															<h2>Produto Resgatado!</h2>
															<p className="date">{this.state.date}</p>
															{this.state.redeemedSuccessMsg ?
																<>
																	<p>Você solicitou o resgate do produto <span>{this.state.redeemOrderItem.name}</span>.</p>
																	<p>{this.state.redeemedSuccessMsg}</p>
																</>
																:
																<>
																	<p>Você solicitou o resgate do produto <span>{this.state.redeemOrderItem.name}</span>. Agora é só aguardar a liberação.</p>
																	<p>Em caso de dúvida, entre em contato com a instituição responsável.</p>

																</>
															}

														</div>
													</div>
												</div>

											) : (
												<div className="redeem-container">
													<div className="info-container">
														<div className="top-info">
															<h2>{this.state.redeemOrderItem.name}</h2>
															<p>Custo: <span>{this.state.redeemOrderItem.points_needed_for_redemption} pontos</span></p>
															<p>Instituição: <span>{name}</span></p>
														</div>
														<div className="bottom-info">
															<p>Você deseja resgatar {this.state.redeemOrderItem.name} por <span>{this.state.redeemOrderItem.points_needed_for_redemption} pontos</span>?</p>
														</div>
													</div>

													<div className="buttons-container">
														<Button
															disabled={this.state.isLoadingReedem}
															width="180px"
															height="40px"
															icon="none"
															type={'full-solid'}
															textAlign={'center'}
															onClick={() => this.redeenItem()}

														>
															{this.state.isLoadingReedem ? (
																<CircularProgress size={24} style={{ color: 'white' }} />
															) : (
																'resgatar'
															)}
														</Button>
														<Button
															width="180px"
															height="40px"
															icon="none"
															textAlign={'center'}
															onClick={() => this.handleBackButton()}
														>
															cancelar
														</Button>
													</div>
												</div>
											)}

										</Grid>

										<Grid container className="item-description">
											<Collapse in={this.state.openAlert}>
												<Alert
													severity="error"
													action={
														<IconButton
															aria-label="close"
															color="inherit"
															size="small"
															onClick={() => {
																this.setState({ openAlert: false });
															}}
														>
															<CloseIcon fontSize="inherit" />
														</IconButton>
													}

												>{this.state.alertError}</Alert>
											</Collapse>
											<h3>Sobre o incentivo:</h3>
											<p>{this.state.redeemOrderItem.description}</p>
										</Grid>
									</Grid>

								</Container>
							) : (
								<Container className="tenant-catalog-container" container direction="column" justify="flex-start">
									<div className="header-container">
										<Grid container justify='center' >
											<img src={logo_url} alt={name}></img>
										</Grid>
										<Grid container className="header-content">
											<Grid container item xs={12} className="tenant-name">
												<h2>{name}</h2>
												<p>Veja abaixo todos os incentivos disponíveis</p>
											</Grid>
											<Grid container item xs={12} className="points-container">
												<p className='points'>{coins}</p>
												<p>Seu saldo de pontos</p>
											</Grid>

										</Grid>

									</div>

									<Grid container className="cards-container">
										{this.state.catalog && this.state.catalog.length > 0 ? (
											this.state.catalog.map(item => (
												<>
													<Card key={item.id}
														className="card-container"
													>
														<CardContent>
															<div className="image-container">
																<img src={item.image_url} alt={item.name} />
															</div>

															<div className="description">{item.name}</div>
															<div className="point-cost">{`${item.points_needed_for_redemption} pontos`}</div>
															<div className={'button-redeem'}>
																<Button
																	icon="none"
																	textAlign={'center'}
																	onClick={() => this.handlePreRedeem(item)}
																>
																	resgatar
																</Button>
															</div>

														</CardContent>
														<div className={'green-bar'} />
													</Card>
												</>
											))
										) : (
											<Grid container className="cards-container">
												<div className="description">Não há incentivos disponíveis para resgate no momento.</div>
											</Grid>
										)}

									</Grid>

								</Container>
							)


						)}

					</Grid>

				</DialogContent >
				{this.state.redeemOrder &&
					<div className="dialog-footer space-between">
						<Button
							icon="none"
							width="180px"
							height="40px"
							color="primary"
							variant="outlined"
							onClick={() => this.handleBackButton()}
						>
							Voltar
						</Button>
					</div>
				}
				{/* {this.state.showModalInfo &&
				<Dialog disableBackdropClick={true} disableEscapeKeyDown={true} maxWidth={'sm'} className="modal-info" open={this.state.showModalInfo}>
					<Grid container justify="center" direction="column" className='modal-info-container'>

						<DialogContent className="dialog-content">
							<div className="img-container">

							<img src={IconGift} alt={'presente'} />
							</div>
							<h1>Falta pouco!</h1>
							<h2>Aguarde mais um tempinho, pois os pontos que você acumula na nossa plataforma poderão ser trocadas por benefícios a partir de <span>Março de 2022</span>.</h2>
							<Button
								type={'full-solid'}
								icon="none"
								textAlign={'center'}
								onClick={this.handleCloseModalInfo}
							>
								sair
							</Button>
						</DialogContent>

					</Grid>
				</Dialog>
				}  */}
			</div>
		)
	}
}

export default watch(withRouter(TenantShop), { user: '' });