import React, { Component } from 'react';
import './styles/helpActions.scss';

import { ArrowBack, ArrowForward } from '@material-ui/icons';

import HelpList from './HelpList';
class HelpActions extends Component {

  state = {
    activeBtn: 0,
    activePagination: 0,
  }

  render() {
    return (
      <>
        <div className="help_action_containerCard">

          <div className="help_action_headerBtn">

            <button className={this.state.activeBtn === 0 ? "help_action_btnHeaderActive"
              : "help_action_btnHeader"} onClick={() => this.setState({ activeBtn: 0 })}>Sobre meu bolso em dia</button>

            <button className={this.state.activeBtn === 1 ? "help_action_btnHeaderActive"
              : "help_action_btnHeader"} onClick={() => this.setState({ activeBtn: 1 })}>Cursos e conteúdos</button>

            <button className={this.state.activeBtn === 2
              ? "help_action_btnHeaderActive"
              : "help_action_btnHeader"} onClick={() => this.setState({ activeBtn: 2 })}>Pontuação</button>

            <button className={this.state.activeBtn === 3 ? "help_action_btnHeaderActive"
              : "help_action_btnHeader"} onClick={() => this.setState({ activeBtn: 3 })}>Ferramentas</button>

          </div>

          <HelpList description={`Lorem ipsum per convallis congue eget porttitor hac per libero pellentesque,
          iaculis ipsum mattis mi tincidunt dictumst sollicitudin duis fringilla suscipit tristique,
          eros tristique malesuada sociosqu molestie blandit urna cursus elementum. commodo elementum
          amet etiam donec elit gravida, rutrum quisque a eget.`} title={`Onde posso usar os pontos que eu ganho?`} />

          <HelpList description={`Lorem ipsum per convallis congue eget porttitor hac per libero pellentesque, 
          iaculis ipsum mattis mi tincidunt dictumst sollicitudin duis fringilla suscipit tristique,`}
            title={`Posso transferir meus pontos para outra pessoa?`} />

          <HelpList description={`Pellentesque, iaculis ipsum mattis mi tincidunt dictumst sollicitudin duis 
          fringilla suscipit tristique,`} title={`Os certificados que ganhamos são aprovados pelo MEC?`} />

          <HelpList description={`Lorem ipsum per convallis congue eget porttitor hac per libero pellentesque, 
          iaculis ipsum mattis mi tincidunt dictumst sollicitudin duis fringilla suscipit tristique,`}
            title={`Posso transferir meus pontos para outra pessoa?`} />

          <div className="help_action_pagination">
            <button className="help_action_btnPagination" disabled={this.state.activePagination === 0 ? true : false}
              onClick={() => this.setState({ activePagination: this.state.activePagination - 1 })}><ArrowBack /></button>

            <button className={this.state.activePagination === 0 ? "help_action_btnPaginationActive"
              : "help_action_btnPagination"} onClick={() => this.setState({ activePagination: 0 })}>1</button>

            <button className={this.state.activePagination === 1 ? "help_action_btnPaginationActive"
              : "help_action_btnPagination"} onClick={() => this.setState({ activePagination: 1 })}>2</button>

            <button className={this.state.activePagination === 2 ? "help_action_btnPaginationActive"
              : "help_action_btnPagination"} onClick={() => this.setState({ activePagination: 2 })}>3</button>

            <button className={this.state.activePagination === 3 ? "help_action_btnPaginationActive"
              : "help_action_btnPagination"} onClick={() => this.setState({ activePagination: 3 })}>4</button>

            <button className="help_action_btnPagination" disabled={this.state.activePagination === 3 ? true : false}
              onClick={() => this.setState({ activePagination: this.state.activePagination + 1 })}><ArrowForward /></button>
          </div>
        </div>
      </>

    )
  }

}

export default HelpActions;

