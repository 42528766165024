import React, { Component } from 'react';
import './styles.scss'

export default class Perfil extends Component {
  render() {
    const { heightAvatar } = this.props;

    const { noMediaQuery = "mediaQuery" } = this.props
    return (
      <div className={`containerPerfil ${noMediaQuery}`}>
        <div className={"boxReport"}>
          <div className={`boxLeft ${noMediaQuery}`}>
            <div className={`avatar ${noMediaQuery}`} style={{ height: heightAvatar }}>
              {this.props.user_avatar &&
                <img src={this.props.user_avatar} alt="Avatar" />
              }
              {this.props.avatarInitials &&
                <span style={{ fontSize: "70px", color: '#2e2e2e' }}>{this.props.avatarInitials}</span>
              }
            </div>
          </div>
          <div className={"boxRight"}>

            <div className={"informationHeader"}>
              <h2 className={`titleHeader ${noMediaQuery}`}>Perfil do Usuário</h2>
              <span className={"textHeader"}>Usuário desde: {this.props.created_at}</span>
              <span className={"textHeader"}>Status: <span style={{ color: "#00B47C" }}>ativo</span> </span>
            </div>
            {this.props.name &&
              <div className={"infomationItem"}>
                <span className={`infomation ${noMediaQuery}`} > Nome: </span>
                <span className={`infomationText ${noMediaQuery}`} >{this.props.name} </span>
              </div>
            }

            {this.props.phone &&
              <div className={"infomationItem"}>
                <span className={`infomation ${noMediaQuery}`} > Telefone: </span>
                <span className={`infomationText ${noMediaQuery}`} > {this.props.phone} </span>
              </div>
            }
            {this.props.email &&
              <div className={"infomationItem"}>
                <span className={`infomation ${noMediaQuery}`} > Email: </span>
                <span className={`infomationText ${noMediaQuery}`} > {this.props.email} </span>
              </div>
            }
            {this.props.cpf &&
              <div className={"infomationItem"}>
                <span className={`infomation ${noMediaQuery}`} > CPF: </span>
                <span className={`infomationText ${noMediaQuery}`} > {this.props.cpf} </span>
              </div>
            }
            {this.props.cadIbgeState &&
              <div className={"infomationItem"}>
                <span className={`infomation ${noMediaQuery}`} > Estado: </span>
                <span className={`infomationText ${noMediaQuery}`} > {this.props.cadIbgeState.state} </span>
              </div>
            }

            {this.props.cadIbgeCounty &&
              <div className={"infomationItem"}>
                <span className={`infomation ${noMediaQuery}`} > Cidade: </span>
                <span className={`infomationText ${noMediaQuery}`} > {this.props.cadIbgeCounty.county} </span>
              </div>
            }
          </div>
        </div>
      </div>
    )
  }
}
