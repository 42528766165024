import React from 'react';
import '../../../backoffice.scss';
import ModuleListing from '../../components/backoffice/components/ModuleListing/ModuleListing';
const Globals = require("../../../Globals.json");

export default function Articles() {

  return (
    <ModuleListing
      title="Lista de Artigos"
      buttonLabel="Novo Artigo"
      typeModuleId={10}
      fullUrl={Globals.paths.backofficeEditArticle}
      urlReplacePattern=":articleId"
      hideDescription
    />
  )

}

