import React, { Component } from 'react';
import "./article.scss"

import { Helmet } from "react-helmet";
import { Skeleton } from '@material-ui/lab';
import { Grid, Hidden, Box } from '@material-ui/core';

import api from '../../../services/api';

import AlertErrors from '../../components/alertErrors/alerts';
import Favorite from '../../components/sidebar/Favorite';
import ToolbarItem from '../../components/toolbar/ToolbarItem';
import ReactPlayer from 'react-player';
import EditorJsRenderer from '../../components/EditorJSRenderer';

const Globals = require("../../../Globals.json");

class Article extends Component {

  state = {
    isLoading: false,
    articleName: "",
    articleDescription: "",
    articleThumbail: "",
    articleChildren: [],
    articleText: "",
    alertError: "",
  }

  async loadData (articleId) {

    this.setState({ isLoading: true });

    const postData = { "container_id": articleId };

    try {
      const response = await api.post(Globals.api.getModules, postData);
      console.log("Loaded: ", response.data);

      const articleObj = response.data.container;
      const articleContents = response.data.children;

      if (articleObj.type_module_id === 31) //É um post do blog
      {
        const extData = articleObj.externalData;

        // let fullText = "";

        // extData.DataModulesDetailsContents.forEach((item) => {
        //   fullText += item.text
        // })

        this.setState({
          isLoading: false,
          articleName: extData.title,
          articleDescription: extData.description_meta_tags,
          articleThumbail: extData.title_image,
          articleChildren: [],
          objContent: extData.text,
        });

      }
      else {
        this.setState({
          isLoading: false,
          articleName: articleObj.name,
          articleDescription: articleObj.description,
          articleThumbail: articleObj.thumbnail,
          articleChildren: response.data.children,
          objContent: articleObj.description,
        });

      }

    } catch (error) {
      console.log("ERROR:", error);
      this.setState({ alertError: error.response.status })
      setTimeout(() => {
        this.setState({ alertError: '' })
      }, 3000)
    }

  }

  async readArticleEvent () {

    const postData = {
      type_action_id: 10, //leitura de artigo
      data_modules_detail_id: this.props.articleId,
      type_module_id: 10,
    };
    try {
      await api.post(Globals.api.userActionEvent, postData);
    } catch (error) {
      this.setState({ alertError: error.response.status })
      setTimeout(() => {
        this.setState({ alertError: '' })
      }, 3000)
    }
  }

  componentWillReceiveProps (nextProps) {
    const currentProductId = this.props.articleId;
    const nextProductId = nextProps.articleId;
    if (currentProductId !== nextProductId) {
      this.loadData(nextProductId)
    }
  }

  componentDidMount () {
    const { articleId } = this.props;
    this.readArticleEvent();
    this.loadData(articleId);
  }

  render () {
    return (

      <Grid container >
        <Helmet>
          <title>Artigo | Meu bolso em dia</title>
        </Helmet>
        <Grid item xs={12} sm={8} md={9} className="feedContainer">
          <AlertErrors error={this.state.alertError} />

          <div className="artigoItem">
            {this.state.isLoading ?
              <Grid>
                <Box pt={0.5} style={{ padding: "15px", display: "flex", flexDirection: "column", alignItems: 'center' }}>
                  <Skeleton variant="rect" width={"80%"} height={15} style={{ marginBottom: "15px" }} />
                  <Skeleton variant="rect" width={"60%"} height={300} style={{ marginBottom: "15px" }} />
                  <Skeleton variant="rect" width={"100%"} height={15} style={{ marginBottom: "15px" }} />
                  <Skeleton variant="rect" width={"100%"} height={15} style={{ marginBottom: "15px" }} />
                  <Skeleton variant="rect" width={"100%"} height={15} style={{ marginBottom: "15px" }} />
                  <Skeleton variant="rect" width={"100%"} height={15} style={{ marginBottom: "15px" }} />
                  <Skeleton variant="rect" width={"100%"} height={15} style={{ marginBottom: "15px" }} />
                </Box>
              </Grid> :
              <>
                <div>

                  <div className="titleBar">
                    {this.state.articleName}
                  </div>

                  <img className="img_artigo" src={this.state.articleThumbail} alt="Imagem do artigo" />

                  {
                    this.state.objContent ? (

                      <section className="article-content">
                        <EditorJsRenderer data={JSON.parse(this.state.objContent)} />
                      </section>
                    )
                      :
                      (
                        <div className={`$"p_description" article-description`} dangerouslySetInnerHTML={{ __html: this.state.articleText }} />
                      )
                  }

                </div>

                {(!this.state.objContent) && this.state.articleChildren.map((item, index) => (
                  <div className="articleVideo" key={index}>
                    {item.type_module_id === 13 &&
                      <ReactPlayer
                        controls
                        url={item.description}
                      />
                    }
                  </div>
                ))
                }
              </>}
          </div>

        </Grid>

        <Hidden xsDown>
          <Grid item container sm={4} md={3} direction="column">

            <ToolbarItem title="Meus favoritos" color="#f2a824" overflow="overflow">
              <Favorite />
            </ToolbarItem>

          </Grid>
        </Hidden>

      </Grid >
    )
  }
}

export default Article;

