import React, { Component } from 'react';
import { Button, Container, DialogActions, DialogContent, Grid, Hidden, IconButton, Paper, Typography } from '@material-ui/core';
import { watch } from 'redux-easy/lib/redux-easy';
import { withRouter } from 'react-router-dom';
import { Helmet } from "react-helmet";
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import CloseIcon from '@material-ui/icons/Close';
import './welcomeContent.scss';

class WelcomeContent extends Component {

    page = 'welcome';

    componentDidMount = () => {

    }

    handleClose = () => {
        if (this.props.handleClose) {
            this.props.handleClose();
        }
    }

    render () {
        return (

            <>
                <MuiDialogTitle disableTypography className="dialog-header">
                    <IconButton color="secondary" aria-label="close" className="close-button" onClick={this.handleClose}>
                        <CloseIcon />
                    </IconButton>
                </MuiDialogTitle>
                <DialogContent className="dialog-content welcome-body" dividers>
                    <Grid container direction="column" justify="flex-start" alignItems="center">
                        <Container className="welcome-content-holder">
                            <Grid container className="welcome-content">
                                <Hidden smDown>
                                    <Grid container item xs={6} className="image-holder image1"></Grid>
                                </Hidden>
                                <Grid container item sm={12} md={6} direction="column" justify="center" alignItems="center" className="p20">
                                    <div>
                                        <h4>Seja bem-vindo ao Índice</h4>
                                        <h1>Meu Bolso em Dia!</h1>
                                        <p>Aqui começa sua jornada de educação financeira. Conheça um pouco do que a plataforma oferece, faça o teste de Índice de Saúde Financeira e aproveite 100% da plataforma.</p>
                                    </div>
                                </Grid>
                                <Hidden smDown>
                                    <Grid container item sm={6} className="image-holder image2">
                                        <Grid container direction="column" justify="center" alignItems="center" className="backdrop" >
                                            <div>
                                                <h4>Simples e fácil de usar</h4>
                                                <p>Nossa plataforma vai te ajudar em toda a jornada de educação financeira, e isso em apenas 3 passos.</p>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </Hidden>

                                <Grid container item sm={12} md={6} direction="row" justify="center" alignItems="center" className="p20">
                                    <Grid container item xs={12} className="welcome-item">
                                        <Grid container item xs={4} direction="row" alignItems="center" justify="center">
                                            <img src="/images/gauge-icon.png" width="135px" alt="" />
                                        </Grid>
                                        <Grid item xs={8} className="pl10">
                                            <h5>Faça o teste</h5>
                                            <p>Descubra qual sua pontuação no Índice de Saúde Financeira.</p>
                                            <span role="button" className="g-pm-know-isfb" onClick={() => this.props.showContent({ to: 'index-detail', from: this.props.page })}>conheça o Índice</span>
                                        </Grid>
                                    </Grid>
                                    <Grid container item xs={12} className="welcome-item">
                                        <Grid container item xs={4} direction="row" alignItems="center" justify="center">
                                            <img src="/images/persona-default-icon.svg" width="135px" alt="" />
                                        </Grid>
                                        <Grid item xs={8} className="pl10">
                                            <h5>Conheça seu perfil</h5>
                                            <p>Com o resultado do teste, nossa ferramenta definiará um perfil que mais se adequa a você.</p>
                                        </Grid>
                                    </Grid>
                                    <Grid container item xs={12} className="welcome-item">
                                        <Grid container item xs={4} direction="row" alignItems="center" justify="center">
                                            <img src="/images/mission-icon-filled.svg" width="100px" alt="" />
                                        </Grid>
                                        <Grid item xs={8} className="pl10">
                                            <h5>Inicie sua jornada</h5>
                                            <p>Pronto! Você já pode iniciar seu apendizado com um conteúdo personalizado ao seu perfil</p>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Container>
                    </Grid>
                </DialogContent >
                <DialogActions className="dialog-footer">
                    <Button
                        id="g-pm-start-test"
                        className={`g-pm-start-test`}
                        variant="contained"
                        color="secondary"
                        onClick={() => { this.props.startIndex(); }}
                        style={{ borderRadius: "0px", color: "white" }}
                    >
                        FAZER O TESTE AGORA
                    </Button>
                </DialogActions>
            </>
        )
    }
}

export default watch(withRouter(WelcomeContent), { user: '' });