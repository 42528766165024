import * as React from 'react';
import moment from 'moment';
import './customizedTables.scss';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';

import { Paper, TableRow, TableHead, TableContainer, TableCell, TableBody, Table } from '@material-ui/core';

const numberToCurrency = (value) => {
  const currency = new Intl.NumberFormat('pt-BR', {
    currency: 'BRL',
    style: 'currency',
  }).format(value);
  return currency
}

export default function CustomizedTables(props) {

  return (
    <>
      {props.data.length > 0 ? (
        <TableContainer className="photographic-tables" component={Paper}>
          <Table aria-label="customized table">
            <TableHead>
              <TableRow>
                <TableCell>Nome</TableCell>
                <TableCell>Valor</TableCell>
                <TableCell>Data de Registro</TableCell>
                <TableCell>Data da Receita</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props.data.length > 0 && props.data.map((row) => (
                <TableRow key={row.id}>
                  <TableCell scope="row">
                    {row.description}
                  </TableCell>
                  <TableCell>{numberToCurrency(row.value)}</TableCell>
                  <TableCell>{moment(row.created_at).format('DD/MM/YYYY')}</TableCell>
                  <TableCell>{moment(row.reference_expense_date).format('DD/MM/YYYY')}</TableCell>
                  <TableCell scope="row" align="right"><button onClick={() => props.edit(row)}><EditIcon /></button> <button onClick={() => props.delete(row.id)}><DeleteIcon /></button></TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        'Ainda não há itens cadastrados.'
      )}


    </>
  );
}