import React, { Component } from 'react';
import { Button, CircularProgress, Container, Dialog, DialogActions, DialogContent, Grid, IconButton, Paper, Typography } from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import CloseIcon from '@material-ui/icons/Close';
import { watch } from 'redux-easy/lib/redux-easy';
import { withRouter } from 'react-router-dom';
import { Helmet } from "react-helmet";
import './welcome.scss';
import Globals from '../../../Globals.json';
import api from '../../../services/api';

import { enumTypeUserStatuses } from '../../../services/enums';

import IncomesWelcome from './incomesWelcome/IncomesWelcome';
import IncomesSelectItems from './incomesSelectItems/IncomesSelectItems';
import IncomesTypeSelect from './incomesTypeSelect/IncomesTypeSelect';
import IncomesFinal from './incomesFinal/IncomesFinal';
import IncomesPanelSelect from './incomesPanelSelect/IncomesPanelSelect';


class PhotographModalIncomes extends Component {

    state = {
        started: false,
        open: false,
        startIndex: 0,
        isLoading: true,
        disableBackdropClick: true,
        disableEscapeKeyDown: true,
        currentPage: '',
        previousPage: '',
        canStartIndex: false,
        history: [],
        data: {},
        width: 'md',
        photographicsTimelapse: [],
        selectedPhotographicId: this.props.selectedPhotographicId,
    }


    changeWidth = (value = 'md') => {
        this.setState({ width: value });
    }

    componentDidMount = () => {

        if (this.props.open) {
            this.handleClickOpen();
        }

        this.init();
    }


    init = async () => {
        this.setState({ isLoading: true });
        await this.loadData();

        if (this.props.isWelcome || this.state.photographicsTimelapse.length === 0) {
            this.setState({ currentPage: 'incomes-welcome' })
        }

        this.setState({ isLoading: false });
    }

    loadData = async () => {
        this.setState({ isLoading: true })
        try {
            await api.get(Globals.api.userFinancialPhotographCombobox)
                .then(res => {
                    this.setState({ photographicsTimelapse: res.data })
                })

        } catch (error) {
            console.log(error.response)
        }
    }

    handleClose = () => {
        this.props.closeWelcomeModal();
    };

    handleClickOpen = () => {
        this.setState({ open: true });
    };

    showContent = (config) => {
        const history = this.state.history;

        if (config.data) {
            let showMessage
            config.showMessage ? showMessage = true : showMessage = false
            const newData = { ...this.state.data, ...config.data, showMessage }
            this.setState({ currentPage: config.to, history, data: newData }, () => {
                history.push(config.from);
            });
        }
        else {
            history.push(config.from);
            this.setState({ currentPage: config.to, history });
        }

        if (config.reset) {
            this.setState({ history: [] })
        }

    }

    goBack = () => {
        const history = this.state.history;
        const last = history.pop();
        this.setState({ currentPage: last, history });
    }

    setCloseConfig = (cantClose) => {
        this.setState({
            disableBackdropClick: cantClose,
            disableEscapeKeyDown: cantClose
        });
    }

    disableIndex = () => {
        this.setState({ canStartIndex: false });
    }


    render() {

        let html = (
            <Grid container className="dialog-content" >
                <Grid item xs={12} className="loading-area">

                    <CircularProgress />

                </Grid>
            </Grid>
        );

        if (!this.state.isLoading) {

            switch (this.state.currentPage) {
                case 'incomes-welcome': {
                    html = <IncomesWelcome page={'incomes-welcome'} goBack={this.goBack} handleClose={this.handleClose} showContent={this.showContent} changeWidth={this.changeWidth} state={this.state} ></IncomesWelcome>;
                    break;
                }
                case 'incomes-type-select': {
                    html = <IncomesTypeSelect page={'incomes-type-select'} goBack={this.goBack} handleClose={this.handleClose} showContent={this.showContent} changeWidth={this.changeWidth} state={this.state}></IncomesTypeSelect>;
                    break;
                }
                case 'incomes-select-items': {
                    html = <IncomesSelectItems page={'incomes-select-items'} goBack={this.goBack} handleClose={this.handleClose} showContent={this.showContent} changeWidth={this.changeWidth} state={this.state}></IncomesSelectItems>;
                    break;
                }
                case 'incomes-final': {
                    html = <IncomesFinal page={'incomes-final'} goBack={this.goBack} handleClose={this.handleClose} showContent={this.showContent} changeWidth={this.changeWidth} state={this.state}></IncomesFinal>;
                    break;
                }
                case 'incomes-panel-select': {
                    html = <IncomesPanelSelect page={'incomes-panel-select'} goBack={this.goBack} handleClose={this.handleClose} showContent={this.showContent} changeWidth={this.changeWidth} state={this.state}></IncomesPanelSelect>;
                    break;
                }

                default: {
                    html = <IncomesPanelSelect page={'incomes-panel-select'} goBack={this.goBack} handleClose={this.handleClose} showContent={this.showContent} changeWidth={this.changeWidth} state={this.state}></IncomesPanelSelect>;
                    break;
                }
            }
        } else {
            html =
                <Grid container className="dialog-content" >
                    <Grid item xs={12} className="loading-area">

                        <CircularProgress />

                    </Grid>
                </Grid>
        }

        return (
            <div>
                <Helmet>
                    <title>Bem vindo | Meu bolso em dia</title>
                </Helmet>
                <Dialog onClose={this.handleClose} disableBackdropClick={this.state.disableBackdropClick} disableEscapeKeyDown={this.state.disableEscapeKeyDown} maxWidth={'md'} className="photograph-dialog-incomes" open={this.state.open}>
                    {/*maxWidth={'md'} para cadastro de despesas */}
                    {html}
                </Dialog>
            </div>
        )
    }
}


export default watch(withRouter(PhotographModalIncomes), { user: '' });