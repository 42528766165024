import React from 'react';
import { useMediaQuery } from 'react-responsive'
import MainText from '../MainText';
import Button from '../Button';
import * as Styled from './Debts.styles';

function Debts({ openRegisterDialog }) {
  const isMobile = useMediaQuery({ maxWidth: 960 });

  return <Styled.Debts>
    <div className="inner-wrapper">
      <MainText size="530" style={{ marginLeft: !isMobile ? '80px' : null, zIndex: 1 }}>
        <h2>Liberte-se das dívidas e assuma o controle da sua vida financeira</h2>
        <p>Cada pessoa aprende melhor de uma maneira, por isso o conteúdo Meu Bolso em Dia foi criado por profissionais que sabem que você não é todo mundo.</p>
        <Button onAction={openRegisterDialog}>Fazer teste agora</Button>
      </MainText>
      <figure className="debt">
        <img className="debt__image" src="./images/debts.png" alt="Assuma o controle da sua vida financeira" />
        <img className="debt__shield debt__shield--small" src="./images/shield-blue.png" alt="" />
        <img className="debt__shield debt__shield--medium" src="./images/shield-blue.png" alt="" />
        <img className="debt__shield debt__shield--regular" src="./images/shield-blue.png" alt="" />
        <img className="debt__shield debt__shield--outlines" src="./images/shield-outlines.png" alt="" />
        <Styled.Logo variant="small" />
        <Styled.Logo variant="medium" />
        <Styled.Logo variant="big" />
      </figure>
    </div>
  </Styled.Debts>;
}

export default Debts;