import React from 'react';
import './styles/lastCertificate.scss';

import { Grid, Box } from '@material-ui/core';

import AwardIcon from '../../../assets/icon/new-icons/certificado.svg';

class LastCertificate extends React.Component {
  render () {
    const { quantity } = this.props;
    return (
      <>
        <Grid item xs={8}>
          <Grid container className="last_certificate_pL">
            <div className="last_certificate_container">
              {quantity !== 0 ?
                <div className="last_certificate_text">
                  <span className="last_certificate_txtTitle"> {quantity}</span>
                  <span className="last_certificate_msg"> Essa é a minha quantidade de certificados na plataforma.</span>
                </div>
                : <span style={{ fontSize: "16px", color: "#ccc" }}>
                  Por enquanto ainda não tem nenhum certificado.
                  </span>
              }
            </div>
          </Grid>
        </Grid>
        <Grid item xs={4}>
          <Grid container>
            <Box>
              <img className="last_certificate_icon_study" alt="study" src={AwardIcon}></img>
            </Box>
          </Grid>
        </Grid>
      </>
    )
  }
}

export default LastCertificate;
