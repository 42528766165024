import React from 'react';
import MainText from '../MainText';
import * as Styled from './Faq.styles';

import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import FaqLeftIcons from '../../../../assets/faq/faq_icons_left.png';
import FaqRightIcons from '../../../../assets/faq/faq_icons_right.png';

function Faq({ openRegisterDialog }) {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Styled.Faq>
    
      <Styled.SnakesLeft />
      <Styled.SnakesRight />

      <div className="faqHead">
          <div className="center">
            <div>
              <img alt="Icone Quiz" src={FaqLeftIcons} className="faqHeadIcons"/>
            </div>
            <div>
              FAQ
              <div className="subtile">As principais dúvidas sobre o portal Meu Bolso em Dia</div>
            </div>
            <div>
            <img alt="Icone Quiz" src={FaqRightIcons} className="faqHeadIcons"/>
            </div>
          </div>
      </div>
      <div className="inner-wrapper">

    <MainText>
      <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')} className="acordHead">
        
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography className="acordTitle">
            Como funciona a plataforma Meu Bolso em Dia?
          </Typography>
        </AccordionSummary>
        
        <AccordionDetails className="acordBody">
          <Typography className="indentParagraph">
            <p>
              Na plataforma Meu Bolso em Dia o usuário vive uma experiência de educação financeira.
              Por meio de conteúdos informativos, educacionais, gamificados com ferramentas, os usuários colocam
              em prática ensinamentos do mundo das finanças, seja para sair das dívidas ou para iniciar uma 
              jornada de investimentos.
            </p>
            <p>
              Para acessá-la, o usuário passa por um cadastro e um diagnóstico de 
              perfil por meio do Índice de Saúde Financeira, que traz raio x da sua saúde e bem-estar financeiros
              e aponta o que precisa ser melhorado. A partir dos resultados do Índice, dos cortes e dimensões
              apresentadas, desenvolvemos um plano de implementação para que isso seja refletido de forma concreta
              na jornada e nos conteúdos propostos pela Plataforma. 
            </p>
            <p>
              Ainda no diagnóstico, o usuário deverá definir
              objetivos e metas, que serão acompanhadas em sua experiência e acompanhar seu desenvolvimento por
              meio  de cursos, missões, artigos, ferramentas entre outros serviços.
            </p>
          </Typography>
        </AccordionDetails>
      
      </Accordion>
      
      <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')} className="acordHead">

        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2bh-content"
          id="panel2bh-header"
        >
          <Typography className="acordTitle">
            O que é Índice de Saúde Financeira?
          </Typography>
        </AccordionSummary>

        <AccordionDetails className="acordBody">
          <Typography className="indentParagraph">
            <p>
              O Índice de Saúde Financeira faz um diagnóstico da sua saúde e bem-estar financeiros e aponta o que
              precisa ser melhorado. Ter um bom índice significa:
            </p>
            <ul>
              <li>ser capaz de cumprir suas obrigações financeiras;</li>
              <li>ser capaz de tomar boas decisões financeiras;</li>
              <li>sentir-se seguro(a) quanto ao presente e futuro financeiro;</li>
              <li>ter liberdade para fazer escolhas que permitam a você aproveitar a sua vida.</li>
            </ul>
            <p>
              O Índice de Saúde Financeira é uma criação 100% brasileira. É o resultado de um ano de trabalho colaborativo
              de mais de 70 especialistas da Febraban, do Banco Central e de membros do sistema financeiro nacional e das
              universidades. É uma ferramenta simples e confiável, disponível para todos.
            </p>
            <p>
              Na plataforma você pode conferir a sua saúde financeira. <span role="button" onClick={openRegisterDialog}>Descubra qual é o seu índice</span>.
            </p>
          </Typography>
        </AccordionDetails>

      </Accordion>

      <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')} className="acordHead">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3bh-content"
          id="panel3bh-header"
        >
          <Typography className="acordTitle">
            Quais serviços a Plataforma oferece?
          </Typography>
        </AccordionSummary>

        <AccordionDetails className="acordBody">
          <Typography className="indentParagraph">
            <p>
              O usuário acessa vários serviços, como cursos, artigos, ferramentas, missões e outras formas interativas
              para embarcar no mundo das finanças. Com esses recursos o usuário traça uma jornada no mundo das finanças,
              de modo a poder aplicar os conhecimentos na sua própria rotina. Dessa forma, fica mais fácil organizar a
              vida financeira, traçar metas e objetivos, e viver melhor. <span role="button" onClick={openRegisterDialog}>Cadastre-se e conheça mais!</span>
            </p>
          </Typography>
        </AccordionDetails>

      </Accordion>

      <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')} className="acordHead">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel4bh-content"
          id="panel4bh-header"
        >
          <Typography className="acordTitle">
            Vou precisar pagar pelos cursos e serviços da Plataforma?
          </Typography>
        </AccordionSummary>

        <AccordionDetails className="acordBody">
          <Typography className="indentParagraph">
            <p>
              Todos os serviços da plataforma são gratuitos, sem custos.
            </p>
            <p>
              Para entrar, basta fazer o cadastro <span role="button" onClick={openRegisterDialog}>clicando aqui</span>.
            </p>
          </Typography>
        </AccordionDetails>

      </Accordion>
      <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')} className="acordHead">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel5bh-content"
          id="panel5bh-header"
        >
          <Typography className="acordTitle">
            Quais são os cursos da Plataforma?
          </Typography>
        </AccordionSummary>

        <AccordionDetails className="acordBody">
        <Typography className="indentParagraph">
          <p>
            Uma trilha de aulas em diversos formatos para educar os usuários de níveis básicos,
            intermediários e avançados. Os conteúdos são personalizados de acordo com cada perfil.
            E incluem certificados assinados pela Federação Brasileira de Bancos e pelo Banco
            Central. <span role="button" onClick={openRegisterDialog}>Acesse e comece já sua jornada de conhecimento!</span>
          </p>
          </Typography>
        </AccordionDetails>

      </Accordion>

      <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')} className="acordHead">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel6bh-content"
          id="panel6bh-header"
        >
          <Typography className="acordTitle">
            Como faço para realizar um curso da plataforma Meu Bolso em Dia?
          </Typography>
        </AccordionSummary>

        <AccordionDetails className="acordBody">
          <Typography className="indentParagraph">
          <p>
            Para iniciar sua jornada na plataforma, basta fazer o cadastro e responder às perguntas
            para descobrir o seu Índice financeiro. Depois disso, é só iniciar os cursos e acessar
            os conteúdos mais adequados ao seu perfil.
          </p>
          </Typography>
        </AccordionDetails>
        
      </Accordion>

      <Accordion expanded={expanded === 'panel7'} onChange={handleChange('panel7')} className="acordHead">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel7bh-content"
          id="panel7bh-header"
        >
          <Typography className="acordTitle">
            Eu ganho um certificado quando concluo cursos?
          </Typography>
        </AccordionSummary>

        <AccordionDetails className="acordBody">
          <Typography className="indentParagraph">
            <p>
              Sim! Durante sua jornada na plataforma, o usuário ganha certificado de conclusão dos cursos.
            </p>
          </Typography>
        </AccordionDetails>
        
      </Accordion>

      <Accordion expanded={expanded === 'panel8'} onChange={handleChange('panel8')} className="acordHead">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel8bh-content"
          id="panel8bh-header"
        >
          <Typography className="acordTitle">
            Vou receber algum incentivo ao concluir algum curso da plataforma?
          </Typography>
        </AccordionSummary>

        <AccordionDetails className="acordBody">
          <Typography className="indentParagraph">
            <p>
              A sua jornada na plataforma rende certificados após os cursos, além de recompensas
              após a conclusão das aulas, dos desafios e das missões propostas.
            </p>
            <p>
              Essas recompensas podem ser trocadas por incentivos, por meio das parcerias exclusivas.
              Para saber mais, <span role="button" onClick={openRegisterDialog}>faça o seu cadastro e inicie a sua jornada</span>.
            </p>
          </Typography>
        </AccordionDetails>
        
      </Accordion>

      <Accordion expanded={expanded === 'panel9'} onChange={handleChange('panel9')} className="acordHead">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel9bh-content"
          id="panel9bh-header"
        >
          <Typography className="acordTitle">
            Como saber se meus dados ficam seguros na Plataforma?
          </Typography>
        </AccordionSummary>

        <AccordionDetails className="acordBody">
          <Typography className="indentParagraph">
            <p>
              O portal Meu Bolso em Dia conta com os procedimentos de segurança da Federação Brasileira
              de Bancos (Febraban) e seguem o mesmo código de ética quanto ao armazenamento de dados dos usuários.
            </p>
            <p>
              Além disso, o objetivo do portal é informar o cidadão e ajudá-lo a buscar educação financeira. Em
              nenhum momento serão pedidos dados financeiros do usuário ou qualquer informação privada mais delicada.
            </p>
            <p>
              Caso haja alguma solicitação de financeiros, entre em contato no 
              via <a href="mailto:faleconosco@meubolsoemdia.com.br" style={{textDecoration: "none"}}><span role="button">faleconosco@meubolsoemdia.com.br</span></a>
            </p>
          </Typography>
        </AccordionDetails>
        
      </Accordion>

      <Accordion expanded={expanded === 'panel10'} onChange={handleChange('panel10')} className="acordHead">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel10bh-content"
          id="panel10bh-header"
        >
          <Typography className="acordTitle">
            Como saber se tenho o perfil para usar a plataforma?
          </Typography>
        </AccordionSummary>

        <AccordionDetails className="acordBody">
          <Typography className="indentParagraph">
            <p>
              Ao fazer o cadastro na plataforma, você recebe três perguntas iniciais (que não levam 5 minutos
              para responder) que nos ajudam a conhecer um pouco melhor o seu perfil. Depois dessas perguntas
              iniciais, você será convidado para uma nova sessão de questões. Essas questões vão nos ajudar a
              classificar o seu Índice de Saúde Financeira e também informarão o seu perfil como usuário.
            </p>
          </Typography>
        </AccordionDetails>
        
      </Accordion>

      <Accordion expanded={expanded === 'panel11'} onChange={handleChange('panel11')} className="acordHead">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel11bh-content"
          id="panel11bh-header"
        >
          <Typography className="acordTitle">
            Não tenho perfil endividado, o que a plataforma me oferece?
          </Typography>
        </AccordionSummary>

        <AccordionDetails className="acordBody">
          <Typography className="indentParagraph">
            <p>
              No momento estamos em fase beta. Mas em breve a plataforma também vai oferecer cursos para aqueles
              que buscam melhorar sua organização financeira e que buscam investir para ter mais renda. <span role="button" onClick={openRegisterDialog}>Você
              pode fazer seu cadastro</span>, descobrir seu perfil e deixar o seu contato que em breve será convidado a
              viver essa experiência. Para outros assuntos sobre finanças, acesse o
              blog <a href="http://www.meubolsoemdia.com.br" target="_blank" style={{textDecoration: "none"}}><span role="button">www.meubolsoemdia.com.br</span></a>.
            </p>
          </Typography>
        </AccordionDetails>
        
      </Accordion>

      <Accordion expanded={expanded === 'panel12'} onChange={handleChange('panel12')} className="acordHead">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel12bh-content"
          id="panel12bh-header"
        >
          <Typography className="acordTitle">
            O Meu Bolso em Dia é uma iniciativa de quem?
          </Typography>
        </AccordionSummary>

        <AccordionDetails className="acordBody">
          <Typography className="indentParagraph">
            <p>
              A plataforma é uma iniciativa da Federação Brasileira de Bancos (Febraban) para a sociedade
              visando melhorar o entendimento e compreensão das pessoas sobre suas próprias finanças, além
              de promover uma educação sobre o universo financeiro. Junto a outros produtos, como o
              blog <a href="http://www.meubolsoemdia.com.br" target="_blank" style={{textDecoration: "none"}}><span role="button">www.meubolsoemdia.com.br</span></a>,
              a Febraban leva conteúdos informativos para orientar os brasileiros sobre assuntos relativos a
              organização financeira, investimentos, empreendedorismo, entre outros. Para conhecer mais sobre
              a instituição, acesse: <a href="https://portal.febraban.org.br" target="_blank" style={{textDecoration: "none"}}><span role="button">https://portal.febraban.org.br</span></a>
            </p>
          </Typography>
        </AccordionDetails>
        
      </Accordion>

      <Accordion expanded={expanded === 'panel13'} onChange={handleChange('panel13')} className="acordHead">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel13bh-content"
          id="panel13bh-header"
        >
          <Typography className="acordTitle">
            Em caso de dúvidas, sugestões ou contato?
          </Typography>
        </AccordionSummary>

        <AccordionDetails className="acordBody">
          <Typography className="indentParagraph">
            <p>
              Envie um e-mail para <a href="mailto:faleconosco@meubolsoemdia.com.br" style={{textDecoration: "none"}}><span role="button">faleconosco@meubolsoemdia.com.br</span></a>
            </p>
          </Typography>
        </AccordionDetails>
        
      </Accordion>

      <div>
        <p style={{textAlign: "center", marginRight: "20px", marginLeft: "20px", fontSize:"16px"}}>
          Não encontrou o que procurava? Mande sua dúvida no email: <a href="mailto:faleconosco@meubolsoemdia.com.br" style={{textDecoration: "none"}}><span role="button">faleconosco@meubolsoemdia.com.br</span></a>
        </p>
      </div>
    </MainText>

    </div>
    </Styled.Faq>
  );
}

export default Faq;
