import React, { Component } from 'react';
import './styles/dados.scss';

import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

import { watch } from 'redux-easy/lib/redux-easy';

class Privacity extends Component {
  state = {
    login: 0,
    pdf: false,
  }

  componentWillReceiveProps = () => {
    if (this.props.pdf) {
      html2canvas(document.querySelector("#divToPrint")).then(canvas => {
        this.setState({ pdf: true })
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF();
        pdf.addImage(imgData, 'PNG', 20, 20, 0, 0);
        pdf.save(`${this.props.user.profile.username}.pdf`);
      })
    }
  }

  render() {
    const props = this.props
  
    const desempenho = this.props.desempenho
    return (
      <div className={`dados_container ${this.state.pdf && 'border_hidden'}`} >
        <div id="divToPrint">
          <strong>Dados cadastrais</strong>
          
          <li>Nome: {props.name}</li>
          <li>Email: {props.email}</li>
          <li>Genêro: {props.gender || 'Não cadastrado'}</li>
          <li>Data de nascimento: {props.birthDate || 'Não cadastrado'}</li>
          <li>Renda aproximada: {props.userIncome || 'Não cadastrado'}</li>
          <li>Escolaridade: {props.userEducation || 'Não cadastrado'}</li>
          <li>Telefone: {props.phone || 'Não cadastrado'}</li>
          <li>CPF: {props.cpf || 'Não cadastrado'}</li>

          <li>Estado: {props.state || 'Não cadastrado'}</li>
          <li>Cidade: {props.city || 'Não cadastrado'}</li>
          <li>CEP: {props.zip_code || 'Não cadastrado'}</li>
          <li>Endereço: {props.landmark || 'Não cadastrado'}</li>
          
          <br />

          <strong>Consumo da plataforma</strong>
          <li>Cursos concluídos: {desempenho.cursos_concluidos}</li>
          <li>Cursos em andamento: {desempenho.cursos_em_andamento}</li>
          <li>Artigos lidos: {desempenho.artigos_lidos}</li>
          <li>Missões concluídas: {desempenho.missoes_concluidas}</li>
          <li>Missões em andamento: {desempenho.missoes_em_andamento}</li>
          <li>Quiz concluídos: {desempenho.questionarios_respondidos}</li>
          <li>Mini Quiz concluídos: {desempenho.mini_quiz_concluidos}</li>

          <li>Troféus na plataforma: {props.trophies}</li>
          
          <li>Índice de Saúde Financeira: {props.indexData.indexScore}</li>
          <li>Certifcados na plataforma: {props.certificate}</li>
          <li>Pontos na plataforma: {props.coin}</li>
          
          <br />
        </div>
      </div>
    )
  }
}

export default watch(Privacity, { user: '' });