import React from 'react';
import './styles/testQuiz.scss';

import { watch } from 'redux-easy/lib/redux-easy';
import { LinearProgress, CircularProgress, Grid } from '@material-ui/core';
import { withRouter } from 'react-router-dom';

import AlertError from '../alertErrors/alerts';
import Button from '../button/Button';

import api from '../../../services/api';
import Globals from '../../../Globals.json';

class TestQuiz extends React.Component {
  state = {
    finished: false,
    resultObj: {},
    currentQuestion: null,
    currentIndex: -1,
    isLoading: false,
    selection: [],
    questions: [],
    maxByGroup: {},
    maxAll: 0,
    endQuestion: false,
    questionActive: false,
    open: true,
    fim: true,
    alertError: '',
    quizObj: {},
  }

  loadData = async () => {
    const quizId = this.props.match.params.quizId;
    // console.log('quizId', quizId)
    this.setState({ isLoading: true });
    try {
      const postData = {
        "container_id": quizId,
      };

      api.post(Globals.api.getModules, postData).then((response) => {
        // console.log("Questions resp data:", response.data);

        const { container, children } = response.data;
        console.log("QUESTIONS:", children);

        this.setState({
          isLoading: false,
          quizObj: container,
          questions: children,
        });

      })
    } catch (error) {
      this.setState({ alertError: error.response.status })
      setTimeout(() => {
        this.setState({ alertError: '' })
      }, 3000)
    }
  }

  async startQuizEvent() {

    const quizId = this.props.match.params.quizId;

    try {
      const postData = {
        type_action_id: 20, //inicio de quiz
        data_modules_detail_id: quizId,
        type_module_id: 1,
      };
      await api.post(Globals.api.userActionEvent, postData);
    }
    catch (error) {
      this.setState({ alertError: error.response.status })
      setTimeout(() => {
        this.setState({ alertError: '' })
      }, 3000)
    }

  }

  async finishQuizEvent() {

    //await this.saveAnswers();

    const quizId = this.props.match.params.quizId;

    try {
      const postData = {
        type_action_id: 21, //finalizar quiz
        data_modules_detail_id: quizId,
        type_module_id: 1,
        answers: this.state.selection,
      };
      await api.post(Globals.api.userActionEvent, postData);
    }
    catch (error) {
      this.setState({ alertError: error.response.status })
      setTimeout(() => {
        this.setState({ alertError: '' })
      }, 3000)
    }

  }

  startQuiz() {

    this.startQuizEvent();

    if (this.state.questions.length > 0) {
      this.setState({ currentIndex: 0, currentQuestion: this.state.questions[0] })
    }

  }

  restartQuiz() {

    this.setState({
      finished: false,
      resultObj: {},
      currentQuestion: null,
      currentIndex: -1,
      isLoading: false,
      selection: [],
    })

  }

  finishQuiz() {

    const quiz = this.state.quizObj;
    console.log("FINISH", quiz.data.type);
    // console.log( "FINISH::", quiz );

    if (quiz.data.type === "scoring") {
      const winner = this.calcScoringWinner();
      console.log("WINNER RESULT::", quiz.data.results[winner]);
      const winnerProfile = quiz.data.results.find(i => i.id === winner);
      this.setState({ resultObj: winnerProfile, finished: true });
    }
    else if (quiz.data.type === "range") {
      const sum = this.calcRangeSum();
      console.log(quiz.data.results);

      for (let i = 0; i < quiz.data.results.length; i++) {
        console.log("RESCHECK::", quiz.data.results[i].min, quiz.data.results[i].max, sum);
        if ((!quiz.data.results[i].min || sum >= quiz.data.results[i].min)
          && (!quiz.data.results[i].max || sum <= quiz.data.results[i].max)
        ) {
          console.log("FOUND RESULT", quiz.data.results[i]);
          this.setState({ resultObj: quiz.data.results[i], finished: true });
          break;
        }
      }

    }

    this.finishQuizEvent();

  }

  calcScoringWinner() {

    const selection = this.state.selection;
    const res = {};
    let winner = null;

    selection.forEach(item => {
      Object.keys(item.values).forEach(key => {
        res[key] = res[key] || 0;
        res[key] += item.values[key];
        if (!winner || res[key] > res[winner]) {
          winner = key;
        }
      })
    })

    console.log("RES::", winner, res);

    return winner;

  }

  calcRangeSum() {

    let sum = 0;

    this.state.selection.forEach(item => {
      console.log(item);
      sum += item.value;
    })

    console.log("SUM::", sum);

    return sum;

  }

  async saveAnswers() {

    console.log(this.state.selection, this.state.currentQuestion, this.state.currentIndex);

    try {
      const postData = {
        type_action_id: 21, //salvar respostas
        data_modules_detail_id: this.props.match.params.quizId,
        answers: this.state.selection,
      };
      await api.post(Globals.api.userActionEvent, postData);
    } catch (error) {
      console.log('error', error)
      this.setState({ alertError: 404 })
      setTimeout(() => {
        this.setState({ alertError: '' })
      }, 3000)
    }
  }

  handleSelection = (option) => {
    const selection = this.state.selection

    selection[this.state.currentIndex] = {
      display: option.display,
      id: option.id,
    };

    if (option.hasOwnProperty('values')) {
      selection[this.state.currentIndex].values = option.values;
    }

    if (option.hasOwnProperty('value')) {
      selection[this.state.currentIndex].value = option.value;
    }

    this.setState({ selection });

  }

  nextQuestion = () => {

    this.saveAnswers();

    if (this.state.questions.length > this.state.currentIndex + 1) {
      this.setState({ currentIndex: this.state.currentIndex + 1, currentQuestion: this.state.questions[this.state.currentIndex + 1] })
    } else {
      this.setState({ fim: false })
    }
  }

  prevQuestion = () => {

    this.saveAnswers();

    if (this.state.currentIndex > 0) {
      this.setState({ currentIndex: this.state.currentIndex - 1, currentQuestion: this.state.questions[this.state.currentIndex - 1], fim: true })
    }
  }

  getOptionClass = (type, option) => {
    const isVertical = this.state.currentQuestion.layout === "vertical";
    if (type === 'images') {
      if (this.state.selection[this.state.currentIndex] && this.state.selection[this.state.currentIndex].id === option.id) {
        return 'testQuiz_respImage active';
      }
      else {
        return 'testQuiz_respImage';
      }
    } else {
      if (this.state.selection[this.state.currentIndex] && this.state.selection[this.state.currentIndex].id === option.id) {
        return isVertical ? 'testQuiz_respsV active' : 'testQuiz_respsH active';
      }
      else {
        return isVertical ? 'testQuiz_respsV' : 'testQuiz_respsH';
      }
    }

  }

  componentDidMount() {
    this.loadData();
  }

  render() {

    const quiz = this.state.quizObj;

    if (!quiz) {
      return (
        <CircularProgress />
      )
    }

    return (
      <>
        <AlertError />
        <div className="testQuiz_header">
          <h3 className="testQuiz_titleTest">
            <span className="headerTitle2">{quiz.name}</span>
          </h3>
          {this.state.currentQuestion &&
            <h3 className="testQuiz_titleQuestions">
              {`Pergunta ${this.state.currentIndex + 1} de ${this.state.questions.length}`}
            </h3>
          }
        </div>

        {
          (!this.state.finished && !this.state.currentQuestion) &&
          <div className="testQuiz_quizStart">

            <Grid container>

              <Grid container item xs={12} md={6} height="400">
                <img src={quiz.thumbnail} width="100%" height="400" style={{ objectFit: "cover", verticalAlign: "middle" }} />
              </Grid>

              <Grid container item xs={12} md={6} direction="column" alignItems="center" id="quizGrid">
                <div style={{
                  flex: 1,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "flex-start",
                  width: "max-content",
                  maxWidth: "80%"
                }}
                  className="quizDescriptionSection"
                >
                  {this.state.quizObj && this.state.quizObj.data &&
                    <div style={{ fontSize: 12, color: "#999", marginBottom: 5 }}>
                      {this.state.quizObj.data.typeName}
                    </div>
                  }
                  <div style={{ fontSize: 20, fontWeight: "bold" }}>
                    {quiz.name}
                  </div>
                  <div style={{ fontSize: 14 }}>
                    {quiz.description}
                  </div>
                </div>
                <div className="testQuiz_buttonContainer">
                  <Button type="primary" icon="none" to="/quiz" textAlign="center" right="30px" minWidth="150px" minHeight="36px" >
                    VOLTAR
                  </Button>
                  <Button type="darkBlue" className="g-q-start" icon="none" onClick={() => this.startQuiz()} minWidth="150px" minHeight="36px" >
                    INICIAR QUIZ
                  </Button>
                </div>

              </Grid>

            </Grid >

          </div>
        }

        {
          (this.state.finished) &&
          <div className="testQuiz_quizStart">

            <Grid container>

              <Grid container item xs={12} md={6} height="400">

                <img src={this.state.resultObj.image} width="100%" height="400" style={{ objectFit: "cover" }} />

              </Grid>
              <Grid container item xs={12} md={6} direction="column" alignItems="center">
                <div style={{
                  flex: 1,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "flex-start",
                  width: "max-content",
                  maxWidth: "80%"
                }}>
                  <div style={{ fontSize: 12, color: "#999", marginBottom: 5 }}>
                    RESULTADO
                  </div>
                  <div style={{ fontSize: 24, fontWeight: "bold" }}>
                    {this.state.resultObj.title}
                  </div>
                  <div style={{ fontSize: 14 }}>
                    {this.state.resultObj.description}
                  </div>
                </div>
                <div className="testQuiz_buttonContainer">
                  <Button type="primary" icon="none" to="/quiz" textAlign="center" right="30px" minWidth="150px" minHeight="36px" >
                    VOLTAR
                  </Button>
                  <Button type="darkBlue" icon="none" id="g-q-restart" className="g-q-restart" onClick={() => this.restartQuiz()} minWidth="150px" minHeight="36px" >
                    REFAZER QUIZ
                  </Button>
                </div>

              </Grid>

            </Grid >

          </div>
        }

        {
          (!this.state.finished && this.state.currentQuestion) &&

          <>
            < LinearProgress variant="determinate" value={(this.state.currentIndex) / this.state.questions.length * 100} />
            {this.state.isLoading ? <CircularProgress /> :
              <div className="testQuiz_containerQuestionsOverflow">
                <div className="testQuiz_contentDescription">
                  <p className="testQuiz_description" dangerouslySetInnerHTML={{ __html: this.state.currentQuestion.description }} />
                </div>
                {
                  this.state.currentQuestion.data.type === "images" ?
                    <div className="testQuiz_flexImages">
                      {this.state.currentQuestion.data.options.map((image, index) => (

                        <div key={index} onClick={() => this.handleSelection(image)} >
                          <img alt={image.text} className={this.getOptionClass('images', image)}
                            src={image.display} />
                        </div>

                      ))}
                    </div>
                    :
                    <>
                      <div className="testQuiz_containerQuestions">
                        <div className={this.state.currentQuestion.data.layout === "vertical" ? 'testQuiz_contentAskCol' : 'testQuiz_contentAskRow'}>
                          {this.state.currentQuestion.data.options.map((itemText, index) => (
                            <div key={index}
                              className={this.getOptionClass('text', itemText)}
                              onClick={() => this.handleSelection(itemText)}>
                              {itemText.display}
                            </div>
                          ))}
                        </div>
                      </div>
                    </>
                }
                <div className="testQuiz_contentBtn"
                  style={
                    this.state.currentIndex > 0 ? { justifyContent: 'space-between' }
                      : { justifyContent: 'flex-end' }
                  } >

                  {
                    this.state.currentIndex > 0 &&
                    <Button icon="none" onClick={() => this.prevQuestion()}>
                      Voltar
                    </Button>
                  }

                  < Button type={!this.state.selection[this.state.currentIndex] ? 'disabled' : 'darkBlue'}
                    onClick={this.state.currentIndex === this.state.questions.length - 1 ?
                      () => this.finishQuiz() : this.nextQuestion
                    }
                  >
                    {this.state.currentIndex === this.state.questions.length - 1 ? "Concluir" : "Próxima"}
                  </Button>
                </div>
              </div>
            }
          </>
        }
      </>
    )
  }
}

export default watch((withRouter(TestQuiz)), { user: '' });