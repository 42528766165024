import React, { Component } from 'react';
import api from '../../../../../services/api';
import "./VideoUploader.scss";
import ReactPlayer from 'react-player';

const Globals = require("../../../../../Globals.json");

const getRnd = () => {
    return Math.floor(Math.random() * 999999999999).toString(16);
}
export class VideoUploader extends Component {

    state = {
        type: this.props.mediaType ?? 'video',
        videoUrl: null,
        fileName: null,
        message: '',
        invalidVideo: false,
        changed: false,
        isUploading: false,
        uploadPercent: 0,
        fileUploadName: 'file-upload-' + getRnd(),
        fileDragName: 'file-drag-' + getRnd(),
        formName: 'file-upload-form-' + getRnd(),
    }

    // File Upload
    //
    init = () => {

        console.log("Upload Initialised");

        let fileSelect = document.getElementById(this.state.fileUploadName),
            fileDrag = document.getElementById(this.state.fileDragName);

        fileSelect.addEventListener('change', this.fileSelectHandler, false);

        // File Drop
        fileDrag.addEventListener('dragover', this.fileDragHover, false);
        fileDrag.addEventListener('dragleave', this.fileDragHover, false);
        fileDrag.addEventListener('drop', this.fileSelectHandler, false);
    }

    fileDragHover = (e) => {
        let fileDrag = document.getElementById(this.state.fileDragName);
        e.stopPropagation();
        e.preventDefault();
        fileDrag.className = (e.type === 'dragover' ? 'hover' : 'modal-body file-upload');
    }

    fileSelectHandler = (e) => {

        this.output();

        // Fetch FileList object
        let files = e.target.files || e.dataTransfer.files;

        // Cancel event and hover styling
        this.fileDragHover(e);

        // Process all File objects
        for (let i = 0, f; f = files[i]; i++) {
            this.parseFile(f);
            this.uploadFile(f);
        }
    }

    // Output
    output = (msg) => {
        // Response
        this.setState({ message: msg });
    }

    parseFile = (file) => {

        let imageName = file.name;


        let isGood = (/\.(?=mp4)/gi).test(imageName);
        if (isGood) {
            this.setState({ videoUrl: URL.createObjectURL(file), invalidVideo: false });
        }
        else {
            this.setState({ videoUrl: null, invalidVideo: true });
            document.getElementById(this.state.formName).reset();
        }
        return;

    }

    updateFileProgress = (e) => {
        if (e.lengthComputable) {
            this.setState({ uploadPercent: e.loaded });
        }
    }

    uploadFile = (file) => {

        let fileSizeLimit = this.props.maxFileSize ?? 15; // In MB

        // Check if file is less than x MB
        if (file.size > (fileSizeLimit * 1024 * 1024)) {
            this.output(`Arquivo excede o limite de ${fileSizeLimit}MB`)
            document.getElementById(this.state.formName).reset();
            this.updateUrl(null);
            return;
        }

        const { moduleId } = this.props;

        const fileInput = document.getElementById(this.state.fileUploadName);
        const formData = new FormData();

        formData.append("storie", fileInput.files[0]);
        formData.append("module_id", moduleId);
        this.setState({ isUploading: true });

        api.post(Globals.api.uploadVideo, formData, {
            headers: {
                "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
            }
        }).then(
            response => {
                this.updateUrl(response.data.url);
            }
        ).catch(() => {
            this.output(`Ocorreu um erro no upload do arquivo`)
            this.setState({ videoUrl: null, invalidVideo: true })
        }
        ).finally(() => {
            this.setState({ isUploading: false });
        });

        return;
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.videoUrl !== this.props.videoUrl) {
            if (this.props.videoUrl) {
                this.setState({ videoUrl: this.props.videoUrl });
            }
        }
    }

    componentDidMount = () => {

        this.setState({ videoUrl: this.props.videoUrl });

        if (window.File && window.FileList && window.FileReader) {
            // Check for the letious File API support.
            this.init();

        } else {
            document.getElementById(this.state.fileDragName).style.display = 'none';
        }
    }

    updateUrl = (url) => {
        this.setState({ videoUrl: url });
        if (this.props.onChange) this.props.onChange(url);
    }

    render() {

        return (
            <div className="uploader">
                <form id={this.state.formName} >
                    <input id={this.state.fileUploadName} type="file" name="fileUpload" accept="video/*" />

                    <label htmlFor={this.state.fileUploadName} id={this.state.fileDragName}>

                        {this.state.videoUrl &&
                            <div className="preview">
                                <ReactPlayer url={this.state.videoUrl} controls={true} width={300} height={200}></ReactPlayer>

                            </div>
                        }

                        <div id="start">
                            <i className="fa fa-download" aria-hidden="true"></i>
                            <div>Clique para selecionar <br />ou solte um arquivo aqui.</div>
                            {this.state.invalidVideo && <div id="invalidVideo">O arquivo selecionado não está em um formato de vídeo válido.</div>}
                            <span className="btn btn-primary">Selecionar...</span>
                        </div>


                        <div id="response">
                            {this.state.message && <div id="messages" style={{ color: "#900" }}>{this.state.message}</div>}
                        </div>

                    </label>
                </form>
            </div>

        )
    }
}

export default VideoUploader;