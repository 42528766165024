
import React, { Component } from 'react';
import './avatar.scss';

import { Helmet } from "react-helmet";
import { Grid } from '@material-ui/core';

import AlertErrors from '../../components/alertErrors/alerts';
import Avatars from '../../components/avatar/avatars';
import FeedItem from '../../components/feed/FeedItem';
import FeedLine from '../../components/feed/FeedLine';
import Button from '../../components/button/Button';

const Globals = require('../../../Globals.json');

class Avatar extends Component {
  state = {
    alertError: ''
  }

  render() {

    return (
      <Grid container>
        <Helmet>
          <title>Avatar | Meu bolso em dia</title>
        </Helmet>
        <AlertErrors error={this.state.alertError} />

        <Grid item xs={12} sm={8} md={9} className="classContainer">
          <div className="card-favorite">
            <span>Escolha seu avatar</span>
          </div>

          <Grid
            container
            item
            xs={12}
            justify={'flex-end'}
            className={'mt20 btn-back'}
          >

            <Button
              icon="none"
              to={Globals.paths.profile}
              textAlign={'center'}
              onClick={this.cancel}
            >
              Voltar
            </Button>

          </Grid>
          <div className="container-recomendados">


            <FeedLine>
              <FeedItem expandHeight noPadding>
                <Avatars />
              </FeedItem>
            </FeedLine>
          </div>

          <Grid
            container
            item
            xs={12}
            justify={'flex-end'}
            className={'mt20 btn-back bottom'}
          >
            <Button
              icon="none"
              to={Globals.paths.profile}
              textAlign={'center'}
              onClick={this.cancel}
            >
              Voltar
            </Button>
          </Grid>
        </Grid>
      </Grid >
    )
  }
}

export default Avatar;
