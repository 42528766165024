import React from 'react';
import './styles/firstChallenge.scss';

import { watch } from 'redux-easy/lib/redux-easy';
import { dispatchSet } from 'redux-easy/lib/redux-easy';
import { CheckCircleOutline, RadioButtonUnchecked } from '@material-ui/icons';
import { Link, withRouter } from 'react-router-dom';

import AlertErrors from '../../components/alertErrors/alerts';
import api from '../../../services/api';
import Pusher from 'react-pusher';
import InitialModalQuiz from '../quiz/InitialModalQuiz';
import Globals from '../../../Globals.json';
import { enumBehaviorQuestTypes, enumQuestStatus } from '../../../services/enums';
import { Button } from '@material-ui/core';

const QuestionarioIndice = require("../../../QuestionarioIndice.json");

class FirstChallenges extends React.Component {

  state = {
    quests: [],
    open: false,
    userId: -1,
    alertError: ''
  }

  openModal = () => {
    this.setState({ open: true });
  }

  closeModal = () => {
    this.setState({ open: false })
  }

  componentDidMount () {
    this.setState({ userId: this.props.user.profile.id });
    this.loadData()
  }

  loadData () {

    try {
      api.get(Globals.api.mission, { params: { status: enumQuestStatus.ongoing } })
        .then(response => {
          this.setState({ quests: response.data })
        })
    } catch (error) {
      this.setState({ alertError: error.response.status })
      setTimeout(() => {
        this.setState({ alertError: '' })
      }, 3000)
    }
  }

  missionsChanged = () => {
    this.loadData()
  }

  backToHome = () => {
    this.props.history.push('/home')

    setTimeout(() => {
      return dispatchSet("tour.show", true)
    }, 1000)
  }

  acceptMission = async (item) => {
    this.setState({ openMission: false })
    try {
      const resp = await api.post(Globals.api.missionAccept, { questUserId: item.id });
      const response = resp.data;
      console.log(response);
      if (!response.error) {
        this.loadData();
      }
    } catch (error) {
      this.setState({ alertError: error.response.status })
      setTimeout(() => {
        this.setState({ alertError: '' })
      }, 3000)
    }
  }

  claimRewards = async (item) => {

    try {
      const postData = {
        questUserId: item.id,
      };

      const resp = await api.post(Globals.api.missionClaim, postData);
      const response = resp.data;
      if (!response.error) {
        this.loadData();
      }
    } catch (error) {
      this.setState({ alertError: error.response.status })
      setTimeout(() => {
        this.setState({ alertError: '' })
      }, 3000)
    }

  }


  doAction = (item) => {
    if (item.DataModulesDetails.data?.hasAction) {
      if (item.DataModulesDetails.data.behaviourType == enumBehaviorQuestTypes.tutorial) {
        this.backToHome();
      }
      else {
        this.props.history.push(Globals.paths[item.DataModulesDetails.data.linkToAction]);
      }
    }
  }

  // sendRoute = (item) => {
  //   return (
  //     item.data.tipo_missao === "completar_perfil" ?
  //       this.props.history.push(Globals.paths.profile) :
  //       item.data.tipo_missao === "artigo" || item.data.tipo_missao === "aula" ?
  //         this.props.history.push(Globals.paths.courses) :
  //         item.data.tipo_missao === "tutorial" ?
  //           this.backToHome() :
  //           item.data.tipo_missao === "indice" ?
  //             this.setState({ open: true }) :
  //             this.props.history.push(Globals.paths.missions)
  //   )
  //   this.loadData()
  // }

  closeModal = () => {
    this.setState({ open: false })
  }

  render () {
    return (
      <>
        {/* <Pusher
          channel={"notifications." + this.state.userId}
          event="missionsChanged"
          onUpdate={(e) => this.missionsChanged()}
        /> */}
        <AlertErrors side error={this.state.alertError} />

        {this.state.quests === "" ?
          <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
            <span className="first_challenge_msg">Você não possuí missões</span>
          </div> :

          <div className="first_challenge_container">
            <div className="first_challenge_challengesContainer">
              {this.state.quests.map((item, index) => (
                <Link key={`mission_${item.id}`} to={Globals.paths.missions.replace(':type?', 'ongoing')}>
                  <div className="first_challenge_box" key={item.id}>
                    {(item.data.status == enumQuestStatus.completed && item.data.redeemed) &&
                      <CheckCircleOutline className="first_challenge_icon green" />
                    }
                    {(item.data.status == enumQuestStatus.completed && !item.data.redeemed) || (item.data.status != enumQuestStatus.completed) &&
                      <RadioButtonUnchecked className="first_challenge_icon" />
                    }
                    <div className="first_challenge_positionText" >
                      {item.DataModulesDetails.name}
                    </div>
                  </div>
                </Link>
              ))}
            </div>
          </div>

        }

        {this.state.open &&
          <InitialModalQuiz onClose={this.closeModal} open={this.state.open} quizData={QuestionarioIndice} />
        }
      </>
    )
  }
}
export default watch(withRouter(FirstChallenges), { user: '' });