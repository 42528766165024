import React from 'react';
import './missionCustom.scss';

import { Grid } from '@material-ui/core';
import { Helmet } from "react-helmet";

import MissionCustomItem from '../../components/mission/MissionCustomItem'
class MissionCustom extends React.Component {
  render() {
    return (
      <>
        <Helmet>
          <title>Missões | Meu bolso em dia</title>
        </Helmet>

        <Grid container>
          <Grid item container xs={12} sm={8} md={9} direction="column" className="customClassContainer">
            <div className="customContainer">
              <h1 className="customTitle">Missões Personalizadas</h1>

              <MissionCustomItem text="Fazer o teste de personalidade financeira novamente" />
              <MissionCustomItem text="Fazer o teste de personalidade financeira novamente" />
              <MissionCustomItem text="Fazer o teste de personalidade financeira novamente" />
              <MissionCustomItem text="Fazer o teste de personalidade financeira novamente" />
              <MissionCustomItem text="Fazer o teste de personalidade financeira novamente" />
              <MissionCustomItem text="Fazer o teste de personalidade financeira novamente" />

              <button className="buttonBlue"> ADICIONAR
                <div className="iconPlus">+</div>
              </button>
            </div>
          </Grid>
        </Grid >
      </>
    )
  }
}

export default MissionCustom;