import React, { Component } from 'react';
import './styles/achievement.scss';

import { Modal } from '@material-ui/core';

import achievementBackground from '../../../assets/icon/fundo_premio.png';
export default class Achievement extends Component {
  render () {
    return (
      <Modal
        open={this.props.open}
        onClose={this.props.onClose}
        className="achievement-modal"
        aria-labelledby="Modal de Conquista"
        aria-describedby="Modal de Conquista"
      >
        <div className="achievement-container-holder" style={{ backgroundImage: `url(${achievementBackground})` }} onClick={this.props.onClose}>
          <div className="achievmennt-badge-holder" style={{ backgroundImage: `url(${this.props.thubmailTropy})` }}></div>
          <div className="achievement-container" >
            <div className="achievement-header">
              <div>Parabéns</div>
              <div>Nova Conquista</div>
            </div>

            <div className="achievement-bottom">
              <span>{this.props.nameTropy}</span>
              <span>{this.props.descriptionTropy}</span>
            </div>
          </div>
        </div>
      </Modal>
    )
  }
}