import React from 'react';
import './styles/missionCustom.scss';

import { Accordion, AccordionDetails, AccordionSummary, MenuItem, Modal, TextField } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import Button from '../button/Button';

class MissionCustom extends React.Component {
  state = {
    options: [
      { id: 1, name: 'Semanal', value: 'weekly' },
      { id: 2, name: 'Quinzenal', value: 'biweekly' },
      { id: 3, name: 'Mensal', value: 'monthly' },
    ],
    selected: '',
    volume: '',
    label: 'volume'
  }

  componentDidMount() {
    const data = JSON.parse(this.props.data.data)
    this.setState({ label: data.config.value?.unity})
  }
  render() {
    return (
      <>
        <Modal
          open={this.props.openMission}
          onClose={this.props.onClose}
          aria-labelledby="Modal Missao Personalizada"
          aria-describedby="Modal Missao Personalizada"
          className="miss_custom_modal"
        >
          <div className="miss_custom_card">

            <div className="miss_custom_header">
              <button type="button" className="miss_custom_close" data-dismiss="modal"
                onClick={this.props.onCloseMission}>&times;</button>
              <h3>Você aceitou a missão</h3>
              <h2>"{this.props.data.name}"</h2>
            </div>

            <div className="miss_custom_subTitle">
              <p>{this.props.data.description}</p>
            </div>
            <div className="miss_custom_subTitle gray">
              <p>
                Escolha abaixo a melhor frequência para ser  <br />
                lembrado sobre essa missão.
              </p>
            </div>

            <div className="miss_custom_inputs">
              <Accordion className="miss_custom_accordion" >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <div>Selecionar opções</div>
                </AccordionSummary>
                <AccordionDetails>
                  <div className="miss_custom_positionInputs">
                    <TextField
                      select
                      label="Selecione"
                      value={this.state.selected}
                      onChange={(e) => this.setState({ selected: e.target.value })}
                      displayEmpty
                      size="small"
                      variant="outlined"
                      className="miss_custom_inputSelect"
                      inputProps={{ 'aria-label': 'Without label' }}
                    >
                      {this.state.options.map((item, index) => (
                        <MenuItem value={item.value} key={item.id}>
                          <em>{item.name}</em>
                        </MenuItem>
                      ))}
                    </TextField>

                    {this.state.label === 'HR' ?
                      <TextField
                        type="time"
                        InputLabelProps={{ shrink: true }}
                        label={this.state.label}
                        value={this.state.volume}
                        onChange={(e) => this.setState({ volume: e.target.value })}
                        displayEmpty
                        size="small"
                        variant="outlined"
                        className="miss_custom_inputSelect"
                        inputProps={{ 'aria-label': 'Without label' }}
                      /> :
                      <>
                        {this.state.label === 'UNID' &&
                          <span className="labelInput">
                            Quantas vezes você gostaria de ser lembrado?
                          </span>
                        }

                        <TextField
                          type="number"
                          label={this.state.label}
                          value={this.state.volume}
                          onChange={(e) => this.setState({ volume: e.target.value })}
                          displayEmpty
                          size="small"
                          variant="outlined"
                          className="miss_custom_inputSelect"
                          inputProps={{ 'aria-label': 'Without label' }}
                        />
                      </>
                    }

                  </div>
                </AccordionDetails>
              </Accordion>

              { this.state.missionAccepted && <p style={{ color: 'var(--green)'}}>Missão aceita!</p> }

              <Button type="darkBlue" width="100px" onClick={() => {
                this.setState({ missionAccepted: true })
                this.props.onCloseMission({frequency: this.state.selected, volume: this.state.volume, id: this.props.data.id})
              }} >Confirmar</Button>
              <br />
            </div>

          </div>
        </Modal>
      </>
    )
  }
}

export default MissionCustom;
