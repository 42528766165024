import { Button, Grid, withStyles } from '@material-ui/core';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import React from 'react';
import './registerStepper2.scss';

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    padding: '50px 50px 25px 50px',
    maxHeight: '60vh',
    overflow: 'auto',
    '@media (max-width: 700px)': {
      padding: '10px',
      overflow: 'auto',
      height: '400px',
    },
  },
  button: {
    marginLeft: '5px',
    marginRight: '5px',
    borderRadius: '0px',
  },
  buttonDisabled: {
    marginLeft: '5px',
    marginRight: '5px',
    backgroundColor: '#999',
  },
  description: {
    fontSize: '20px',
    color: '#707070',
    textAlign: 'center',
    marginTop: '-14px',
    marginBottom: '20px',
    '@media (max-width: 700px)': {
      marginTop: '0',
      fontSize: '13px',
      marginBottom: '10px',
    },
  },
  loading: {
    display: 'flex',
    direction: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    height: '350px',
  },
  containerBtn: {
    display: 'flex',
    flexDirection: 'row-reverse',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '15px',
  },
  explanation: {
    '@media (max-width: 700px)': {
      padding: '10px 30px !important',
    },
  }
});

class RegisterStep2 extends React.Component {
  render() {
    const { classes, nextStep } = this.props;

    return (
      <div className={classes.root + ' registerStepper2'}>
        <Grid container spacing={2}>
          <Grid item xs={12} className={classes.explanation}>
            <h1 className="welcome-title">Seja Bem-vindo!</h1>
            <p>Antes de iniciar sua jornada queremos conhecer você um pouco mais.</p>
            <p>São apenas 2 perguntas, e você não vai levar mais do que 5 minutos.</p>
            <p>Vamos lá?</p>
          </Grid>
          <Grid
            container
            item
            xs={12}
            direction="row"
            justify="center"
            alignItems="center"
            className={classes.containerBtn}
          >
            <Button
              variant="contained"
              color="primary"
              className={`${classes.button} g-rs2-next`}
              style={{ position: 'absolute', bottom: '30px', right: '35px' }}
              onClick={() => {
                nextStep();
              }}
            >
              PRÓXIMA
              <ArrowRightIcon />
            </Button>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles)(RegisterStep2);
