import React, { useEffect, useRef, useState } from 'react';
// import '../../backoffice.scss';

import { Slider } from '@material-ui/core';
import api from "../../../../../services/api";
const Globals = require("../../../../../Globals.json");

export default function DimensionInfluenceInput( {paramTags, updateTags} ) {

    const influenceMarks = [
        { value: 0, label: "Nenhuma" },
        { value: 25, label: "25%" },
        { value: 50, label: "50%" },
        { value: 75, label: "75%" },
        { value: 100, label: "100%" },
    ];

    const [tags, setTags] = useState([]);

    const loadData = async () => {


        const response = await api.get(Globals.api.getRelevanceTags);
        // console.log("RELEVANCE TAGS::", response.data);
        // console.log("PARAM TAGS::", paramTags);

        const allTags = response.data.data;
        allTags.forEach( tag =>{ 
            const valuedTag = paramTags?.find( i => i.type_relevance_tag_id === tag.id );
            // console.log( "VALUEDTAG::", tag.id, valuedTag );
            tag.value = valuedTag ? valuedTag.value : 0;
        })

        setTags( allTags );

        if( updateTags ) updateTags( allTags );

    }

    useEffect(() => {

        loadData();

    }, []);

    const setTagValue = ( tagId, newVal ) =>
    {
        const tag = tags.find( i => i.id === tagId );
        if( tag )
        {
            tag.value = newVal;
        }
        // console.log( tags );

        if( updateTags ) updateTags( tags );
    }

    return (
        <div className="influence-setup-container">

            { tags.map((item, index) => (
                <div key={index} className="dimension-container">
                    <div className="dimension-label">
                        {item.type}
                    </div>
                    <div className="dimension-slider">
                        <Slider
                            onChange={ (e, newValue) => setTagValue( item.id, newValue ) }
                            defaultValue={item.value}
                            valueLabelFormat={(val) => val + "%"}
                            valueLabelDisplay="auto"
                            step={25}
                            marks
                            min={0}
                            max={100}
                            marks={influenceMarks}
                        />
                    </div>
                </div>
            ))}

        </div>

    )

}
