import React from 'react';
import { useMediaQuery } from 'react-responsive';
import Button from '../Button';
import MainText from '../MainText';
import * as Styled from './MainJourney.style';

function MainJourney({ openRegisterDialog }) {
  const isMobile = useMediaQuery({ maxWidth: 960 });
  const isIpadPro = useMediaQuery({ minWidth: 960, maxWidth: 1024 });

  return (
    <Styled.MainJourney>
      <div className="inner-wrapper">
        <MainText size={isIpadPro ? 430 : 530} style={{ marginLeft: !isMobile ? '80px' : null, zIndex: 1 }}>
          <h2 className="thin">
            Aqui você vai encontrar uma <strong>jornada personalizada</strong> para o seu problema.
          </h2>
          <p>
            Saúde financeira é como saúde física: cada problema pede uma solução específica. Por isso, o{' '}
            <strong>Meu Bolso em Dia da FEBRABAN</strong> desenvolveu diversos materiais, cursos e vídeos, procurando
            oferecer a ajuda mais adequada para cada perfil de pessoa e cada tipo de problema financeiro.
          </p>
          <Button onAction={openRegisterDialog}>Comece já</Button>
        </MainText>
        <figure>
          <img className="journey-steps" src="./images/main-journey.png" alt="Jornada Personalizada" />
          <img className="journey-steps__shield journey-steps__shield--small" src="./images/hero/shield.png" alt="" />
        </figure>
      </div>
    </Styled.MainJourney>
  );
}

export default MainJourney;
