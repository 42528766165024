import React from 'react';
import styled from 'styled-components';
import { useMediaQuery } from 'react-responsive'
import { withTheme } from "@material-ui/core/styles";
import { ReactComponent as LogoImage } from '../../../../assets/logo/logo-blue.svg';
import { ReactComponent as LogoImageShort } from '../../../../assets/logo/logo-blue-short.svg';

export const MainNav = withTheme(styled('div')`
  text-align: center;
  position: absolute;
  width: 100vw;

  .inner-wrapper {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin: auto;
    max-width: 1440px;
    padding: 39px 53px;

    @media(max-width: ${({ theme }) => `${theme.breakpoints.values.sm}px`}) {
      padding: 30px;
    } 

    @media only screen and (
      min-width: ${({ theme }) => `${theme.breakpoints.values.sm}px`}
    ) and (max-width: ${({ theme }) => `${theme.breakpoints.values.md}px`}) {
      align-items: flex-start;
    }

    svg {
      min-width: 285px;
      width: 285px;

      @media(max-width: ${({ theme }) => `${theme.breakpoints.values.sm}px`}) {
        min-width: 168px;
        width: 168px;
      }

      @media only screen and (
        min-width: ${({ theme }) => `${theme.breakpoints.values.sm}px`}
      ) and (max-width: ${({ theme }) => `${theme.breakpoints.values.md}px`}) {
        min-width: 168px;
        width: 168px;
      }
    }
  }

  .desktop-menu {
    a, button {
      background-color: transparent;
      border: none;
      box-sizing: border-box;
      color: var(--alternativeBlue);
      cursor: pointer;
      font-size: 14px;
      margin-right: 20px;
      text-decoration: none;
      text-transform: uppercase;

      &:last-child {
        margin-right: 0;
      }

      &.button {
        border: 1px solid var(--green);
        padding: 8px 13px;
        transition: .3s all ease-in-out;

        &:hover {
          background-color: var(--green);
          color: var(--white);
        }
      }
    }

    @media(max-width: 780px) {
      display: none;
    } 
  }

  .menu-icon {
    display: none;

    @media(max-width: 780px) {
      color: ${({ theme }) => `${theme.palette.primary.main}`};
      border: 1px solid ${({ theme }) => `${theme.palette.secondary.main}`};
      border-radius: 3px;
      display: block;
      height: 35px;
      padding: 5px;
      position: absolute;
      right: 30px;
      top: 30px;
      width: 35px;
      z-index: 10;
    } 
  }
`)

export const Logo = styled(() => {
  const isMobile = useMediaQuery({ maxWidth: 780 });
  return !isMobile ? <LogoImage style={{cursor: 'pointer'}} /> : <LogoImageShort style={{cursor: 'pointer'}} />
})``;
