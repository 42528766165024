import React, { useRef, useState } from 'react';
import { CompactPicker } from 'react-color';
import { useParams } from 'react-router';

import { FormControl, InputLabel, Select, TextareaAutosize, TextField, Switch, FormControlLabel } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

import Globals from '../../../Globals.json';
import api from '../../../services/api';
import Dialogs from '../../../services/dialogs';

import FileUploader from '../../components/backoffice/components/FileUploader/FileUploader';
import VideoUploader from '../../components/backoffice/components/VideoUploader/VideoUploader';
import ModuleEditing from '../../components/backoffice/components/ModuleEditing/ModuleEditing';
import Button from '../../components/button/Button';
import Storie from '../../components/stories/Stories';

import '../../../backoffice.scss';

export default function EditClassContentStory() {
  const timer = useRef();
  const params = useParams();
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const [slides, setSlides] = useState([]);
  const [curSlide, setCurSlide] = useState(null);
  const [previewSlide, setPreviewSlide] = useState(null);
  const [showPreview, setShowPreview] = useState(true);
  const [moduleIdWhenSave, setModuleIdWhenSave] = useState(params.contentId);

  const baseSlide = {
    backgroundType: 'default',
    textContent: '',
    background: '',
    contents: [],
    darkMode: false,
  };

  const baseContent = {
    size: 1,
    text: '',
    color: '#000',
  };

  const forceStateUpdate = () => {
    setPreviewSlide(JSON.parse(JSON.stringify(curSlide)));
  };

  const refreshStory = () => {
    setShowPreview(false);
    forceStateUpdate();
    clearInterval(timer.current);
    timer.current = setTimeout(() => {
      setShowPreview(true);
    }, 1000);
  };

  const onReceiveData = async (container, _, parent) => {
    const slides = container.data?.slides ?? [];
    setSlides(slides);
    setCurSlide(slides.length > 0 ? slides[0] : null);
    setPreviewSlide(slides.length > 0 ? slides[0] : null);

    const postData = {
      container_id: params.courseId,
      type_module_status_ids: [0, 1, 2],
    };
    const responseCourse = await api.post(Globals.api.getModules, postData);
    const course = responseCourse.data.container;

    setBreadcrumbs([
      {
        label: 'Cursos',
        link: Globals.paths.backofficeCourses,
      },
      {
        label: course.name,
        link: Globals.paths.backofficeEditCourse.replace(':courseId', course.id),
      },
      {
        label: parent.name,
        link: Globals.paths.backofficeEditClass
          .replace(':courseId', course.id)
          .replace(':classId', parent.id),
      },
      {
        label: container.name,
      },
    ]);
  };

  const addNewSlide = () => {
    slides.push(baseSlide);
    setCurSlide(slides[slides.length - 1]);
    setPreviewSlide(slides[slides.length - 1]);
  };

  const addNewContent = () => {
    if (!curSlide) return;
    curSlide.contents.push(baseContent);
    setSlides(slides.concat());
  };

  const getCurSlideIndex = () => slides.indexOf(curSlide);
  const isPrevEnabled = () => getCurSlideIndex() > 0;
  const isNextEnabled = () => getCurSlideIndex() < slides.length - 1;

  const prevSlide = () => {
    if (!isPrevEnabled()) return;
    setCurSlide(slides[getCurSlideIndex() - 1]);
    setPreviewSlide(slides[getCurSlideIndex() - 1]);
  };

  const nextSlide = () => {
    if (!isNextEnabled()) return;
    setCurSlide(slides[getCurSlideIndex() + 1]);
    setPreviewSlide(slides[getCurSlideIndex() + 1]);
  };

  const setCurSlideProp = (prop, value) => {
    curSlide[prop] = value;
    if (prop === 'backgroundType') curSlide.background = '';
    refreshStory();
  };

  const setContentProp = (content, prop, value) => {
    content[prop] = value;
    refreshStory();
  };

  const moveContent = (content, amount) => {
    const index = curSlide.contents.indexOf(content);
    const tmp = curSlide.contents.splice(index, 1);
    curSlide.contents.splice(index + amount, 0, tmp[0]);
    refreshStory();
  };

  const deleteContent = (content) => {
    Dialogs.show('Confirmação de Exclusão', 'Você tem certeza de que deseja excluir o conteúdo?', [
      { label: 'Cancelar' },
      {
        label: 'Sim',
        onClick: () => doDeleteContent(content),
      },
    ]);
  };

  const doDeleteContent = (content) => {
    const index = curSlide.contents.indexOf(content);
    curSlide.contents.splice(index, 1);
    refreshStory();
  };

  const deleteCurSlide = () => {
    Dialogs.show('Confirmação de Exclusão', 'Você tem certeza de que deseja excluir o slide?', [
      {
        label: 'Cancelar',
      },
      {
        label: 'Sim',
        onClick: () => {
          const oldIndex = getCurSlideIndex();
          slides.splice(oldIndex, 1);
          if (slides.length === 0) {
            setCurSlide(null);
            setPreviewSlide(null);
          } else {
            let newIndex = oldIndex;
            if (newIndex > slides.length - 1) newIndex = slides.length - 1;
            setCurSlide(slides[newIndex]);
            setPreviewSlide(slides[newIndex]);
          }
        },
      },
    ]);
  };

  const getBackgrountType = (type) => {
    const types = {
      0: '0',
      1: '1',
      2: '2',
    };

    if (curSlide && types.hasOwnProperty(type)) {
      return types[type];
    }

    return 'default';
  };

  const handleToogleDarkMode = (e) => {
    setCurSlideProp('darkMode', e.target.checked);
  }

  const custom = (
    <div>
      {!moduleIdWhenSave || moduleIdWhenSave === 'new' ? (
        <div className="before-valid-id-container">
          <p>Para adicionar um slide é necessário salvar o story primeiro.</p>
        </div>
      ) : (
        <div className="slides-container">
          <div className="data-panel">
            <div className="slides-top">
              <div className="current-slide">
                <div
                  onClick={prevSlide}
                  className={'button-slide-arrow prev' + (!isPrevEnabled() ? ' disabled' : '')}
                >
                  <ArrowBackIosIcon style={{ fontSize: '15px' }} />
                </div>
                Slide {slides.indexOf(curSlide) + 1} de {slides.length}
                <div
                  onClick={nextSlide}
                  className={'button-slide-arrow next' + (!isNextEnabled() ? ' disabled' : '')}
                >
                  <ArrowForwardIosIcon style={{ fontSize: '15px' }} />
                </div>
              </div>
              <div className="buttons">
                <Button
                  icon="none"
                  type={'darkBlue'}
                  textAlign="center"
                  height="30px"
                  onClick={() => addNewSlide()}
                >
                  Adicionar Slide
                </Button>
                <div onClick={() => deleteCurSlide()} className="button-delete-slide">
                  <DeleteForeverIcon style={{ fontSize: '20px' }} />
                </div>
              </div>
            </div>
            {curSlide && (
              <div className="slide-fields">
                <FormControl>
                  <InputLabel htmlFor="backgroundType">Tipo de Fundo</InputLabel>
                  <Select
                    native
                    value={getBackgrountType(curSlide.backgroundType)}
                    onChange={(e) => {
                      setCurSlideProp('backgroundType', e.target.value);
                    }}
                    inputProps={{
                      id: 'backgroundType',
                    }}
                  >
                    <option value="default">Selecione...</option>
                    <option value="0">Video</option>
                    <option value="1">Imagem</option>
                    <option value="2">Color</option>
                  </Select>
                </FormControl>

                <div className="background-selection">
                  {getBackgrountType(curSlide.backgroundType) === '0' && ( //Video
                    <VideoUploader
                      onChange={(url) => setCurSlideProp('background', url)}
                      moduleId={moduleIdWhenSave}
                      maxFileSize={50}
                      videoUrl={curSlide.background}
                    />
                  )}
                  {getBackgrountType(curSlide.backgroundType) === '1' && ( //Image
                    <FileUploader
                      onChange={(url) => setCurSlideProp('background', url)}
                      folder="stories"
                      imageUrl={curSlide.background}
                      maxFileSize={2}
                    />
                  )}
                  {getBackgrountType(curSlide.backgroundType) === '2' && ( //Color
                    <div className="picker-content">
                      <div
                        className="picker-preview"
                        style={{ backgroundColor: curSlide.background }}
                      ></div>
                      <CompactPicker
                        color={curSlide.background}
                        onChange={(color) => setCurSlideProp('background', color.hex)}
                      />
                    </div>
                  )}
                </div>

                <FormControl>
                  <TextareaAutosize
                    placeholder="Conteúdo de Texto"
                    value={curSlide.textContent}
                    rowsMin={3}
                    onChange={(e) => setCurSlideProp('textContent', e.target.value)}
                  />
                </FormControl>

                <div className="slide-contents">
                  {curSlide.contents.map((item, index) => (
                    <div key={index} className="slide-content">
                      <div className="content-top-bar">
                        <div className="content-top-title">Conteúdo</div>
                        <div onClick={() => moveContent(item, +1)} className="content-mini-button">
                          <ArrowDownwardIcon style={{ fontSize: '15px' }} />
                        </div>
                        <div onClick={() => moveContent(item, -1)} className="content-mini-button">
                          <ArrowUpwardIcon style={{ fontSize: '15px' }} />
                        </div>
                        <div onClick={() => deleteContent(item)} className="content-mini-button">
                          <DeleteForeverIcon style={{ fontSize: '15px', color: '#900' }} />
                        </div>
                      </div>
                      <div className="content-content">
                        <FormControl>
                          <InputLabel htmlFor={'itemSize_' + index}>Tamanho</InputLabel>
                          <Select
                            native
                            value={item.size}
                            onChange={(e) => setContentProp(item, 'size', parseInt(e.target.value))}
                            inputProps={{
                              id: 'itemSize_' + index,
                            }}
                          >
                            <option value={0}>Pequeno</option>
                            <option value={1}>Médio</option>
                            <option value={2}>Grande</option>
                            <option value={3}>Automático</option>
                          </Select>
                        </FormControl>
                        <TextField
                          label="Texto"
                          value={item.text}
                          onChange={(e) => setContentProp(item, 'text', e.target.value)}
                        />
                        <div className="color-picker">
                          <div className="label">Cor</div>
                          <div className="picker-content">
                            <div
                              className="picker-preview"
                              style={{ backgroundColor: item.color }}
                            ></div>
                            <CompactPicker
                              color={item.color}
                              onChange={(color) => setContentProp(item, 'color', color.hex)}
                            />
                          </div>
                        </div>
                        <div className="add-content-button">
                          <FormControlLabel
                            control={
                              <Switch checked={ curSlide.darkMode || false } onChange={(e) => setCurSlideProp('darkMode', e.target.checked)} />
                            }
                            label="Cor clara na setas"
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                </div>

                <div className="add-content-button">
                  <Button
                    icon="none"
                    type="darkBlue"
                    textAlign="center"
                    height="30px"
                    onClick={() => addNewContent()}
                  >
                    Adicionar Conteúdo
                  </Button>
                </div>
              </div>
            )}
          </div>
          <div className="preview-panel">
            {!showPreview && (
              <div
                style={{
                  width: '432px',
                  height: '100%',
                  backgroundColor: '#eee',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <CircularProgress />
              </div>
            )}
            {previewSlide && showPreview && (
              <Storie storie={{ id: -1, name: '', slides: [previewSlide] }}></Storie>
            )}
            {getCurSlideIndex() > 0 && showPreview && (
              <div onClick={() => prevSlide()} className="move-slide left">
                <ArrowBackIcon style={{ fontSize: '25px' }} />
              </div>
            )}
            {getCurSlideIndex() < slides.length - 1 && showPreview && (
              <div onClick={() => nextSlide()} className="move-slide right">
                <ArrowForwardIcon style={{ fontSize: '25px' }} />
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );

  const isNew = () => (moduleIdWhenSave === 'new' && !!moduleIdWhenSave);

  const onBeforeSaveData = (postData) => {
    postData.thumbnail = 'http://';
    postData.description = JSON.stringify(slides);
    postData.data.slides = slides;
  };

  const onAfterSaveData = async (postData, responseData) => {
    if (responseData.id) {
      setModuleIdWhenSave(String(responseData.id))
      return
    }
    if (responseData.data.id) {
      setModuleIdWhenSave(String(responseData.data.id))
      return
    }
  };

  const onValidate = (itemStatus) => {
    if (slides.length === 0 && itemStatus === 1) {
      Dialogs.show(
        'Nenhum Slide',
        'É necessário que seja adicionado ao menos 1 slide ao conteúdo para que o stories seja salvo como ativo.'
      );
      return false;
    }
    return true;
  };

  return (
    <ModuleEditing
      isNew={isNew()}
      moduleId={moduleIdWhenSave}
      typeModuleId={35}
      parentId={params.classId}
      title="Detalhe de Conteúdo de Stories"
      onReceiveData={onReceiveData}
      onAfterSaveData={onAfterSaveData}
      onBeforeSaveData={onBeforeSaveData}
      onValidate={onValidate}
      customDataFields={custom}
      hideThumbnailField
      hideDescriptionField
      hideRewards
      breadcrumbs={breadcrumbs}
      hideDeletedContent={true}
      preventBackOnSave={true}
    />
  );
}
