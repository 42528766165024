import React, { useState } from 'react';
import { useParams } from 'react-router';
import { TextField } from '@material-ui/core';
import ModuleEditing from '../../components/backoffice/components/ModuleEditing/ModuleEditing';
import '../../../backoffice.scss';
import Globals from '../../../Globals.json';

export default function EditQuiz() {
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const [options, setOptions] = useState({});
  const params = useParams();

  const onReceiveData = (container, _) => {
    console.log({ container });
    setBreadcrumbs([
      {
        label: 'Mini Quiz',
        link: Globals.paths.backofficeMiniQuiz,
      },
      {
        label: container.name,
      },
    ]);

    setOptions(
      container.data.options.reduce((acc, item) => ({
        ...acc,
        [item.id]: item.text,
      }), {})
    );
  };

  const onBeforeSaveData = (postData) => {
    postData.data.options = Object.keys(options).reduce((acc, id) => ([
      ...acc,
      {
        text: options[id],
        id: Number(id),
      },
    ]), []);
  };

  const handleChange = ({ target }) => {
    setOptions((prevState) => ({
      ...prevState,
      [target.name]: target.value,
    }));
  };

  const customDataFields = (
    <div className="miniquiz-container">
      <div className="miniquiz-options-container">
        <div className="page-title">Opções</div>
        {Array(5)
          .fill('')
          .map((_, idx) => {
            return (
              <div key={idx} className="quiz-option">
                <TextField
                  name={(idx + 1).toString()}
                  style={{ width: '500px' }}
                  label={`Opção ${idx + 1}`}
                  value={options.hasOwnProperty(idx + 1) ? options[idx + 1] : ''}
                  onChange={handleChange}
                />
              </div>
            );
          })}
      </div>
    </div>
  );

  return (
    <ModuleEditing
      isNew={params.miniquizId === 'new'}
      typeModuleId={33}
      moduleId={params.miniquizId}
      title="Detalhes do Mini Quiz"
      customDataFields={customDataFields}
      onReceiveData={onReceiveData}
      onBeforeSaveData={onBeforeSaveData}
      breadcrumbs={breadcrumbs}
    />
  );
}
