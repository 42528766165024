export const enumQuestStatus = {
  available: 1,
  ongoing: 2,
  completed: 3,
  completedAndRedeemed: 4,
};

export const enumTypeModules = {
  quest: 20,
  customQuest: 30,
  questline: 34,
  miniQuiz: 33,
  quiz: 1,
  course: 2,
  article: 10,
  articleBlog: 31,
};

export const enumBehaviorQuestTypes = {
  tutorial: 0,
  profileCompleted: 1,
  articleById: 2,
  articleAmount: 3,
  assetById: 4,
  assetAmount: 5,
  courseById: 6,
  courseAmount: 7,
  classById: 8,
  classAmount: 9,
  utilityAmount: 10,
  utilityById: 11,
  quizById: 12,
  quizAmount: 13,
  selfDeclared: 14,
  calculateIndexAmount: 15,
};

export const enumTypeUserStatuses = {
  notVerified: 0,
  active: 1,
  inactive: 2,
  excluded: 3,
  firstIndexMustBeFilled: 4,
  validatingIndebtProfile: 6,
  forwardToBlog: 7,
};

let ContentSize;
(function (ContentSize) {
  ContentSize[(ContentSize['Small'] = 0)] = 'Small';
  ContentSize[(ContentSize['Medium'] = 1)] = 'Medium';
  ContentSize[(ContentSize['Big'] = 2)] = 'Big';
  ContentSize[(ContentSize['Auto'] = 3)] = 'Auto';
})(ContentSize || (ContentSize = {}));

let BackgroundType;
(function (BackgroundType) {
  BackgroundType[(BackgroundType['Video'] = 0)] = 'Video';
  BackgroundType[(BackgroundType['Image'] = 1)] = 'Image';
  BackgroundType[(BackgroundType['Color'] = 2)] = 'Color';
})(BackgroundType || (BackgroundType = {}));

export const enumContentSize = ContentSize;
export const enumBackgroundType = BackgroundType;
