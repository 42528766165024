import React from 'react';

//Componentes
import { withStyles } from '@material-ui/core';

const styles = theme => ({

  container: {
    padding: "30px",
  },
  
  optionsContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    flexWrap: "wrap",
    height: 300
  },

  option: {

    height: "30px",
    display: "flex",
    padding: "10px",
    fontSize: "12px",
    alignItems: "center",
    border: "#004178 1px solid",
    color: "#004178",
    flexDirection: "row",
    justifyContent: "flex-start",
    marginBottom: "5px",
    marginRight: "10px",
    cursor: "pointer",

  },

  optionSelected: {

    height: "30px",
    display: "flex",
    padding: "10px",
    fontSize: "12px",
    alignItems: "center",
    border: "2px solid #80D9BD",
    color: "#80D9BD",
    flexDirection: "row",
    justifyContent: "flex-start",
    marginBottom: "5px",
    marginRight: "10px",
    cursor: "pointer",

  }

});

class MultiselectOptionList extends React.Component {

  state = {
    options: []
  }

  componentDidMount() {

    if (this.props.options) {
      this.setState({ options: this.props.options.concat() });
    }

  }

  handleSelection = item => {

    console.log( item );

    const opt = this.state.options;
    opt.forEach( optItem => {
      if( optItem.id === item.id )
      {
        optItem.selected = optItem.selected ? false : true;
      }
    })

    this.setState( { options: opt } );

    if( this.props.onUpdateSelected )
    {
      const selected = [];
      opt.forEach( optItem => {
        if( optItem.selected )
        {
          selected.push( optItem );
        }
      })

      this.props.onUpdateSelected( selected );

    }

  }

  render() {

    const { classes } = this.props;

    return (
      <div className={classes.container}>
        <div className={classes.optionsContainer}>
          {this.state.options.map((item, index) => (
            <div  
              key={index}
              className={item.selected ? classes.optionSelected : classes.option }
              onClick={() => this.handleSelection(item)}>
                {item.text}
            </div>
          ))}
        </div>
      </div>
    )
  }

}

export default withStyles(styles)(MultiselectOptionList);
