import React, { Component } from 'react';
import './styles/expandCertificateImg.scss';

import { Modal } from '@material-ui/core';

import CertificateComponent from '../certificate/CertificateComponent';

class ExpandCertificateImg extends Component {

  state = {
    user: '',
  }

  render() {
    return (
      <>
        <Modal
          open={this.props.open}
          onClose={this.props.onClose}
          className="expanded_modal"
        >
          <CertificateComponent courseId={this.props.courseId} onClose={this.props.onClose} />
        </Modal>
      </>
    )
  }

}

export default ExpandCertificateImg;
