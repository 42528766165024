import { TextField } from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import '../../../backoffice.scss';

import ModuleEditing from '../../components/backoffice/components/ModuleEditing/ModuleEditing';

import Globals from "../../../Globals.json";
import api from '../../../services/api';


export default function EditClassContentAudio() {

  const [url, setUrl] = useState({});

  const [container, setContainer] = useState({});
  const [breadcrumbs, setBreadcrumbs] = useState([]);

  const params = useParams();
  const history = useHistory();

  const onReceiveData = async (container, children, parent) => {

    setContainer(container);
    setUrl(container.description);

    const postData = {
      "container_id": params.courseId,
      type_module_status_ids: [0, 1, 2],
    };
    const responseCourse = await api.post(Globals.api.getModules, postData);
    const course = responseCourse.data.container;

    setBreadcrumbs([
      {
        label: "Cursos",
        link: Globals.paths.backofficeCourses,
      },
      {
        label: course.name,
        link: Globals.paths.backofficeEditCourse.replace(":courseId", course.id)
      },
      {
        label: parent.name,
        link: Globals.paths.backofficeEditClass.replace(":courseId", course.id).replace(":classId", parent.id)
      },
      {
        label: container.name
      }
    ]);

  }

  const custom =
    <>
      <div style={{ borderBottom: "#999 1px solid", paddingBottom: "30px" }}>
        <TextField
          style={{ width: "400px" }}
          key="txtUrl"
          label="Áudio URL"
          value={url}
          onChange={(e) => setUrl(e.target.value)}
        />
        <div style={{ marginTop: "20px" }}>Áudio Preview</div>
        <video id="ctMedia" className="classDetailVideoSmUp" width="400px" height="200" controls="controls">
            <source src={url} />
            Seu navegador não suporta este tipo de mídia.
          </video>
      </div>
    </>

  const onBeforeSaveData = (postData) => {
    postData.description = url;
  }

  const onAfterSaveData = (postData) => {
    history.goBack();
  }

  const onNewData = (newData) => {
  }

  return (

    <ModuleEditing
      isNew={params.contentId === "new"}
      moduleId={params.contentId}
      typeModuleId={14}
      parentId={params.classId}
      title="Detalhe de Conteúdo de Vídeo"
      onReceiveData={onReceiveData}
      onBeforeSaveData={onBeforeSaveData}
      onAfterSaveData={onAfterSaveData}
      onNewData={onNewData}
      customDataFields={custom}
      hideThumbnailField
      hideDescriptionField
      hideRewards
      breadcrumbs={breadcrumbs}
    >

    </ModuleEditing>

  );
}

