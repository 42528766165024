import { Dialog, DialogContent, Grid, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import Globals from '../../Globals.json';
import RegisterStep1 from './RegisterStep1';
import RegisterStep2 from './RegisterStep2';
import RegisterStep3 from './RegisterStep3';
import RegisterStep4 from './RegisterStep4';
import './registerStepper.scss';

const useStyles = makeStyles((theme) => ({
  button: {
    marginRight: theme.spacing(1),
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  dialogPaper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    spacing: 0,
    padding: 0,
  },
  actions: {
    marginTop: theme.spacing(2),
  },
  dialogPaperScrollPaper: {
    maxHeight: 'none',
  },
  dialogContent: {
    width: '100%',
    overflow: 'hidden',
    padding: '0px',
    '&:first-child': {
      paddingTop: 0,
    },
  },
  mainContainer: {
    backgroundColor: '#004178',
  },
  header: {
    width: '100%',
    height: '80px',
    backgroundColor: '#004178',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    '@media (max-width: 700px)': {
      height: '50px',
    },
  },
  title: {
    padding: '30px',
    fontSize: '10pt',
    color: '#FFF',
    '@media (max-width: 700px)': {
      padding: '10px 10px 0',
      fontSize: '13px',
    },
  },
  closeButton: {
    position: 'absolute',
    top: '5px',
    right: '5px',
  },
  content: {
    backgroundColor: '#fff',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    paddingBottom: '20px',
  },
  footer: {
    width: '100%',
    height: '20px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'flex-end',
  },
  footerStep: {
    flexGrow: 1,
    backgroundColor: '#E5E5E5',
    height: '10px',
  },
  explanation: {
    fontSize: '14px',
    color: '#666',
    textAlign: 'left',
    paddingTop: '20px',
    paddingBottom: '20px',
    '@media (max-width: 700px)': {
      padding: '10px 15px',
      fontSize: '13px',
    },
  },
  outerContainer: {
    display: 'flex',
    flexDirection: 'row',
  },

  imageLeft: {
    width: '50%',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    '@media (max-width: 700px)': {
      display: 'none',
    },
  },
  headerText: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '@media (max-width: 700px)': {
      height: '50px',
      fontSize: '11px',
    },
  },
}));

export default function RegisterStepper(props) {
  let history = useHistory();
  const classes = useStyles();
  const { closeFunction } = props;
  const [curStep, setCurStep] = useState(props.startStep || 1);
  const [classImage, setClassImage] = useState('img1');

  const nextStep = () => {
    setCurStep(curStep + 1);
  };

  const changeClassImage = (i) => {
    setClassImage('img' + i);
  };

  const goToFeed = () => {
    history.replace(Globals.paths.home);
  };

  return (
    <Dialog
      open={props.open}
      disableBackdropClick={false}
      disableEscapeKeyDown={false}
      fullWidth={true}
      maxWidth={'md'}
      classes={{
        paper: classes.dialogPaper,
        paperScrollPaper: classes.dialogPaperScrollPaper,
      }}
      className="registerStepper"
      hideBackdrop={false}
    >
      <DialogContent spacing={0} className={classes.dialogContent}>
        <div className={classes.outerContainer}>
          {curStep === 1 && <div className={classes.imageLeft + ' imageLeft ' + classImage}></div>}
          {curStep === 2 && (
            <div className={classes.imageLeft + ' step2-left'}>
              <img
                src="/images/mbd-logo.svg"
                className="mbd-logo"
                alt="Logo Meu Bolso em Dia"
              ></img>
            </div>
          )}
          {curStep === 4 && <div className={classes.imageLeft + ' imageLeft img4'}></div>}
          <Grid direction="column" container className={classes.mainContainer}>
            <Grid
              item
              className={
                classes.header + ' ' + (curStep === 2 || curStep === 4 ? 'green-header' : '')
              }
            >
              <Grid container direction="column" className={classes.title}>
                {curStep === 1 && <div>VAMOS COMEÇAR JUNTOS</div>}
                {curStep === 2 && <div>VAMOS COMEÇAR JUNTOS</div>}
                {curStep === 3 && (
                  <div className="step3-logo-container">
                    <img
                      src="/images/mdb-icon-on-dark.svg"
                      className="mbd-logo"
                      alt="Logo Meu Bolso em Dia"
                    ></img>
                  </div>
                )}
                {curStep === 4 && <div>OBRIGADO PELO SEU INTERESSE!</div>}
              </Grid>

              {curStep === 1 && (
                <IconButton
                  size="small"
                  className={classes.closeButton}
                  color="primary"
                  onClick={() => {
                    closeFunction();
                  }}
                >
                  <CloseIcon style={{ color: '#fff' }} />
                </IconButton>
              )}
            </Grid>
            <div className={classes.content}>
              {curStep === 1 && (
                <RegisterStep1
                  nextStep={nextStep}
                  closeFunction={closeFunction}
                  changeClassImage={changeClassImage}
                />
              )}
              {curStep === 2 && <RegisterStep2 nextStep={nextStep} />}
              {curStep === 3 && <RegisterStep3 nextStep={goToFeed} />}
              {curStep === 4 && <RegisterStep4 />}
            </div>
          </Grid>
        </div>
      </DialogContent>
    </Dialog>
  );
}
