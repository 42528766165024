import React from 'react';
import './styles.scss';

import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

class ProfileChart extends React.Component {
  render() {
    console.log(this.props)
    return (
      <>
        {this.props.responseChart ?
          <div className="chart">
            <ResponsiveContainer>
              <AreaChart data={this.props.data} >
                <CartesianGrid strokeDasharray="3" />
                <XAxis dataKey="category" fontSize="12" />
                <YAxis />
                <Tooltip />
                <Area className="area" type="monotone" name="Cursos" dataKey="course" stroke="#004178" fill="#004178" fillOpacity={.6} />
                <Area className="area" type="monotone" name="Artigos" dataKey="article" stroke="#4fad96" fill="#4fad96" fillOpacity={.6} />
                <Area className="area" type="monotone" name="Quiz" dataKey="quiz" stroke="#6E44B3" fill="#6E44B3" fillOpacity={.6} />
                <Area className="area" type="monotone" name="Missões" dataKey="quest" stroke="#a1d1af" fill="#a1d1af" fillOpacity={.6} />
                <Area className="area" type="monotone" name="Mini Quiz" dataKey="miniQuiz" stroke="#f6b09c" fill="#f6b09c" fillOpacity={.6} />
              </AreaChart>
            </ResponsiveContainer>
          </div> :

          <AreaChart 
            data={this.props.data}
            width={this.props.width || 700}
            height={this.props.height || 300}
          >
            <CartesianGrid strokeDasharray="3" />
            <XAxis dataKey="category" fontSize="12" />
            <YAxis />
            <Tooltip/>
            <Area className="area" type="monotone" name="Cursos" dataKey="course" stroke="#004178" fill="#004178" fillOpacity={.6} />
            <Area className="area" type="monotone" name="Artigos" dataKey="article" stroke="#4fad96" fill="#4fad96" fillOpacity={.6} />
            <Area className="area" type="monotone" name="Quiz" dataKey="quiz" stroke="#6E44B3" fill="#6E44B3" fillOpacity={.6} />
            <Area className="area" type="monotone" name="Missões" dataKey="quest" stroke="#a1d1af" fill="#a1d1af" fillOpacity={.6} />
            <Area className="area" type="monotone" name="Mini Quiz" dataKey="miniQuiz" stroke="#f6b09c" fill="#f6b09c" fillOpacity={.6} />
          </AreaChart>
        }
      </>
    )
  }
}
export default ProfileChart;