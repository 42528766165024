import React from 'react';
import ModuleListing from '../../components/backoffice/components/ModuleListing/ModuleListing';
import Globals from '../../../Globals.json';
import '../../../backoffice.scss';

export default function Quizzes() {
  return (
    <ModuleListing
      title="Lista de Mini Quizzes"
      buttonLabel="Novo Mini Quiz"
      typeModuleId={33}
      fullUrl={Globals.paths.backofficeEditMiniQuiz}
      urlReplacePattern=":miniquizId"
    />
  );
}
