import React from 'react';
import { useMediaQuery } from 'react-responsive';
import MainText from '../MainText';
import * as Styled from './Video.styles';

function Video({ openVideoDialog }) {
  const isIpadPro = useMediaQuery({ minWidth: 960, maxWidth: 1024 });

  return (
    <Styled.Video>
      <div className="inner-wrapper">
        <div>
          <MainText size={isIpadPro ? 700 : 1000} style={{ textAlign: 'center' }}>
            <h2>Coloque suas finanças em forma</h2>
            <p>
              Você encontra um programa de treinamento completo para fortalecer suas finanças, de um
              jeito simples, rápido e de graça.
            </p>
            {/* <Button onAction={openRegisterDialog}>Comece já</Button> */}
          </MainText>
        </div>
        <figure className="featured">
          <div className="featured__image" onClick={openVideoDialog}>
            <img
              className="featured__image"
              src="./images/video/foto_video_play.png"
              alt="abrir video"
            />
          </div>
        </figure>
      </div>
    </Styled.Video>
  );
}

export default Video;
