import React, { Component } from 'react';
import { Button, Container, DialogActions, DialogContent, DialogTitle, Grid, Hidden, IconButton, Paper, Typography } from '@material-ui/core';
import { watch } from 'redux-easy/lib/redux-easy';
import { withRouter } from 'react-router-dom';
import CloseIcon from '@material-ui/icons/Close';
import './personaDetail.scss';

class PersonaDetail extends Component {

    componentDidMount = () => {
    }

    handleClose = () => {
        if (this.props.handleClose) {
            this.props.handleClose();
        }
    }

    render () {

        return (

            <>
                <DialogTitle disableTypography className="dialog-header">
                    <IconButton color="secondary" aria-label="close" className="close-button" onClick={this.handleClose}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent className="dialog-content persona-detail-body" dividers>
                    <Grid container direction="column" justify="flex-start" alignItems="center">
                        <Container className="content-holder">
                            <Grid container className="persona-detail-content">
                                <Grid container>
                                    <Grid container item xs={12} md={6} justify="center" alignItems="center" className="p20 persona-image-holder">
                                        <Hidden smDown>
                                            <img src={`/images/personas/${this.props.data.persona.type}.svg`} width="80%" alt="" />
                                        </Hidden>
                                        <Hidden mdUp>
                                            <img src={`/images/personas/${this.props.data.persona.type}.svg`} width="40%" alt="" />
                                        </Hidden>
                                    </Grid>
                                    <Grid container item xs={12} md={6} justify="center" alignItems="center" className="p20">
                                        <div className="profile-content">
                                            <h1>{this.props.data.persona.name}</h1>
                                            <p>
                                                {this.props.data.persona.description}
                                            </p>
                                        </div>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Container>
                    </Grid>
                </DialogContent >
                <DialogActions className="dialog-footer space-between">
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => { this.props.goBack(); }}
                        style={{ borderRadius: "0px", color: "white" }}
                    >
                        Voltar
                    </Button>
                </DialogActions>
            </>
        )
    }
}

export default watch(withRouter(PersonaDetail), { user: '' });