import React from "react";
import './styles/toolList.scss';

import { Hidden } from '@material-ui/core';

import ToolItem from './ToolItem';

class ToolList extends React.Component {
  state = {
    index: 0
  };

  handleChangeIndex = (index) => {
    this.setState({
      index
    });
  };

  render() {
    return (
      <>
        <Hidden xsDown>
          <div className="tool_list_containerBox">
            <ToolItem />
          </div>
        </Hidden>


        <Hidden smUp>
          <ToolItem />
        </Hidden>
      </>
    );
  }
}

export default ToolList;
