import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import { watch } from 'redux-easy/lib/redux-easy';
import BackofficeLayout from './logged_in/Layout/BackofficeLayout';
import MainLayout from './logged_in/Layout/MainLayout';
import ConfirmEmail from './logged_out/components/ConfirmEmail';
import LandingPage from './logged_out/components/LandingPage';
import MigrateFromIndex from './logged_out/components/MigrateFromIndex';
import PublicCertificate from './logged_out/components/pageCertificate/PublicCertificate';
import ResetPassword from './logged_out/components/register_login/ResetPassword';
import GeneratePdf from './logged_out/components/report/GeneratePdf/GeneratePdf';
import PersonalReport from './logged_out/components/report/PersonalReport';
import { isAdmin, isAuthenticated, canAccessAdminArea } from './services/auth';
import Dialogs from './services/dialogs';
import { enumTypeUserStatuses } from './services/enums';
import CookiesWarning from './shared/components/CookiesWarning';

const Globals = require('./Globals.json');

const PrivateRoute = ({ component: Component, location, user, ...rest }) => {

  if (isAuthenticated()) {
    if (user && user.profile) {
      const status = user.profile.type_users_status_id;
      const permitted = [
        Globals.paths.home,
        Globals.paths.missionsWithoutType,
        Globals.paths.missionsWithId,
        Globals.paths.profile,
        Globals.paths.article,
        Globals.paths.missionsOngoing,
        Globals.paths.avatar,
        Globals.paths.incentive
      ];

      switch (status) {

        case enumTypeUserStatuses.forwardToBlog: {
          if (location.pathname !== Globals.paths.forwardToBlog) {
            return (
              <Redirect to={{ pathname: Globals.paths.forwardToBlog, state: { from: location } }} />
            );
          }
          break;
        }

        case enumTypeUserStatuses.notVerified: {
          if (location.pathname !== Globals.paths.confirmEmail) {
            return (
              <Redirect to={{ pathname: Globals.paths.confirmEmail, state: { from: location } }} />
            );
          }
          break;
        }

        case enumTypeUserStatuses.validatingIndebtProfile: {
          if (location.pathname !== Globals.paths.welcome) {
            return (
              <Redirect
                to={{ pathname: Globals.paths.validatingIndebtProfile, state: { from: location } }}
              />
            );
          }
          break;
        }

        default: {
          if (user.profile.progress && user.profile.progress.level === 1) {
            if (
              permitted.indexOf(location.pathname) === -1 &&
              location.pathname.indexOf(Globals.paths.articleBase) === -1
            ) {
              return <Redirect to={{ pathname: Globals.paths.home, state: { from: location } }} />;
            }

          }
          break;
        }

      }
    }

    return <Route {...rest} render={(props) => <Component {...props} />} />;
  } else {
    return <Redirect to={{ pathname: '/', state: { from: location } }} />;
  }
};

const AdminRoute = ({ component: Component, location, user, ...rest }) => {

  const isAdm = () => {
    const roles = ['Admin'];

    const userRoles = user.profile.Roles;

    let isAllowed = false;

    if (userRoles) {
      isAllowed = !!userRoles.find(r => roles.indexOf(r.name) > -1);
    }

    return isAllowed;
  }

  return (
    <Route
      {...rest}
      render={(props) =>
        isAdm() ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: '/', state: { from: props.location } }} />
        )
      }
    />
  );
};

const Routes = ({ user, location, history }) => {
  const [dialogData, setDialogData] = useState({ isOpened: false });

  useEffect(() => {
    const target = document.querySelector('body');
    const observer = new MutationObserver((mutations) => {
      mutations.forEach(() => {
        target.style.overflow = 'auto';
        target.style.paddingRight = 'unset';
      });
    });
    observer.observe(target, { attributes: true, attributeFilter: ['style'] });
    return () => observer.disconnect();
  }, []);

  Dialogs.setDialogSetter((data) => setDialogData(data));

  const backofficeRoutes = [];
  for (let s in Globals.paths) {
    if (s.indexOf('backoffice') > -1) backofficeRoutes.push(s);
  }

  history.listen(() => {
    window.scrollTo(0, 0);
  });

  if (isAuthenticated()) {
    if (user && user.profile) {
      const status = user.profile.type_users_status_id;
      console.log(window.location.href);

      if (
        window.location.href.indexOf(Globals.paths.forwardToBlog) != -1 &&
        status !== enumTypeUserStatuses.forwardToBlog
      ) {
        window.location.href = Globals.paths.home;
      }
    }
  }

  return (
    <div>
      <div>
        <CookiesWarning />
        <Dialog
          open={dialogData.isOpened}
          onClose={() => console.log('fecha nada')}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{dialogData.title}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {dialogData.message}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            {(dialogData.buttons ?? []).map((item, index) => (
              <Button
                key={index}
                onClick={() => {
                  if (item.onClick) {
                    item.onClick();
                  }
                  Dialogs.close();
                }}
                color={item.color || 'primary'}
              >
                {item.label}
              </Button>
            ))}
          </DialogActions>
        </Dialog>
      </div>

      <Switch>
        <Route exact path={Globals.paths.root} component={LandingPage} />

        <Route exact path={Globals.paths.faq}>
          <LandingPage faq={true} />
        </Route>

        <Route exact path={Globals.paths.changePassword}>
          <LandingPage openModalPass={true} />
        </Route>

        <PrivateRoute exact path={Globals.paths.validatingIndebtProfile}>
          <LandingPage isValidatingIndebtProfile={true} />
        </PrivateRoute>

        <PrivateRoute exact path={Globals.paths.forwardToBlog}>
          <LandingPage isForwardToBlog={true} />
        </PrivateRoute>

        <Route exact path={Globals.paths.check}>
          <LandingPage openModalLogin={true} emailVerified={true} />
        </Route>

        <Route path={Globals.paths.migrateFromIndex}>
          <MigrateFromIndex />
        </Route>

        <Route exact path={Globals.paths.confirmEmail} component={ConfirmEmail} />

        <Route path={Globals.paths.register} component={() => <h1>Registrar usuario</h1>} />
        <Route exact path={Globals.paths.resetPassword} component={ResetPassword} />
        <Route exact path={Globals.paths.personalReport} component={PersonalReport} />
        <Route exact path={Globals.paths.generatePdf} component={GeneratePdf} />
        <Route exact path={Globals.paths.publicCertificate} component={PublicCertificate} />

        <PrivateRoute user={user} exact path={Globals.paths.article} component={MainLayout} />
        <PrivateRoute user={user} exact path={Globals.paths.certificate} component={MainLayout} />
        <PrivateRoute user={user} exact path={Globals.paths.class} component={MainLayout} />
        <PrivateRoute user={user} exact path={Globals.paths.quizTest} component={MainLayout} />

        <PrivateRoute user={user} exact path={Globals.paths.courses} component={MainLayout} />
        <PrivateRoute user={user} exact path={Globals.paths.courseDetail} component={MainLayout} />
        <PrivateRoute user={user} exact path={Globals.paths.help} component={MainLayout} />
        <PrivateRoute user={user} exact path={Globals.paths.home} component={MainLayout} />
        <PrivateRoute user={user} exact path={Globals.paths.quiz} component={MainLayout} />
        <PrivateRoute
          user={user}
          exact
          path={Globals.paths.missionsWithId}
          component={MainLayout}
        />
        <PrivateRoute user={user} exact path={Globals.paths.missions} component={MainLayout} />
        <PrivateRoute user={user} exact path={Globals.paths.planning} component={MainLayout} />
        <PrivateRoute user={user} exact path={Globals.paths.photograph} component={MainLayout} />
        <PrivateRoute
          user={user}
          exact
          path={Globals.paths.certificationTest}
          component={MainLayout}
        />
        <PrivateRoute
          user={user}
          exact
          path={Globals.paths.certificationTestResult}
          component={MainLayout}
        />
        <PrivateRoute user={user} exact path={Globals.paths.trailDetail} component={MainLayout} />
        <PrivateRoute user={user} exact path={Globals.paths.progresso} component={MainLayout} />
        <PrivateRoute user={user} exact path={Globals.paths.tools} component={MainLayout} />
        <PrivateRoute
          user={user}
          exact
          path={Globals.paths.missionsCustom}
          component={MainLayout}
        />
        <PrivateRoute user={user} exact path={Globals.paths.profile} component={MainLayout} />
        <PrivateRoute user={user} exact path={Globals.paths.incentive} component={MainLayout} />
        <PrivateRoute
          user={user}
          exact
          path={Globals.paths.certificateFinal}
          component={MainLayout}
        />
        <PrivateRoute user={user} exact path={Globals.paths.favorite} component={MainLayout} />
        <PrivateRoute user={user} exact path={Globals.paths.avatar} component={MainLayout} />
        <PrivateRoute user={user} exact path={Globals.paths.openShopping} component={MainLayout} />
        <PrivateRoute
          user={user}
          exact
          path={Globals.paths.closedShopping}
          component={MainLayout}
        />
        <PrivateRoute user={user} exact path={Globals.paths.inbox} component={MainLayout} />
        <PrivateRoute user={user} exact path={Globals.paths.quizTest} component={MainLayout} />

        <Route exact path={Globals.paths.backofficeLogin} component={BackofficeLayout} />

        {backofficeRoutes.map((route) => {
          return (
            <AdminRoute
              user={user}
              key={'route_' + route}
              exact
              path={Globals.paths[route]}
              component={BackofficeLayout}
            />
          );
        })}

        <Route path="*">
          <Redirect to={{ pathname: Globals.paths.root }} />
        </Route>
      </Switch>
    </div>
  );
};

export default withRouter(watch(Routes, { user: '' }));
