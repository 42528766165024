import React from "react";

import PropTypes from "prop-types";
import { Dialog, DialogContent } from "@material-ui/core";
import DialogTitleWithCloseIcon from "../../../shared/components/DialogTitleWithCloseIcon";
import './video.scss';


function RegisterVideo(props) {
  const { onClose } = props;


  return (
    <Dialog
      open
      onClose={onClose}
      fullscreen={true}
      fullWidth={true}
      maxWidth={'lg'}
      class={'videoDialog-modal'}

    >
      <DialogTitleWithCloseIcon
        onClose={onClose}
      />
      <DialogContent className={'videoDialog-container'} >
        <div className='video-container'>
          <iframe width="560" height="315" src="https://www.youtube.com/embed/6LsFgFapdQM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen"></iframe>
        </div>
      </DialogContent>
    </Dialog>
  );
}

RegisterVideo.propTypes = {

  onClose: PropTypes.func.isRequired,

};

export default RegisterVideo;
