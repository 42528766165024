import React, { Component } from 'react';
import { Button, CircularProgress, Container, DialogActions, DialogContent, Grid, Hidden, IconButton, Paper, Typography, FormControl, InputLabel, Select, MenuItem, FormHelperText, OutlinedInput, InputAdornment, TextField, Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { watch } from 'redux-easy/lib/redux-easy';
import { withRouter } from 'react-router-dom';
import { Helmet } from "react-helmet";
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import CloseIcon from '@material-ui/icons/Close';
import './incomesSelectItems.scss';
import api from '../../../../services/api';
import Globals from '../../../../Globals.json';
import MaskedInput from 'react-text-mask';

import ControlPointIcon from '@material-ui/icons/ControlPoint';
import Dialogs from '../../../../services/dialogs';

import CurrencyInput from 'react-currency-input';

import { MuiPickersUtilsProvider, DatePicker, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { format, formatWithOptions } from 'date-fns'
import { ptBR } from 'date-fns/locale';

class IncomesSelectItems extends Component {

    step = 3;

    state = {

        loadedData: null,
        isLoading: false,
        photographId: null,
        selectedDate: new Date(),
        amountValueView: '0,00',
        amountValue: '',
        valueError: false,
        incomesType: this.props.state.data.incomesType,
        valueMessage: '',
        expenseError: false,
        expenseMessage: '',
        customItemName: '',
        customItemNameError: false,
        customItemNameMessage: '',
        canSubmit: false,
        isCustomItem: false,
        loadedDataPhotograph: null,
        editMode: this.props.state.data.action === 'edit' ? true : false,
        selectedDateWithDay: null,
        maxDate: new Date('2050-12-31'),
        minDate: new Date('2000-1-1'),
        errorDay: false,
        id: null,
        openTo: "month",
        views: ["month", "date"],
        isSavingLoading: false,
        alertMessage: '',
        openAlert: false,
        alertSeverity: 'success'
    }

    getProgressWidth() {
        const w = this.step * 25 + '%';
        return w === '100%' ? '101%' : w;
    }

    componentDidMount = () => {

        this.props.changeWidth('xs')
        this.loadData()
    }

    setData = async () => {
        const item = this.props.state.data.item;

        try {
            const dataItem = await api.get(Globals.api.userIncomeItem + `/${item.id}`)

            this.setState({
                photographId: dataItem.data.cad_user_financial_photograph_id,
                selectedDate: new Date(dataItem.data.reference_expense_date),
                selectedDateWithDay: new Date(dataItem.data.reference_expense_date),
                amountValue: dataItem.data.value,
                amountValueView: dataItem.data.value,
                incomesType: dataItem.data.type_tool_income_id,
                id: dataItem.data.id,
                customItemName: dataItem.data.description
            })
        } catch (error) {
            console.log(error)
        }

    }

    componentDidUpdate = (prevProps, prevState) => {

        if (prevState.amountValue !== this.state.amountValue) {
            this.canSubmit()
        }
        if (prevState.customItemName !== this.state.customItemName) {
            this.canSubmit()
        }
        if (prevState.selectedDateWithDay !== this.state.selectedDateWithDay) {
            this.canSubmit()
        }
    }

    handleClose = () => {
        if (this.props.handleClose) {
            this.props.handleClose();
        }
    }

    loadData = async () => {
        this.setState({ isLoading: true })
        if (this.state.editMode) {
            await this.setData()
        }
        this.setConfigCalendar()

        this.setState({ isLoading: false })

    }

    handleChangeName = (e) => {
        this.setState({
            customItemName: e.target.value,
            customItemNameError: false,
            customItemNameMessage: ''
        })
    }

    onBlurFieldName = (e) => {
        this.setState({
            customItemName: e.target.value.trim(),
        })
        if (e.target.value.trim() === '') {
            this.setState({
                customItemNameError: true,
                customItemNameMessage: 'Informe um nome'
            })
        }
    }

    handleChangeValue = (e) => {
        this.setState({
            amountValueView: e.target.value,
            valueError: false,
            valueMessage: ''
        })
        if (e.target.value) {
            const value = this.currencyToNumber(e.target.value)
            if (isNaN(value)) {
                this.setState({
                    amountValue: ''
                })
            } else {
                this.setState({
                    amountValue: this.currencyToNumber(e.target.value)
                })
            }

        } else if (e.target.value === '') {
            this.setState({
                amountValue: ''
            })
        }

        return
    }

    onBlurFieldValue = () => {
        this.setState({
            valueError: false,
            valueMessage: ''
        })
        if (!this.state.amountValue && this.state.amountValue !== 0) {
            this.setState({
                valueError: true,
                valueMessage: 'Informe um valor válido.',
            })
        }
    }

    handleSubmit = async (e) => {
        e.preventDefault();
        this.setState({ isSavingLoading: true })
        let sucess = false

        if (this.state.canSubmit) {
            sucess = await this.postData()
        }
        if (sucess) {
            if (this.props.state.data.action === 'new' || this.props.state.data.action === 'edit') {
                this.props.showContent({ to: 'incomes-panel-select', from: this.props.page, reset: true, showMessage: true,  data: {status: 'success'} })

            } else {
                this.props.showContent({ to: 'incomes-final', from: this.props.page })
            }
        }
        else {
            console.log('erro no post')
            this.setState({
                alertMessage: 'Não foi possível cadastrar sua receita',
                alertSeverity: 'error',
                openAlert: true
            })
        }

        this.setState({ isSavingLoading: false })

    }
    canSubmit = () => {
        let error = true

        if (this.state.customItemName.trim() === '' || this.state.customItemNameError) {
            error = false
        }

        if (!this.state.amountValue || this.state.valueError) {
            error = false
        }
        if (!this.state.selectedDateWithDay || this.state.valueError) {
            error = false
        }

        this.setState({ canSubmit: error })
    }

    postData = async () => {

        const formatedDate = format(this.state.selectedDateWithDay, 'd MM yyyy', { locale: ptBR }).split(' ')
        const reference_day = parseInt(formatedDate[0])
        const reference_month = parseInt(formatedDate[1])
        const reference_year = parseInt(formatedDate[2])
        console.log(this.state.incomesType)

        try {
            const postData = {
                description: this.state.customItemName,
                type_tool_income_id: this.state.incomesType,
                value: this.state.amountValue,
                reference_day,
                reference_month,
                reference_year
            }

            if (this.state.editMode) {
                const postDataEditMode = {
                    type_tool_income_id: postData.type_tool_income_id,
                    value: postData.value,
                    reference_day,
                    description: postData.description,
                }

                await api.put(Globals.api.userIncomeItem + `/${this.state.id}`, postDataEditMode)

            } else {
                await api.post(Globals.api.userIncomeItem, postData)
            }

            return true
        } catch (error) {
            console.log(error.response)
            return false
        }
    }

    numberToCurrency = (value) => {
        const currency = new Intl.NumberFormat('pt-BR', {
            currency: 'BRL',
            style: 'currency',
        }).format(value);
        return currency
    }
    currencyToNumber = (value) => {
        const currency = parseFloat(value.replace(/[^\d\,]/g, '').replace(/[,]/g, '.'))
        return currency
    }
    handleValueChange = (event, maskedvalue, floatvalue) => {
        this.setState({
            amountValueView: maskedvalue,
            valueError: false,
            valueMessage: '',
            amountValue: floatvalue
        })
    };

    changeDate = async (date) => {

        this.setState({
            selectedDateWithDay: date,
            selectedDate: date,
            errorDay: false
        })
    }

    setConfigCalendar = () => {

        if (this.state.editMode) {
            const year = format(this.state.selectedDate, 'yyyy', { locale: ptBR })
            const month = format(this.state.selectedDate, 'MM', { locale: ptBR })
            const lastDayMonth = format(new Date(parseInt(year), parseInt(month), 0), 'd', { locale: ptBR });
            const baseDate = `${year}-${month}-`

            this.setState({
                minDate: `${baseDate}1`,
                maxDate: `${baseDate}${lastDayMonth} 23:59:59`,
                openTo: "date",
                views: ["date"],

            })

        } else {
            this.setState({
                maxDate: new Date('2050-12-31'),
                minDate: new Date('2000-1-1'),
                openTo: "date",
                views: ["month", "date"],
            })
        }

    }

    render() {
        return (

            <Grid className='incomes-select-items'>
                <MuiDialogTitle disableTypography className="dialog-header">
                    {this.state.isLoading ?
                        (<h2><CircularProgress size={24} style={{ color: "white" }} /></h2>)
                        :
                        (<h2>{this.state.editMode ? 'Edição de receitas' : 'Receitas'} {this.state.incomesType === 1 ? 'fixas' : 'variáveis'} - {format(this.state.selectedDate, 'MMMM yyyy', { locale: ptBR }).toUpperCase()}</h2>)
                    }

                    <IconButton color="secondary" aria-label="close" className="close-button" onClick={this.handleClose}>
                        <CloseIcon />
                    </IconButton>
                </MuiDialogTitle>
                <Grid container className="progress-floater">
                    <div className="progress-container">
                        <div className="progress" style={{ width: this.getProgressWidth() }}></div>
                    </div>
                </Grid>
                <Grid className="container">
                    <DialogContent className="dialog-content" >
                        <Grid item xs={12} className="classDetailContainer">
                            <Snackbar
                                open={this.state.openAlert}
                                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                                autoHideDuration={2000}
                                onClose={() =>
                                    setTimeout(() => {
                                        this.setState({ openAlert: false });
                                    }, 2000)
                                }
                            >
                                <Alert
                                    onClose={() => {
                                        this.setState({ openAlert: false });
                                    }}
                                    severity={this.state.alertSeverity}
                                >
                                    {this.state.alertMessage}
                                </Alert>
                            </Snackbar>
                        </Grid>
                        {this.state.isLoading ? (
                            <Grid item xs={12} className="loading-area">
                                
                                    <CircularProgress />
                               
                            </Grid>
                        ) : (
                            <Grid container item xs={12} direction="column" justify="center" alignItems="center" className="content-holder">
                                <h6><span>{this.state.editMode ? 'Edite' : 'Cadastre'}</span> sua receita <span>{this.state.incomesType === 1 ? 'fixa' : 'variável'}</span></h6>

                                <TextField
                                    id="outlined-adornment-amount"
                                    label="Nome"
                                    type="text"
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                    autoComplete="off"
                                    value={this.state.customItemName}
                                    onChange={(e) => this.handleChangeName(e)}
                                    onBlur={(e) => this.onBlurFieldName(e)}
                                    error={this.state.customItemNameError}
                                    helperText={this.state.customItemNameMessage}
                                />

                                <Grid container item xs={12} className="value-and-date" direction="row" justify="space-between">
                                    <div className="form-group">
                                        <label>Valor</label>
                                        <CurrencyInput
                                            prefix="R$ "
                                            ref={null}
                                            className="form-control"
                                            placeholder="Valor"
                                            decimalSeparator=","
                                            thousandSeparator="."
                                            value={this.state.amountValueView}
                                            precision={2}
                                            onChangeEvent={this.handleValueChange}
                                        />
                                    </div>

                                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
                                        <DatePicker
                                            label={'Data da Receita'}
                                            emptyLabel={'__/__/____'}
                                            format="dd/MM/yyyy"
                                            okLabel="Selecionar"
                                            cancelLabel="Cancelar"
                                            inputVariant="outlined"
                                            size="small"
                                            openTo={this.state.openTo}
                                            value={this.state.selectedDateWithDay}
                                            onChange={(newValue) => this.changeDate(newValue)}
                                            views={this.state.views}
                                            minDate={new Date(this.state.minDate)}
                                            minDateMessage={'data invalida'}
                                            maxDate={new Date(this.state.maxDate)}
                                            maxDateMessage={'data invalida'}
                                            error={this.state.errorDay}
                                            invalidDateMessage={'Data invalida '}

                                        />

                                    </MuiPickersUtilsProvider>
                                </Grid>
                            </Grid>

                        )}


                    </DialogContent >
                </Grid>
                <DialogActions className="dialog-footer space-between">
                    <Button
                        id="g-pm-start-test"
                        className={`g-pm-start-test`}
                        color="primary"
                        variant="outlined"
                        onClick={() => { this.props.goBack() }}
                        style={{ borderRadius: "0px", width: "120px" }}
                    >
                        voltar
                    </Button>
                    <Button
                        id="g-pm-start-test"
                        className={`g-pm-start-test`}
                        variant="contained"
                        color="primary"
                        onClick={(e) => this.handleSubmit(e)}
                        style={{ borderRadius: "0px", color: "white", width: "120px" }}
                        disabled={!this.state.canSubmit && !this.state.isSavingLoading}
                    >
                        {this.state.isSavingLoading ? (<CircularProgress size={24} style={{ color: "white" }} />) : 'salvar'}
                    </Button>
                </DialogActions>

            </Grid >
        )
    }
}

export default watch(withRouter(IncomesSelectItems), { user: '' });