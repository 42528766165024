
import React from 'react';
import { withStyles, CircularProgress, Modal } from '@material-ui/core';
import api from '../../../services/api';
import AlertErrors from '../../components/alertErrors/alerts';
import Button from '../button/Button';
import InitialModalQuiz from './InitialModalQuiz';
import FinalModal from './FinalModal';

import SwipeableViews from "react-swipeable-views";
import Pagination from "../pagination/Pagination";
import { getCoinsFromRewards, getCoinsTextFromRewards } from '../../../services/util';

const styles = theme => ({

  containerBox: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginLeft: "-5px",
    overflow: "hidden",
  },
  itemBox: {
    height: "170px",
    minWidth: "280px",
    maxWidth: "290px",
    marginLeft: "10px",
    overflowX: "hidden",
    [theme.breakpoints.down('xs')]: {
      minWidth: "98%",
      marginRight: "2%",
    }
  },
  toolImage: {
    display: "flex",
    alignItems: "center",
    minWidth: "15vw",
    backgroundPosition: "center",
    backgroundSize: "cover",
    height: "100%",
  },
  toolContainerText: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    width: "100%",
    height: "100%",
  },
  toolTitle: {
    color: "#fff",
    fontSize: "16px",
    width: "98%",
    height: "30px",
    paddingTop: "10px",
    // marginLeft: "8px",
    marginTop: "-10px",
    display: "-webkit-box",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "normal",
    WebkitLineClamp: "1",
    WebkitBoxOrient: "vertical",
  },
  toolText: {
    maxWidth: "100%",
    color: "#fff",
    // marginLeft: "8px",
    fontSize: "16px",
    display: "-webkit-box",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "normal",
    WebkitLineClamp: "2",
    WebkitBoxOrient: "vertical",
    marginTop: "8px",
  },
  texts: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    maxWidth: "100%",
    height: "100px",
    marginTop: "10px",
    padding: "0px 12px 0px 12px",
  },
  courseButtonContainer:
  {
    display: "flex",
    flexDirection: "row-reverse",
    padding: "0px 12px 12px 0px",
    overflow: "hidden",
    marginBottom: "1px",
    // marginRight: "5px",
  },
  containerTop: {
    display: "flex",
    flexDirection: "row-reverse",
    height: "25px",
  },
  card: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#FBDBD0",
    color: "#9e4326",
    width: "100px",
    height: "25px",
    fontSize: "11px",
    fontWeight: 'bold'
  },
  teste: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
  },
  azul: {
    backgroundColor: "#FBDBD0",
    color: "#000",
    minWidth: "7px",
    marginTop: "-10px"
  },
  root: {
    overflow: "hidden",
  },
  test: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
  },
  root2: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
  }
});

const Globals = require("../../../Globals.json");

class CourseList extends React.Component {

  state = {
    open: false,
    openFinalModal: false,
    isLoading: false,
    quiz: [],
    length: 0,
    id: 0,
    results: [],
    questions: [],
    index: 0
  }

  async loadData () {
    this.setState({ isLoading: true });
    try {
      const basePayload = this.props.payload || {};
      const postData = { ...basePayload };

      const { endpoint } = this.props;
      const sendMethod = this.props.method || "post";
      const options = {
        method: sendMethod,
        url: endpoint,
      };

      if (sendMethod === "post") {
        options.data = postData;
      }
      else if (sendMethod === "get") {
        options.params = postData;
      }
      const response = await api(options);
      this.setState({ quiz: response.data, isLoading: false });
      this.setState({ length: response.data.length });

    } catch (error) {
      this.setState({ alertError: 400 })
      setTimeout(() => {
        this.setState({ alertError: '' })
      }, 3000)
    }
  }

  componentDidMount () {
    this.loadData();
  }

  openFinalModal = () => {
    this.setState({ open: false, openFinalModal: true });
  }

  closeFinalModal = () => {
    this.setState({ openFinalModal: false })
  }

  againQuiz = (id) => {
    this.setState({ id: id, open: true, openFinalModal: false });
  }

  handleChangeIndex = (index) => {
    this.setState({
      index
    });
  };

  getCoins = (rewards) => {

    // console.log( "GET_COINS:", rewards );
    if (!rewards) return null;
    const coins = rewards.filter((i) => i.asset_id === 1);
    if (coins.length > 0) {
      return coins[0];
    }
    else {
      return null;
    }

  }

  render () {

    const { classes } = this.props;
    const { index } = this.state;

    return (
      <>
        {this.state.isLoading ?
          <>
            <CircularProgress />
            <AlertErrors error={this.state.alertError} />
          </> :
          <div>
            <div className={classes.containerBox}>
              <div className={classes.root}>
                <SwipeableViews index={index} onChangeIndex={this.handleChangeIndex} slideStyle={{ overflow: 'visible' }}>
                  {this.state.quiz.map((item, index) => (
                    <div className={classes.root2}>
                      <div className={classes.itemBox}>
                        <div className={classes.toolImage} style={{ backgroundImage: `url(${item.thumbnail})` }}>
                          <div className={classes.toolContainerText}>
                            {getCoinsFromRewards(item.data.rewards) > 0 &&
                              <div className={classes.containerTop}>
                                <div className={classes.card} >
                                  {getCoinsTextFromRewards(item.data.rewards)}
                                </div>
                              </div>
                            }

                            <div className={classes.texts}>
                              <span className={classes.toolTitle}>{item.name}</span>
                              <span className={classes.toolText}>{item.description}</span>
                            </div>

                            <div className={classes.courseButtonContainer}>

                              <Button type="invert" icon="none" minWidth="128px" minHeight="30px" fontSize="12px"
                                to={Globals.paths.quizTest.replace(':quizId', item.id)}>
                                Descobrir
                              </Button>


                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </SwipeableViews>

                <Pagination
                  dots={this.state.quiz.length}
                  index={index}
                  onChangeIndex={this.handleChangeIndex}
                />

                {this.state.open &&
                  <InitialModalQuiz dados={this.state.questions} onClose={() => this.setState({ open: false })} open={this.state.open}
                    endpoint={Globals.api.recommendedModule} payload={{ module_type: "1" }} id={this.state.id} onCloseQuiz={this.openFinalModal} />
                }

                {this.state.openFinalModal &&
                  <Modal
                    open={this.state.openFinalModal}
                    onClose={this.closeFinalModal}
                    aria-labelledby="modal"
                    aria-describedby="modal"
                    className={classes.modal}
                  >
                    <FinalModal dados={this.state.results} closeFinalModal={this.closeFinalModal} indexId={this.state.id} againQuiz={() => this.againQuiz(this.state.id)} />
                  </Modal>
                }
              </div>
            </div>
          </div>
        }
      </>
    )
  }
}
export default withStyles(styles)(CourseList);

