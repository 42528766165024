import React from 'react';
import './styles/objectiveItem.scss';

import { Hidden } from '@material-ui/core';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import Globals from "../../../Globals.json";

import InitialModalQuiz from '../quiz/InitialModalQuiz';

class ObjectiveItem extends React.Component {

  state = {
    open: false,
    isLoading: false,
    id: 0,
  }

  openFinalModal = () => {
    this.setState({ open: false, openFinalModal: true });
  }

  closeFinalModal = () => {
    this.setState({ openFinalModal: false })
  }

  render() {

    const { number, text } = this.props;

    return ( 
      <>
        <Hidden xsDown>
          {/* <Link onClick={() => this.setState({ open: true })} to={`/artigo/${item.id}`} className="objective_item_containerObjective"> */}
            <div className="objective_item_descriptionTextNumber">{number < 10 ? `0${number}` : number}</div>
            <div className="objective_item_descriptionBox">
              <div className="objective_item_descriptionText">
                {text}
              </div>
              <div className="objective_item_descriptionIcon">
                <ArrowRightIcon className="objective_item_courseButtonIcon" />
              </div>
            </div>
          {/* </Link> */}
        </Hidden>

        <Hidden smUp>
          <div className="objective_item_content">
            {/* <Link onClick={() => this.setState({ open: true })} to={`/artigo/${item.id}`} className="objective_item_linkDecoration" > */}
              <div className="objective_item_box">
                <div className="objective_item_header">
                  <div className="objective_item_number">{number < 10 ? `0${number}` : number}</div>
                </div>
                <div className="objective_item_description">
                  {text}
                </div>
              </div>
            {/* </Link> */}
          </div>
        </Hidden>

        {this.state.open &&
          <InitialModalQuiz dados={this.state.questions} onClose={() => this.setState({ open: false })} open={this.state.open}
            endpoint={Globals.api.recommendedModule} payload={{ module_type: "1" }} id={19} />
        }
      </>
    )
  }

}

export default ObjectiveItem;

