import React from 'react';
import './styles.scss';
import { Grid, Hidden } from '@material-ui/core';
import { watch } from 'redux-easy/lib/redux-easy';

import IconHour from '../../../../assets/icon/relogio.png'

import Button from '../../button/Button';
const Globals = require("../../../../Globals.json");

class HistoryPerfil extends React.Component {
  handleProfile() {
    window.open(`${Globals.paths.personalReport.replace(':userId', this.props.user.profile.uuid)}`);
  }

  render() {
    return (
      <>
        <Hidden xsDown>
          <Grid item container xs={12} sm={12} className="history_perfil_container" direction="row">
            <Grid item xs={2} sm={2} className="image">
              <img src={IconHour} alt="Icone" />
            </Grid>

            <Grid item xs={8} sm={8} className="history_perfil_content" >
              <span>Veja seu histórico, acompanhe seu desempenho e compartilhe suas conquistas através da área de relatório</span>
            </Grid>

            <Grid item xs={2} sm={2} className="positionBtn" >
              <Button onClick={() => this.handleProfile()}>VER MAIS</Button>
            </Grid>
          </Grid>
        </Hidden>

        <Hidden smUp>
          <div className="history_perfil_sm_container">
            <img src={IconHour} alt="Icone" />
            <span>Veja seu histórico, acompanhe seu desempenho e compartilhe suas conquistas através da área de relatório</span>

            <div className="history_perfil_sm_positionBtn">
              <Button onClick={() => this.handleProfile()}>VER MAIS</Button>
            </div>
          </div>
        </Hidden>
      </>
    )
  }
}
export default watch((HistoryPerfil), { user: '' });
