import React, { Component } from 'react';
import './styles/conquest_nv.scss';

import { Modal } from '@material-ui/core';

import Fundo from '../../../assets/icon/fundo_premio_2.png';
import IconTeste from '../../../assets/icon/alvo.png';
import { Fragment } from 'react';

export default class ConquestNv extends Component {
  render () {

    //console.log('PROPS CONQUEST NV', this.props);

    return (
      <Modal
        open={this.props.open}
        onClose={this.props.onClose}
        className="conquest_nv_modal"
        aria-labelledby="Modal quest"
        aria-describedby="Modal da tela de missoes"
      >
        <div className="content" onClick={this.props.onClose}>

          <div className="conquest_nv_icon" style={{ backgroundImage: `url(${Fundo})` }} >

            <div className="conquest_nv_header">
              <span>Novo nível</span>
              <span>Conquistado</span>
            </div>

            <div className="conquest_nv_positionImg">
              <img src={IconTeste} alt="Conquista" />
            </div>

            <div className="conquest_nv_bottom">
              <span>Nível {this.props.level}</span>
              {/* <span>Ítens desbloqueados</span> */}
            </div>

            {/* <div className="conquest_nv_bottomDesc">
              <small>
                {!!this.props.unblock && this.props.unblock.map((item, index) => (<span>• {item}</span>))}
              </small>
            </div> */}
          </div>

        </div>
      </Modal>
    )
  }
}