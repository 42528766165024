import { FormControl, InputLabel, Select, Slider, TextField } from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import '../../../backoffice.scss';

import ModuleEditing from '../../../logged_in/components/backoffice/components/ModuleEditing/ModuleEditing';
import ChildrenListing from '../../../logged_in/components/backoffice/components/Listing/ChildrenListing';

import Globals from "../../../Globals.json";
import Button from '../../components/button/Button';
import { formatMinutesDisplay } from '../../../services/util';


export default function EditCourseDetails() {

  const [dataTags, setDataTags] = useState('');
  const [dataCategories, setDataCategories] = useState('');
  const [dataDifficulty, setDataDifficulty] = useState(0);
  const [dataDuration, setDataDuration] = useState(15);
  const [dataOrder, setDataOrder] = useState(null);

  const [container, setContainer] = useState({});
  const [itemChildren, setItemChildren] = useState([]);

  const [childrenReordered, setChildrenReordered] = useState( false );

  const [breadcrumbs, setBreadcrumbs] = useState([]);

  const params = useParams();

  const history = useHistory();

  const onReceiveData = (container, children) => {
    console.log(container.data)

    setContainer(container);
    setItemChildren(children);

    setDataTags(container.data.tags);
    setDataCategories((container.data.categories ?? []).join(", "));
    setDataDifficulty(container.data.difficulty ?? 0);
    setDataDuration(container.data.duration ?? 15);
    setDataOrder(container.data.order ?? null);

    setBreadcrumbs([
      {
        label: "Cursos",
        link: Globals.paths.backofficeCourses
      },
      {
        label: container.name
      }
    ]);

  }

  const onBeforeSaveData = ( postData ) => { 
    postData.data.tags = dataTags;
    postData.data.categories = dataCategories.split(",").map( i => i.trim() );
    postData.data.difficulty = dataDifficulty;
    postData.data.duration = dataDuration;
    postData.data.order = dataOrder;

    if( childrenReordered )
    {
      const childrenOrder = [];
      itemChildren.forEach( (item, index) => {
        childrenOrder.push( item.id );
      })
      postData.setChildrenOrder = childrenOrder;
    }
  }

  const onNewData = () => {
    return {
      tags: "",
      categories: ""
    };
  }

  const newClass = () => {
    history.push(Globals.paths.backofficeEditClass.replace(":courseId", container.id).replace(":classId", "new"));
  }
  const newCertificationTest = () => {
    history.push(Globals.paths.backofficeEditCertificationTest.replace(":courseId", container.id).replace(":certificationTestId", "new"));
  }
  const newCertificate = () => {
    history.push(Globals.paths.backofficeEditCertificate.replace(":courseId", container.id).replace(":certificateId", "new"));
  }

  const onClassClick = (item) => {
    history.push(Globals.paths.backofficeEditClass.replace(":courseId", container.id).replace(":classId", item.id));
  }
  const onCertificationTestClick = (item) => {
    history.push(Globals.paths.backofficeEditCertificationTest.replace(":courseId", container.id).replace(":certificationTestId", item.id));
  }
  const onCertificateClick = (item) => {
    history.push(Globals.paths.backofficeEditCertificate.replace(":courseId", container.id).replace(":certificateId", item.id));
  }

  const onClassesReordered = (reorderedList) => {
    //Essa função de reorenação pode ser reutilizada em outros tipos de módulo, 
    //mas tem que ser alterada de acordo com os tipos de item filho que ele possui.
    const oldList = [...itemChildren];
    console.log( oldList );
    let newChildrenList = [];
    //Encontra cada item na sequencia ordenada e adiciona na nova lista
    reorderedList.forEach( (item, index) => {
      const oldItem = oldList.find( i => i.id === item.id );
      if( oldItem ) {
        newChildrenList.push( oldItem );
        //Remove o item da lista antiga
        oldList.splice( oldList.indexOf(oldItem), 1 );
      }
    })
    //Adiciona os itens antigos no final da nova lista
    newChildrenList = newChildrenList.concat( oldList );
    console.log( newChildrenList );
    setItemChildren( newChildrenList );
    setChildrenReordered( true );
  }

  const customDataFields = <>
    <TextField
      key="txtTags"
      label="Tags"
      value={dataTags}
      onChange={(e) => setDataTags(e.target.value)}
    />

    <TextField
      key="txtCat"
      label="Categorias"
      value={dataCategories}
      onChange={(e) => setDataCategories(e.target.value)}
    />

    <FormControl>
      <InputLabel htmlFor={'course_level'}>Dificuldade</InputLabel>
      <Select
        native
        value={dataDifficulty}
        onChange={(e) => setDataDifficulty(parseInt(e.target.value))}
        inputProps={{
          id: 'course_level',
        }}
      >
        <option value={0}>Iniciante</option>
        <option value={1}>Intermediário</option>
        <option value={2}>Avançado</option>
      </Select>
    </FormControl>

    <TextField
      key="valueOrder"
      label="Ordem"
      value={dataOrder}
      type="number"
      onChange={(e) => setDataOrder(e.target.value)}
    />

    <div className="reward-container" style={{ width: '500px', display: 'flex', 'flexDirection': 'column' }}>
      <div style={{ flexGrow: 1, paddingTop: '16px', color: '#0000008a' }}>Duração: { formatMinutesDisplay( dataDuration ).full }</div>
      <Slider
        onChange={(e, newValue) => { setDataDuration(newValue) }}
        value={dataDuration}
        // valueLabelFormat={(minutes) => formatMinutes( minutes ) }
        // valueLabelDisplay="auto"
        aria-labelledby="discrete-slider"
        step={15}
        marks
        min={15}
        max={5 * 60}
      />
    </div>
  </>;

  return (
    <>

      <ModuleEditing
        isNew={params.courseId === "new"}
        typeModuleId={2}
        moduleId={params.courseId}
        title="Detalhe de Curso"
        customDataFields={customDataFields}
        onReceiveData={onReceiveData}
        onBeforeSaveData={onBeforeSaveData}
        onNewData={onNewData}
        breadcrumbs={breadcrumbs}
        hideDeletedContent={true}
      >

        <div style={{ borderTop: "#CCC 1px solid", marginTop: "20px" }}></div>

        <div className="button-right-container">
          <Button
            icon="none"
            textAlign="center"
            type={'darkBlue'}
            height="40px"
            onClick={() => newClass()}>Nova Aula</Button>
        </div>
        <ChildrenListing
          label="Aulas"
          columns={[{ label: "Nome", field: "name" }, { label: "Descrição", field: "description" }]}
          items={itemChildren.filter(i => i.type_module_id === 6)}
          onClick={(item) => onClassClick(item)}
          ordenable
          onReorder={(orderedList) => onClassesReordered(orderedList) }
        />

        {!itemChildren.find(i => i.type_module_id === 8) &&
          <div className="button-right-container">
            <Button
              icon="none"
              textAlign="center"
              type={'darkBlue'}
              height="40px"
              onClick={() => newCertificationTest()}>Nova Prova de Certificação</Button>
          </div>
        }
        <ChildrenListing
          label="Prova de Certificação"
          columns={[{ label: "Nome", field: "name" }, { label: "Descrição", field: "description" }]}
          items={itemChildren.filter(i => i.type_module_id === 8)}
          onClick={(item) => onCertificationTestClick(item)}
        />
        {!itemChildren.find(i => i.type_module_id === 9) &&
          <div className="button-right-container">
            <Button
              icon="none"
              textAlign="center"
              type={'darkBlue'}
              height="40px"
              onClick={() => newCertificate()}>Novo Certificado</Button>
          </div>
        }
        <ChildrenListing
          label="Certificado"
          columns={[{ label: "Nome", field: "name" }, { label: "Descrição", field: "description" }]}
          items={itemChildren.filter(i => i.type_module_id === 9)}
          onClick={(item) => onCertificateClick(item)}
        />

      </ModuleEditing>
    </>
  );
}

