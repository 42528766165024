import React from 'react';
import "./certificateComponent.scss"
import { CircularProgress } from '@material-ui/core';

import api from '../../../services/api';

const Globals = require("../../../Globals.json");

class CertificateComponent extends React.Component {

  state = {
    certificateUrl: '',
    isLoading: true,
    width: 0,
    height: 0,
  }

  async loadCertificate () {
    try {
      if (this.props.certificateUrl) {
        this.setState({ certificateUrl: this.props.certificateUrl, isLoading: false });
      }
      else {
        const response = await api.get(Globals.api.getMyCertificate, { params: { courseId: this.props.courseId } });
        this.setState({ certificateUrl: response.data, isLoading: false });
      }
    } catch (error) {

    }
  }

  handlePrint () {
    window.print();
  }

  handleClose = () => {
    if (typeof this.props.onClose == 'function') {
      this.props.onClose();
    }
  }


  componentDidMount () {
    this.setState({ width: window.innerWidth, height: window.innerHeight });

    this.loadCertificate();
  }

  render () {

    if (this.state.isLoading) {
      return (
        <CircularProgress />
      )
    }

    return (
      <div className='certificateContainer' onClick={this.handleClose}>
        <div className='certificate'>
          <img id={`certificate-image-${this.props.courseId}`} src={this.state.certificateUrl} alt="Certificado" className="img-fluid" />
        </div>
      </div>
    )
  }
}

export default CertificateComponent;
