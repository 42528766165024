import React from 'react';
import './buttons.scss';

import { Link } from 'react-router-dom';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';

class Button extends React.Component {
  render() {
    /**
     * type: primary, secondary, invert, solid
     */

    const {
      to,
      children,
      icon,
      onClick,
      width,
      minWidth,
      height,
      textAlign,
      right,
      left,
      fontSize,
      style,
      minHeight,
      type = 'primary',
      disabled = false,
      className,
      id,
    } = this.props;

    return (
      <>
        {to !== undefined ? (
          <Link
            to={to}
            id={id}
            className={`articleButton ${type} ${className}`}
            style={{
              width: `${width}`,
              minWidth: `${minWidth}`,
              marginRight: `${right}`,
              marginLeft: `${left}`,
              height: `${height}`,
              textAlign: `${textAlign}`,
              fontSize: `${fontSize}`,
              minHeight: `${minHeight}`,
              ...style,
            }}
            onClick={onClick}
          >
            <div className={'courseButtonLabel'}>{children}</div>
            {icon === 'none' ? '' : icon ? icon : <ArrowRightIcon />}
          </Link>
        ) : (
          <button
            id={id}
            className={`articleButton ${type} ${className}`}
            disabled={disabled}
            style={{
              width: `${width}`,
              minWidth: `${minWidth}`,
              marginRight: `${right}`,
              marginLeft: `${left}`,
              height: `${height}`,
              textAlign: `${textAlign}`,
              fontSize: `${fontSize}`,
              minHeight: `${minHeight}`,
              ...style,
            }}
            onClick={onClick}
          >
            <div className={'courseButtonLabel'}>{children}</div>
            {icon === 'none' ? '' : icon ? icon : <ArrowRightIcon />}
          </button>
        )}
      </>
    );
  }
}

export default Button;
