import React, { Component } from 'react';
import { Collapse, Container, DialogContent, Grid, IconButton, Switch, Checkbox, CircularProgress, TextField, FormControl, FormControlLabel } from '@material-ui/core';
import { watch, dispatch } from 'redux-easy/lib/redux-easy';
import { withRouter } from 'react-router-dom';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import Button from '../../../components/button/Button';
import api from '../../../../services/api';
import MaskedInput from 'react-text-mask';
import Alert from '@material-ui/lab/Alert';


import './Relationship.scss';

const Globals = require('../../../../Globals.json');


class Relationship extends Component {
	invalidCpfs = [
		'00000000000',
		'11111111111',
		'22222222222',
		'33333333333',
		'44444444444',
		'5555555555',
		'666666666666',
		'777777777777',
		'888888888888',
		'99999999999',
	];

	state = {
		name: '',
		active: '',
		tenant_id: '',
		logo_url: '',
		validated: '',
		description: '',
		isLoading: true,
		agreementSendData: false,
		agreementRelation: false,
		disabledSave: true,
		cpf: '',
		fieldsValidation: {},
		loading: false,
		alertError: '',
		openAlert: false,

	};

	async loadData() {
		if (this.props.clickedTenant) {

			this.setState({ isLoading: true });
			try {
				const tenants = await api.get(Globals.api.getTenants);
				const tenant = tenants.data.find(tenant => tenant.tenant_id === this.props.clickedTenant.tenant_id);
				this.setState({
					name: tenant.name,
					active: tenant.active,
					tenant_id: tenant.tenant_id,
					logo_url: tenant.logo_url,
					validated: tenant.validated,
					description: tenant.description,
					isLoading: false,
				});
			} catch (error) {
				console.error(error);
			}
		}
		if (this.props.user) {
			this.setState({ isLoading: true });

			const userProfile = this.props.user.profile;

			let fullCpf = userProfile.cpf_cnpj;
			let coins = userProfile.progress.total_moedas;

			const formattedCpf = !!fullCpf
				? fullCpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
				: '';
			this.setState({

				coins: coins,
				isLoading: false,
				cpf: formattedCpf,

			});


		} else {
			dispatch('updateUserProfile');
		}
	}
	async loadToggle() {
		if (this.props.clickedTenant) {

			try {
				const tenants = await api.get(Globals.api.getTenants);
				const tenant = tenants.data.find(tenant => tenant.tenant_id === this.props.clickedTenant.tenant_id);
				this.setState({
					active: tenant.active,
				});
			} catch (error) {
				console.error(error);
			}
		}
	}

	componentDidMount = () => {
		this.loadData()

	}
	componentDidUpdated = () => {

		this.loadData();



	}

	handleClose = () => {
		if (this.props.handleClose) {
			this.props.handleClose();
		}
	}
	handleAgreementSendData(e) {

		this.setState({ agreementSendData: e.target.checked })


	}
	handleAgreementRelation(e) {

		this.setState({ agreementRelation: e.target.checked })

	}


	isConnected = () => {
		return this.state.active === 1 ? true : false;
	}

	async handleToggleTenant(tenantId) {
		try {
			await api.post(Globals.api.toggleUserTenant, { tenant_id: tenantId });
		} catch (error) {
			console.log('error handleToggleTenant')
		}
		this.loadToggle()
		this.props.loadDataTenants();
	}
	validateCPF(strCPF) {
		var Soma;
		var Resto;
		Soma = 0;

		if (strCPF.indexOf('.') > -1 || strCPF.indexOf('-') > -1) {
			strCPF = strCPF.replace(/\./g, '').replace(/\-/g, '');
		}

		for (let i = 1; i <= 9; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
		Resto = (Soma * 10) % 11;

		if (Resto === 10 || Resto === 11) Resto = 0;
		if (Resto !== parseInt(strCPF.substring(9, 10))) return false;

		Soma = 0;
		for (let i = 1; i <= 10; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i);
		Resto = (Soma * 10) % 11;

		if (Resto === 10 || Resto === 11) Resto = 0;
		if (Resto !== parseInt(strCPF.substring(10, 11))) return false;
		return true;
	}

	validateForm() {
		let ret = true;

		const fieldsValidation = {};


		if (!this.validateCPF(this.state.cpf ?? '')) {
			fieldsValidation.cpf_cnpj = 'O CPF informado é inválido';
			ret &= false;
		}


		this.setState({ fieldsValidation });

		return ret;
	}

	clearValidation = () => {
		const fieldsValidation = this.state.fieldsValidation;
		for (let key in fieldsValidation) {
			fieldsValidation[key] = null;
		}
	};
	clearItemValidation = (key) => {
		if (this.state.fieldsValidation && this.state.fieldsValidation[key]) {
			this.setState({ fieldsValidation: { ...this.fieldsValidation, key: null } });
		}
	};

	handleCpf = (e, key) => {
		this.setState({ cpf: e.target.value });
		this.clearItemValidation(key);
	};
	InputMaskCPF(props) {
		const { inputRef, ...other } = props;
		const cepMaskFormat = [
			/[0-9]/,
			/\d/,
			/\d/,
			'.',
			/\d/,
			/\d/,
			/\d/,
			'.',
			/\d/,
			/\d/,
			/\d/,
			'-',
			/\d/,
			/\d/,
		];
		return (
			<MaskedInput
				{...other}
				ref={(ref) => {
					inputRef(ref ? ref.inputElement : null);
				}}
				mask={cepMaskFormat}
				placeholderChar={'_'}
				guide
				showMask
			/>
		);
	}
	sendData = (tenantId) => {
		this.setState({ loading: true });

		let postData = {
			tenant_id: this.state.tenant_id,
		};
		if (!this.props.user.profile.cpf_cnpj) {
			postData.cpf = this.state.cpf
		}

		api
			.post(Globals.api.setUserTenants, postData)
			.then((response) => {
				dispatch('updateUserProfile');
				//this.loadData()
				// this.showSuccessAlert('Dados alterados com sucesso!');
				this.props.loadDataTenants();
				this.props.loadDataParent();
				this.loadData();
			})
			// if (error.response?.data?.msg?.error?.message && typeof (error.response.data?.msg?.error?.message) === 'string' ) {
			.catch((error) => {
				this.setState({ openAlert: true });


				if (error.response?.data?.msg && typeof (error.response.data?.msg) === 'string') {

					this.setState({ alertError: error.response.data.msg });

				} else if (error.response?.data?.msg?.error?.message && typeof (error.response.data?.msg?.error?.message) === 'string') {

					this.setState({ alertError: error.response?.data?.msg?.error?.message });
					
				}	else {
					this.setState({ alertError: 'Ocorreu um erro para relacionar com a instituição, tente novamente.' });

				}
			})
			.finally(() => {
				this.setState({ loading: false });
			})
			;
	};

	render() {
		const fieldsValidation = this.state.fieldsValidation;
		return (
			<>
				<MuiDialogTitle disableTypography className="dialog-header">
					<IconButton color="secondary" aria-label="close" className="close-button" onClick={this.handleClose}>
						<CloseIcon />
					</IconButton>
				</MuiDialogTitle>
				<DialogContent className="dialog-content" dividers>
					<Grid container direction="column" justify="flex-start" alignItems="center">
						{this.state.isLoading ? (
							<Grid container item xs={12} justify="center" className="loading">
								<CircularProgress size={40} />
							</Grid>
						) : (
							<Container className="relationship-modal-container" container direction="column" justify="flex-start">
								<Grid className='header'>
									<Grid container justify='center'>
										<img src={this.state.logo_url} alt={this.state.name}></img>
									</Grid>

									<Grid
										container
										item
										xs={12}
										className="name"
									>
										<p>{this.state.name}</p>
									</Grid>
									<Grid
										container
										item
										xs={12}
										className="relationship"
									>
										<p>status de relacionamento: {this.state.active ? (<span className='connected green'> Conectado</span>) : (this.props.clickedTenant.validated ? (<span className='gray'> Inativo</span>) : (<span className=' orange'> Não relacionado</span>))}</p>
									</Grid>

								</Grid>

								<Grid
									container
									item
									xs={12}
									className="description"
								>
									{this.state.description}
								</Grid>

								<Container className={`relationship-modal-container-actions ${this.isConnected() ? 'border-green' : 'border-orange'}`} container direction="column" justify="flex-start">
									<Grid container className="action-container">
										{this.state.validated ? (
											<>
												<Grid container item xs={12} className="subtitle">
													<p>Você<strong className={this.isConnected() ? ('hidden') : ('')}> não</strong> tem um relacionamento ativo com essa instituição.</p>
												</Grid>
												<Grid container item xs={12} className="description">
													Para mudar seu status de relacionamento com a instituição, mude a opção abaixo.
												</Grid>
												<Switch
													size="small"
													checked={!!this.state.active}
													onChange={() => {
														this.handleToggleTenant(this.state.tenant_id);
													}}
													name="checkedA"
													inputProps={{ 'aria-label': 'secondary checkbox' }}
												/>
												<label className={`switch ${this.isConnected() ? 'green' : 'gray'}`}>{this.isConnected() ? ('Conectado') : ('inativo')}</label>
											</>
										) : (
											<>
												<Grid container item xs={12} className="subtitle">
													<p>Você<strong> não</strong> tem um relacionamento ativo com essa instituição.</p>
												</Grid>
												<Grid container item xs={12} className="description">
													Para mudar seu status de relacionamento com a instituição é necessário enviar seus dados para validação da instituição.
												</Grid>

												<Collapse in={this.state.openAlert} className="collapse-error">
													<Alert
														severity="error"
														action={
															<IconButton
																aria-label="close"
																color="inherit"
																size="small"
																onClick={() => {
																	this.setState({ openAlert: false });
																}}
															>
																<CloseIcon fontSize="inherit" />
															</IconButton>
														}

													>{this.state.alertError}</Alert>
												</Collapse>



												<Grid container direction="column" alignItems="center" item xs={12} className="checkbox-container">
													<FormControl
														variant="outlined"
														size="small"
														fullWidth
														margin="normal"
													>
														<Grid container item alignItems="center" >
															<FormControlLabel
																control={<Checkbox
																	name={'agreementRelation'}
																	checked={this.state.agreementRelation}
																	onChange={(e) => this.handleAgreementRelation(e)}

																/>}
																label='Quero me relacionar e aceito que enviem os meu dados para o banco indicado por mim.'
															/>


														</Grid>
														<Grid container item alignItems="center" >
															<FormControlLabel
																control={<Checkbox
																	name={'agreementSendData'}
																	checked={this.state.agreementSendData}
																	onChange={(e) => this.handleAgreementSendData(e)}

																/>}
																label='Aceito compartilhar os meus dados de aprendizagem, meu score e a minha pontuação adquirida com os treinamentos, para obter os benefícios oferecidos pelo banco com o qual tenho relacionamento.'
															/>

														</Grid>
														{!this.props.user.profile.cpf_cnpj &&
															<>
																<Grid container className="cpf-message" item xs={12}>
																	<p>Para efetuar o relacionamento com a instituição é necessário informar o numero do cpf:</p>
																</Grid>
																<Grid container item xs={12} sm={6}>
																	<TextField
																		label="CPF *"
																		type="text"
																		variant="outlined"
																		size="small"
																		fullWidth
																		margin="normal"
																		value={this.state.cpf}
																		onChange={(e) => this.handleCpf(e, 'cpf_cnpj')}
																		error={!!fieldsValidation.cpf_cnpj}
																		helperText={fieldsValidation.cpf_cnpj}
																		InputProps={{
																			inputComponent: this.InputMaskCPF,
																		}}
																	/>
																</Grid>
															</>
														}

													</FormControl>
												</Grid>
											</>
										)}

									</Grid>

								</Container>
								{!this.state.validated &&
									<Grid container
										item
										xs={12}
										justify={'flex-end'}>
										<Button
											type={this.state.agreementSendData && this.state.agreementRelation ? 'primary' : 'disabled'}
											icon="none"
											style={{ height: '40px' }}
											textAlign={'center'}
											disabled={!(this.state.agreementSendData && this.state.agreementRelation) || this.state.loading}
											onClick={() => {
												if (this.validateForm()) {
													this.sendData(this.state.tenant_id);

												}
											}}
										>
											{this.state.loading ?
												<CircularProgress size={16} s />
												:
												'Salvar'
											}

										</Button>
									</Grid>
								}
							</Container>
						)}
					</Grid>
				</DialogContent >
			</>
		)
	}
}

export default watch(withRouter(Relationship), { user: '' });