import styled from 'styled-components';
import { withTheme } from "@material-ui/core/styles";

export const MainJourney = withTheme(styled('section')`
  margin: 0;
  background: radial-gradient(50% 50% at 50% 50%, rgba(255, 255, 255, 0) 44.27%, #E5E5E5 100%);

  .inner-wrapper {
    display: flex;
    max-width: 1440px;
    margin: auto;
    /* padding-top: 200px; */
    max-height: 935px;
    height: 62vw;
    align-items: center;
    position: relative;

    /* MOBILE */
    @media(max-width: ${({ theme }) => `${theme.breakpoints.values.sm}px`}) {
      flex-direction: column-reverse;
      height: auto;
      position: initial;
    }

    /* TABLET */
    @media only screen and (
      min-width: ${({ theme }) => `${theme.breakpoints.values.sm}px`}
    ) and (max-width: ${({ theme }) => `${theme.breakpoints.values.md}px`}) {
      flex-direction: column-reverse;
      height: auto;
      position: initial;
      padding: 60px 0;
      max-height: initial;
    }
  }

  @keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }

  figure {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 0;
    
    /* MOBILE */
    @media(max-width: ${({ theme }) => `${theme.breakpoints.values.sm}px`}) {
      position: initial;
    }

    /* TABLET */
    @media only screen and (
      min-width: ${({ theme }) => `${theme.breakpoints.values.sm}px`}
    ) and (max-width: ${({ theme }) => `${theme.breakpoints.values.md}px`}) {
      position: initial;
    }

    /* IPAD PRO */
    @media only screen and (
      min-width: ${({ theme }) => `${theme.breakpoints.values.md}px`}
    ) and (max-width: ${({ theme }) => `${theme.breakpoints.values.lg}px`}) {
      top: 70px;
    }

    .journey-steps {
      position: relative;
      max-width: 780px;
      width: 50vw;
      animation: spin 50s linear infinite;

      /* MOBILE */
      @media(max-width: ${({ theme }) => `${theme.breakpoints.values.sm}px`}) {
        width: 85vw;
      }

      /* TABLET */
      @media only screen and (
        min-width: ${({ theme }) => `${theme.breakpoints.values.sm}px`}
      ) and (max-width: ${({ theme }) => `${theme.breakpoints.values.md}px`}) {
        width: 70vw;
      }

      /* IPAD PRO */
      @media only screen and (
        min-width: ${({ theme }) => `${theme.breakpoints.values.md}px`}
      ) and (max-width: ${({ theme }) => `${theme.breakpoints.values.lg}px`}) {
        width: 45vw;
      }

      &__shield {
        position: absolute;
        
        &--small {
          width: 62px;
          top: 130px;
          left: -550px;
          filter: blur(10px);
        }
      }
    }
  }
`);