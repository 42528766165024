import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import '../../../backoffice.scss';

import ModuleEditing from '../../components/backoffice/components/ModuleEditing/ModuleEditing';

import Globals from "../../../Globals.json";
import api from '../../../services/api';
import FBBEditorJS from '../../components/FBBEditorJs';


export default function EditClassContentArticle() {

  const editorRef = useRef();

  const [editorContent, setEditorContent] = useState({});

  const [container, setContainer] = useState({});
  const [breadcrumbs, setBreadcrumbs] = useState([]);

  const params = useParams();
  const history = useHistory();

  const onReceiveData = async (container, children, parent) => {

    setContainer(container);

    let contentJson = null;
    try {
      contentJson = JSON.parse(container.description)
    }
    catch (e) {
      console.log("JSON ERROR::", e);
    }

    console.log("CONTENTJSON::", contentJson);

    setEditorContent(contentJson);

    const postData = {
      "container_id": params.courseId,
      type_module_status_ids: [0, 1, 2],
    };
    const responseCourse = await api.post(Globals.api.getModules, postData);
    const course = responseCourse.data.container;

    setBreadcrumbs([
      {
        label: "Cursos",
        link: Globals.paths.backofficeCourses,
      },
      {
        label: course.name,
        link: Globals.paths.backofficeEditCourse.replace(":courseId", course.id)
      },
      {
        label: parent.name,
        link: Globals.paths.backofficeEditClass.replace(":courseId", course.id).replace(":classId", parent.id)
      },
      {
        label: container.name
      }
    ]);

  }

  // const editorContentChange = async () => {
  //   setEditorContent(await editorRef.current.save());
  // }

  const copyData = () => {

    window.getSelection().removeAllRanges()

    var range = document.createRange()
    range.selectNode(document.getElementById("htmlContainer"));
    window.getSelection().addRange(range)

    document.execCommand('copy')

  }

  const customDataFields =
    <>
      <div style={{ marginTop: "20px" }}>Conteúdo</div>
      <div style={{ borderBottom: "#999 1px solid" }}>
        <FBBEditorJS
          data={editorContent}
          onChange={(api, newData) => setEditorContent(newData)}
        />
      </div>
      {!editorContent &&
        <>
          <button onClick={() => copyData()}>COPY</button>
          <div id="htmlContainer" dangerouslySetInnerHTML={{ __html: container.description }}></div>
        </>
      }
    </>

  const onBeforeSaveData = (postData) => {
    postData.thumbnail = "-";
    postData.description = JSON.stringify(editorContent);
  }

  const onNewData = (newData) => {}

  return (
    <ModuleEditing
      isNew={params.contentId === "new"}
      moduleId={params.contentId}
      typeModuleId={15}
      parentId={params.classId}
      title="Detalhe de Conteúdo de Texto"
      onReceiveData={onReceiveData}
      onBeforeSaveData={onBeforeSaveData}
      onNewData={onNewData}
      customDataFields={customDataFields}
      hideThumbnailField
      hideDescriptionField
      hideRewards
      breadcrumbs={breadcrumbs}
    />
  );
}

