import React from 'react';
import '../../../backoffice.scss';
import ModuleListing from '../../components/backoffice/components/ModuleListing/ModuleListing';
const Globals = require("../../../Globals.json");

export default function Courses() {

  return (
    <ModuleListing
      title="Lista de Cursos"
      buttonLabel="Novo Curso"
      typeModuleId={2}
      fullUrl={Globals.paths.backofficeEditCourse}
      urlReplacePattern=":courseId"
      showOrder
    />
  )

}

