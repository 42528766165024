import React, { Component } from 'react';
import { Button, CircularProgress, Container, DialogActions, DialogContent, Grid, Hidden, IconButton, Paper, Typography, FormControl, InputLabel, Select, MenuItem, FormHelperText, OutlinedInput, InputAdornment, TextField, Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { watch } from 'redux-easy/lib/redux-easy';
import { withRouter } from 'react-router-dom';
import { Helmet } from "react-helmet";
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import CloseIcon from '@material-ui/icons/Close';
import './expensesSelectItems.scss';
import api from '../../../../services/api';
import Globals from '../../../../Globals.json';
import MaskedInput from 'react-text-mask';

import ControlPointIcon from '@material-ui/icons/ControlPoint';
import Clear from '@material-ui/icons/Clear';
import Dialogs from '../../../../services/dialogs';

import CurrencyInput from 'react-currency-input';

import { MuiPickersUtilsProvider, DatePicker, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { format, formatWithOptions } from 'date-fns'
import { ptBR } from 'date-fns/locale';



class ExpensesSelectItems extends Component {

    step = 4;

    state = {

        loadedData: null,
        isLoading: false,
        photographId: null,
        selectedDate: this.props.state.data.selectedDate ? new Date(this.props.state.data.selectedDate) : new Date(),
        selectedItemId: '',
        amountValueView: '0,00',
        amountValue: '',
        valueError: false,
        expensesType: this.props.state.data.expensesType,
        expensesMatrixId: this.props.state.data.expensesMatrixId,
        valueMessage: '',
        expenseError: false,
        expenseMessage: '',
        customItemName: '',
        customItemNameError: false,
        customItemNameMessage: '',
        canSubmit: false,
        isCustomItem: false,
        loadedDataPhotograph: null,
        editMode: this.props.state.data.action === 'edit' ? true : false,
        selectedDateWithDay: null,
        maxDate: null,
        minDate: null,
        errorDay: false,
        id: null,
        itemMatrixId: null,
        matrixName: this.props.state.data.matrixName,
        openTo: "month",
        views: ["month", "date"],
        showInputDisabled: false,
        showInputSelect: true,
        isSavingLoading: false,
        alertMessage: '',
        openAlert: false,
        alertSeverity: 'success'
    }

    getProgressWidth() {
        const w = this.step * 20 + '%';
        return w === '100%' ? '101%' : w;
    }

    componentDidMount = () => {

        this.props.changeWidth('xs')
        this.loadData()
        console.log(this.props.state.data)
    }

    setData = async () => {
        const item = this.props.state.data.item;

        try {
            const dataItem = await api.get(Globals.api.userExpenseItem + `/${item.id}`)

            this.setState({
                photographId: dataItem.data.cad_user_financial_photograph_id,
                selectedDate: new Date(dataItem.data.reference_expense_date),
                selectedDateWithDay: new Date(dataItem.data.reference_expense_date),
                amountValue: dataItem.data.value,
                amountValueView: dataItem.data.value,
                selectedItemId: dataItem.data.type_expenses_matrix_item_id,
                expensesType: dataItem.data.type_expense_id,
                itemMatrixId: dataItem.data.type_expenses_matrix_id,
                isCustomItem: dataItem.data.is_custom_item,
                id: dataItem.data.id
            })
        } catch (error) {
            console.log(error)
        }

    }

    componentDidUpdate = (prevProps, prevState) => {
        if (prevState.selectedItemId !== this.state.selectedItemId) {
            this.canSubmit()
        }
        if (prevState.amountValue !== this.state.amountValue) {
            this.canSubmit()
        }
        if (prevState.customItemName !== this.state.customItemName) {
            this.canSubmit()
        }
        if (prevState.selectedDateWithDay !== this.state.selectedDateWithDay) {
            this.canSubmit()
        }
    }

    handleClose = () => {
        if (this.props.handleClose) {
            this.props.handleClose();
        }
    }

    loadData = async () => {
        this.setState({ isLoading: true })
        try {
            await api.get(Globals.api.typeExpensesMatrixItemsComboboxUser, { params: { type_expense_id: this.props.state.data.expensesType, type_expenses_matrix_id: this.props.state.data.expensesMatrixId } })
                .then(res => {
                    this.setState({ loadedData: res.data })

                })

            const res3 = await api.get(Globals.api.userFinancialPhotographCombobox)

            this.setState({
                loadedDataPhotograph: res3.data,
            })

            if (this.state.editMode) {
                await this.setData()
            }

            this.setConfigCalendar()

        } catch (error) {
            console.log(error.response)
        } finally {
            this.setState({ isLoading: false })
        }
    }

    handleSelectItem = (e, ref) => {
        if (e.target.value === 'new') {
            this.setState({
                showInputDisabled: true,
                showInputSelect: false
            })
        }
        this.setState({
            selectedItemId: e.target.value,
            isCustomItem: ref.props.iscustomitem,
            expenseError: false,
            expenseMessage: ''
        })

    }
    onBlurFieldSelectItem = (e) => {
        if (this.state.selectedItemId === '') {
            this.setState({
                expenseError: true,
                expenseMessage: 'Selecione uma opção ou adicione uma nova.'
            })
        }
    }

    handleChangeCustomItemName = (e) => {
        this.setState({
            customItemName: e.target.value,
            customItemNameError: false,
            customItemNameMessage: ''
        })
    }

    onBlurFieldcustomItemName = (e) => {
        this.setState({
            customItemName: e.target.value.trim(),
        })
        if (e.target.value.trim() === '') {
            this.setState({
                customItemNameError: true,
                customItemNameMessage: 'Informe um nome'
            })
        }
    }

    handleChangeValue = (e) => {
        this.setState({
            amountValueView: e.target.value,
            valueError: false,
            valueMessage: ''
        })
        if (e.target.value) {
            const value = this.currencyToNumber(e.target.value)
            if (isNaN(value)) {
                this.setState({
                    amountValue: ''
                })
            } else {
                this.setState({
                    amountValue: this.currencyToNumber(e.target.value)
                })
            }

        } else if (e.target.value === '') {
            this.setState({
                amountValue: ''
            })
        }

        return
    }

    onBlurFieldValue = () => {
        this.setState({
            valueError: false,
            valueMessage: ''
        })
        if (!this.state.amountValue && this.state.amountValue !== 0) {
            this.setState({
                valueError: true,
                valueMessage: 'Informe um valor válido.',
            })
        }
    }

    handleSubmit = async (e) => {
        e.preventDefault();
        this.setState({ isSavingLoading: true })
        let sucess = false


        if (this.state.canSubmit) {
            sucess = await this.postData()
        }
        if (sucess) {
            if (this.props.state.data.action === 'new' || this.props.state.data.action === 'edit') {
               
                this.props.showContent({ to: 'expenses-panel-select', from: this.props.page, reset: true, showMessage: true, data: { status: 'success' } })

            } else {
                this.props.showContent({ to: 'expenses-final', from: this.props.page })
            }
        }
        else {
            console.log('erro no post')
            this.setState({
                alertMessage: 'Não foi possível cadastrar sua despesa',
                alertSeverity: 'error',
                openAlert: true
            })
        }
        this.setState({ isSavingLoading: false })

    }
    canSubmit = () => {
        let error = true
        if (this.state.selectedItemId === 'new') {
            if (this.state.customItemName.trim() === '' || this.state.customItemNameError) {
                error = false
            }
        } else {
            if (this.state.selectedItemId === '' || this.state.expenseError) {
                error = false
            }
        }

        if (this.state.amountValue === '' || this.state.valueError) {
            error = false
        }
        if (!this.state.selectedDateWithDay || this.state.valueError) {
            error = false
        }

        this.setState({ canSubmit: error })
    }

    postData = async () => {

        const formatedDate = format(this.state.selectedDateWithDay, 'd MM yyyy', { locale: ptBR }).split(' ')
        const reference_day = parseInt(formatedDate[0])
        const reference_month = parseInt(formatedDate[1])
        const reference_year = parseInt(formatedDate[2])

        try {
            const postData = {
                type_expenses_matrix_item_id: this.state.selectedItemId,
                value: this.state.amountValue,
                is_custom_item: 0,
                reference_day,
                reference_month,
                reference_year
            }

            // cria novo item personalizado
            if (this.state.selectedItemId === 'new') {
                const resNewItem = await api.post(Globals.api.userCustomTypeExpensesMatrixItem, {
                    type_expenses_matrix_id: this.state.expensesMatrixId,
                    name: this.state.customItemName,
                })

                postData.type_expenses_matrix_item_id = resNewItem.data.id
                postData.is_custom_item = 1
            } else if (this.state.isCustomItem) {
                postData.is_custom_item = 1
            }

            if (this.state.editMode) {
                const postDataEditMode = {
                    type_expenses_matrix_item_id: postData.type_expenses_matrix_item_id,
                    value: postData.value,
                    is_custom_item: postData.is_custom_item,
                    reference_day,
                }

                await api.put(Globals.api.userExpenseItem + `/${this.state.id}`, postDataEditMode)

            } else {
                await api.post(Globals.api.userExpenseItem, postData)
            }

            return true
        } catch (error) {
            console.log(error.response)
            return false
        }
    }

    numberToCurrency = (value) => {
        const currency = new Intl.NumberFormat('pt-BR', {
            currency: 'BRL',
            style: 'currency',
        }).format(value);
        return currency
    }
    currencyToNumber = (value) => {
        const currency = parseFloat(value.replace(/[^\d\,]/g, '').replace(/[,]/g, '.'))
        return currency
    }
    handleValueChange = (event, maskedvalue, floatvalue) => {
        this.setState({
            amountValueView: maskedvalue,
            valueError: false,
            valueMessage: '',
            amountValue: floatvalue
        })
    };

    changeDate = async (date) => {

        this.setState({
            selectedDateWithDay: date,
            selectedDate: date,
            errorDay: false
        })

    }
    setConfigCalendar = () => {

        if (this.state.editMode) {
            const year = format(this.state.selectedDate, 'yyyy', { locale: ptBR })
            const month = format(this.state.selectedDate, 'MM', { locale: ptBR })
            const lastDayMonth = format(new Date(parseInt(year), parseInt(month), 0), 'd', { locale: ptBR });
            const baseDate = `${year}-${month}-`

            this.setState({
                minDate: `${baseDate}1`,
                maxDate: `${baseDate}${lastDayMonth} 23:59:59`,
                openTo: "date",
                views: ["date"],

            })

        } else {
            this.setState({
                maxDate: new Date('2050-12-31'),
                minDate: new Date('2000-1-1'),
                openTo: "date",
                views: ["month", "date"],
            })
        }

    }

    showInput = () => {

        this.setState({
            showInputDisabled: false,
            showInputSelect: true,
            selectedItemId: ''
        })

    }

    render() {
        return (

            <Grid className='expenses-select-items'>
                <MuiDialogTitle disableTypography className="dialog-header">
                    {this.state.isLoading ?
                        (<h2><CircularProgress size={24} style={{ color: "white" }} /> </h2>)
                        :
                        (<h2>{this.state.editMode ? 'Edição de despesas' : 'Despesas'} {this.state.expensesType === 1 ? 'fixas' : 'variáveis'} - {format(this.state.selectedDate, 'MMMM yyyy', { locale: ptBR }).toUpperCase()}</h2>)
                    }

                    <IconButton color="secondary" aria-label="close" className="close-button" onClick={this.handleClose}>
                        <CloseIcon />
                    </IconButton>
                </MuiDialogTitle>
                <Grid container className="progress-floater">
                    <div className="progress-container">
                        <div className="progress" style={{ width: this.getProgressWidth() }}></div>
                    </div>
                </Grid>
                <Grid className="container">
                    <DialogContent className="dialog-content" >

                        <Grid item xs={12} className="classDetailContainer">
                            <Snackbar
                                open={this.state.openAlert}
                                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                                autoHideDuration={2000}
                                onClose={() =>
                                    setTimeout(() => {
                                        this.setState({ openAlert: false });
                                    }, 2000)
                                }
                            >
                                <Alert
                                    onClose={() => {
                                        this.setState({ openAlert: false });
                                    }}
                                    severity={this.state.alertSeverity}
                                >
                                    {this.state.alertMessage}
                                </Alert>
                            </Snackbar>
                        </Grid>

                        {this.state.isLoading ?
                            (<Grid item xs={12} className="loading-area">
                                
                                    <CircularProgress />
                                
                            </Grid>)
                            :
                            (<Grid container item xs={12} direction="column" justify="center" alignItems="center" className="content-holder">
                                <h6><span>{this.state.editMode ? 'Edite' : 'Cadastre'}</span> sua despesa da categoria <span>{this.state.matrixName}</span></h6>
                                {this.state.showInputDisabled &&
                                    <Grid xs={12} className='hidden-input'>
                                        <TextField
                                            id="outlined-adornment-amount"
                                            label="Tipo de despesa"
                                            type="text"
                                            variant="outlined"
                                            size="small"
                                            fullWidth
                                            value={'Novo tipo de despesa'}
                                            autoComplete="off"
                                            disabled={true}
                                        />
                                        <IconButton type="button" onClick={this.showInput} aria-label="search">
                                            <Clear color='error' />
                                        </IconButton>
                                    </Grid>
                                }

                                {this.state.showInputSelect &&
                                    <FormControl fullWidth error={this.state.expenseError}>
                                        <InputLabel id="select-expenses-label">Tipo de despesa</InputLabel>
                                        <Select
                                            id="select-expenses-label"
                                            labelId="select-expenses"
                                            value={this.state.selectedItemId}
                                            label="Tipo de despesa"
                                            onChange={(e, ref) => this.handleSelectItem(e, ref)}
                                            onBlur={(e) => this.onBlurFieldSelectItem(e)}
                                            variant="outlined"
                                            fullWidth

                                        >

                                            <MenuItem value='' >
                                                <em>Selecione</em>
                                            </MenuItem >

                                            {this.state.loadedData && this.state.loadedData.length > 0 && (

                                                this.state.loadedData.map((item, index) => (
                                                    <MenuItem key={index} value={item.id} iscustomitem={item.is_custom_item}>{item.name}</MenuItem>
                                                ))
                                            )}
                                            <MenuItem hidden={true} value='new' >
                                                {this.state.selectedItemId !== 'new' &&
                                                    <ControlPointIcon style={{ marginRight: "4px" }} fontSize="small" />
                                                }
                                                Novo tipo de despesa
                                            </MenuItem >

                                        </Select>

                                        <FormHelperText>{this.state.expenseMessage}</FormHelperText>

                                    </FormControl>
                                }


                                {this.state.selectedItemId === 'new' &&
                                    <TextField
                                        id="outlined-adornment-amount"
                                        label="Meu novo tipo de despesa"
                                        type="text"
                                        variant="outlined"
                                        size="small"
                                        fullWidth
                                        value={this.state.customItemName}
                                        autoComplete="off"
                                        onChange={(e) => this.handleChangeCustomItemName(e)}
                                        onBlur={(e) => this.onBlurFieldcustomItemName(e)}
                                        error={this.state.customItemNameError}
                                        helperText={this.state.customItemNameMessage}
                                    />
                                }
                                <Grid container item xs={12} className="value-and-date" direction="row" justify="space-between">
                                    <div className="form-group">
                                        <label>Valor</label>
                                        <CurrencyInput
                                            prefix="R$ "
                                            ref={null}
                                            className="form-control"
                                            placeholder="Valor"
                                            decimalSeparator=","
                                            thousandSeparator="."
                                            value={this.state.amountValueView}
                                            precision={2}
                                            onChangeEvent={this.handleValueChange}
                                        />
                                    </div>

                                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
                                        <DatePicker
                                            label={'Data da despesa'}
                                            emptyLabel="__/__/____"
                                            format="dd/MM/yyyy"
                                            okLabel="Selecionar"
                                            cancelLabel="Cancelar"
                                            inputVariant="outlined"
                                            size="small"
                                            openTo={this.state.openTo}
                                            value={this.state.selectedDateWithDay}
                                            onChange={(newValue) => this.changeDate(newValue)}
                                            views={this.state.views}
                                            minDate={new Date(this.state.minDate)}
                                            minDateMessage={'data invalida'}
                                            maxDate={new Date(this.state.maxDate)}
                                            maxDateMessage={'data invalida'}
                                            error={this.state.errorDay}
                                            invalidDateMessage={'Data invalida '}

                                        />

                                    </MuiPickersUtilsProvider>
                                </Grid>
                            </Grid>
                            )}
                    </DialogContent >
                </Grid>
                <DialogActions className="dialog-footer space-between">
                    <Button
                        id="g-pm-start-test"
                        className={`g-pm-start-test`}
                        color="primary"
                        variant="outlined"
                        onClick={() => { this.props.goBack() }}
                        style={{ borderRadius: "0px", width: "120px" }}
                    >
                        voltar
                    </Button>
                    <Button
                        id="g-pm-start-test"
                        className={`g-pm-start-test`}
                        variant="contained"
                        color="primary"
                        onClick={(e) => this.handleSubmit(e)}
                        style={{ borderRadius: "0px", color: "white", width: "120px" }}
                        disabled={!this.state.canSubmit && !this.state.isSavingLoading}
                    >
                        {this.state.isSavingLoading ? (<CircularProgress size={24} style={{ color: "white" }} />) : 'salvar'}
                    </Button>
                </DialogActions>

            </Grid >
        )
    }
}

export default watch(withRouter(ExpensesSelectItems), { user: '' });