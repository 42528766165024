import React from 'react';
import './styles/levels.scss';

import { watch } from 'redux-easy/lib/redux-easy';
import { Box, Grid } from '@material-ui/core';

import { Skeleton } from '@material-ui/lab';

class NextTrophy extends React.Component {

  state = {
    userLevel: 0
  }

  componentDidMount () {
    if (this.props.user && this.props.user.profile.progress) {
      this.setState({ userLevel: this.props.user.profile.progress.level });
    }
  }

  componentDidUpdate (oldProps) {
    if (JSON.stringify(this.props.user) !== JSON.stringify(oldProps.user)) {
      if (this.props.user && this.props.user.profile.progress) {
        this.setState({ userLevel: this.props.user.profile.progress.level });
      }
    }
  }

  render () {
    return (
      <>
        {this.props.isLoading ?
          <Grid>
            <Box pt={0.5} style={{ display: "flex", margin: "10px 0px 10px 0px", padding: "0px 10px 0px 0px" }}>
              <Skeleton variant="rect" className="level_skeleton" />
            </Box>
          </Grid> :

          <div>
            <div className="level_cardContent">

              <div className="level_containerLeft">
                <div className="level_profile">
                  <img src={`https://pefmbddiag.blob.core.windows.net/cdn/images/icon/level_${this.state.userLevel}.png`} alt={`Imagem do Nível ${this.state.userLevel}`} className="level_imgIcon" />
                </div>
                <span className="level_title"></span>
                <span className="level_subtitle">Nível {this.props.user.profile.progress.level}</span>
              </div>
              <div className="level_texts">
                <span className="level_titleProgress">Seu Progresso atual</span>
                <span className="level_subTitleProgress">Nível {this.props.user.profile.progress.level}</span>
                <div className="level_contentProgressDivided">
                  <progress value={this.props.user.profile.progress.xp} max={this.props.user.profile.progress.next_level_xp} className="level_progressDivided" />
                </div>
              </div>
            </div>
          </div>
        }
      </>
    )
  }
}
export default watch((NextTrophy), { user: '' });
