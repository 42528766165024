import React from 'react';

//Componentes
import api from '../../../services/api';

import { ArrowRight } from '@material-ui/icons';
import { dispatchSet } from 'redux-easy/lib/redux-easy';
import { withStyles, Button, LinearProgress, CircularProgress, Backdrop } from '@material-ui/core';

import AlertErrors from '../../components/alertErrors/alerts';
import MultiselectOptionList from './MultiselectOptionList';

const styles = theme => ({
  containerQuestionsOverflow: {
    backgroundColor: "#FFF",
    height: "80%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",

    [theme.breakpoints.down('xs')]: {
      overflow: "auto",
      height: "400px",
    }
  },

  container: {
    width: "80%",
    height: "700px",
    margin: "auto !important",
    padding: "0",
    position: "relative",
    flexDirection: "column",
    maxWidth: "1500px",
    boxShadow: "4px 4px 10px #0003",
    top: "280px",
    minHeight: "200px",
    backgroundColor: "#fff",
  },

  "@media only screen and (max-width: 950px)": {
    container: {
      marginLeft: "10% !important",
      marginRight: "20% !important",
    }
  },
  contentBtn: {
    width: "100%",
    display: "flex",
    marginTop: "50px",
    padding: "0px 10% 0px 10%",
    justifyContent: "space-between",

    '@media only screen and (max-width: 599px)': {
      marginTop: "10px",
    }
  },

  quizButtonLabel:
  {
    flexGrow: 1,
  },
  quizButtonIcon:
  {
    padding: "0px",
  },
  quizButton:
  {
    marginBottom: "20px",
    display: "flex",
    flexDirection: "row",
    textTransform: "uppercase",
    flexWrap: "nowrap",
    padding: "5px",
    justifyContent: "center",
    alignItems: "center",
    border: "#004178 1px solid",
    backgroundColor: "#004178",
    color: "#FFF",
    borderRadius: "0px",
    fontSize: "9pt",
    width: "100px",
    paddingLeft: "10px",
    transition: "color .3s ease-out, border-color .3s ease-out",
    '&:hover': {
      backgroundColor: "#1d446f",
      cursor: "pointer",
    },
    '&:disabled': {
      color: "#D3D3D3",
      backgroundColor: "#FFF",
      border: "#D3D3D3 1px solid",
    }
  },
  quizButtonReturn:
  {
    marginBottom: "20px",
    display: "flex",
    flexDirection: "row",
    textTransform: "uppercase",
    flexWrap: "nowrap",
    padding: "5px",
    justifyContent: "center",
    alignItems: "center",
    border: "#004178 1px solid",
    backgroundColor: "#FFF",
    color: "#004178",
    borderRadius: "0px",
    fontSize: "9pt",
    width: "100px",
    paddingLeft: "10px",
    transition: "color .3s ease-out, border-color .3s ease-out",
    '&:hover': {
      backgroundColor: "#D3D3D3",
      cursor: "pointer",
    },
  },
  header: {
    color: "#FFF",
    width: "100%",
    height: "100px",
    display: "flex",
    padding: "30px",
    position: "relative",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: "#004178",

    [theme.breakpoints.down('xs')]: {
      // marginTop: "45%",
      height: "55px",
    }
  },

  titleTest: {
    fontWeight: "400",

    [theme.breakpoints.down('xs')]: {
      fontSize: "12px",
      height: "30px",
    }
  },

  titleQuestions: {
    [theme.breakpoints.down('xs')]: {
      fontSize: "12px",
    }
  },

  qtndQuestions: {
    fontWeight: "400",
    marginRight: "70px",

    [theme.breakpoints.down('xs')]: {
      marginRight: "0px",
      fontSize: "14px",
    }
  },

  linearProgress: {
    width: "100% !important",
  },

  contentDescription: {
    width: "100%",
    display: "flex",
    fontSize: "20px",
    textAlign: "center",
    alignItems: "center",
    justifyContent: "center",

    [theme.breakpoints.down('xs')]: {
      fontSize: "16px",
    }
  },

  containerQuestions: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",

  },

  contentAskCol: {
    width: "60%",
    display: "flex",
    flexDirection: "column",
    // position: "absolute",
    [theme.breakpoints.down('xs')]: {
      width: "90%",
    }
  },

  contentAskRow: {
    width: "90%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    // position: "absolute",
    [theme.breakpoints.down('xs')]: {
      flexDirection: "column",
      width: "100%",
    },
  },

  respsH: {
    display: "flex",
    justifyContent: "center",
    paddingLeft: "15px",
    paddingRight: "15px",
    border: "2px solid #D3D3D3",
    borderRadius: "3px",
    height: "50px",
    cursor: "pointer",
    marginLeft: "6px",
    marginRight: "6px",
    fontWeight: "500",
    fontSize: "16px",
    flexGrow: 1,
    flexBasis: 100,
    alignItems: "center",
    textAlign: "center",

    [theme.breakpoints.down('xs')]: {
      fontSize: "11px",
      width: "90%",
      flexBasis: "45px",
      minHeight: "45px",
      marginBottom: "10px",
      height: "100%",
    },

    "@media only screen and (max-width: 350px)": {
      fontSize: "11px",
    }
  },

  respsV: {
    display: "flex",
    alignItems: "center",
    paddingLeft: "5px",
    paddingRight: "5px",
    border: "2px solid #D3D3D3",
    borderRadius: "3px",
    width: "100%",
    height: "40px",
    cursor: "pointer",
    marginTop: "12px",
    fontWeight: "500",
    fontSize: "16px",

    [theme.breakpoints.down('xs')]: {
      fontSize: "11px",
      width: "100%",
      flexBasis: "45px",
      minHeight: "45px",
      marginBottom: "10px",
      height: "100%",
    },
    "@media only screen and (max-width: 350px)": {
      fontSize: "11px",
    }
  },

  respsHActive: {
    display: "flex",
    paddingLeft: "15px",
    paddingRight: "15px",
    border: "2px solid #80D9BD",
    color: "#80D9BD",
    borderRadius: "3px",
    height: "50px",
    cursor: "pointer",
    marginLeft: "6px",
    marginRight: "6px",
    fontWeight: "500",
    fontSize: "16px",
    flexGrow: 1,
    flexBasis: 100,
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",


    [theme.breakpoints.down('xs')]: {
      fontSize: "11px",
      width: "90%",
      flexBasis: "45px",
      minHeight: "45px",
      marginBottom: "10px",
      height: "100%",
    },
    "@media only screen and (max-width: 350px)": {
      fontSize: "11px",
    }
  },

  respsVActive: {
    display: "flex",
    alignItems: "center",
    paddingLeft: "5px",
    paddingRight: "5px",
    border: "2px solid #80D9BD",
    color: "#80D9BD",
    borderRadius: "3px",
    width: "100%",
    height: "40px",
    cursor: "pointer",
    marginTop: "12px",
    fontWeight: "500",
    fontSize: "16px",

    [theme.breakpoints.down('xs')]: {
      fontSize: "11px",
      width: "100%",
      flexBasis: "45px",
      minHeight: "45px",
      marginBottom: "10px",
      height: "100%",
    },

    "@media only screen and (max-width: 350px)": {
      fontSize: "11px",
    }
  },

  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },

  description: {
    overflow: "auto",
    width: "85%",
    fontWeight: "bold",

    [theme.breakpoints.down('xs')]: {
      fontSize: "14px",
    }
  }
});

const Globals = require("../../../Globals.json");

class ListModal extends React.Component {

  state = {
    currentQuestion: null,
    currentIndex: -1,
    isLoading: false,
    selection: [],
    questions: [],
    maxByGroup: {},
    maxAll: 0,
    endQuestion: false,
    questionActive: false,
    open: true,
    fim: true,
    alertError: '',
  }

  async loadData(id) {

    this.setState({ isLoading: true });
    try {
      const postData = {
        "container_id": 19,
      };

      const response = await api.post(Globals.api.getModules, postData);
      console.log("Questions resp data:", response.data);

      const { container } = response.data;

      console.log(container);

      this.setState({
        isLoading: false,
        questions: container.data.questions || [],
      });

      if (this.state.questions.length > 0) {
        this.setState({ currentQuestion: this.state.questions[0], currentIndex: 0 });
      }
    } catch (error) {
      this.setState({ alertError: error.response.status })
      setTimeout(() => {
        this.setState({ alertError: '' })
      }, 3000)
    }
  }

  async postData(selection) {
    this.setState({ isLoading: true });

    try {
      const { indexValue, indexByDimension } = this.calculateIndice();

      dispatchSet("isfb");

      if (this.props.setResult) {
        this.props.setResult(indexValue);
      }
      console.log('postData =====>', 'this.props.setResult', this.props.setResult, 'this.props.setResult(indexValue)', this.props.setResult(indexValue))

      const postData = {
        type_action_id: 19,
        data_modules_detail_id: 58,
        answers: this.state.selection,
        isfb: indexValue,
        dimensions: indexByDimension
      };

      await api.post(Globals.api.userActionEvent, postData);

      this.setState({ isLoading: false });

      this.props.sendData();
      console.log("this.props.sendData();", this.props.sendData())
    } catch (error) {
      this.setState({ alertError: error.response.status })
      setTimeout(() => {
        this.setState({ alertError: '' })
      }, 3000)
    }
  }

  async saveAnswers() {
    try {
      const postData = {
        type_action_id: 17, //salvar respostas
        data_modules_detail_id: 58,
        answers: this.state.selection,
      };
      await api.post(Globals.api.userActionEvent, postData);
    } catch (error) {
      this.setState({ alertError: error.response.status })
      setTimeout(() => {
        this.setState({ alertError: '' })
      }, 3000)
    }
  }

  handleSelection = (option) => {
    const selection = this.state.selection

    selection[this.state.currentIndex] = {
      qid: this.state.currentQuestion.qid,
      dimension: this.state.currentQuestion.dimension,
      group: this.state.currentQuestion.group,
      selCod: option.cod,
      id: option.id,
    };

    this.setState({ selection })

    this.calculateIndice();

    this.saveAnswers();

  }

  calculateIndice() {

    const max = this.state.maxAll;
    let sum = 0;

    const sumDimensions = {};

    this.state.selection.forEach(item => {
      if (item) {
        console.log("Item:", item);
        sum += item.selCod;
        if (!sumDimensions.hasOwnProperty(item.group)) {
          sumDimensions[item.group] = 0;
        }
        sumDimensions[item.group] += item.selCod;
      }
    })

    const indexByDimension = {};

    Object.keys(sumDimensions).forEach(key => {

      indexByDimension[key] = Math.round(sumDimensions[key] / this.state.maxByGroup[key] * 100);

    })

    const indexValue = Math.round(Math.min(1000, 1000 / max * sum));
    console.log("SUM:", sum);
    // console.log("CALC:", calc);
    console.log("BY DIMENSION:", indexByDimension);

    return {
      indexValue,
      indexByDimension
    };

  }

  nextQuestion = () => {

    if (this.state.questions.length > this.state.currentIndex + 1) {
      this.setState({ currentIndex: this.state.currentIndex + 1, currentQuestion: this.state.questions[this.state.currentIndex + 1] })
    } else {
      this.setState({ fim: false })
    }
  }

  prevQuestion = () => {
    if (this.state.currentIndex > 0) {
      this.setState({ currentIndex: this.state.currentIndex - 1, currentQuestion: this.state.questions[this.state.currentIndex - 1], fim: true })
    }
  }

  handleClose = () => {
    this.setState({ open: false })
  }

  getOptionClass = (option) => {

    const isVertical = this.state.currentQuestion.layout === "vertical";
    if (this.state.selection[this.state.currentIndex] && this.state.selection[this.state.currentIndex].id === option.id) {
      return isVertical ? this.props.classes.respsVActive : this.props.classes.respsHActive;
    }
    else {
      return isVertical ? this.props.classes.respsV : this.props.classes.respsH;
    }

  }

  onUpdateSelected = (selected) => {

    if (this.state.currentQuestion.type === "mapAmount") {
      const amount = selected.length;
      const mapped = this.state.currentQuestion.map.filter(item => (amount >= item.min && amount <= item.max));
      const cod = mapped[0].cod;
      // console.log( amount, mapped[0].cod );

      this.handleSelection({ amount, cod });

    }

  }

  componentDidMount() {
    // const id = this.props.id;
    // console.log( "QUESTIONS::", this.props );
    console.log('componentDidMount ======> this.props.questions', this.props.questions)
    if (this.props.questions) {

      const blocks = this.props.questions;
      const questions = [];
      const maxByGroup = {};

      const firstBlocks = [];
      const randomBlocks = [];
      const lastBlocks = [];

      blocks.forEach(block => {
        if (block.position === "first") {
          // console.log("ADD FIRST BLOCK");
          firstBlocks.push(block);
        }
        if (block.position === "last") {
          // console.log("ADD LAST BLOCK");
          lastBlocks.push(block);
        }
        if (block.position === "random") {
          // console.log("ADD RANDOM BLOCK");
          randomBlocks.push(block);
        }
      })

      let allBlocks = firstBlocks.concat();
      while (randomBlocks.length > 0) {
        const i = Math.floor(Math.random() * randomBlocks.length);
        allBlocks.push(randomBlocks[i])
        randomBlocks.splice(i, 1);
      }
      allBlocks = allBlocks.concat(lastBlocks);

      const selection = [];
      let maxAll = 0;

      allBlocks.forEach(block => {
        block.questions.forEach(question => {
          questions.push(question);

          if (!maxByGroup.hasOwnProperty(question.group)) {
            maxByGroup[question.group] = 0;
          }

          if (question.type === "mapAmount") {
            selection.push({ group: question.group, selCod: 0 });
            let maxCod = 0;
            question.map.forEach(el => maxCod = Math.max(maxCod, el.cod));
            maxByGroup[question.group] += maxCod;
            maxAll += maxCod;
          }
          else {
            selection.push(null);
            let maxCod = 0;
            question.options.forEach(el => maxCod = Math.max(maxCod, el.cod));
            maxByGroup[question.group] += maxCod;
            maxAll += maxCod;
          }

        })
      })


      this.setState({ selection, maxByGroup, maxAll })

      this.setState({
        isLoading: false,
        questions: questions,
        currentQuestion: questions[0],
        currentIndex: 0
      });
    }
    else {
      this.loadData();
    }
  }



  render() {

    const { classes } = this.props;

    return (
      <>
        {this.state.isLoading ?
          <Backdrop className={classes.backdrop} open={this.state.isLoading}>
            <CircularProgress color="inherit" />
            <AlertErrors error={this.state.alertError} />
          </Backdrop> :
          <>
            <div className={classes.header}>
              <h3 className={classes.titleTest}>ÍNDICE DE SAÚDE FINANCEIRA</h3>
              <h3 className={classes.titleQuestions}>
                {`Pergunta ${this.state.currentIndex + 1} de ${this.state.questions.length}`}</h3>
            </div>
            <div className={classes.linearProgress}>
              <LinearProgress variant="determinate" value={(this.state.currentIndex) / this.state.questions.length * 100} />
            </div>
            {
              this.state.currentQuestion &&
              <>
                {this.state.isLoading ? <CircularProgress /> :
                  <div className={classes.containerQuestionsOverflow}>
                    <div className={classes.contentDescription}>
                      <p className={classes.description} dangerouslySetInnerHTML={{ __html: this.state.currentQuestion.question }}>
                        {/* {this.state.currentQuestion.question} */}
                      </p>
                    </div>
                    {(!this.state.currentQuestion.type || this.state.currentQuestion.type === "default") &&
                      <div className={classes.containerQuestions}>
                        <div className={this.state.currentQuestion.layout === "vertical" ? classes.contentAskCol : classes.contentAskRow}>
                          {this.state.currentQuestion.options.map((item, index) => (
                            <div key={index}
                              className={this.getOptionClass(item)}
                              onClick={() => this.handleSelection(item)}>
                              {item.text}
                            </div>
                          ))}
                        </div>
                      </div>
                    }

                    {this.state.currentQuestion.type === "mapAmount" &&
                      <MultiselectOptionList options={this.state.currentQuestion.options} onUpdateSelected={selected => this.onUpdateSelected(selected)} />
                    }


                    <div className={classes.contentBtn}
                      style={
                        this.state.currentIndex > 0 ? { justifyContent: 'space-between' }
                          : { justifyContent: 'flex-end' }
                      }>

                      {this.state.currentIndex > 0 &&
                        <Button className={classes.quizButtonReturn} onClick={() => this.prevQuestion()}>
                          Voltar
                      </Button>
                      }

                      <Button className={classes.quizButton}
                        onClick={
                          this.state.currentIndex === this.state.questions.length - 1 ?
                            () => this.postData(this.state.selection) : this.nextQuestion
                        }
                        disabled={!this.state.selection[this.state.currentIndex]} >
                        {this.state.currentIndex === this.state.questions.length - 1 ? "Concluir" : "Próxima"}
                        <ArrowRight className={classes.quizButtonIcon} />
                      </Button>
                    </div>
                  </div>
                }
              </>
            }
          </>
        }

      </>

    )
  }

}

export default withStyles(styles)(ListModal);
