import { addReducer } from 'redux-easy';
import { getState } from 'redux-easy/lib/redux-easy';
import api from '../services/api';
const Globals = require("../Globals.json");

addReducer('updateUserProfile', (state) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await api.get(Globals.api.selfInfo);
      const newState = { ...getState(), user: { profile: response.data } };
      resolve(newState);
    } catch (e) {
      reject(e);
    }
  })
});