import { Dialog, FormControl, InputLabel, Select, Slider, TextField } from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Button from '../../../button/Button';
import FileUploader from '../FileUploader/FileUploader';

import '../../../../../backoffice.scss';
import { parseIdentifierInput, parseIntInput } from '../../../../../services/util';
import Dialogs from '../../../../../services/dialogs';

function QuizResultEditor({
  quizType,
  resultData = null,
  open = false,
  onClose,
  onSave,
  onRemove,
}) {

  const [resultImage, setResultImage] = useState('');
  const [resultName, setResultName] = useState('');
  const [resultDescription, setResultDescription] = useState('');
  const [resultRulesMin, setResultRulesMin] = useState('');
  const [resultRulesMax, setResultRulesMax] = useState('');
  const [resultRulesId, setResultRulesId] = useState('');
  const [formValidation, setFormValidation] = useState({});

  const validate = () => {

    const validation = {};

    if (resultImage.length === 0) {
      validation.image = "É necessário selecionar uma imagem para o resultado."
    }
    if (resultName.length === 0) {
      validation.name = "É necessário informar um nome para o resultado."

    }
    if (resultDescription.length === 0) {
      validation.description = "É necessário informar uma descrição para o resultado."

    }
    if (quizType === 'range') {

      if (
        (resultRulesMin.length === 0 || isNaN(resultRulesMin)) &&
        (resultRulesMax.length === 0 || isNaN(resultRulesMax))
      ) {
        validation.rulesMin = "Pelo menos uma pontuação mínima ou máxima precisa ser definida";
        validation.rulesMax = validation.rulesMin;
      }

      if (resultRulesMin.length > 0 &&
        resultRulesMax.length > 0 &&
        parseInt(resultRulesMin) > parseInt(resultRulesMax)
      ) {
        validation.rulesMax = "A pontuação máxima necessária precisa ser superior ou igual à pontuação mínima necessária";
      }

    }

    if( quizType === 'scoring' )
    {
      if (resultRulesId.length === 0) {
        validation.rulesId = "É necessário informar um identificador de perfil para o resultado."
      }
    }

    if (Object.keys(validation).length > 0) {
      setFormValidation(validation);
      return false;
    }

    return true;

  }

  const save = () => {

    if (validate()) {
      const dataObject = {
        image: resultImage,
        title: resultName,
        description: resultDescription,
      }

      if (quizType === 'range')
      {
        dataObject.min = parseInt(resultRulesMin);
        dataObject.max = parseInt(resultRulesMax);
      }
      else if( quizType === 'scoring' )
      {
        dataObject.id = resultRulesId;
      }

      if (onSave) onSave(dataObject);
    }

  }

  const clearValidation = (field) => {
    const ret = { ...formValidation };
    delete ret[field];
    setFormValidation(ret);
  }

  const close = () => {

    if (onClose) onClose();

  }

  const removeCheck = () => {

    Dialogs.show(
      <div>Confirmação</div>,
      'Tem certeza que deseja excluir este resultado?',
      [
        {
          label: 'Cancelar',
        },
        {
          label: "Sim",
          onClick: () => doRemove(),
        },
      ]
    );

  }

  const doRemove = () => {
    onRemove();
    close();
  }

  useEffect(() => {

    setResultImage(resultData?.image ?? '');
    setResultName(resultData?.title ?? '');
    setResultDescription(resultData?.description ?? '');

    if (quizType === 'range') {
      setResultRulesMin(resultData?.min ?? '');
      setResultRulesMax(resultData?.max ?? '');
    }
    else if (quizType === 'scoring')
    {
      setResultRulesId(resultData?.id ?? '');
    }

  }, [resultData]);

  return (
    <Dialog open={open}>
      <div className='edit-quiz-result-dialog'>
        <div className='edit-result-title'>
          {!!resultData ? 'Edição de Resultado' : 'Novo Resultado'}
        </div>
        <>
          <div className="field-label">Imagem</div>
          <FileUploader onChange={(url) => { setResultImage(url); clearValidation('image'); }} folder={'quiz'} imageUrl={resultImage} maxFileSize={3} />
          {!!formValidation.image &&
            <div className='image-error-message' >{formValidation.image}</div>
          }
        </>
        <TextField
          key="txtResultTitle"
          label="Título"
          value={resultName}
          onChange={(e) => { setResultName(e.target.value); clearValidation('name') }}
          error={!!formValidation.name}
          helperText={formValidation.name ?? ''}
        />
        <TextField
          key="txtResultDescription"
          label="Descrição"
          value={resultDescription}
          multiline
          rowsMax={6}
          onChange={(e) => { setResultDescription(e.target.value); clearValidation('description') }}
          error={!!formValidation.description}
          helperText={formValidation.description ?? ''}
        />
        {quizType === 'range' &&
          <>
            <TextField
              key="txtResultRuleMin"
              label="Pontuação mínima para o resultado"
              type="number"
              value={resultRulesMin}
              onChange={(e) => { setResultRulesMin(parseIntInput(e.target.value, true)); clearValidation('rulesMin') }}
              error={!!formValidation.rulesMin}
              helperText={formValidation.rulesMin ?? ''}
            />
            <TextField
              key="txtResultRuleMax"
              label="Pontuação máxima para o resultado"
              type="number"
              value={resultRulesMax}
              onChange={(e) => { setResultRulesMax(parseIntInput(e.target.value, true)); clearValidation('rulesMax') }}
              error={!!formValidation.rulesMax}
              helperText={formValidation.rulesMax ?? ''}

            />
          </>
        }
        {quizType === 'scoring' &&
          <>
            <TextField
              key="txtRulesId"
              label="Identificador de Perfil"
              value={resultRulesId}
              onChange={(e) => { setResultRulesId( parseIdentifierInput( e.target.value ) ); clearValidation('rulesId') }}
              error={!!formValidation.rulesId}
              helperText={formValidation.rulesId ?? 'Utilize letras, números e hífen sem espaços'}
            />
          </>
        }
        <div className='buttons-container'>
          <div style={{ flex: 1 }}>
            <Button
              style={{backgroundColor: "#900", border: "none", color: "#FFF"}}
              icon="none"
              textAlign="center"
              height="40px"
              onClick={() => { removeCheck() }}>Excluir</Button>
          </div>

          <Button
            right="15px"
            icon="none"
            textAlign="center"
            height="40px"
            onClick={() => { close() }}>Cancelar</Button>

          <Button
            icon="none"
            type={'darkBlue'}
            textAlign="center"
            height="40px"
            onClick={() => { save() }}>Salvar</Button>
        </div>
      </div>
    </Dialog>
  );
}

QuizResultEditor.propTypes = {
  quizType: PropTypes.string.isRequired,
  resultData: PropTypes.object,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
};

export default QuizResultEditor