import styled from 'styled-components';
import { withTheme } from "@material-ui/core/styles";

export const Journey = withTheme(styled('section')`
  background: linear-gradient(180deg, rgba(229,229,229,1) 0%, rgba(229,229,229,0) 15%, rgba(229,229,229,0) 85%, rgba(229,229,229,1) 100%);

  .header {
    background-color: #4faf98;
    padding: 34px;
    width: 100%;
    text-align: center;
    
    * {
      margin: 0;
    }
    
    h1 {
      color: #004178;
      font-family: 'Caros Soft ExtraBold';
      font-size: 36px;
      line-height: 43px;
      margin-bottom: 5px;

      @media(max-width: ${({ theme }) => `${theme.breakpoints.values.sm}px`}) {
        font-size: 6vw;
        line-height: initial;
      }
    }

    h2 {
      font-family: 'Caros Soft Regular';
      color: #fff;
      font-size: 16px;

      @media(max-width: ${({ theme }) => `${theme.breakpoints.values.sm}px`}) {
        font-size: 4vw;
      }
    }
  }
`);
