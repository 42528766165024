import { CheckCircleOutline, RadioButtonChecked, RadioButtonUnchecked } from '@material-ui/icons';
import React from 'react';
import { Link } from 'react-router-dom';
import Globals from '../../../Globals.json';
import './styles/classLesson.scss';

class ClassLesson extends React.Component {
  renderIcon() {
    const { current, done, started } = this.props;

    if (current) {
      return <RadioButtonChecked className="lesson_icon_item_checked" />;
    } else if (done) {
      return <CheckCircleOutline className="lesson_icon_item" />;
    } else if (started) {
      return <CheckCircleOutline className="lesson_icon_item" />;
    } else {
      return <RadioButtonUnchecked className="circleOff" />;
    }
  }

  render() {
    const { current, done, started, classData, courseId } = this.props;

    return (
      <>
        <div className="lesson_containerItems">
          {(done || (started && !current)) && (
            <Link
              onClick={this.props.onClick}
              to={Globals.paths.class
                .replace(':classId', classData.id)
                .replace(':courseId', courseId)}
            >
              {this.renderIcon()}
              <span className="lesson_nameLesson">{classData.name}</span>
            </Link>
          )}

          {!done && (!started || current) && (
            <>
              {this.renderIcon()}
              <span className="lesson_nameLesson"> {classData.name} </span>
            </>
          )}
        </div>
      </>
    );
  }
}
export default ClassLesson;
