// *https://www.registers.service.gov.uk/registers/country/use-the-api*
import fetch from 'cross-fetch';
import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import api from '../../../services/api';
import Globals from '../../../Globals.json';

function sleep (delay = 0) {
    return new Promise((resolve) => {
        setTimeout(resolve, delay);
    });
}

export default function CitiesAutoComplete (props) {
    const [open, setOpen] = React.useState(false);
    const [options, setOptions] = React.useState([]);
    const [term, setTerm] = React.useState('');
    const [value, setValue] = React.useState(null);
    const loading = open && options.length === 0 && term.length >= 3;

    React.useEffect(() => {

        if (props.value) {
            setValue(props.value);
        }

        let el = document.getElementById("auto-23");
        if (el) {
            el.attributes.autocomplete.value = "new-password";
        }

        let active = true;

        if (!loading || term.length < 3) {
            return undefined;
        }

        (async () => {
            try {
                const cities = (await api.get(Globals.api.counties, { params: { stateId: props.stateId, term: term } })).data;
                if (active) {
                    setOptions(cities);
                }
                else {
                    setOptions([]);
                }
            }
            catch (error) {
                console.log(error);
            }
        })();

        return () => {
            active = false;
        };
    }, [loading, term, props.stateId, props.value]);

    React.useEffect(() => {
        if (!open) {
            setOptions([]);
        }
    }, [open]);

    const handleChange = (event, newValue) => {
        setOptions(newValue ? [newValue, ...options] : options);
        if (props.setCity) {
            props.setCity(newValue);
        }
        setValue(newValue);
    }

    return (
        <Autocomplete
            open={open}
            onOpen={() => { setOpen(true); }}
            onClose={() => { setOpen(false); }}
            onInputChange={(event, newInputValue) => { setTerm(newInputValue); }}
            value={value}
            onChange={handleChange}
            getOptionSelected={(option, value) => option.label === value.label}
            getOptionLabel={(option) => option.label}
            options={options}
            loading={loading}
            disabled={!props.stateId || props.disabled}
            id="auto-23"
            clearText="Limpar"
            loadingText="Pesquisando..."
            label="Cidade *"
            noOptionsText={term.length > 2 ? "Nenhuma cidade encontrada." : "O termo da pesquisa precisa ser maior que 3 caracteres."}
            renderInput={(params) => (
                <TextField
                    {...params}
                    error={props.error}
                    helperText={props.helperText}
                    label="Cidade *"
                    variant="outlined"
                    size="small"
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <React.Fragment>
                                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        ),
                    }}
                />
            )}
        />
    );
}