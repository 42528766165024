import React from 'react';
import '../../../backoffice.scss';
import ModuleListing from '../../components/backoffice/components/ModuleListing/ModuleListing';
const Globals = require("../../../Globals.json");

export default function Missions() {

  return (
    <ModuleListing
      title="Lista de Missões"
      buttonLabel="Nova Missão"
      typeModuleId={20}
      fullUrl={Globals.paths.backofficeEditMission}
      urlReplacePattern=":missionId" />
  )

}

