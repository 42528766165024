import React, { Component } from 'react';
import { Button, CircularProgress, Container, DialogActions, DialogContent, DialogTitle, Grid, Hidden, IconButton, Paper, Typography } from '@material-ui/core';
import { watch } from 'redux-easy/lib/redux-easy';
import { withRouter } from 'react-router-dom';
import CloseIcon from '@material-ui/icons/Close';
import './personaList.scss';
import api from '../../../../services/api';

import Globals from '../../../../Globals.json';

class PersonaList extends Component {

    state = {
        personas: [],
        loading: true,
    }

    componentDidMount = async () => {
        try {
            const personas = await api.get(Globals.api.personas);

            this.setState({ personas: personas.data, loading: false });
        } catch (e) {
            this.setState({ loading: false });
        }
    }

    handleClose = () => {
        if (this.props.handleClose) {
            this.props.handleClose();
        }
    }

    render () {


        let html = [];
        html.push(
            <Grid item xs={12} className="loading p20">
                <div className="loading">
                    <CircularProgress />
                </div>
            </Grid>
        );

        if (!this.state.isLoading) {

            const colors = ['#F1EFE3', '#A3D2B1', '#FCDBCF', '#CBDDFE'];

            html = [];

            html.push(
                this.state.personas.map((persona) => (
                    <Grid key={`persona-${persona.type}`} onClick={() => this.props.showContent({ to: 'persona-detail', from: this.props.page, data: { persona: persona } })} container item xs={12} sm={6} md={4} lg={3} justify="center" alignItems="center" className="persona-item-holder">
                        <Grid container item style={{ backgroundColor: colors[persona.type % 4] }} justify="center" alignItems="center" xs={12} className="persona-item">
                            <div className="persona-label">
                                <h5>{persona.name}</h5>
                            </div>
                            <img src={`/images/personas/${persona.type}.svg`} alt="" />
                        </Grid>
                    </Grid>
                ))
            );

        }

        return (

            <>
                <DialogTitle disableTypography className="dialog-header">
                    <IconButton color="secondary" aria-label="close" className="close-button" onClick={this.handleClose}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent className="dialog-content persona-list-body" dividers>
                    <Grid container direction="column" justify="flex-start" alignItems="center">
                        <Container className="content-holder">
                            <Grid container className="persona-list-content">
                                <Grid container direction="row" justify="flex-start" alignItems="flex-start" className="pt20 pl5 pr5 pb20">
                                    {html}
                                </Grid>
                            </Grid>
                        </Container>
                    </Grid>
                </DialogContent >
                <DialogActions className="dialog-footer space-between">
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => { this.props.goBack(); }}
                        style={{ borderRadius: "0px", color: "white" }}
                    >
                        Voltar
                    </Button>
                </DialogActions>
            </>
        )
    }
}

export default watch(withRouter(PersonaList), { user: '' });