/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
// import '../../backoffice.scss';

import { Input, Slider, TextField } from '@material-ui/core';
import api from "../../../../../services/api";
const Globals = require("../../../../../Globals.json");

export default function RewardsInput({ rewardData, onUpdate }) {

    const [coins, setCoins] = useState(0);
    const [xp, setXp] = useState(0);

    const loadData = async () => {

        const rewards = rewardData ?? [];

        setCoins(rewards.find(i => i.dataAssetDetailId == 5)?.amount ?? 0);
        setXp(rewards.find(i => i.dataAssetDetailId == 7)?.amount ?? 0);
        updateData();

    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const updateData = () => {

        if (onUpdate) {
            const retData = [];
            retData.push({ dataAssetDetailId: 5, amount: coins });
            retData.push({ dataAssetDetailId: 7, amount: xp });
            // console.log( "Call update data!", retData );
            onUpdate(retData);
        }

    }

    useEffect(() => {
        loadData();
    }, []);

    useEffect(() => {
        updateData();
    }, [coins, xp]);


    return (
        <div className="rewards-setup-container">

            <div className="reward-container" style={{ width: '300px', display: 'flex', 'flexDirection': 'column' }}>
                <div style={{ flexGrow: 1, paddingTop: '16px', color: '#0000008a'}}>Pontos: {coins}</div>
                <Slider
                    key="xpkey"
                    onChange={(e, newValue) => {setCoins(newValue); }}
                    value={coins}
                    valueLabelFormat={(num) => num}
                    valueLabelDisplay="auto"
                    aria-labelledby="discrete-slider"
                    step={5}
                    marks
                    min={0}
                    max={100}
                />
            </div>
            <div className="reward-container" style={{ width: '300px', display: 'flex', 'flexDirection': 'column' }}>
                <div style={{ flexGrow: 1, paddingTop: '16px', color: '#0000008a'}}>XP: {xp}</div>
                <Slider
                    key="xpkey"
                    onChange={(e, newValue) => {setXp(newValue); }}
                    value={xp}
                    valueLabelFormat={(xp) => xp}
                    valueLabelDisplay="auto"
                    aria-labelledby="discrete-slider"
                    step={25}
                    marks
                    min={0}
                    max={200}
                />
            </div>

        </div>

    )

}
