import Ws from '@adonisjs/websocket-client';
import { getToken } from "./auth";


let ws = undefined;

const WebSocket = {};

WebSocket.connect = () => {
    if (!ws) {
        const url = `${process.env.REACT_APP_WS}`;

        if (url) {
            ws = Ws(url)
                .withApiToken(getToken())
                .connect();

            ws.on('open', () => {
                console.log('Connection initialized')
            });

            ws.on('close', () => {
                console.log('Connection closed')
            });
        }
    }

    return ws;
};

WebSocket.disconnect = () => {
    if(ws && ws.close){
        ws.close();
        ws = undefined;
    }
}

WebSocket.subscribe = (channel, event, handler) => {

    if (channel && event) {

        if (!ws) {
            WebSocket.connect();
            return WebSocket.subscribe(channel, event, handler);
        } else {
            let result = ws.getSubscription(channel);

            if (!result) {
                result = ws.subscribe(channel);
            }

            result.on(event, handler);

            result.on('error', (error) => {
                console.error(error)
            });

            return result;
        }
    }
};





export default WebSocket;