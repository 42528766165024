import { Checkbox, FormControlLabel, Slider, TextField } from '@material-ui/core';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import React, { useState } from 'react';
import { useParams } from 'react-router';
import '../../../backoffice.scss';
import Globals from '../../../Globals.json';
import Dialogs from '../../../services/dialogs';
import ModuleEditing from '../../components/backoffice/components/ModuleEditing/ModuleEditing';
import Button from '../../components/button/Button';

export default function EditCertificationTest() {
  const params = useParams();
  const baseOption = { id: -1, text: '', correct: false };
  const [, setParent] = useState({});
  const [, setContainer] = useState({});
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [isNew] = useState(params.certificationTestId === 'new');
  const [minScore, setMinScore] = useState(0);

  const onReceiveData = (container, children, parent) => {
    setContainer(container);
    const _questions = container.data.questions ?? [];
    // _questions.minScore = container.data.minScore ?? 0;
    _questions.forEach((question) => {
      const _options = [];
      for (let i = 0; i < 5; i++) {
        if (question.options[i]) {
          _options.push({ ...question.options[i], id: 1 + 1 });
        } else {
          _options.push({ ...baseOption, id: i + 1 });
        }
      }
      question.options = _options;
      question.weight = question.weight || 1;
    });

    setMinScore(container.data.minScore || 0);
    setQuestions(_questions);
    setBreadcrumbs([
      {
        label: 'Cursos',
        link: Globals.paths.backofficeCourses,
      },
      {
        label: parent.name,
        link: Globals.paths.backofficeEditCourse.replace(':courseId', parent.id),
      },
      {
        label: container.name,
      },
    ]);
  };

  const onNewData = (parent) => {
    setParent(parent);
    setBreadcrumbs([
      {
        label: 'Cursos',
        link: Globals.paths.backofficeCourses,
      },
      {
        label: parent.name,
        link: Globals.paths.backofficeEditCourse.replace(':courseId', parent.id),
      },
      {
        label: 'Nova Prova de Certificação',
      },
    ]);
  };

  let [, _forceUpdate] = useState();
  const forceUpdate = () => _forceUpdate({});

  const setQuestionProp = (question, prop, value) => {
    question[prop] = value;
    forceUpdate();
  };

  const setOptionProp = (question, option, prop, value) => {
    if (prop === 'correct') {
      question.options.forEach((item) => {
        item.correct = false;
      });
    }
    option[prop] = value;
    forceUpdate();
  };

  const deleteQuestion = (question) => {
    Dialogs.show('Confirmação de Exclusão', 'Você tem certeza de que deseja excluir a pergunta?', [
      {
        label: 'Cancelar',
      },
      {
        label: 'Sim',
        onClick: () => {
          questions.splice(questions.indexOf(question), 1);
          forceUpdate();
        },
      },
    ]);
  };

  const addQuestion = () => {
    const options = [];
    for (let i = 0; i < 5; i++) {
      options.push({ ...baseOption, id: i + 1 });
    }
    questions.push({ question: '', options, weight: 1 });
    forceUpdate();
  };

  const custom = (
    <>
      <div
        className="min-score-container"
        style={{ width: '500px', display: 'flex', flexDirection: 'column', marginTop: '15px' }}
      >
        <div style={{ flexGrow: 1, paddingTop: '16px', color: '#0000008a', fontSize: "16px" }}>
          Aproveitamento mínimo: {minScore}%
        </div>
        <Slider
          onChange={(_, newValue) => setMinScore(newValue)}
          value={minScore}
          aria-labelledby="discrete-slider"
          step={5}
          min={0}
          max={100}
          valueLabelDisplay="auto"
        />
      </div>
      <div className="field-label" style={{ marginTop: '35px' }}>
        Perguntas
      </div>
      <div className="questions-container">
        {questions.map((question, index) => (
          <div key={index} className="quiz-container">
            <div onClick={() => deleteQuestion(question)} className="delete-button">
              <DeleteForeverIcon style={{ fontSize: '20px' }} />
            </div>
            <div className="quiz-question">
              <TextField
                style={{ width: '100%' }}
                key="txtQuestion"
                label="Pergunta"
                multiline
                rowsMax={4}
                value={question.question}
                onChange={(e) => setQuestionProp(question, 'question', e.target.value)}
              />
            </div>
            <div
              className="weight-container"
              style={{ width: '500px', display: 'flex', flexDirection: 'column' }}
            >
              <div
                style={{ flexGrow: 1, paddingTop: '16px', color: '#0000008a', fontSize: '16px' }}
              >
                Peso: {question.weight}
              </div>
              <Slider
                onChange={(_, newValue) => setQuestionProp(question, 'weight', newValue)}
                value={question.weight}
                aria-labelledby="discrete-slider"
                step={0.05}
                min={0}
                max={10}
                valueLabelDisplay="auto"
              />
            </div>
            <div className="quiz-options-container">
              {question.options.map((option, index) => (
                <div key={index} className="quiz-option">
                  <TextField
                    style={{ width: '100%' }}
                    key="txtQuestion"
                    label={`Opção ${index}`}
                    value={option.text}
                    onChange={(e) => {
                      setOptionProp(question, option, 'text', e.target.value);
                    }}
                    InputProps={{
                      style: { color: option.correct ? '#4AAF98' : '#000' },
                    }}
                  />
                  <FormControlLabel
                    style={{ visibility: option.text.trim().length ? 'visible' : 'hidden' }}
                    control={
                      <Checkbox
                        checked={option.correct == true}
                        onChange={(e) => {
                          setOptionProp(question, option, 'correct', e.target.checked);
                        }}
                      />
                    }
                    label="Opção correta"
                  />
                </div>
              ))}
            </div>
          </div>
        ))}

        <div className="button-right-container">
          <Button
            icon="none"
            textAlign="center"
            type={'darkBlue'}
            height="40px"
            onClick={() => {
              addQuestion();
            }}
          >
            Adicionar Pergunta
          </Button>
        </div>
      </div>
    </>
  );

  const generateJson = () => {
    const json = [];
    questions.forEach((question) => {
      const q = { question: question.question, options: [], weight: question.weight };
      question.options.forEach((item, index) => {
        if (item.text.trim().length > 0) {
          q.options.push({
            id: index + 1,
            text: item.text,
            correct: item.correct,
          });
        }
      });
      json.push(q);
    });
    return json;
  };

  const onValidate = () => {
    for (let i = 0; i < questions.length; i++) {
      const q = questions[i];

      if (q.question.trim().length === 0) {
        Dialogs.show('Pergunta Necessária', 'É necessário digitar uma pergunta válida.');
        return false;
      }

      let countCorrect = 0;
      let countOptions = 0;
      q.options.forEach((item, index) => {
        if (item.text.trim().length > 0) {
          countOptions++;
          if (item.correct) {
            countCorrect++;
          }
        }
      });

      if (countOptions < 2) {
        Dialogs.show('Opções Inválidas', 'São necessárias ao menos 2 opções para a sua pergunta.');
        return false;
      }

      if (countCorrect == 0) {
        Dialogs.show('Opções Inválidas', 'Uma das opções precisa ser a opçao correta.');
        return false;
      }

      if (countCorrect > 1) {
        Dialogs.show('Opções Inválidas', 'Apenas uma das opções pode ser a opção correta.');
        return false;
      }
    }

    return true;
  };

  const onBeforeSaveData = (postData) => {
    postData.thumbnail = '-';
    postData.data.minScore = minScore;
    postData.data.questions = generateJson();
  };

  return (
    <ModuleEditing
      isNew={isNew}
      moduleId={params.certificationTestId}
      typeModuleId={8}
      parentId={params.courseId}
      title="Detalhe de Prova de Certificação"
      onReceiveData={onReceiveData}
      onBeforeSaveData={onBeforeSaveData}
      onValidate={onValidate}
      onNewData={onNewData}
      customDataFields={custom}
      hideThumbnailField
      hideRewards
      hideInfluences
      breadcrumbs={breadcrumbs}
    ></ModuleEditing>
  );
}
