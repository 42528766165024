import { Breadcrumbs, Grid, Hidden, Typography } from '@material-ui/core';
import { QueryBuilder, SignalCellularAlt } from '@material-ui/icons';
import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { dispatchSet, watch } from 'redux-easy/lib/redux-easy';
import CertIcon from '../../../assets/certIcon.svg';
import NotebookIcon from '../../../assets/notebookIcon.svg';
import TrendingUpIcon from '../../../assets/trendingUpIcon.svg';
import Globals from '../../../Globals.json';
import api from '../../../services/api';
import { formatMinutesDisplay, getDifficultyLabel } from '../../../services/util';
import AlertErrors from '../../components/alertErrors/alerts';
import Button from '../../components/button/Button';
import './courseDetail.scss';

class CourseDetail extends React.Component {
  state = {
    isLoading: false,
    courseId: null,
    courseName: '',
    courseDescription: '',
    courseThumbnail: '',
    courseData: {},
    alertError: '',
    userData: {},
    courseFinished: false,
    classes: [],
    hasCertificate: false,
    gridSize: 4,
  };

  async loadData(courseId) {
    this.setState({ isLoading: true });

    const postData = {
      container_id: courseId,
    };

    try {
      const response = await api.post(Globals.api.getModules, postData);
      const { container, children } = response.data;
      const courseClasses = [];

      children.forEach((item) => {
        courseClasses.push(item);
      });

      this.setState({
        isLoading: false,
        courseName: container.name,
        courseDescription: container.description,
        courseThumbnail: container.thumbnail,
        userData: container.user_module_data,
        courseFinished: container.user_module_data.finishedCourseAt,
        courseData: container.data,
        classes: courseClasses,
        hasCertificate: children.find((item) => item.type_module_id === Globals.types.certificate),
        gridSize: children.find((item) => item.type_module_id === Globals.types.certificate)
          ? 4
          : 6,
      });

      dispatchSet('courses.selectedCourse', container);
    } catch (error) {
      this.setState({ alertError: error.response.status });
      setTimeout(() => {
        this.setState({ alertError: '' });
      }, 3000);
    }
  }

  componentWillReceiveProps(nextProps) {
    const currentProductId = this.props.courseId;
    const nextProductId = nextProps.courseId;
    if (currentProductId !== nextProductId) {
      this.loadData(nextProductId);
    }
  }

  componentDidMount() {
    const { courseId } = this.props;
    this.setState({ courseId });
    this.loadData(courseId);
  }

  calcCompletion() {
    if (this.state.classes.length === 0) return 0;

    let count = 0;
    let total = 0;

    this.state.classes.forEach((item) => {
      if (
        item.type_module_id === Globals.types.class ||
        item.type_module_id === Globals.types.certificationTest
      ) {
        total++;
        if (item.user_module_data.finishedClassAt || item.user_module_data.finishedCourseAt)
          count++;
      }
    });

    return (count / total) * 100;
  }

  continueCourse() {
    if (this.state.courseFinished) {
      this.props.history.push(
        Globals.paths.certificateFinal.replace(':courseId', this.state.courseId)
      );
    } else {
      const nextClass = this.getNextUnfinishedClass();
      if (nextClass) {
        if (nextClass.type_module_id === Globals.types.class) {
          this.props.history.push(
            Globals.paths.class
              .replace(':courseId', this.state.courseId)
              .replace(':classId', nextClass.id)
          );
        } else if (nextClass.type_module_id === Globals.types.certificationTest) {
          this.props.history.push(
            Globals.paths.certificationTest.replace(':courseId', this.state.courseId)
          );
        } else if (nextClass.type_module_id === Globals.types.certificate) {
          this.props.history.push(
            Globals.paths.certificateFinal.replace(':courseId', this.state.courseId)
          );
        }
      }
    }
  }

  getNextUnfinishedClass() {
    for (let i = 0; i < this.state.classes.length; i++) {
      const item = this.state.classes[i];
      if (!item.user_module_data.finishedClassAt) {
        return item;
      }
    }
    return null;
  }

  getClassStatus(classItem) {
    if (classItem.user_module_data.finishedCourseAt) return 2;
    if (classItem.user_module_data.finishedClassAt) return -1;
    if (classItem === this.getNextUnfinishedClass()) return 0;
    return 1;
  }

  renderContinueButton() {
    const nextClass = this.getNextUnfinishedClass();

    if (!nextClass) return;

    let buttonLabel = nextClass.user_module_data.startedAt ? 'CONTINUAR' : 'COMEÇAR';

    if (this.state.courseFinished) {
      buttonLabel = 'VER CERTIFICADO';
    } else if (nextClass.type_module_id === Globals.types.certificationTest) {
      buttonLabel = 'FAZER PROVA';
    }

    if (this.state.courseFinished && !this.state.hasCertificate) {
      return null;
    }

    return (
      <div className="courseDetailContentButton">
        <Button type="darkBlue" onClick={() => this.continueCourse()}>
          {buttonLabel}
        </Button>
      </div>
    );
  }

  renderClassButton(classItem) {
    const status = this.getClassStatus(classItem);
    let buttonClass = status === 0 ? 'darkBlue ' : 'primary ';
    let buttonLabel = 'COMEÇAR';
    let url = Globals.paths.class
      .replace(':courseId', this.state.courseId)
      .replace(':classId', classItem.id);

    if (classItem.type_module_id === Globals.types.certificationTest) {
      url = Globals.paths.certificationTest.replace(':courseId', this.state.courseId);
    }

    if (
      classItem.type_module_id === Globals.types.certificationTest &&
      classItem.user_module_data.finishedAt
    ) {
      url = Globals.paths.certificationTestResult.replace(':courseId', this.state.courseId);
    }

    if (classItem.type_module_id === Globals.types.certificate) {
      url = Globals.paths.certificateFinal.replace(':courseId', this.state.courseId);
    }

    if (status === -1) {
      buttonLabel = 'REVER';
      buttonClass += ' g-cl-see-the-class-again';
    }

    if (classItem.type_module_id === Globals.types.class && status === 0) {
      buttonLabel = classItem.user_module_data.startedAt ? 'CONTINUAR' : 'COMEÇAR';
    }

    if (classItem.type_module_id === Globals.types.certificationTest) {
      buttonLabel = status === 2 ? 'VER RESULTADO' : 'FAZER A PROVA';
    }

    if (classItem.type_module_id === Globals.types.certificate) {
      buttonLabel = 'VER CERTIFICADO';
      buttonClass += ' g-cl-see-certificate';
    } else {
      buttonClass += ' g-cl-start-class';
    }

    if (status !== 1 || this.state.courseFinished) {
      return (
        <Button to={url} type={buttonClass}>
          {buttonLabel}
        </Button>
      );
    }
  }

  render() {
    if (this.state.isLoading) {
      return null;
    }

    return (
      <>
        <Breadcrumbs aria-label="breadcrumb" className="course_detail_breadCrumb">
          <Link color="inherit" to={Globals.paths.courses} className="course_detail_breadCrumbLink">
            Cursos
          </Link>
          <Typography color="textPrimary" className="course_detail_breadCrumbLabel">
            {this.state.courseName}{' '}
          </Typography>
        </Breadcrumbs>

        <Helmet>
          <title>Curso | Meu bolso em dia</title>
        </Helmet>

        <Grid container>
          <AlertErrors error={this.state.alertError} />
          <Grid item xs={12} className="courseDetailFeedContainer">
            <AlertErrors error={this.state.alertError} />
            <Grid item container className="courseDetailPageItem">
              <Hidden xsDown>
                <Grid
                  item
                  xs={4}
                  className="courseHeaderImage"
                  style={{ backgroundImage: `url(${this.state.courseThumbnail})` }}
                ></Grid>

                <Grid item xs={8} className="courseHeaderInfo">
                  <div className="courseHeaderInfoLabel">CURSO</div>
                  <div xs={12} className="courseHeaderInfoTitle">
                    {this.state.courseName}
                  </div>
                  <Grid container direction="row" className="courseDetailExtraInfo">
                    <div className="courseDetailExtraInfoBox">
                      {' '}
                      <QueryBuilder className="courseIcon" />
                      {formatMinutesDisplay(this.state.courseData.duration).full}
                    </div>
                    <div className="courseDetailExtraInfoBox">
                      {' '}
                      <SignalCellularAlt className="courseIcon" />
                      {getDifficultyLabel(this.state.courseData.difficulty)}
                    </div>
                    <div className="courseExtraInfoBoxProgressBar">
                      <div
                        className="courseExtraInfoBoxProgressBarFill"
                        style={{ width: this.calcCompletion() + '%' }}
                      ></div>
                    </div>
                    {!this.state.userData.startedAt && (
                      <div className="courseDetailExtraInfoBox">Não Iniciado</div>
                    )}
                  </Grid>
                  <div xs={12}>{this.renderContinueButton()}</div>
                </Grid>
              </Hidden>

              <Hidden smUp>
                <Grid item xs={12} className="courseHeaderImage">
                  <div className="courseContainerImg">
                    <img
                      src={this.state.courseThumbnail}
                      alt={'Imagem do curso'}
                      className="imgCourseSmUp"
                    />
                  </div>
                </Grid>

                <Grid item xs={8} className="courseHeaderInfo">
                  <div className="courseHeaderInfoLabel">CURSO</div>
                  <div xs={12} className="courseHeaderInfoTitle">
                    {this.state.courseName}
                  </div>
                  <Grid container direction="row" className="extraInfo">
                    <div className="courseDetailExtraInfoBox">
                      {' '}
                      <QueryBuilder className="courseIcon" />
                      {formatMinutesDisplay(this.state.courseData.duration).full}
                    </div>
                    <div className="courseDetailExtraInfoBox">
                      {' '}
                      <SignalCellularAlt className="courseIcon" />
                      {getDifficultyLabel(this.state.courseData.difficulty)}
                    </div>
                    {!this.state.userData.startedAt && (
                      <div className="courseDetailExtraInfoBox">Não Iniciado</div>
                    )}
                  </Grid>
                  <div className="courseExtraInfoBoxProgressBar" style={{ marginTop: '-12px' }}>
                    <div
                      className="courseExtraInfoBoxProgressBarFill"
                      style={{ width: this.calcCompletion() + '%' }}
                    ></div>
                  </div>
                </Grid>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    width: '100%',
                    marginTop: '-15px',
                    paddingRight: '25px',
                    borderBottom: '#F4F4F4 2px solid',
                  }}
                >
                  {this.renderContinueButton()}
                </div>
              </Hidden>

              <Grid item xs={12} className="courseDescription">
                <div className="courseDescriptionLabel">Resumo do curso</div>
                <div className="courseDescriptionText">{this.state.courseDescription}</div>
              </Grid>

              <Hidden smUp>
                <Grid item xs={this.state.gridSize} className="courseCard">
                  <div className="courseCardImage">
                    <img src={NotebookIcon} alt="Caderno" className="courseCardImageIcon" />
                  </div>
                  <div className="courseCardLabel">Estude um tema</div>
                </Grid>
                <Grid item xs={this.state.gridSize} className="courseCard">
                  <div className="courseCardImage">
                    <img src={TrendingUpIcon} alt="Evolução" className="courseCardImageIcon" />
                  </div>
                  <div className="courseCardLabel">Evolua seu conhecimento</div>
                </Grid>
                {this.state.hasCertificate ? (
                  <Grid item xs={this.state.gridSize} className="courseCard">
                    <div className="courseCardImage">
                      <img src={CertIcon} alt="Certificado" className="courseCardImageIcon" />
                    </div>
                    <div className="courseCardLabel">Certifique-se!</div>
                  </Grid>
                ) : null}
              </Hidden>

              <Hidden xsDown>
                <Grid item xs={this.state.gridSize} className="courseCard">
                  <div className="courseCardImage">
                    <img src={NotebookIcon} alt="Caderno" className="courseCardImageIcon" />
                  </div>
                  <div className="courseCardLabel">Estude um tema</div>
                  <div className="courseCardText">
                    Por curiosidade, ou para alcançar um objetivo, seja ele pessoal ou profissional.
                    Fortaleça a base de seu conhecimento em finanças pessoais.
                  </div>
                </Grid>
                <Grid item xs={this.state.gridSize} className="courseCard">
                  <div className="courseCardImage">
                    <img src={TrendingUpIcon} alt="Evolução" className="courseCardImageIcon" />
                  </div>
                  <div className="courseCardLabel">Evolua seu conhecimento</div>
                  <div className="courseCardText">
                    Amplie seus horizontes de conhecimento, evolua e atinja novos patamares. Foque e
                    se aprofunde nos assuntos mais relevantes na trilha de Finanças Pessoais.
                  </div>
                </Grid>
                {this.state.hasCertificate ? (
                  <Grid item xs={this.state.gridSize} className="courseCard">
                    <div className="courseCardImage">
                      <img src={CertIcon} alt="Certificado" className="courseCardImageIcon" />
                    </div>
                    <div className="courseCardLabel">Certifique-se!</div>
                    <div className="courseCardText">
                      Complete este curso, receba o certificado de conclusão e destaque-se!
                    </div>
                  </Grid>
                ) : null}
              </Hidden>

              <Grid item xs={12} className="courseStructure">
                <div className="courseStructureLabel">Estrutura do curso</div>
                <div className="courseClassList">
                  {this.state.classes.map((item, index) => (
                    <div key={index} className="classItem">
                      <div className="classItemDotContainer">
                        <div
                          className={
                            this.state.courseFinished || item.user_module_data.startedAt
                              ? 'classItemDotGreen'
                              : 'classItemDot'
                          }
                        />
                      </div>

                      <div className="classItemBox">
                        <div className="classItemBoxInfo">
                          <div className="classItemInfoLabel">
                            {item.type_module_id === Globals.types.class && (
                              <span>LIÇÃO {index + 1}</span>
                            )}
                            {item.type_module_id === Globals.types.certificationTest && (
                              <span>TESTE DE CERTIFICAÇÃO</span>
                            )}
                            {item.type_module_id === Globals.types.certificate && (
                              <span>CERTIFICADO</span>
                            )}
                          </div>
                          <div className="classItemInfoTitle">{item.name}</div>
                          <Grid container direction="row">
                            {item.user_module_data.status != null && (
                              <div className="courseExtraInfoBoxProgressBar">
                                <div
                                  className="courseExtraInfoBoxProgressBarFill"
                                  style={{ width: item.user_module_data.status + '%' }}
                                ></div>
                              </div>
                            )}
                            <Hidden smUp>
                              <div className="classItemBoxButton">
                                {this.renderClassButton(item)}
                              </div>
                            </Hidden>
                            {!item.user_module_data.startedAt && (
                              <div className="courseDetailExtraInfoBox">Não Iniciado</div>
                            )}
                            {!!item.data.duration && (
                              <>
                                <div className="courseDetailExtraInfoBox">
                                  {formatMinutesDisplay(item.data.duration).full}
                                </div>
                                <div className="courseDetailExtraInfoBox">
                                  {getDifficultyLabel(item.data.difficulty)}
                                </div>
                              </>
                            )}
                          </Grid>
                        </div>
                        <Hidden xsDown>
                          <div className="classItemBoxButton">{this.renderClassButton(item)}</div>
                        </Hidden>
                      </div>
                    </div>
                  ))}
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </>
    );
  }
}

export default watch(CourseDetail, { courses: '' });
