import React, { Component } from 'react';
import { Button, Container, DialogActions, DialogContent, Grid, Hidden, IconButton, Paper, Typography } from '@material-ui/core';
import { watch } from 'redux-easy/lib/redux-easy';
import { withRouter } from 'react-router-dom';
import { Helmet } from "react-helmet";
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import CloseIcon from '@material-ui/icons/Close';
import './incomesTypeSelect.scss';

class IncomesTypeSelect extends Component {

    step = 2;

    state = {
        selectedType: null,
    }

    getProgressWidth() {
        const w = this.step * 25 + '%';
        return w === '100%' ? '101%' : w;
    }

    componentDidMount = () => {
        this.props.changeWidth()
    }

    changeType = (value) => {
        this.setState({ selectedType: value });
    }

    handleClose = () => {
        if (this.props.handleClose) {
            this.props.handleClose();
        }
    }

    render() {
        return (

            <Grid className='incomes-types-select'>
                <MuiDialogTitle disableTypography className="dialog-header">
                    <h2>Cadastro de receitas</h2>
                    <IconButton color="secondary" aria-label="close" className="close-button" onClick={this.handleClose}>
                        <CloseIcon />
                    </IconButton>
                </MuiDialogTitle>
                <Grid container className="progress-floater">
                    <div className="progress-container">
                        <div className="progress" style={{ width: this.getProgressWidth() }}></div>
                    </div>
                </Grid>
                <Grid className="container" container item xs={12} >
                    <DialogContent className="dialog-content" >

                        <Grid container item xs={12} direction="column" justify="center" alignItems="center" className="content-holder">
                            <h6>escolha um tipo de <span>Receita</span> para cadastrar</h6>
                            <Grid container item xs={12} direction="row" className={this.state.selectedType === 1 ? 'card-container selected' : 'card-container'} onClick={() => this.changeType(1)} >
                                <Grid container item xs={8} direction="column" justify="flex-start" className='text-container' >
                                    <h5>Receitas fixas</h5>
                                    <p>São aquelas que entram todo o mês como salário, bônus, o aluguel de um imóvel.</p>
                                </Grid>
                                <Grid container item xs={4} direction="column" justify="center">
                                    <img src="/images/expenses-select-coin.png" width="100%" alt="" />

                                </Grid>
                            </Grid>
                            <Grid container item xs={12} direction="row" className={this.state.selectedType === 2 ? 'card-container selected' : 'card-container'} onClick={() => this.changeType(2)} >
                                <Grid container item xs={8} direction="column" justify="flex-start" className='text-container'>
                                    <h5>Receitas variáveis</h5>
                                    <p>São aquelas pontuais como um bico temporário, ou um valor que receberá de um amigo etc.</p>
                                </Grid>
                                <Grid container item xs={4} direction="column" justify="center">
                                    <img src="/images/expenses-select-coin.png" width="100%" alt="" />

                                </Grid>
                            </Grid>

                        </Grid>


                    </DialogContent >
                </Grid>
                <DialogActions className="dialog-footer space-between">
                    <Button
                        id="g-pm-start-test"
                        className={`g-pm-start-test`}
                        color="primary"
                        variant="outlined"
                        onClick={() => { this.props.goBack() }}
                        style={{ borderRadius: "0px", width: "120px" }}

                    >
                        voltar
                    </Button>
                    <Button
                        id="g-pm-start-test"
                        className={`g-pm-start-test`}
                        variant="contained"
                        color="primary"
                        onClick={() => this.props.showContent({ to: 'incomes-select-items', from: this.props.page, data: { incomesType: this.state.selectedType } })}
                        style={{ borderRadius: "0px", color: "white", width: "120px" }}
                        disabled={this.state.selectedType === null}
                    >
                        continuar
                    </Button>
                </DialogActions>
            </Grid>
        )
    }
}

export default watch(withRouter(IncomesTypeSelect), { user: '' });