import axios from "axios";
import { getToken } from "./auth";

const api = axios.create({
  // baseURL: "http://localhost:3333/"
  // baseURL: "https://fbb-api.hyst.dev/"
  // baseURL: "https://pefpi.meubolsoemdia.com.br/"
  // baseURL: "https://pefapipi.meubolsoemdia.com.br"
  baseURL: `${process.env.REACT_APP_API}`
});

api.interceptors.request.use(async config => {
  const token = getToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

api.interceptors.response.use(function (response) {
  return response;
}, function (error) {

  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    //console.log('DATA', error.response.data);
    //console.log('STATUS', error.response.status);
    //console.log('HEADERS', error.response.headers);
  } else if (error.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
    //console.log('REQUEST', error.request);
  } else {
    // Something happened in setting up the request that triggered an Error
    //console.log('Error', error.message);
  }


  if (error.toJSON().message === 'Network Error') {
    if (error.response) {
      error.response.status = "Não foi possível se conectar ao servidor, por favor, tente novamente mais tarde.";
    }
    else {
      error.response = { status: "Não foi possível se conectar ao servidor, por favor, tente novamente mais tarde." };
    }
  }


  return Promise.reject(error);
});

export default api;