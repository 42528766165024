import { TextField } from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import '../../../backoffice.scss';

import ModuleEditing from '../../components/backoffice/components/ModuleEditing/ModuleEditing';

import Globals from "../../../Globals.json";
import Button from '../../components/button/Button';
import FileUploader from '../../components/backoffice/components/FileUploader/FileUploader';


export default function EditCertificate() {

  const params = useParams();

  const [parent, setParent] = useState({});
  const [bgImage, setBgImage] = useState('');
  const [breadcrumbs, setBreadcrumbs] = useState([]);

  const [isNew, setIsNew] = useState(params.certificateId === "new");

  const onReceiveData = (container, children, parent) => {

    setBgImage( container.data.image ?? '' );

    setBreadcrumbs([
      {
        label: "Cursos",
        link: Globals.paths.backofficeCourses
      },
      {
        label: parent.name,
        link: Globals.paths.backofficeEditCourse.replace(":courseId", parent.id)
      },
      {
        label: container.name
      }
    ]);

  }

  const onNewData = (parent) => {

    setParent(parent);

    setBreadcrumbs([
      {
        label: "Cursos",
        link: Globals.paths.backofficeCourses
      },
      {
        label: parent.name,
        link: Globals.paths.backofficeEditCourse.replace(":courseId", parent.id)
      },
      {
        label: "Novo Certificado"
      }
    ]);
  }

  const onBeforeSaveData = (postData) => {
    postData.thumbnail = "-";
    postData.data.image = bgImage;
  }

  return (

    <ModuleEditing
      isNew={isNew}
      moduleId={params.certificateId}
      typeModuleId={9}
      parentId={params.courseId}
      title="Detalhe de Certificado"
      onReceiveData={onReceiveData}
      onBeforeSaveData={onBeforeSaveData}
      onNewData={onNewData}
      hideThumbnailField
      hideRewards
      hideInfluences
      breadcrumbs={breadcrumbs}
    >

      <div style={{ marginTop: "5px", fontSize: "12px", color: "#269" }}>
        * Utilize a expressão <b><i>#name#</i></b> na descrição do certificado para incluir o nome do usuário.
      </div>
      <div className="field-label" style={{marginTop: "10px"}}>Imagem de Fundo</div>
      <FileUploader onChange={ (url) => setBgImage( url ) } folder="certificates" imageUrl={bgImage} maxFileSize={5} />

    </ModuleEditing>

  );
}

