import React from 'react';
// import './backoffice.scss';

import { watch } from 'redux-easy/lib/redux-easy';

import EditUser from '../components/backoffice/Users/EditUsers/EditUsers';
import HomeBackoffice from '../components/backoffice/Home/Home';
import Login from '../components/backoffice/Login/Login';
import RegisterProfile from '../components/backoffice/RegisterBo/Profile/Profile';
import TypeProfile from '../components/backoffice/RegisterBo/TypeProfile/TypeProfile';
import Users from '../components/backoffice/Users/Users';
import EditRoles from '../components/backoffice/UserPerfil/EditRoles/EditRole';
import UserPerfil from '../components/backoffice/UserPerfil/userPerfil';

import NavigationAppBar from '../components/backoffice/AppBar/NavigationAppBar';

import { Grid } from '@material-ui/core';
import { Route, Switch } from 'react-router-dom';

import Courses from '../Pages/backoffice/Courses';
import Quizzes from '../Pages/backoffice/Quizzes';
import MiniQuiz from '../Pages/backoffice/MiniQuiz';
import EditCourseDetails from '../Pages/backoffice/EditCourseDetails';
import EditClassDetails from '../Pages/backoffice/EditClassDetails';
import EditClassContentArticle from '../Pages/backoffice/EditClassContentArticle';
import EditClassContentVideo from '../Pages/backoffice/EditClassContentVideo';
import EditClassContentAudio from '../Pages/backoffice/EditClassContentAudio';
import EditClassContentStory from '../Pages/backoffice/EditClassContentStory';
import EditClassContentQuiz from '../Pages/backoffice/EditClassContentQuiz';
import EditCertificationTest from '../Pages/backoffice/EditCertificationTest';
import EditCertificate from '../Pages/backoffice/EditCertificate';
import Missions from '../Pages/backoffice/Missions';
import Articles from '../Pages/backoffice/Articles';
import EditArticle from '../Pages/backoffice/EditArticle';
import EditQuiz from '../Pages/backoffice/EditQuiz';
import EditMiniQuiz from '../Pages/backoffice/EditMiniQuiz';

const Globals = require("../../Globals.json");

class BackofficeLayout extends React.Component {

  render() {
    return (
      <Grid item sm={12} xs={12}>
        <NavigationAppBar />

        <Switch>
          <Route exact path={Globals.paths.backofficeLogin}>
            <Login history={this.props.history} />
          </Route>

          <Route exact path={Globals.paths.backofficeHome}>
            <HomeBackoffice history={this.props.history} />
          </Route>

          {/* Listagens de Módulos */}
          <Route exact path={Globals.paths.backofficeCourses} component={Courses} />
          <Route exact path={Globals.paths.backofficeQuizzes} component={Quizzes} />
          <Route exact path={Globals.paths.backofficeMissions} component={Missions} />
          <Route exact path={Globals.paths.backofficeArticles} component={Articles} />
          <Route exact path={Globals.paths.backofficeMiniQuiz} component={MiniQuiz} />

          {/* Edição de Módulos */}
          <Route exact path={Globals.paths.backofficeEditCourse} component={EditCourseDetails} />
          <Route exact path={Globals.paths.backofficeEditClass} component={EditClassDetails} />
          <Route exact path={Globals.paths.backofficeEditClassContentArticle} component={EditClassContentArticle} />
          <Route exact path={Globals.paths.backofficeEditClassContentVideo} component={EditClassContentVideo} />
          <Route exact path={Globals.paths.backofficeEditClassContentAudio} component={EditClassContentAudio} />
          <Route exact path={Globals.paths.backofficeEditClassContentStory} component={EditClassContentStory} />
          <Route exact path={Globals.paths.backofficeEditClassContentQuiz} component={EditClassContentQuiz} />
          <Route exact path={Globals.paths.backofficeEditCertificationTest} component={EditCertificationTest} />
          <Route exact path={Globals.paths.backofficeEditCertificate} component={EditCertificate} />
          <Route exact path={Globals.paths.backofficeEditArticle} component={EditArticle} />
          <Route exact path={Globals.paths.backofficeEditQuiz} component={EditQuiz} />
          <Route exact path={Globals.paths.backofficeEditMiniQuiz} component={EditMiniQuiz} />

          {/* Gestão de Usuário */}
          <Route exact path={Globals.paths.backofficeUsers}>
            <Users history={this.props.history} />
          </Route>
          <Route exact path={Globals.paths.backofficeUsersPerfil}>
            <UserPerfil history={this.props.history} />
          </Route>
          <Route exact path={Globals.paths.backofficeUsersEdit}>
            <EditUser history={this.props.history} />
          </Route>
          <Route exact path={Globals.paths.backofficeProfileRegister}>
            <RegisterProfile history={this.props.history} />
          </Route>
          <Route exact path={Globals.paths.backofficeTypeRegister}>
            <TypeProfile history={this.props.history} />
          </Route>
          <Route exact path={Globals.paths.backofficeRolesEdit}>
            <EditRoles history={this.props.history} />
          </Route>
        </Switch>

        <div className="backoffice_footer" />
      </Grid>
    )
  }
}

export default watch((BackofficeLayout), { user: '' });

 