import React, { Component } from 'react';
import PropTypes from 'prop-types';

function percentageValidation (isRequired) {
    return function (props, propName) {
        const prop = props[propName];
        if (prop == null) {
            if (isRequired) {
                throw new Error('Percentage is a required.');
            }
        } else {
            if (typeof prop !== 'number') {
                return new Error(
                    'Invalid percentage. Must be a number between 0 and 100.'
                );
            }
            if (prop < 0 || prop > 100) {
                return new Error(
                    'Invalid percentage. Must be a number between 0 and 100.'
                );
            }
        }
    };
}

class RectProgress extends Component {

    constructor (props) {
        super(props);
        this.state = {
            barWidth: this.props.width,
        }
    }

    static propTypes = {
        height: PropTypes.number,
        itemHeight: PropTypes.number,
        width: PropTypes.string,
        background: PropTypes.string,
        backgroundRoundness: PropTypes.number,
        fillColor: PropTypes.string,
        fillRoundness: PropTypes.number,
        showValueLabel: PropTypes.bool,
        labelColor: PropTypes.string,
        typeValue: PropTypes.oneOf(['percent', 'value']),
        percentage: percentageValidation(true),
        cssClass: PropTypes.string,
        cssStyle: PropTypes.object,
        id: PropTypes.string,
    };

    static defaultProps = {
        height: 20,
        itemHeight: 16,
        width: '100%',
        background: '#ececec',
        backgroundRoundness: 0,
        fillColor: '#ccc',
        fillRoundness: 0,
        showValueLabel: false,
        labelColor: '#ccc',
        typeValue: 'value',
        percentage: percentageValidation(true),
        cssClass: '',
        cssStyle: {},
        id: '1',
    };

    debounce = (func, wait, immediate) => {
        let timeout;
        return function () {
            let context = this, args = arguments;
            let later = function () {
                timeout = null;
                if (!immediate) func.apply(context, args);
            };
            let callNow = immediate && !timeout;
            clearTimeout(timeout);
            timeout = setTimeout(later, wait);
            if (callNow) func.apply(context, args);
        };
    };

    calculateBarWidth = this.debounce(() => {
        let el = document.getElementById(`bar-container-${this.props.id}`);
        if (el)
            this.setState({ barWidth: el.clientWidth });
    }, 250)

    componentDidMount () {
        this.calculateBarWidth();
        window.addEventListener('resize', this.calculateBarWidth)
    }

    componentWillUnmount () {
        window.removeEventListener('resize', this.calculateBarWidth)
    }

    componentDidUpdate (prevProps, prevState) {
        if (prevProps.width !== this.props.width) {
            this.calculateBarWidth();
        }
    }

    render () {
        const percentageValue = this.props.percentage;
        const padding = (this.props.height - this.props.itemHeight) / 2;
        const width = (this.state.barWidth - padding) + 'px'

        let label = <span></span>;

        if (this.props.showValueLabel) {
            label = (
                <span
                    className='progress-bar-label'
                    style={{
                        right: (percentageValue + '').length * -8 + padding + 'px',
                        top: '-20px',
                        textAlign: 'center',
                        position: 'absolute',
                        color: this.props.labelColor,
                    }}
                >
                    {this.props.typeValue === 'value' ? percentageValue : percentageValue + '%'}
                </span>
            )
        }

        return (
            <div id={`bar-container-${this.props.id}`} className={`bar-container ${this.props.cssClass}`}
                style={{
                    position: 'relative',
                    height: this.props.height,
                    width: this.props.width,
                    backgroundColor: this.props.background,
                    borderRadius: this.props.backgroundRoundness,
                    display: 'flex',
                    alignItems: 'center',
                    padding: `0 ${padding}px`,
                }}>

                <div className="label-container"
                    style={{
                        transition: 'width .3s ease 0s',
                        width: percentageValue + '%',
                        height: 0,
                        position: 'absolute',
                        top: 0,
                    }}>
                    {this.props.showValueLabel && (label)}
                    {this.props.children}
                </div>

                <div className="progress-bar"
                    style={{
                        transition: 'width .3s ease 0s',
                        width: percentageValue + '%',
                        height: this.props.itemHeight,
                        borderRadius: this.props.fillRoundness,
                        position: 'relative',
                        overflow: 'hidden',
                    }}>
                    <div className="progress-bar-bg"
                        style={{ backgroundColor: this.props.fillColor, height: this.props.itemHeight, width: width, ...this.props.cssStyle }}>
                    </div>
                    {this.props.children}
                </div>
            </div >
        );
    }
}

export default RectProgress;