import React, { Component } from 'react';
import { Button, CircularProgress, Container, Grid } from '@material-ui/core';
import { watch } from 'redux-easy/lib/redux-easy';
import './smallProfileGauge.scss';
import Globals from '../../../Globals.json';
import SemiCircleProgress from '../../../logged_out/components/progress/semi-circle/SemiCircleProgress';

class SmallProfileGauge extends Component {
  constructor(props) {
    super(props);
    this.state = {
      averageBR: 100,
      indexScore: 100,
      outerDiameter: 300,
      innerDiameter: 240,
      containerWidth: 0,
    };
  }

  loadData = () => {
    let indexData = {
      indexScore: 0,
    };

    if (!this.props.data) {
      const userProfile = this.props.user.profile;
      indexData = userProfile.indexData;
    } else {
      indexData = this.props.data;
    }

    if (indexData) {
      if (!this.props.avoidLoading) {
        setTimeout(() => {
          this.setState({
            averageBR: Globals.data.averageBRIndex,
            indexScore: indexData.indexScore,
          });
        }, 1500);
      }
      else{
        this.setState({
            averageBR: Globals.data.averageBRIndex,
            indexScore: indexData.indexScore,
          });
      }
    }
  };

  componentDidMount = () => {
    this.loadData();
    this.calculateBarWidth();
    window.addEventListener('resize', this.calculateBarWidth);
  };

  debounce = (func, wait, immediate) => {
    let timeout;
    return function () {
      let context = this,
        args = arguments;
      let later = function () {
        timeout = null;
        if (!immediate) func.apply(context, args);
      };
      let callNow = immediate && !timeout;
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
      if (callNow) func.apply(context, args);
    };
  };

  calculateBarWidth = this.debounce(() => {
    const el = document.getElementById(`small-profile-container-${this.props.id}`);
    if (el) {
      const outerDiameter = el.clientWidth - 30;
      const innerDiameter = Math.round(outerDiameter * 0.8);
      this.setState({ containerWidth: el.clientWidth, outerDiameter, innerDiameter });
    }
  }, 250);

  componentWillUnmount() {
    window.removeEventListener('resize', this.calculateBarWidth);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.width !== this.props.width) {
      this.calculateBarWidth();
    }
    if (prevProps.user !== this.props.user) {
      this.loadData();
    }
  }

  render() {
    const defs = [
      <linearGradient key="gradientBR" id="gradientBR" cx="50%" cy="50%" r="50%" fx="50%" fy="50%">
        <stop offset="50%" stopColor="#a6d2ae" />
        <stop offset="100%" stopColor="#ddd364" />
      </linearGradient>,
      <linearGradient key="gradient" id="gradient" cx="50%" cy="50%" r="50%" fx="50%" fy="50%">
        <stop offset="0%" stopColor="#004178" />
        <stop offset="100%" stopColor="#4EAF98" />
      </linearGradient>,
      <linearGradient key="gradientBRText" id="gradientBRText" x1="0%" y1="0%" x2="0%" y2="100%">
        <stop offset="0%" stopColor="#d4d416" />
        <stop offset="100%" stopColor="#4EAF98" />
      </linearGradient>,
      <linearGradient key="gradientText" id="gradientText" x1="0%" y1="0%" x2="0%" y2="100%">
        <stop offset="0%" stopColor="#004178" />
        <stop offset="100%" stopColor="#4EAF98" />
      </linearGradient>,
    ];

    return (
      <Grid
        container
        item
        xs={12}
        direction="row"
        justify="center"
        alignItems="center"
        className="p5 small-profile-gauge"
        id={`small-profile-container-${this.props.id}`}
      >
        <Grid container item xs={12} direction="column" justify="center" alignItems="center">
          <div
            className="chart-container comparator"
            style={{
              width: this.state.outerDiameter + 'px',
              height: Math.round(this.state.outerDiameter / 2 + 5) + 'px',
            }}
          >
            <SemiCircleProgress
              cssClass={'outer'}
              diameter={this.state.outerDiameter}
              defs={defs}
              strokeWidth={Math.round(this.state.innerDiameter * 0.05)}
              background={'url(#gradientBR)'}
              percentage={this.state.averageBR}
            ></SemiCircleProgress>
            <SemiCircleProgress
              cssClass={'inner'}
              cssStyle={{ marginLeft: Math.round(this.state.innerDiameter / -2) + 'px' }}
              diameter={this.state.innerDiameter}
              strokeWidth={Math.round(this.state.outerDiameter * 0.08)}
              background={'url(#gradient)'}
              percentage={this.state.indexScore}
            ></SemiCircleProgress>
            <div
              className="inner-description"
              style={{ fontSize: Math.round(0.145 * this.state.innerDiameter) + 'px' }}
            >
              <svg
                preserveAspectRatio="xMinYMax meet"
                height={this.state.outerDiameter / 2}
                width={this.state.outerDiameter}
                viewBox={`0 0 ${this.state.outerDiameter} ${this.state.outerDiameter / 2}`}
              >
                <text
                  x={this.state.outerDiameter / 2}
                  textAnchor="middle"
                  y={(this.state.outerDiameter / 2) * 0.8}
                  fill="url(#gradientText)"
                >
                  {this.state.indexScore}
                </text>
              </svg>
            </div>
            <div
              className="outer-description"
              style={{ fontSize: Math.round(0.08 * this.state.outerDiameter) + 'px' }}
            >
              <svg
                preserveAspectRatio="xMinYMax meet"
                height={this.state.outerDiameter / 2}
                width={this.state.outerDiameter}
                viewBox={`0 0 ${this.state.outerDiameter} ${this.state.outerDiameter / 2}`}
              >
                <text
                  x={this.state.outerDiameter / 2}
                  textAnchor="middle"
                  y={(this.state.outerDiameter / 2) * 0.95}
                  fill="url(#gradientBRText)"
                >
                  {this.state.averageBR}
                </text>
              </svg>
            </div>
          </div>
        </Grid>
        <Grid container item xs={12} direction="row" justify="center" alignItems="center">
          <div className="chart-legend your-result">
            <span className="legend-icon"></span>
            <span className="legend-text">Meu resultado</span>
          </div>
          <div className="chart-legend br-result">
            <span className="legend-icon"></span>
            <span className="legend-text">Média Brasileira</span>
          </div>
        </Grid>
      </Grid>
    );
  }
}

export default watch(SmallProfileGauge, { user: '' });
