import React, { useEffect, useRef, useState } from 'react';
import { Grid, Table, TableBody, TableCell, TableContainer, CircularProgress, TableRow, TableHead, TableSortLabel, FormControl, Select, MenuItem, TextField, Typography, Slider } from '@material-ui/core';
// import '../../backoffice.scss';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import DragHandleIcon from '@material-ui/icons/DragHandle';


import api from "../../../../../services/api";
const Globals = require("../../../../../Globals.json");

export default function ChildrenListing({ label = '',
    columns = [],
    items = [],
    onClick,
    headless = false,
    ordenable = false,
    onReorder = null }) {

    const onItemClick = (item) => {
        if (onClick) onClick(item);
    }

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    const onDragEnd = (result) => {
        // dropped outside the list
        if (!result.destination) {
            return;
        }

        const reorderedItems = reorder(
            [...items],
            result.source.index,
            result.destination.index
        );

        if (onReorder) onReorder(reorderedItems);
        // this.setState({
        //     items
        // });
    }

    const renderChildrenTable = (provided = null) => <>
        <Table
            ref={provided && provided.innerRef}
            className="table-list-items"
            style={{ height: "10px", minHeight: "10px", marginTop: "5px" }}
        >
            {!headless &&
                <TableHead>
                    <TableRow>
                        {ordenable && <TableCell key={'dragger_header'} style={{width: "50px"}} ></TableCell>}
                        {columns.map((col, index) => (
                            <TableCell key={index}>{col.label}</TableCell>
                        ))}
                    </TableRow>
                </TableHead>
            }

            <TableBody
            >
                {items.map((item, index) => (
                    <>
                        {ordenable ?
                            <Draggable key={"td_" + item.id} draggableId={"td_" + item.id} index={index}>
                                {(provided, snapshot) => (
                                    <TableRow
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        className="tb-data-row"
                                        hover
                                        key={index}
                                    >
                                        <TableCell
                                            style={{width: "50px"}}
                                            {...provided.dragHandleProps}
                                            key={'dragger_' + item.id}
                                        >
                                            <DragHandleIcon />
                                        </TableCell>
                                        {columns.map((col, index) => (
                                            <TableCell key={index}
                                                onClick={() => onItemClick(item)}
                                                className="tb-data-cell" align="left">
                                                <span
                                                    style={{ opacity: item.type_module_status_id === 1 ? 1 : .35 }}
                                                >
                                                    {item[col.field]}
                                                </span>
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                )}
                            </Draggable>
                            :
                            <TableRow
                                className="tb-data-row"
                                hover
                                key={index}
                            >
                                {columns.map((col, index) => (
                                    <TableCell key={index}
                                        onClick={() => onItemClick(item)}
                                        className="tb-data-cell" align="left">
                                        <span
                                            style={{ opacity: item.type_module_status_id === 1 ? 1 : .35 }}
                                        >
                                            {item[col.field]}
                                        </span>
                                    </TableCell>
                                ))}
                            </TableRow>
                        }
                    </>
                ))}
                {ordenable && provided.placeholder}
            </TableBody>
        </Table>
    </>

    return (
        <>
            <div className="table_user_contentTexts" style={{ marginTop: "30px" }}>
                <span className="table_user_textList">{label}</span>
            </div>

            <TableContainer>
                {ordenable ?
                    <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable droppableId="droppable">
                            {(provided, snapshot) => (
                                renderChildrenTable(provided)
                            )}
                        </Droppable>
                    </DragDropContext>
                    :
                    renderChildrenTable()
                }
            </TableContainer>
        </>
    )

}
