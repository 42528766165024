import React from 'react';
import './styles/objectiveList.scss';

import { Skeleton } from '@material-ui/lab';
import { watch } from 'redux-easy/lib/redux-easy';
import { withRouter } from 'react-router-dom'
import { Hidden, Box, Grid } from '@material-ui/core';

import api from '../../../services/api';
import AlertErrors from '../../components/alertErrors/alerts';
import FeedItem from '../feed/FeedItem';
import FeedLine from '../feed/FeedLine';
import InitialModalQuiz from '../quiz/InitialModalQuiz';
import SwipeableViews from "react-swipeable-views";
import ObjectiveItem from './ObjectiveItem';
import Pagination from "../../components/pagination/Pagination";
import { enumTypeUserStatuses } from '../../../services/enums';

const QuestionarioIndice = require("../../../QuestionarioIndice.json");

const customStyle = {
  root: {
    padding: '0px 460px 0px 0px',
    width: "600px",
  },

  slideContainer: {
    overflow: 'visible',
  },
};

const Globals = require("../../../Globals.json");

class ObjectiveList extends React.Component {

  state = {
    isLoading: false,
    articles: [],
    gridCols: 3,
    index: 0,
    objectives: [],
    objectiveTitle: "",
    open: false,
    isfb: -1,
    levelOne: 0,
    levelTwo: 0,
    levelTree: 0,
    userLevel: 0,
    alertError: '',
  }

  handleChangeIndex = (index) => {
    this.setState({
      index
    });
  };

  async loadData () {

    this.setState({ isLoading: true });
    try {

      // const response = await api.post(Globals.api.getModules, postData)
      api.get(Globals.api.userObjectives)
        .then(response => {
          if (response.data !== 'Erro ao buscar objetivos para o usuario')
            // console.log('response', response.data)
            this.setState({
              isLoading: false,
              objectiveTitle: response.data.data.objectiveTitle,
              objectives: response.data.data.steps || [],
            });
        }
        )
    } catch (error) {
      this.setState({ alertError: 404 })
      setTimeout(() => {
        this.setState({ alertError: '' })
      }, 3000)
    }
  }

  async loadIndiceData () {
    try {
      const response = await api.post(Globals.api.getModules, { container_id: 58 });
      if (response.data.container.user_module_data.isfb) {
        this.setState({ isfb: response.data.container.user_module_data.isfb });
      }
    } catch (error) {
      this.setState({ alertError: error.response.status })
      setTimeout(() => {
        this.setState({ alertError: '' })
      }, 3000)
    }
  }

  componentDidMount () {
    if (this.props.user && this.props.user.profile.progress) {
      this.setState({ userLevel: this.props.user.profile.progress.level });
    }

    this.loadData();
    this.loadIndiceData();
  }

  goToCourse = (courseId) => {
    // console.log( "Gotocourse props:", this.props );
    this.props.history.push(Globals.paths.courseDetail.replace(":courseId", courseId));
  }

  handleClick (item) {
    console.log(item, Globals.paths.missionsWithId.replace(":id", item.module_id))
    switch(item.action){
      case 'course': {
        this.props.history.push(Globals.paths.courseDetail.replace(":courseId", item.module_id));
        break;
      }
      case 'article': {
        this.props.history.push(Globals.paths.article.replace(":articleId", item.module_id));
        break;
      }
      case 'mission': {
        this.props.history.push(Globals.paths.missionsWithId.replace(":id", item.module_id));
        break;
      }
      default:
        break;
    }    
  }

  openModal = () => {
    this.setState({ open: true });
  }

  closeModal = () => {
    this.setState({ open: false })
  }


  render () {

    const { index } = this.state;
    const firstIndexMustBeFilled = this.props.user.profile.type_users_status_id === enumTypeUserStatuses.firstIndexMustBeFilled

    return (
      <>
        <AlertErrors error={this.state.alertError} />
        <div className="home-objetivo" >
          <FeedLine>
            <FeedItem title="Seu Objetivo" overflowHidden={true}
              subtitle={
                <Hidden xsDown>
                  <div style={{ fontSize: "16px" }}>
                    Para você alcançar seu objetivo de <span style={{ color: "#004178" }}>{this.state.objectiveTitle}</span> apresentamos os seus próximos passos
                  </div>
                </Hidden>
              } >

              <Hidden xsDown>

                <div className="objective_list_container">
                  <div className="objective_list_itemsContainer">
                    {this.state.isLoading ?
                      <Grid>
                        <Box pt={0.5} style={{ display: "flex" }}>
                          <Skeleton variant="rect" width={190} height={100} style={{ marginLeft: "10px" }} />
                          <Skeleton variant="rect" width={190} height={100} style={{ marginLeft: "10px" }} />
                          <Skeleton variant="rect" width={190} height={100} style={{ marginLeft: "10px" }} />
                          <Skeleton variant="rect" width={190} height={100} style={{ marginLeft: "10px" }} />
                        </Box>
                      </Grid> :
                      <>
                        {this.state.userLevel > 1 && this.state.objectives.length > 0 ?
                          <>
                            {this.state.objectives.map((item, index) => (
                              <div key={index}>
                                {
                                  <div className={`objective_list_containerObjective ${index === 0 ? '' : 'disabled' }`} onClick={() => { if(index === 0)this.handleClick(item)}}>
                                    <ObjectiveItem number={index + 1} text={item.title} />
                                  </div>
                                }
                              </div>
                            ))}
                          </> :
                          <h1 style={{ color: '#646464', fontSize: '18px' }}>
                            {
                              firstIndexMustBeFilled ? 'Você ainda não preencheu o seu Índice.' :
                                this.state.userLevel === 1 ? 'Para alcançar os seus objetivos e ter acesso aos conteúdos para iniciar a sua jornada, complete suas missões iniciais.' : 'Parabéns você completou todos os seus objetivos!'
                            }
                          </h1>
                        }
                      </>
                    }
                  </div>
                </div>
              </Hidden>

              <Hidden smUp>

                {this.state.isLoading ?
                  <Grid>
                    <Box pt={0.5} style={{ display: "flex" }}>
                      <Skeleton variant="rect" width={210} height={150} style={{ marginLeft: "10px" }} />
                      <Skeleton variant="rect" width={210} height={150} style={{ marginLeft: "10px" }} />
                      <Skeleton variant="rect" width={210} height={150} style={{ marginLeft: "10px" }} />
                    </Box>
                  </Grid> :
                  <>
                    {this.state.userLevel > 1 && this.state.objectives.length > 0 ?
                      <div className="objective_list_rootContainer">

                        <SwipeableViews style={customStyle.root} index={index} onChangeIndex={this.handleChangeIndex} slideStyle={{ overflow: 'visible' }}>
                          {this.state.objectives.map((item, index) => (
                            <div key={index}>
                              {
                                <div className={`objective_list_linkDecoration ${index === 0 ? '' : 'disabled' }`} onClick={() => { if(index === 0)this.handleClick(item)}}>
                                  <ObjectiveItem number={index + 1} text={item.title} />
                                </div>
                              }
                            </div>
                          ))
                          }
                        </SwipeableViews>

                        <Pagination
                          dots={this.state.objectives.length - 1}
                          index={index}
                          onChangeIndex={this.handleChangeIndex}
                        />
                      </div> : (
                        <h1 style={{ color: '#646464', fontSize: '16px' }}>
                          {
                            firstIndexMustBeFilled ? 'Você ainda não preencheu o seu Índice.' :
                              this.state.userLevel === 1 ? 'Para alcançar os seus objetivos e ter acesso aos conteúdos para iniciar a sua jornada, complete suas missões iniciais.' : 'Parabéns você completou todos os seus objetivos!'
                          }
                        </h1>

                      )
                    }
                  </>
                }
              </Hidden>
            </FeedItem>
          </FeedLine>
        </div>

        {this.state.open &&
          <InitialModalQuiz onClose={this.closeModal} open={this.state.open}
            quizData={QuestionarioIndice} />
        }
      </>
    )
  }
}

export default watch((withRouter(ObjectiveList)), { user: '' });

