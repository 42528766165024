
import React, { Component } from 'react';

import { withStyles, Grid } from '@material-ui/core';

import api from "../../../../services/api";
import Perfil from '../Perfil/Perfil';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

//Componentes
import AlertErrors from '../../../../logged_in/components/alertErrors/alerts';
import FeedItem from '../../../../logged_in/components/feed/FeedItem';
import FeedLine from '../../../../logged_in/components/feed/FeedLine';
import Chart from '../Chart/Chart';
import QrCode from '../QrCode/QrCode';
import PointsBalance from '../../../../logged_in/components/sidebar/PointsBalance'
import LastCertificate from '../../../../logged_in/components/sidebar/LastCertificate'
import LastTrophy from '../../../../logged_in/components/sidebar/LastTrophy';
import ToolbarItem from '../../../../logged_in/components/toolbar/ToolbarItem';
import Gauge from '../../../../logged_in/components/gauge/index';
import Logo from '../../../../assets/logo/hyst_new_logo.svg'

import './styles.scss'

const Globals = require("../../../../Globals.json");


const styles = theme => ({
  "@global": {
    body: {
      overflow: "hidden",
    }
  },
});

class GeneratePdf extends Component {
  state = {
    address: "",
    name: "",
    email: "",
    created_at: "",
    country: "",
    state: "",
    cpf: "",
    phone: "",
    zipCode: "",
    user_avatar: "",
    avatarInitials: "",
    isfb: 0,
    coins: 0,
    trophy: 0,
    totalCertificates: 0,
    isLoading: false,
    landmark: '',
    message: 'Preparando o download do seu PDF...',
    estado: '',
    alertError: '',
    indexData: {
      indexScore: 0
    }
  }

  async loadData() {
    this.setState({ isLoading: true });
    let hasToken = false;
    let getToken = '';

    if (localStorage.getItem("@fbb-Token") !== null) {
      hasToken = true
      getToken = localStorage.getItem('@fbb-Token');

      localStorage.removeItem('@fbb-Token')
    }

    try {
      const userId = this.props.match.params.userId

      const response = await api.get(`${Globals.api.userInfoReport}/${userId}`);


      console.log(2);

      let fullCpf = response.data.cpf_cnpj;
      let phone = response.data.phone
      let date = response.data.created_at;
      let fullName = response.data.username;

      const formattedCpf = (fullCpf).replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4")

      const formattedPhone = (phone).replace(/(\d{2})/, "$1 ");
      const createdAt = date.substring(0, 10).split('-').reverse().join('/');

      if (fullName.indexOf(" ") === -1) fullName += " ";
      const initials = (fullName).match(/^(.).* (.)?/);

      this.setState({
        dataChart: response.data.desempenho.evolucao_grafico,
        user_avatar: response.data.user_avatar,
        cpf: formattedCpf,
        name: response.data.username,
        email: response.data.email,
        state: response.data.state,
        address: response.data.adress,
        cadIbgeCounty: response.data.cadIbgeCounty,
        cadIbgeState: response.data.cadIbgeState,
        country: response.data.country,
        zipCode: response.data.zip_code,
        desempenho: response.data.desempenho,
        avatarInitials: initials[1] + (initials[2] ? initials[2] : ""),
        created_at: createdAt,
        phone: formattedPhone,
        landmark: response.data.landmark,
        estado: response.data.state,
        trophy: response.data.total_trofeis,
        isfb: response.data.isfb,
        coins: response.data.progress.total_moedas,
        totalCertificates: response.data.total_certificado,
        userId: response.data.id,
        isLoading: false,
        indexData: response.data.indexData || this.state.indexData
      });

      this.generatePdf()

    } catch (error) {
      this.setState({ alertError: error.response.status })
      setTimeout(() => {
        this.setState({ alertError: '' })
      }, 3000)
    }

    if (hasToken) {
      localStorage.setItem('@fbb-Token', getToken)
    }
  }

  generatePdf() {
    setTimeout(() => {
      if (this.state.dataChart !== undefined && this.state.isLoading === false && this.state.user_avatar !== "") {
        html2canvas(document.querySelector("#divToPrint")).then(canvas => {
          const imgData = canvas.toDataURL('image/png');
          const pdf = new jsPDF();
          pdf.addImage(imgData, 'PNG', -2, 0, 212, 295);
          pdf.save("febraban.pdf");

          if (canvas) {
            this.setState({ message: "PDF baixado com sucesso!" })
          }
        });
      }
      this.setState({ message: "Baixando pdf, por favor aguarde..." })
    }, 3000)
  }

  componentDidMount() {
    this.loadData();
  }

  render() {

    return (
      <>
        <div className="report-container">
          <AlertErrors error={this.state.alertError} />
          {/* <div className="loadingPdf">
            {this.state.message}
          </div> */}
          {this.state.isLoading ? <div className="loading">Carregando...</div> : (
            <Grid container style={{ backgroundColor: "#f7f7f7" }} id="divToPrint">
              <header >
                <div className='logo-container-pdf'>
                  <img src={Logo} className="logo-pdf" alt="Imagem do logo" />
                </div>
              </header>
              <FeedItem title="Informações Cadastrais" overflowHidden expandHeight>
                <Perfil heightAvatar="150px"
                  created_at={this.state.created_at}
                  name={this.state.name}
                  phone={this.state.phone}
                  email={this.state.email}
                  cpf={this.state.cpf}
                  zipCode={this.state.zipCode}
                  landmark={this.state.landmark}
                  estado={this.state.estado}
                  cadIbgeCounty={this.state.cadIbgeCounty}
                  cadIbgeState={this.state.cadIbgeState}
                  user_avatar={this.state.user_avatar}
                  noMediaQuery="noMediaQuery"
                />
              </FeedItem>

              <FeedLine>
                <FeedItem title="Evolução - últimos 6 meses" overflowHidden expandHeight>
                  <Chart data={this.state.dataChart} responseChart noMediaQuery="noMediaQuery" />
                </FeedItem>
              </FeedLine>

              <FeedLine>
                <FeedItem expandHeight>
                  <QrCode
                    desempenho={this.state.desempenho}
                    userId={this.state.userId}
                    trophy={this.state.trophy}
                  // print="pdf"
                  // noMediaQuery="noMediaQuery"
                  />
                </FeedItem>
              </FeedLine>



              <ToolbarItem print="print" className="toolbar" title="Saldo de Pontos" hiddenTooltip="hiddenTooltip" color="#FFF">
                <PointsBalance coins={this.state.coins} ></PointsBalance>
              </ToolbarItem>

              <ToolbarItem print="print" className="toolbar" title="Certificados" color="#FFF">
                <LastCertificate quantity={this.state.quantity} />
              </ToolbarItem>

              <ToolbarItem print="print" className="toolbar" title="Troféus" color="#FFF">
                <LastTrophy quantity={this.state.trophy} />
              </ToolbarItem>
            </Grid >
          )
          }
        </div>
      </>
    )
  }
}

export default withStyles(styles)(GeneratePdf);
