import React, { Component } from 'react';
import { Button, Container, DialogActions, DialogContent, Grid, Hidden, IconButton, Paper, Typography } from '@material-ui/core';
import { watch } from 'redux-easy/lib/redux-easy';
import { withRouter } from 'react-router-dom';
import { Helmet } from "react-helmet";
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import CloseIcon from '@material-ui/icons/Close';
import './incomesWelcome.scss';

class IncomesWelcome extends Component {

    step = 1;

    componentDidMount = () => {
        if (this.props.changeWidth) {
            this.props.changeWidth()
        }

    }

    getProgressWidth() {
        const w = this.step * 25 + '%';
        return w === '100%' ? '101%' : w;
    }


    handleClose = () => {
        if (this.props.handleClose) {
            this.props.handleClose();
        }
    }
    
    handleStart = () => {
        this.props.showContent({ to: 'incomes-type-select', from: this.props.page })
    }

    render() {
        return (

            <Grid className='incomes-welcome'>
                <MuiDialogTitle disableTypography className="dialog-header">
                    <h2>Cadastro de receitas</h2>
                    <IconButton color="secondary" aria-label="close" className="close-button" onClick={this.handleClose}>
                        <CloseIcon />
                    </IconButton>
                </MuiDialogTitle>
                <Grid container className="progress-floater">
                    <div className="progress-container">
                        <div className="progress" style={{ width: this.getProgressWidth() }}></div>
                    </div>
                </Grid>
                <DialogContent className="dialog-content" >


                    <Grid container item xs={12} direction="column" justify="center" alignItems="center" className="content-holder">
                        <h6>Cadastrando</h6>
                        <h1>Suas Receitas</h1>
                        <img src="/images/welcome-income.png" width="260px" alt="" />
                        <p>O cadastro das suas receitas é muito importante, ele dará visibilidade de todas as entradas de dinheiro na sua conta, sejam elas entradas fixas ou variáveis. Vamos começar a cadastrá-las?</p>
                    </Grid>


                </DialogContent >
                <DialogActions className="dialog-footer">
                    <Button
                        id="g-pm-start-test"
                        className={`g-pm-start-test`}
                        variant="contained"
                        color="primary"
                        onClick={() => this.handleStart()}
                        style={{ borderRadius: "0px", color: "white", width: "120px" }}
                    >
                        começar
                    </Button>
                </DialogActions>
            </Grid>
        )
    }
}

export default watch(withRouter(IncomesWelcome), { user: '' });