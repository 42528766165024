import { Grid, Hidden, Modal, Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { dispatchSet, watch } from 'redux-easy/lib/redux-easy';
import IconMission from '../../../assets/icon/new-icons/bandeira.svg';
import Globals from '../../../Globals.json';
import api from '../../../services/api';
import { enumTypeUserStatuses } from '../../../services/enums';
import { getNextIndexAssessmentDateText } from '../../../services/util';
import AppTour from '../../../shared/components/AppTour';
import AppTourMobile from '../../../shared/components/AppTourMobile';
import AlertErrors from '../../components/alertErrors/alerts';
import { default as RecomendedList } from '../../components/article/ArticleList';
import Button from '../../components/button/Button';
import ArticleList from '../../components/course/CourseArticList';
import CourseList from '../../components/course/CourseList';
import CourseListResp from '../../components/course/CourseListResp';
import CourseListRespOnlyFirst from '../../components/course/CourseListRespOnlyFirst';
import FeedItem from '../../components/feed/FeedItem';
import FeedLine from '../../components/feed/FeedLine';
import ObjectiveList from '../../components/objective/ObjectiveList';
import SmallProfileGauge from '../../components/profileGauge/SmallProfileGauge';
import MiniQuiz from '../../components/quiz/DailyQuizList';
import CurrentLevel from '../../components/sidebar/CurrentLevel';
import FirstChallenges from '../../components/sidebar/FirstChallenges';
import LatestAchievements from '../../components/sidebar/LatestAchievements';
import PointsBalance from '../../components/sidebar/PointsBalance';
import ToolList from '../../components/tool/ToolList';
import ToolbarItem from '../../components/toolbar/ToolbarItem';
import ProfileModal from '../profile-modal/ProfileModal';
import './home.scss';

class Home extends Component {
  state = {
    isLoading: false,
    isTourOpen: false, //desabilita tour
    isShowingMore: false,
    isModalOpen: false,
    isfb: 0,
    lock: false,
    tutorialOpen: false,
    items: [],
    open: false,
    userLevel: 0,
    alertError: '',
    later: false,
    nextIndexAssessmentDateText: '',
    firstIndexMustBeFilled: false,
    profileModalOpened: false,
    openAlert: false,
    successMessage: '',
  };

  componentDidMount() {
    this.handleTutorial();
    this.loadDataQuestIndice();
    this.loadData();

    dispatchSet('tour.show', false);

    if (this.props.history.location.state?.showTutorial) {
      this.setState({ isTourOpen: true, isModalOpen: false, lock: true });
      this.props.history.replace();
    }
  }

  async componentDidUpdate(prevProps) {
    if (JSON.stringify(this.props.user) !== JSON.stringify(prevProps.user)) {
      this.loadData(false);
    }
    if (prevProps.tour.show !== this.props.tour.show) {
      this.showTour();
    }
  }

  toggleShowMore = () => {
    this.setState((prevState) => ({
      isShowingMore: !prevState.isShowingMore,
    }));
  };

  closeTour = async (click) => {
    this.setState({ isTourOpen: false, isModalOpen: false, lock: false });

    dispatchSet('tour.show', false);

    if (click === 'finalizar') {
      const postData = {
        type_action_id: 12,
        data_modules_detail_id: 155,
      };
      try {
        await api.post(Globals.api.userActionEvent, postData);
      } catch (error) {
        this.setState({ alertError: error.response.status });
        setTimeout(() => {
          this.setState({ alertError: '' });
        }, 3000);
      }
    }
  };

  openTour = async (item) => {
    if (item === 'start') {
      this.setState({ isTourOpen: true, isModalOpen: false, lock: true });

      const postData = {
        type_action_id: 11,
        data_modules_detail_id: 155,
      };
      try {
        await api.post(Globals.api.userActionEvent, postData);
      } catch (error) {
        this.setState({ alertError: error.response.status });
        setTimeout(() => {
          this.setState({ alertError: '' });
        }, 3000);
      }
    }
  };

  handleModal = async (item) => {
    dispatchSet('tour.show', false);

    if (item === 'later') {
      this.setState({ isModalOpen: false, lock: false });

      if (!this.state.later) {
        const postData = {
          type_action_id: 15,
          data_modules_detail_id: 155,
        };
        try {
          await api.post(Globals.api.userActionEvent, postData);
        } catch (error) {
          this.setState({ alertError: error.response.status });
          setTimeout(() => {
            this.setState({ alertError: '' });
          }, 3000);
        }
      }
    }
  };

  openModal = () => {
    this.setState({ open: true });
  };

  closeModal = () => {
    this.setState({ open: false });
  };

  closeWelcomeModal = () => {
    this.setState({ profileModalOpened: false });
  };

  showTour() {
    if (this.props.tour.show) {
      console.log('this.props.tour.show', this.props.tour.show);
      this.setState({ isTourOpen: true, isModalOpen: false, lock: true });
    }
  }

  async handleTutorial() {
    try {
      const response = await api.get(Globals.api.selfInfo);

      const { tutorial } = response.data;

      this.setState({ later: tutorial.after });

      tutorial === 0 || tutorial.after === true
        ? this.setState({ isModalOpen: true, lock: true })
        : this.setState({ isModalOpen: false, lock: false });

      this.showTour();
    } catch (error) {
      this.setState({ alertError: 400 });
      setTimeout(() => {
        this.setState({ alertError: '' });
      }, 3000);
    }
  }

  async loadDataQuestIndice() {
    this.setState({ isLoading: true });
    const postData = { container_id: 53 };
    try {
      const response = await api.post(Globals.api.getModules, postData);
      const { container } = response.data;

      this.setState({
        isLoading: false,
        objectiveTitle: container.user_module_data.objectiveTitle,
        items: container.user_module_data.steps || [],
      });
    } catch (error) {
      this.setState({ alertError: error.response.status });
      setTimeout(() => {
        this.setState({ alertError: '' });
      }, 3000);
    }
  }

  handleClick(item) {
    if (item.action === 'calc_indice_sfb') {
      this.setState({ open: true });
    }
  }

  loadData = async (reloadIndexModal = true) => {
    if (this.props.user && this.props.user.profile.progress) {
      this.setState({ userLevel: this.props.user.profile.progress.level });
    }

    // if (reloadIndexModal) {
    //   this.setState({
    //     nextIndexAssessmentDateText: await getNextIndexAssessmentDateText(),
    //     firstIndexMustBeFilled:
    //       this.props.user.profile.type_users_status_id ===
    //       enumTypeUserStatuses.firstIndexMustBeFilled,
    //     profileModalOpened:
    //       this.props.user.profile.type_users_status_id ===
    //       enumTypeUserStatuses.firstIndexMustBeFilled,
    //   });
    // } else {
    //   this.setState({
    //     nextIndexAssessmentDateText: await getNextIndexAssessmentDateText(),
    //     firstIndexMustBeFilled:
    //       this.props.user.profile.type_users_status_id ===
    //       enumTypeUserStatuses.firstIndexMustBeFilled,
    //   });
    // }
  };

  showSuccessAlert = (message) => {
    this.setState({ openAlert: true, successMessage: message });
    setTimeout(() => {
      return this.setState({ openAlert: false, successMessage: '' });
    }, 4500);
  };

  sendVerificationEmail = async () => {
    try {
      this.setState({ loading: true });

      await api.post(Globals.api.sendVerificationEmail);
      this.showSuccessAlert('E-mail de verificação enviado com sucesso.');
      this.setState({ loading: false });
    } catch (error) {
      this.setState({ loading: false });
    }
  };

  getJourneyLockedNextStep() {
    if (this.state.firstIndexMustBeFilled) {
      return (
        <Button
          variant="contained"
          color="primary"
          className="q-hs-start-test"
          onClick={() => {
            this.setState({ profileModalOpened: true });
          }}
          style={{ borderRadius: '0px' }}
        >
          Preencher
        </Button>
      );
    } else if (this.props.user.profile.account_status === 'pending') {
      return (
        <>
          {
            <Snackbar
              open={this.state.openAlert}
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
              autoHideDuration={6000}
              onClose={() => {
                this.setState({ openAlert: false });
              }}
            >
              <Alert
                onClose={() => {
                  this.setState({ openAlert: false });
                }}
                severity="info"
              >
                {this.state.successMessage}
              </Alert>
            </Snackbar>
          }
          <p>
            Você ainda não validou seu e-mail. <br />
            <span
              role="button"
              onClick={this.sendVerificationEmail}
              className="primary q-hs-resend-validation-email"
            >
              Clique aqui
            </span>{' '}
            para reenviar um novo e-mail de validação
          </p>
        </>
      );
    } else {
      return (
        <Button
          variant="contained"
          color="primary"
          className="q-hs-redeem-missions"
          onClick={() => {
            this.props.history.push(Globals.paths.missionsWithoutType);
          }}
          style={{ borderRadius: '0px' }}
        >
          Resgatar Recompensas das Missões
        </Button>
      );
    }
  }

  render() {
    const { isTourOpen, isModalOpen, lock } = this.state;

    return (
      <Grid container>
        <Helmet>
          <title>Home | Meu bolso em dia</title>
        </Helmet>
        <Grid item xs={12} sm={8} md={9} className="containerHome">
          <AlertErrors error={this.state.alertError} />
          <div className="feedContainer">
            {this.state.userLevel > 1 && <ObjectiveList navigation={this.props.navigation} />}
            {this.state.userLevel === 1 && (
              <Grid
                container
                direction="column"
                justify="center"
                alignItems="center"
                className="home-journey-locked mb25"
              >
                <Grid container item xs={12} direction="column">
                  <h1>Libere a sua Jornada!</h1>
                  <p>
                    Falta pouco para aproveitar 100% da plataforma.
                    <br />
                    Complete as missões abaixo e desbloqueie o conteúdo.
                  </p>
                </Grid>
                <Grid container item xs={12} direction="column">
                  <ol>
                    {/* <li className={!this.state.firstIndexMustBeFilled ? 'done' : ''}>
                      Preenchimento do Índice
                    </li> */}
                    <li
                      className={this.props.user.profile.account_status === 'active' ? 'done' : ''}
                    >
                      Valide seu e-mail
                    </li>
                    <li>Resgate as recompensas das missões concluídas</li>
                  </ol>
                </Grid>
                <Grid
                  container
                  item
                  xs={12}
                  direction="column"
                  justify="center"
                  alignItems="flex-start"
                  className="pl20"
                >
                  {this.getJourneyLockedNextStep()}
                </Grid>
              </Grid>
            )}

            {this.state.userLevel > 1 && (
              <FeedLine>
                <FeedItem
                  title="Recomendações para você"
                  subtitle={
                    <Hidden xsDown> Dá uma olhada nessas sugestões que separamos para você </Hidden>
                  }
                >
                  <RecomendedList history={this.props.history} />
                </FeedItem>
              </FeedLine>
            )}

            {this.state.userLevel > 1 && <MiniQuiz history={this.props.history} />}

            {this.state.userLevel === 1 && (
              <FeedLine>
                <div className="home-cursos" style={{ width: '100%' }}>
                  <FeedItem title="Cursos">
                    <CourseListRespOnlyFirst
                      sliceCourse
                      history={this.props.history}
                      endpoint={Globals.api.recommendedModule}
                      payload={{ module_type: '2' }}
                    />
                  </FeedItem>
                </div>
              </FeedLine>
            )}
            
            {this.state.userLevel >= 2 && 
              <FeedLine>
                <div className="home-cursos" style={{ width: '100%' }}>
                  <FeedItem title="Artigos">
                    <ArticleList />
                  </FeedItem>
                </div>
              </FeedLine>
            }

            {this.state.userLevel >= 2 && (
              <Hidden xsDown>
                <FeedLine>
                  <div className="home-cursos" style={{ width: '100%' }}>
                    <FeedItem title="Cursos recomendados para você">
                      <CourseList
                        sliceCourse
                        history={this.props.history}
                        endpoint={Globals.api.recommendedModule}
                        payload={{ module_type: '2' }}
                      />
                    </FeedItem>
                  </div>
                </FeedLine>
              </Hidden>
            )}

            {this.state.userLevel >= 2 && (
              <Hidden smUp>
                <FeedLine>
                  <FeedItem title="Cursos recomendados para você">
                    <CourseListResp
                      sliceCourse
                      history={this.props.history}
                      endpoint={Globals.api.recommendedModule}
                      payload={{ module_type: '2' }}
                    />
                  </FeedItem>
                </FeedLine>
              </Hidden>
            )}
            
          </div>
        </Grid>

        {this.state.profileModalOpened && (
          <ProfileModal
            closeWelcomeModal={this.closeWelcomeModal}
            open={this.state.profileModalOpened}
          ></ProfileModal>
        )}

        <Hidden xsDown>
          {lock && <style>{'html {overflow: hidden }'}</style>}
          <Grid item sm={4} md={3} className="rightPanel">
            <Modal
              open={isModalOpen}
              onClose={this.handleModal}
              aria-labelledby="Modal Home"
              aria-describedby="Modal Home"
              className="modal"
            >
              <div className="modal-container">
                <div className="modal-content-top">
                  <div className="modal-content-border">
                    <span role="img" aria-label="Acenando">
                      👋
                    </span>
                  </div>
                </div>
                <div className="modal-content">
                  <div className="modal-content-middle">
                    <p>
                      Seja bem-vindo(a)! A plataforma <strong>Meu Bolso em Dia</strong> traz uma
                      experiência em em educação e planejamento financeiro. Aqui inicia a sua
                      jornada e um tour para você conhecer melhor todos os recursos para a sua para
                      a sua liberdade financeira.
                    </p>
                    <p>Está pronto para começar?</p>
                  </div>
                  <div className="modal-content-bottom">
                    <Button type="solid" onClick={() => this.openTour('start')}>
                      SIM, VAMOS FAZER UM TOUR
                    </Button>
                    <div
                      className="modal-leave"
                      data-dismiss="modal"
                      onClick={() => this.handleModal('later')}
                    >
                      Deixar para depois
                    </div>
                  </div>
                </div>
              </div>
            </Modal>

            <div className="home-missoes">
              <ToolbarItem title="Suas missões" color="#45AF98" overflow="column">
                <div className="positionMission">
                  <span>Realize suas missões e não perca de vista seu objetivo</span>
                  <img src={IconMission} alt="Icone Missões" />
                </div>
                <FirstChallenges />
              </ToolbarItem>
            </div>

            <ToolbarItem
              title="Seu Nível"
              tooltip="O Nível representa o seu status de usuário na Plataforma. Ele aumenta conforme você vai usando e aprendendo com a Plataforma: cumprir os objetivos e missões da sua jornada de aprendizado 
              e consumir conteúdos e usar ferramentas te dão experiência. Essa experiência se acumula, e quando você atinge determinada quantidade, passa de nível. Níveis mais altos concedem benefícios e 
              vantagens exclusivas para os usuários."
              color="#45AF98"
            >
              <CurrentLevel />
            </ToolbarItem>

            <ToolbarItem
              title="Saldo de Pontos"
              tooltip="Os Pontos representam o reconhecimento do seu engajamento no uso da Plataforma. Praticamente qualquer atividade da Plataforma é 
              recompensada com uma quantidade de pontos ao ser completada. Junte os pontos e as troque por benefícios e conteúdos exclusivos da Plataforma."
              color="#45AF98"
            >
              <PointsBalance coins={this.props.coins} level={this.state.userLevel}></PointsBalance>
            </ToolbarItem>

            <ToolbarItem title="Última Conquista" color="#45AF98">
              <LatestAchievements />
            </ToolbarItem>

            {isTourOpen && (
              <AppTour
                onClose={this.closeTour}
                userLevel={this.state.userLevel}
                isOpened={isTourOpen}
              />
            )}
          </Grid>
        </Hidden>
        <Hidden smUp>
          {isTourOpen && (
            <AppTourMobile
              onClose={this.closeTour}
              userLevel={this.state.userLevel}
              isOpened={isTourOpen}
            />
          )}
        </Hidden>
      </Grid>
    );
  }
}

export default watch(Home, { tour: '', user: '' });
