import styled, { css } from 'styled-components';
import { withTheme } from "@material-ui/core/styles";
import { ReactComponent as MiniLogo } from '../../../../assets/logo/mini-logo.svg'

export const Hero = withTheme(styled('section')`
  background: radial-gradient(50% 50% at 33% 43%, #fff 44.27%, #e5e5e5 100%);
  /* max-height: 1024px; */
  padding-top: 90px;
  padding-bottom: 90px;
  
  @media(max-width: ${({ theme }) => `${theme.breakpoints.values.sm}px`}) {
    padding-top: 120px;
    padding-bottom: 0;
    min-height: initial;
  }

  .inner-wrapper {
    align-items: center;
    display: flex;
    max-width: 1440px;
    margin: auto;

    @media(max-width: ${({ theme }) => `${theme.breakpoints.values.sm}px`}) {
      flex-direction: column;
    }

    @media(
      min-width: ${({ theme }) => `${theme.breakpoints.values.sm}px`}
    ) and (max-width: ${({ theme }) => `${theme.breakpoints.values.md}px`}) {
      flex-direction: column; 
      max-height: initial;
    }

    .featured {
      position: relative;
      margin: 0;
      
      &__image {
        margin-left: 130px;
        height: auto;
        max-width: 748px;
        width: 52vw;
        z-index: -1;

        @media(max-width: ${({ theme }) => `${theme.breakpoints.values.sm}px`}) {
          margin: 0;
          width: 90vw;
          margin: auto;
        }

        @media only screen and (
          min-width: ${({ theme }) => `${theme.breakpoints.values.sm}px`}
        ) and (max-width: ${({ theme }) => `${theme.breakpoints.values.md}px`}) {
          margin: 0;
          width: 80vw;
          margin: auto;
        }
      }

      &__shield {
        position: absolute;

        &--super-small {
          width: 62px;
          top: 540px;
          left: 820px;
          filter: blur(5px);
        }
        
        &--small {
          width: 117px;
          top: 530px;
          left: 0;
        }
        
        &--regular {
          width: 137px;
          top: 110px;
          left: 827px;
        }
        
        &--big {
          width: 300px;
          top: 805px;
          left: 1140px;
        }

        @media(max-width: ${({ theme }) => `${theme.breakpoints.values.sm}px`}) {
          display: none;

          &--small {
            display: block;
            top: 400px;
            left: initial;
            right: -100px;
            width: 150px;
          }
        }
      } 
    }
  }
`);

export const Logo = withTheme(styled(MiniLogo)`
  position: absolute;

  ${({ variant }) => variant === 'left' 
    ? css`
      width: 88px;
      top: 730px;
      left: 80px;

      @media(max-width: ${({ theme }) => `${theme.breakpoints.values.sm}px`}) {
        width: 43px;
        top: calc(100vw - (100vh / 10));
        left: 0px;
      }

      @media only screen and (
        min-width: ${({ theme }) => `${theme.breakpoints.values.sm}px`}
      ) and (max-width: ${({ theme }) => `${theme.breakpoints.values.md}px`}) {
        top: 610px;
        left: -70px;
      }

      @media only screen and (
        min-width: ${({ theme }) => `${theme.breakpoints.values.md}px`}
      ) and (max-width: ${({ theme }) => `${theme.breakpoints.values.lg}px`}) {
        top: 510px;
        left: 130px;
      }
    `
    : css`
      width: 85px;
      left: 920px;
      top: 740px;

      @media(max-width: ${({ theme }) => `${theme.breakpoints.values.sm}px`}) {
        width: 42px;
        top: calc(100vw - (100vh / 3.5));
        left: initial;
        right: 10px;
      }

      @media only screen and (
        min-width: ${({ theme }) => `${theme.breakpoints.values.sm}px`}
      ) and (max-width: ${({ theme }) => `${theme.breakpoints.values.md}px`}) {
        top: 330px;
        left: 550px;
      }
    `
  }
`);