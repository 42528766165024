import React, { Fragment } from 'react';
import './article.scss';

import { Skeleton } from '@material-ui/lab';
import { watch } from 'redux-easy/lib/redux-easy';
import { Hidden, Grid, Box } from '@material-ui/core';
import { Link } from 'react-router-dom';
import AlertErrors from '../../components/alertErrors/alerts';

import api from '../../../services/api';
import Button from '../button/Button';
import { enumTypeModules } from '../../../services/enums';
const Globals = require("../../../Globals.json");

class ArticleList extends React.Component {

  state = {
    image: "",
    isLoading: false,
    articles: [],
    courses: [],

    articleDescription: '',
    articleId: '',
    articleName: '',
    articleThumbnail: '',
    courseDescription: '',
    courseId: '',
    courseName: '',
    courseThumbnail: '',
    course: '',
    alertError: '',
    index: 0,
    message: '',
  }

  loadData() {
    this.setState({ isLoading: true });

    api.get(Globals.api.getWeekRecommended)
      .then(response => {
        console.log('responde recomendação', response.data)
        if (response.data.recommendation_article.length === 0 && response.data.recommendation_course.length === 0) {
          this.setState({ message: '...', isLoading: false })
          console.log('tudo zerado')
        }
          else {
            this.setState({
              articles: response.data.recommendation_article,
              courses: response.data.recommendation_course,
              isLoading: false
            });
          }
      }).catch(error => {
        this.setState({ alertError: error.response.status })
        setTimeout(() => {
          this.setState({ alertError: '' })
        }, 3000)
      })
  }

  getDisplayItems() {
    const items = [];
    if (this.state.userLevel > 1) {
      if (this.state.courses.length > 0) {
        items.push(this.state.courses[0]);
      }
    }
    if (this.state.articles.length > 0) {
      items.push(this.state.articles[0]);
    }
    /*if (items.length < 2) {
      if (this.state.articles.length > 1) {
        items.push(this.state.articles[1]);
      }
    }*/

    return items;

  }

  componentDidMount() {
    if (this.props.user && this.props.user.profile.progress) {
      this.setState({ userLevel: this.props.user.profile.progress.level });
    }
    this.loadData();
  }

  componentDidUpdate(oldProps) {
    if (this.props.user !== oldProps.user) {
      this.setState({ userLevel: this.props.user.profile.progress.level });
    }
  }

  goToarticle = (articleId) => {
    this.props.history.push(Globals.paths.articleDetail.replace(":articleId", articleId));
  }

  handleChangeIndex = (index) => {
    this.setState({
      index
    });
  };

  render() {
    return (
      <>
        <Hidden xsDown>
          {this.state.isLoading ?
            <Grid>
              <Box pt={0.5} style={{ padding: "15px", display: "flex" }}>
                <Skeleton variant="rect" width={"50%"} height={200} />
                <Skeleton variant="rect" width={"50%"} height={200} style={{ marginLeft: "15px" }} />
              </Box>
            </Grid> :
            <div className='articleContainer'>
              {this.state.message !== '' ?
              <div className='artcile_message'>
                <span>Já consumiu todo os conteúdos recomendados?<br />Existe uma relação de artigos que podem te ajudar a cumprir o seu objetivo.
                <Link to={Globals.paths.courses} className='artcileContainerLink'>Acesse!</Link>
               </span>
              </div>
               : <>

              {this.getDisplayItems().map((item, index) => (

                <div key={index} className='containerLeft' >
                  <div className='articleImage' style={{ backgroundImage: `url(${item.thumbnail})` }}>
                    <div className='articleInfoContainer'>
                      <h3 className='articleTitle'>
                        <div className='articleBorderTop' />
                        {item.name}
                      </h3>
                      <div className='articleInfo'>
                        {/* <div className='color_info'>
                            <QueryBuilder /> <span className='font_info'> 20 min </span>
                            <SignalCellularAlt /> <span className='font_info'>Fácil </span>
                          </div> */}
                        {(item.type_module_id === enumTypeModules.article || item.type_module_id === enumTypeModules.articleBlog) &&
                          <div className='articleButtonContainer'>
                            <Button to={Globals.paths.article.replace(":articleId", item.id)} type="darkBlue" width="130px" minWidth="130px" >
                              LER ARTIGO
                            </Button>
                          </div>
                        }
                        {item.type_module_id === enumTypeModules.course &&
                          <div className='articleButtonContainer'>
                            <Button to={Globals.paths.courseDetail.replace(":courseId", item.id)} type="darkBlue" width="130px" minWidth="130px" >
                              {item.started ? "CONTINUAR" : "COMEÇAR"}
                            </Button>
                          </div>
                        }
                      </div>
                    </div>
                  </div>
                </div>

              ))}
          </>}
            </div >
          }
        </Hidden >

        <Hidden smUp>
          {/* <SwipeableViews index={index} onChangeIndex={this.handleChangeIndex}> */}
          {this.state.isLoading ?
            <Grid>
              <Box pt={0.5} >
                <Skeleton variant="rect" width={"100%"} height={150} />
              </Box>
            </Grid> :


            <div className='articleContainer'>
              <>
              {this.state.message !== '' ?
              <div className='artcileMessageMobile'>
                <span>
                  Já consumiu todo os conteúdos que a plataforma
                  reservou para você nesta semana?! Existe uma relação de artigos que podem te ajudar a cumprir o seu objetivo.
                  <Link to={Globals.paths.courses} className='artcileContainerLinkMobile'>Acesse!</Link>
                </span>
              </div>: <>
                {this.getDisplayItems().map((item, index) => (

                  <div className='containerLeft' key={index}>
                    <div className='articleImage' style={{ backgroundImage: `url(${item.thumbnail})` }}>
                      <div className='articleInfoContainer'>
                        <h3 className='articleTitle'>
                          <div className='articleBorderTop' />
                          {item.name}
                        </h3>
                        {/* <span className='description_text'>{item.description}</span> */}
                        <div className='articleInfo'>
                          {/* <div className='color_info'>
                            <QueryBuilder /> <span className='font_info'> 20 min </span>
                            <SignalCellularAlt /> <span className='font_info'>Fácil </span>
                          </div> */}

                          {item.type_module_id === 10 &&
                            <div className='articleButtonContainer'>
                              <Button to={Globals.paths.article.replace(":articleId", item.id)} type="darkBlue" width="130px" >
                                LER ARTIGO
                              </Button>
                            </div>
                          }
                          {item.type_module_id === 2 &&
                            <div className='articleButtonContainer'>
                              <Button to={Globals.paths.courseDetail.replace(":courseId", item.id)} type="darkBlue" width="130px" >
                                {item.iniciado ? "CONTINUAR" : "COMEÇAR"}
                              </Button>
                            </div>
                          }

                        </div>
                      </div>
                    </div>
                  </div>

                ))}
              </>}
              </>
              <AlertErrors side error={this.state.alertError} />
            </div>
          }
          {/* </SwipeableViews>

          <Pagination
            dots={2}
            index={index}
            onChangeIndex={this.handleChangeIndex}
          /> */}
        </Hidden>
      </>
    )
  }
}

export default watch((ArticleList), { user: '' });
