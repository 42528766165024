import React from 'react';

import { watch } from 'redux-easy/lib/redux-easy';
import { Link } from 'react-router-dom';


import UsersIcon from '../../../../assets/icon/admin/users.svg';
import ArticlesIcon from '../../../../assets/icon/admin/articles.svg';
import CoursesIcon from '../../../../assets/icon/admin/courses.svg';
import MiniQuizIcon from '../../../../assets/icon/admin/mini-quiz.svg';
import QuizIcon from '../../../../assets/icon/admin/quiz.svg';

import { CircularProgress } from '@material-ui/core';

import './home.scss';

const Globals = require("../../../../Globals.json");
class Home extends React.Component {

  render() {

    return (

      <>
        <div className="backoffice_header">
          <h2>Bem vindo(a), {this.props.user.profile.username} </h2>
        </div>
        <div className="containerHomeBo">

          <Link to={Globals.paths.backofficeUsers} className="itemHome">
            <img alt="ícone usuários" src={UsersIcon} className="user-icon" />
            <div className="itemTitle">Gestão de Usuários</div>
            <div className="itemText">Visualizar, editar e adicionar informações.</div>
            <div className="borderGreen" />
          </Link>

          <Link to={Globals.paths.backofficeArticleHome} className="itemHome">
            <img alt="ícone artigos" src={ArticlesIcon} />
            <div className="itemTitle">Artigos</div>
            <div className="itemText">Visualizar, editar e adicionar conteúdos.</div>
            <div className="borderGreen" />
          </Link>

          <Link to={Globals.paths.backofficeCourses} className="itemHome">
            <img alt="ícone cursos" src={CoursesIcon} />
            <div className="itemTitle">Cursos</div>
            <div className="itemText">Visualizar, editar e adicionar conteúdos.</div>
            <div className="borderGreen" />
          </Link>

          <Link to={Globals.paths.backofficeMiniQuiz} className="itemHome">
            <img alt="ícone cursos" src={MiniQuizIcon} />
            <div className="itemTitle">Mini Quizzes</div>
            <div className="itemText">Visualizar, editar e adicionar conteúdos.</div>
            <div className="borderGreen" />
          </Link>

          <Link to={Globals.paths.backofficeQuizzes} className="itemHome">
            <img alt="ícone cursos" src={QuizIcon} />
            <div className="itemTitle">Quizzes</div>
            <div className="itemText">Visualizar, editar e adicionar conteúdos.</div>
            <div className="borderGreen" />
          </Link>

        </div>
      </>
    )
  }
}
export default watch((Home), { user: '' });
