import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import WebSocket from "../../../services/websocket";

export default function Socket(props) {

    const { channel, event, onUpdate, user } = props;
    let currentChannel;

    useEffect(() => {
        console.log('SOCKET', props.user);
        if (channel && event && user.profile && user.profile.progress && user.profile.progress.level >= 1) {
            if (!currentChannel) {
                currentChannel = WebSocket.subscribe(channel, event, onUpdate);
            }
        }

        return function cleanup() {
            if (currentChannel) {
                currentChannel.off(event, onUpdate);
            }
        }
    }, [])
    return null;
}

Socket.propTypes = {
    channel: PropTypes.string.isRequired,
    event: PropTypes.string.isRequired,
    onUpdate: PropTypes.func,
    user: PropTypes.object.isRequired,
}