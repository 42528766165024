import React from 'react';
import { CircularProgress, withStyles } from '@material-ui/core';
import api from '../../../services/api';
import FeedItem from '../../components/feed/FeedItem';
import FeedLine from '../../components/feed/FeedLine';
import Globals from '../../../Globals.json';
import './styles/dailyQuiz.scss';

const styles = (theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    justifyContent: 'space-between',
    overflow: 'hidden',
  },
  containerQuizz: {
    padding: '15px',
    diplay: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    marginTop: '-10px',
  },
  coursePointsContainer: {
    display: 'flex',
    flexDirection: 'row-reverse',
  },
  coursePoints: {
    color: '#fff',
    fontSize: '12px',
    padding: '5px',
    fontWeight: 'bold',
  },
  textTitle: {
    fontSize: '16px',
    fontWeight: 'bold',
  },
  textSubtitle: {
    fontWeight: '500',
    fontSize: '16px',
    margin: '-15px 0px 0px 0px',

    [theme.breakpoints.down('xs')]: {
      width: '95%',
      fontSize: '14px',
      margin: '-15px 0px 0px 0px',
    },
  },
});

class DailyQuizList extends React.Component {
  state = {
    title: '',
    title2: '',
    quizQuestions: [],
    questionSelect: '',
    loading: false,
    hidden: true,
    none: true,
    quizId: null,
  };

  loadData() {
    this.setState({ loading: true });
    api
      .get(Globals.api.getMyDailyMiniQuiz)
      .then((response) => {
        if (response.data) {
          this.setState({
            title: response.data.name,
            quizQuestions: response.data.data.options,
            title2: response.data.description,
            quizId: response.data.id,
            hidden: false,
            none: false,
          });
        }
      })
      .catch((error) => console.log(error))
      .finally(() => this.setState({ loading: false }));
  }

  getOptionClass = (id) => {
    if (this.state.completed && this.state.questionSelect === id) {
      if (this.state.msgCorrect) {
        return 'answerCorret';
      } else if (this.state.msgIncorrect) return 'answerCorretQuiz false';
    }
    return this.state.questionSelect === id
      ? 'test_containerAnswerSelected2Quiz'
      : 'test_containerAnswer2Quiz';
  };

  handleSelection = (id) => {
    const postData = {
      type_action_id: 24,
      data_modules_detail_id: this.state.quizId,
      answer: id,
    };

    api
      .post(Globals.api.userActionEvent, postData)
      .then(() => {
        this.setState({ hidden: true });
        setTimeout(() => {
          this.setState({ none: true });
        }, 1100);
      })
      .catch((error) => console.log(error));
  };

  componentDidMount() {
    this.loadData();
  }

  render() {
    const { classes } = this.props;

    return (
      <div className={`dailyQuiz_fade ${this.state.hidden && 'out'} ${this.state.none && 'none'}`}>
        <FeedLine>
          <FeedItem>
            {this.state.loading ? (
              <CircularProgress />
            ) : (
              <div className={classes.container}>
                <span className={classes.textTitle}>{this.state.title} </span> <br />
                <span className={classes.textSubtitle}>{this.state.title2}</span>
                <div className="classDetailContainerQuestionQuiz">
                  {this.state.quizQuestions.map((item) => {
                    if (item.text.trim() !== '') {
                      return (
                        <div
                          key={`mini-quiz-answer-${item.id}`}
                          className="classDetail_containerAnswerQuiz"
                        >
                          <div
                            className={this.getOptionClass(item.id)}
                            onClick={() => this.handleSelection(item.id)}
                          >
                            <span>{item.text}</span>
                          </div>
                        </div>
                      );
                    }
                    return null;
                  })}
                </div>
              </div>
            )}
          </FeedItem>
        </FeedLine>
      </div>
    );
  }
}
export default withStyles(styles)(DailyQuizList);
