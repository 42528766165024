import React from 'react';
import './styles/progressCourses.scss';

import { Grid } from '@material-ui/core';

import Button from '../button/Button';

class ProgressCourses extends React.Component {
  render() {
    const { title, on, img } = this.props;
    return (
      <>
        <Grid container direction="column">
          <div className="progress_course_container_courses">
            <div className="progress_course_courseItemContainer">
              <div className="progress_course_containerLeft">
                <div className="progress_course_img" style={{ backgroundImage: `url(${img})` }} />
              </div>
              <div className="progress_course_containerRight" >
                <p className="titleprogress_course_">
                  {title}
                </p>
                <Button icon="none" type="transparent" onClick={on}>Continuar</Button>
              </div>
            </div>
          </div>
        </Grid>
      </>
    )
  }

}

export default ProgressCourses;
