import React, { Component } from 'react';
import EditorJs from "react-editor-js";
import Embed from "@editorjs/embed";
import Table from "@editorjs/table";
import List from "@editorjs/list";
import Warning from "@editorjs/warning";
import Code from "@editorjs/code";
import LinkTool from "@editorjs/link";
import Raw from "@editorjs/raw";
import Header from "@editorjs/header";
import Quote from "@editorjs/quote";
import Marker from "@editorjs/marker";
import CheckList from "@editorjs/checklist";
import Delimiter from "@editorjs/delimiter";
import InlineCode from "@editorjs/inline-code";
import SimpleImage from "@editorjs/simple-image";

import HystImageTool from "hyst-editorjs-image-tool";

import { forwardRef, useRef } from "react";
import api from '../../../services/api';

const Globals = require('../../../Globals.json');

const FBBEditorJS = forwardRef( ({ onChange, data }, ref ) => {

  const EDITOR_JS_TOOLS = {
    table: Table,
    // marker: Marker,
    list: {
      class: List,
      inlineToolbar: true,
    },
    // warning: Warning,
    // code: Code,
    // linkTool: LinkTool,
    // raw: Raw,
    header: {
      class: Header,
      // inlineToolbar: true,
    },
    quote: {
      class: Quote,
      // inlineToolbar: true,
    },
    // delimiter: Delimiter,
    // inlineCode: InlineCode,
    embed: {
      class: Embed,
      config: {
        services: {
          soundcloud: {
            regex: /(https?:\/\/soundcloud.com\/.*)/,
            embedUrl: 'https://w.soundcloud.com/player/?url=<%= remote_id %>',
            html: '<iframe width="100%" height="166" scrolling="no" frameborder="no" allow="autoplay"></iframe>',
            height: 300,
            width: 600,
            id: (groups) => groups.join('/embed/')
          }
        }
      }
    },
    image: {
      class: HystImageTool,
      config: {
        captionPlaceholder: "Legenda da Imagem",
        altPlaceholder: "Texto Alt",
        /**
         * Custom uploader
         */
        uploader: {
          /**
           * Upload file to the server and return an uploaded image data
           * @param {File} file - file selected from the device or pasted by drag-n-drop
           * @return {Promise.<{success, file: {url}}>}
           */
          uploadByFile(file) {

            console.log("CUSTOM UPLOAD...");

            const formData = new FormData();
            formData.append('image', file);
            formData.append('folder', 'content/class/article');

            let config = {
              headers: {
                "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
              }
            }

            return api.post( Globals.api.uploadImg, formData, config ).then((res) => {
              return {
                success: 1,
                file: {
                  url: res.data.url,
                }
              };
            });
          },

          /**
           * Send URL-string to the server. Backend should load image by this URL and return an uploaded image data
           * @param {string} url - pasted image URL
           * @return {Promise.<{success, file: {url}}>}
           */
          uploadByUrl(url){

            return new Promise(
              // a função resolve() é chamada com a capacidade para resolver ou
              // rejeitar a promise
              function(resolve, reject) {
                // Isto é apenas um exemplo para criar assincronismo
                window.setTimeout( () => resolve({ success: 1, file: { url: url } } ), 10 );
              }
            )

          }
        }
      }
    }
  };

  return (

    <EditorJs ref={ref} tools={EDITOR_JS_TOOLS} data={data} onChange={onChange} />

  );
});

export default FBBEditorJS;
