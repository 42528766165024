import React, { Component } from 'react'

import Button from '../../../button/Button';

import { Dialog, DialogContent } from '@material-ui/core'

export default class DialogUser extends Component {
  render() {
    return (
      <Dialog
        open={this.props.open}
        maxWidth={"xs"}
        className="modal"
      >
        <DialogContent className="dialog_user">
          <div className="dialog_user_content centerAlign">

            <strong style={{ color: '#004178' }}>Atenção</strong>
            <span>Ao efetuar essa ação, ela será <b>registrada</b> no banco de dados e <b>compartilhada</b> ao administrador master!</span>
            <Button
              left="170px"
              icon="none"
              height="36px"
              width="150px"
              type="darkBlue"
              textAlign="center"
              onClick={this.props.onClose}>
              CONTINUAR
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    )
  }
}

