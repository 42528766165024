import { Dialog, FormControl, InputBase, InputLabel, Select, Slider, TextField } from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import '../../../../../backoffice.scss';
import ModuleEditing from '../ModuleEditing/ModuleEditing';
import { parseIntInput } from '../../../../../services/util';
import FileUploader from '../FileUploader/FileUploader';

function QuizQuestionEditor({
  quizType,
  results,
  questionId,
  parentId,
  open = false,
  onClose,
  onSave,
}) {

  const [container, setContainer] = useState({});

  const [type, setType] = useState('');
  const [options, setOptions] = useState([]);

  const onReceiveData = (container, children) => {

    setContainer(container);

    setType(container.data.type ?? 'text');
    loadOptions(container.data.options);

  }

  const onNewData = () => {

    setType('text');
    loadOptions([]);

  }

  const loadOptions = (options) => {
    const allOptions = [];
    for (let i = 0; i < 5; i++) {
      if (options[i]) {
        console.log( "OPTION::", options[i] );
        allOptions.push({ id: i + 1, ...options[i] });
      }
      else {
        allOptions.push({ id: i + 1, display: '', value: 0 })
      }
    }
    setOptions(allOptions);
  }

  const changeOptionsType = (newType) => {

    setType(newType);
    loadOptions([]);

  }

  const setItemProp = (item, prop, value) => {
    item[prop] = value;
    setOptions([...options]);
  }

  const setOptionIdValue = (option, id, value) => {
    if( !option.values ) option.values = {};
    if( value === '' || parseInt(value) === 0 )
    {
      delete option.values[id];
    }
    else
    {
      option.values[id] = parseInt(value);
    }
    console.log( options );
    setOptions([...options]);
  }

  const customDataFields = <div>
    <FormControl>
      <InputLabel htmlFor={'question_type'}>Tipo da Pergunta</InputLabel>
      <Select
        native
        value={type}
        onChange={(e) => changeOptionsType(e.target.value)}
        inputProps={{
          id: 'question_type',
        }}
      >
        <option value='text'>Opções em Texto</option>
        <option value='images'>Opções em Imagens</option>
      </Select>
    </FormControl>

    <div className='quiz-question-options-container'>
      {options.map((item, index) => (
        <div key={`quizOption-${index}`} className='question'>
          <div className='option-label'>{`Opção ${index + 1}`}</div>
          {type === 'text' &&
            <TextField
              key={`txtOption_${index}`}
              label="Texto"
              value={item.display}
              onChange={(e) => { setItemProp(item, 'display', e.target.value) }}
            />
          }
          {type === 'images' &&
            <>
              <div className="field-label">Imagem</div>
              <FileUploader onChange={(url) => { setItemProp(item, 'display', url) }} folder={'quiz/options'} imageUrl={item.display} maxFileSize={3} />
            </>
          }
          {quizType === 'range' &&
            <TextField
              key={`txtScore_${index}`}
              label="Pontuação"
              type="number"
              value={item.value}
              onChange={(e) => { setItemProp(item, 'value', parseInt(parseIntInput(e.target.value))) }}
            />
          }
          {quizType === 'scoring' &&
            <table cellPadding="0" cellSpacing="0">
              <thead>
                <tr>
                  <th>Identificador</th>
                  <th>Pontuação</th>
                </tr>
              </thead>
              <tbody>
                {results.map((res) => (
                  <tr key={`${res.id}-${index}`}>
                    <td>{res.id}</td>
                    <td>
                      <InputBase
                        value={item.values?.[res.id] ?? ''}
                        onChange={ (e) => setOptionIdValue(item, res.id, parseIntInput(e.target.value, true)) }
                        placeholder="Pontuação"
                       />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          }
        </div>
      ))}
    </div>

  </div>;

  const onBeforeSaveData = (postData) => {
    postData.name = 'Pergunta do Quiz Id - ' + parentId;
    postData.thumbnail = '-';

    const opts = [];
    //Limpeza do options antes de enviar...
    options.forEach( option => {
      if( quizType === 'range' )
      {
        delete option.values;
      }
      else if ( quizType === 'scoring' )
      {
        delete option.value;
      }
      if( option.display )
      {
        opts.push( option );
      }
    })
    postData.data.options = opts;
    postData.data.type = type;
  }

  const onAfterSaveData = (postData) => {
    onSave(postData);
  }

  return (
    <Dialog open={open} className='edit-quiz-question-dialog'>
      <div >
        {open &&
          <ModuleEditing
            isNew={!questionId}
            typeModuleId={32}
            parentId={parentId.toString()}
            moduleId={questionId ? questionId.toString() : null}
            title="Detalhe de Pergunta de Quiz"
            customDataFields={customDataFields}
            onReceiveData={onReceiveData}
            onNewData={onNewData}
            onBeforeSaveData={onBeforeSaveData}
            onAfterSaveData={onAfterSaveData}
            onBack={onClose}
            hideNameField
            hideInfluences
            hideRewards
            hideThumbnailField
            noBox
          >
          </ModuleEditing>
        }
      </div>
    </Dialog>
  );
}

QuizQuestionEditor.propTypes = {
  quizType: PropTypes.string.isRequired,
  data: PropTypes.object,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
};

export default QuizQuestionEditor