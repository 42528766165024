import React, { Component } from 'react';
import './calcIndice.scss';

import { Helmet } from "react-helmet";
import { Grid } from '@material-ui/core';
import { watch } from 'redux-easy/lib/redux-easy';
import { withRouter } from 'react-router-dom';

import InitialModalQuiz from '../../components/quiz/InitialModalQuiz';

import Logo from '../../../assets/logo/MBED_horizontal_cores.png';
import Globals from '../../../Globals.json';

const QuestionarioIndice = require("../../../QuestionarioIndice.json");

class CalcIndice extends Component {

  state = {
    open: true,
  }

  closeModal = () => {
    this.setState({ open: false })
  }

  componentDidMount = () => {
    if (Number(this.props.user.profile.isfb) > Number(0)) {
      this.props.history.push(Globals.paths.home)
    }
  }

  render() {
    return (

      <Grid container className="calc_indice_container">
        <Helmet>
          <title>Indice | Meu bolso em dia</title>
        </Helmet>

        {this.state.open ?
          <InitialModalQuiz onClose={this.closeModal} open={this.state.open} quizData={QuestionarioIndice} />
          :
          <div className="calc_indice">
            <img src={Logo} alt="Logo Meu Bolso em Dia" />
          </div>
        }
      </Grid>
    )
  }
}

export default watch(withRouter(CalcIndice), { user: '' });
