import styled, { css } from 'styled-components';
import { withTheme } from "@material-ui/core/styles";
import { ReactComponent as MiniLogo } from '../../../../assets/logo/mini-logo.svg'
import { ReactComponent as FaqLeftIcons } from '../../../../assets/faq/faq_icons_left.svg';
import { ReactComponent as FaqRightIcons } from '../../../../assets/faq/faq_icons_right.svg';
import { ReactComponent as FaqSnakesLeft } from '../../../../assets/faq/faq_snakes_left.svg';
import { ReactComponent as FaqSnakesRight } from '../../../../assets/faq/faq_snakes_right.svg';

export const Faq = withTheme(styled('section')`
  background: radial-gradient(50% 50% at 33% 43%, #fff 44.27%, #e5e5e5 100%);
  padding-top: 130px;
  
  @media(max-width: ${({ theme }) => `${theme.breakpoints.values.sm}px`}) {
    padding-top: 120px;
    min-height: initial;
  }

  .faqHeadIcons{
    @media(
      max-width: ${({ theme }) => `${theme.breakpoints.values.md}px`}) {
        display: none;
    }
  }
  
  .faqHead{
    height: 287px;
    background-color: #FCDBCF;
    margin-bottom: 50px;
  }

  .center {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    font-size: 4rem;
    color: #004178
    text-align: center

    .subtile{
      font-size: 1.3rem;
      color: #000000;
      margin-right: 20px;
      margin-left: 20px;
    }
  }

  .inner-wrapper {
    align-items: center;
    display: flex;
    max-width: 1440px;
    margin: auto;
    padding-bottom: 25px;

    @media(max-width: ${({ theme }) => `${theme.breakpoints.values.sm}px`}) {
      flex-direction: column;
      box-sizing: initial;
    }

    @media(
      min-width: ${({ theme }) => `${theme.breakpoints.values.sm}px`}
    ) and (max-width: ${({ theme }) => `${theme.breakpoints.values.md}px`}) {
      flex-direction: column; 
      max-height: initial;
    }


    .acordHead {
      background-color: #4FAF98;
      margin-bottom: 50px;

      @media(
        min-width: ${({ theme }) => `${theme.breakpoints.values.md}px`}) {
          margin-left: 50px;
          margin-right: 50px;
      }
  
    }
    
    .acordTitle {
      color: #FFFFFF;
      font-size: 1.3rem;
      margin-left: 20px;
      margin-right: 20px;
      margin-bottom: 0px;
      margin-top: 0px!important;
    }

    .acordBody {
      color: #787777;
      background-color: #FFFFFF;      
      margin-left: 20px;
      margin-right: 20px;
      padding: 20px !important;
      p{
        margin-bottom: 0px!important;
        font-size: 16px !important;
      }
    }

    .indentParagraph{
      text-indent: 25px;
      text-align: justify;
      text-justify: inter-word;
    }
    .indentParagraph>p{
      margin-top: 10px;
      margin-botton: 10px;
    }

    .MuiIconButton-root{
      color: #FFFFFF;
      margin-right: 20px;
    }

    .MuiCollapse-wrapperInner{
      background-color: #FFFFFF;
    }
    
    .MuiAccordionDetails-root{
      padding: 20px;
    }

    .featured {
      position: relative;
      margin: 0;
      
      &__image {
        margin-left: 130px;
        height: auto;
        max-width: 748px;
        width: 52vw;
        z-index: -1;

        @media(max-width: ${({ theme }) => `${theme.breakpoints.values.sm}px`}) {
          margin: 0;
          width: 90vw;
          margin: auto;
        }

        @media only screen and (
          min-width: ${({ theme }) => `${theme.breakpoints.values.sm}px`}
        ) and (max-width: ${({ theme }) => `${theme.breakpoints.values.md}px`}) {
          margin: 0;
          width: 80vw;
          margin: auto;
        }
      }

      &__shield {
        position: absolute;

        &--super-small {
          width: 62px;
          top: 540px;
          left: 820px;
          filter: blur(5px);
        }
        
        &--small {
          width: 117px;
          top: 530px;
          left: 0;
        }
        
        &--regular {
          width: 137px;
          top: 110px;
          left: 827px;
        }
        
        &--big {
          width: 300px;
          top: 805px;
          left: 1140px;
        }

        @media(max-width: ${({ theme }) => `${theme.breakpoints.values.sm}px`}) {
          display: none;

          &--small {
            display: block;
            top: 400px;
            left: initial;
            right: -100px;
            width: 150px;
          }
        }
      } 
    }
  }
`);

export const Logo = withTheme(styled(MiniLogo)`
  position: absolute;

  ${({ variant }) => variant === 'left' 
    ? css`
      width: 88px;
      top: 730px;
      left: 80px;

      @media(max-width: ${({ theme }) => `${theme.breakpoints.values.sm}px`}) {
        width: 43px;
        top: calc(100vw - (100vh / 10));
        left: 0px;
      }

      @media only screen and (
        min-width: ${({ theme }) => `${theme.breakpoints.values.sm}px`}
      ) and (max-width: ${({ theme }) => `${theme.breakpoints.values.md}px`}) {
        top: 610px;
        left: -70px;
      }

      @media only screen and (
        min-width: ${({ theme }) => `${theme.breakpoints.values.md}px`}
      ) and (max-width: ${({ theme }) => `${theme.breakpoints.values.lg}px`}) {
        top: 510px;
        left: 130px;
      }
    `
    : css`
      width: 85px;
      left: 920px;
      top: 740px;

      @media(max-width: ${({ theme }) => `${theme.breakpoints.values.sm}px`}) {
        width: 42px;
        top: calc(100vw - (100vh / 3.5));
        left: initial;
        right: 10px;
      }

      @media only screen and (
        min-width: ${({ theme }) => `${theme.breakpoints.values.sm}px`}
      ) and (max-width: ${({ theme }) => `${theme.breakpoints.values.md}px`}) {
        top: 330px;
        left: 550px;
      }
    `
  }
`);

export const FaqLeft = withTheme(styled(FaqLeftIcons)`
  @media(
    max-width: ${({ theme }) => `${theme.breakpoints.values.md}px`}) {
      display: none;
  }
`);

export const FaqRight = withTheme(styled(FaqRightIcons)`
  @media(
    max-width: ${({ theme }) => `${theme.breakpoints.values.md}px`}) {
      display: none;
  }
`);

export const SnakesLeft = withTheme(styled(FaqSnakesLeft)`
  position: absolute;
  top: 900px;
  left: 0px;
`);

export const SnakesRight = withTheme(styled(FaqSnakesRight)`
  position: absolute;
  top: 500px;
  right: 0px;
`);