import React, { Component } from 'react'
import './styles/dialogPdf.scss';

import Button from '../../../button/Button';
import Mail from '../../../../../assets/icon/mail.svg';

import { Dialog, DialogContent } from '@material-ui/core'

export default class DialogPdf extends Component {
  render() {
    return (
      <Dialog
        open={this.props.open}
        maxWidth={"xs"}
        className="modal"
      >
        <DialogContent className="dialogPdf_content">
          <img src={Mail} alt="..." />

          <strong>E-mail enviado</strong>

          <p>Um e-mail foi enviado para o usuário com um link para baixar o documento em PDF.</p>
          <span>ID: {this.props.id}</span>

          <Button text-align="center" icon="none" type="primary" onClick={this.props.onClose}>VOLTAR</Button>

        </DialogContent>
      </Dialog>
    )
  }
}