//import moment from 'moment';
import moment from 'moment/min/moment-with-locales';
import api from './api';

const Globals = require("../Globals.json");

export const getNextIndexAssessmentDateText = async () => {
    moment.locale('pt-br');

    //const profile = await api.get(Globals.api.selfInfo);

    const date = moment().add(1, 'M');
    //TO-DO
    //Pegar data real; ${date.fromNow()}

    return `O Índice de Saúde Financeira é um indicador criado para entender como é a sua relação com o seu dinheiro. 
            Sua pontuação no Índice nos ajuda a entender quais são os fatores que podem estar impedindo você de ter
            uma vida financeira saudável em um determinado período da vida. Você poderá refazer o questionário para saber a sua evolução do seu perfil e no controle das suas finanças. Para isso, é importante manter o seu questionário sempre atualizado.`;
}

export const formatMinutesDisplay = (minutes) => {
    const restMin = minutes % 60;
    const restHours = Math.floor((minutes - restMin) / 60);
    let lbl1 = '';
    let lbl2 = '';
    if (restHours > 0) {
        lbl1 = restHours + 'h';
        lbl2 = restHours + ' hora' + (restHours > 1 ? 's' : '');
    }
    if (restHours === 0 || restMin > 0) {
        lbl1 += restMin + 'm';
        if (restHours > 0) {
            lbl2 += ' e ';
        }
        lbl2 += restMin + ' minutos';
    }
    if (restMin === 0 && restHours > 1) {
        lbl1 += 's';
    }
    return { short: lbl1, full: lbl2, hours: restHours, minutes: restMin };
}

export const getCoinsFromRewards = (rewards) => {
    let coins = 0;

    if (rewards) {
        rewards.forEach(reward => {
            if ((+reward.asset_id === 5 || +reward.dataAssetDetailId === 5) && reward.amount) {
                coins += reward.amount;
            }
        })
    }

    return coins;
}

export const getCoinsTextFromRewards = (rewards) => {

    let numberCoins = 0;
    let coins = "";

    if (rewards) {
        numberCoins = getCoinsFromRewards(rewards);
        coins = '+ ' + numberCoins + ' pontos';
    }

    return coins;
}

export const getDifficultyLabel = (difficulty) => {
    switch (+difficulty) {
        case 0:
            return "Iniciante";
        case 1:
            return "Intermediário";
        case 2:
            return "Avançado";
        default:
            return "";
    }
}

export const parseIntInput = (str, allowEmpty = false) => {
    if (str.length === 0) return allowEmpty ? '' : '0';
    let ret = str;
    ret = ret.replace(/[^0-9]/g, '');
    ret = parseInt(ret);
    if (isNaN(ret)) return '0';
    return ret.toString();
}

export const parseIdentifierInput = (str) => {
    let ret = str.normalize("NFD").replace(/[\u0300-\u036f]/g, ""); //remove acentuação
    ret = ret.replace( / /g, '-' );
    ret = ret.replace( /-{2,}/g, '-' );
    ret = ret.replace( /[^a-z0-9\-]/g, '' );
    // console.log( str, ret );
    return ret.toLowerCase();
}
