import api from "./api";
import WebSocket from "./websocket";

const Globals = require("../Globals.json");

export const TOKEN_KEY = "@fbb-Token";
export const isAuthenticated = () => localStorage.getItem(TOKEN_KEY) !== null;
export const getToken = () => localStorage.getItem(TOKEN_KEY);

export const login = token => {
  localStorage.setItem(TOKEN_KEY, token);
};
export const logout = () => {
  localStorage.removeItem(TOKEN_KEY);
  WebSocket.disconnect();
};

export const canAccessAdminArea = async () => {  
  
  const roles = ['Admin'];

  try {
    const res = await api.get(Globals.api.selfInfo);
    const userRoles = res.data.Roles;
    
    if (userRoles) {
      return !!userRoles.find(r => roles.indexOf(r.name ) > -1);
    }

    return false;
  }
  catch (error) {
    console.log(error);
  }
}

export const isAdmin = async () => {
  const permission = 'admin.access';

  try {
    const res = await api.get(Globals.api.selfInfo);
    const userRoles = res.data.Roles;
    if (userRoles) {
      return !!userRoles.find(r => r.Permissions.find(p => p.name === permission) != null);
    }

    return false;
  }
  catch (error) {
    console.log(error);
  }
}

export const getStatus = async () => {
  try {
    const res = await api.get(Globals.api.selfInfo);
    if (res.data && res.data.type_users_status_id) {
      return res.data.type_users_status_id;
    }
  } catch (error) {
    console.log(error);
  }

}