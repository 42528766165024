import { FormControl, InputLabel, Select, Slider, TextField } from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import '../../../backoffice.scss';

import ModuleEditing from '../../../logged_in/components/backoffice/components/ModuleEditing/ModuleEditing';
import ChildrenListing from '../../../logged_in/components/backoffice/components/Listing/ChildrenListing';

import DescriptionIcon from '@material-ui/icons/Description';
import GraphicEqIcon from '@material-ui/icons/GraphicEq';
import VideocamIcon from '@material-ui/icons/Videocam';
import DynamicFeedIcon from '@material-ui/icons/DynamicFeed';
import ListAltIcon from '@material-ui/icons/ListAlt';

import Globals from "../../../Globals.json";
import Button from '../../components/button/Button';
import { formatMinutesDisplay } from '../../../services/util';


export default function EditClassDetails() {

  const params = useParams();
  const history = useHistory();

  const [parent, setParent] = useState({});
  const [container, setContainer] = useState({});
  const [itemChildren, setItemChildren] = useState([]);
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const [dataDifficulty, setDataDifficulty] = useState(0);
  const [dataDuration, setDataDuration] = useState(15);

  const [isNew, setIsNew] = useState(params.classId === "new");

  const defaultAddMessage = "Adicionar novo conteúdo";
  const [addNewItemMessage, setAddNewItemMessage] = useState(defaultAddMessage);


  const onReceiveData = (container, children, parent) => {

    setContainer(container);
    setItemChildren(children);

    setDataDifficulty(container.data.difficulty ?? 0);
    setDataDuration(container.data.duration ?? 15);

    setParent(parent);

    setBreadcrumbs([
      {
        label: "Cursos",
        link: Globals.paths.backofficeCourses
      },
      {
        label: parent.name,
        link: Globals.paths.backofficeEditCourse.replace(":courseId", parent.id)
      },
      {
        label: container.name
      }
    ]);

  }

  const onNewData = (parent) => {

    console.log("ONNEWDATA::", parent);
    setParent(parent);

    setBreadcrumbs([
      {
        label: "Cursos",
        link: Globals.paths.backofficeCourses
      },
      {
        label: parent.name,
        link: Globals.paths.backofficeEditCourse.replace(":courseId", parent.id)
      },
      {
        label: "Nova Aula"
      }
    ]);
  }

  const onBeforeSaveData = (postData) => {
    postData.thumbnail = "-";
    postData.data.difficulty = dataDifficulty;
    postData.data.duration = dataDuration;
  }

  const onAfterSaveData = (savedItem, responseData) => {
    if (isNew) {
      setTimeout(() => {
        history.push(Globals.paths.backofficeEditClass.replace(":courseId", parent.id).replace(":classId", responseData.id));
      }, 50)

    }
    else {
      history.goBack();
    }
  }

  const editContent = (content) => {

    let url = '';

    if (content.type_module_id === 15) {
      url = Globals.paths.backofficeEditClassContentArticle
        .replace(":courseId", parent.id)
        .replace(":classId", container.id)
        .replace(":contentId", content.id)
    }
    else if (content.type_module_id === 13) {
      url = Globals.paths.backofficeEditClassContentVideo
        .replace(":courseId", parent.id)
        .replace(":classId", container.id)
        .replace(":contentId", content.id)
    }
    else if (content.type_module_id === 14) {
      url = Globals.paths.backofficeEditClassContentAudio
        .replace(":courseId", parent.id)
        .replace(":classId", container.id)
        .replace(":contentId", content.id)
    }
    else if (content.type_module_id === 35) {
      url = Globals.paths.backofficeEditClassContentStory
        .replace(":courseId", parent.id)
        .replace(":classId", container.id)
        .replace(":contentId", content.id)
    }
    else if (content.type_module_id === 29) {
      url = Globals.paths.backofficeEditClassContentQuiz
        .replace(":courseId", parent.id)
        .replace(":classId", container.id)
        .replace(":contentId", content.id)
    }

    history.push(url);

  }

  const MiniIconButton = ({ icon, type, url }) => {

    const urlNew = (url ?? '').replace(":courseId", parent.id)
      .replace(":classId", container.id)
      .replace(":contentId", "new");

    return (
      <div className="button-add"
        onMouseEnter={() => setAddNewItemMessage('Adicionar novo ' + type)}
        onMouseLeave={() => setAddNewItemMessage(defaultAddMessage)}
        onClick={() => history.push(urlNew)}
      >
        {React.createElement(icon)}
      </div>
    )

  }

  const MiniIcon = ({ icon }) => {

    if (icon) {
      return React.createElement(icon, { style: { fontSize: "16px", marginRight: "3px" } });

    }


  }

  const customDataFields = <>

    <FormControl>
      <InputLabel htmlFor={'course_level'}>Dificuldade</InputLabel>
      <Select
        native
        value={dataDifficulty}
        onChange={(e) => setDataDifficulty(parseInt(e.target.value))}
        inputProps={{
          id: 'course_level',
        }}
      >
        <option value={0}>Iniciante</option>
        <option value={1}>Intermediário</option>
        <option value={2}>Avançado</option>
      </Select>
    </FormControl>

    <div className="reward-container" style={{ width: '500px', display: 'flex', 'flexDirection': 'column' }}>
      <div style={{ flexGrow: 1, paddingTop: '16px', color: '#0000008a' }}>Duração: {formatMinutesDisplay(dataDuration).full}</div>
      <Slider
        onChange={(e, newValue) => { setDataDuration(newValue) }}
        value={dataDuration}
        // valueLabelFormat={(minutes) => formatMinutes( minutes ) }
        // valueLabelDisplay="auto"
        aria-labelledby="discrete-slider"
        step={15}
        marks
        min={15}
        max={5 * 60}
      />
    </div>
  </>;


  const mapTypes = {
    15: "Artigo",
    13: "Vídeo",
    14: "Áudio",
    35: "Story",
    29: "Quiz Pós-Aula",
  }

  const mapIcons = {
    15: DescriptionIcon,
    13: VideocamIcon,
    14: GraphicEqIcon,
    35: DynamicFeedIcon,
    29: ListAltIcon,
  }

  return (

    <ModuleEditing
      isNew={isNew}
      moduleId={params.classId}
      typeModuleId={6}
      parentId={params.courseId}
      title="Detalhe de Aula"
      customDataFields={customDataFields}
      onReceiveData={onReceiveData}
      onBeforeSaveData={onBeforeSaveData}
      onAfterSaveData={onAfterSaveData}
      onNewData={onNewData}
      hideThumbnailField
      breadcrumbs={breadcrumbs}
      hideDeletedContent={true}
    >
      {!isNew &&
        <div className="class-content-container" style={{ marginTop: "30px" }}>
          {itemChildren.map((item, index) => (
            <div key={index} className="class-content-card" style={{ opacity: item.type_module_status_id === 0 ? .5 : 1 }}>
              <div className="class-content-type">
                {mapIcons[item.type_module_id] &&

                  <MiniIcon icon={mapIcons[item.type_module_id] ?? ''} />

                }

                {mapTypes[item.type_module_id] ?? '-'}


              </div>
              <div className="class-content-text">
                {item.name}
              </div>
              <div className="class-content-buttons">
                <Button
                  icon="none"
                  type={'darkBlue'}
                  textAlign="center"
                  height="25px"
                  width="100%"
                  onClick={() => editContent(item)}
                >EDITAR</Button>
              </div>
            </div>
          ))}
          <div key={'add'} className="class-content-add">
            <div className="class-content-buttons">
              <MiniIconButton url={Globals.paths.backofficeEditClassContentArticle} icon={DescriptionIcon} type="artigo/texto" />
              <MiniIconButton url={Globals.paths.backofficeEditClassContentAudio} icon={GraphicEqIcon} type="áudio" />
              <MiniIconButton url={Globals.paths.backofficeEditClassContentVideo} icon={VideocamIcon} type="vídeo" />
              <MiniIconButton url={Globals.paths.backofficeEditClassContentStory} icon={DynamicFeedIcon} type="story" />
              {!itemChildren.find(i => i.type_module_id === 29) &&
                <MiniIconButton url={Globals.paths.backofficeEditClassContentQuiz} icon={ListAltIcon} type="quiz pós-aula" />
              }
            </div>
            <div className="class-content-text">
              {addNewItemMessage}
            </div>
          </div>
        </div>
      }
    </ModuleEditing>

  );
}

