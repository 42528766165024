import React from 'react';
import './styles/currentLevel.scss';

import { Grid, Box } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { watch } from 'redux-easy/lib/redux-easy';

const Globals = require("../../../Globals.json");

class Profile extends React.Component {

  state = {
    userLevel: 0
  }

  componentDidMount () {
    if (this.props.user && this.props.user.profile.progress) {
      this.setState({ userLevel: this.props.user.profile.progress.level });
    }
  }

  componentDidUpdate (oldProps) {
    if (JSON.stringify(this.props.user) !== JSON.stringify(oldProps.user)) {
      if (this.props.user && this.props.user.profile.progress) {
        this.setState({ userLevel: this.props.user.profile.progress.level });
      }
    }
  }

  render () {

    return (
      <>
        <Grid item xs={8}>
          <Grid container className="current_level_pL">
            <ul className="current_level_ul_study">
              <li>
                <label className="current_level_text_value_level">
                  {this.state.userLevel}
                </label>
                <div className="current_level_containerText">
                  {this.state.userLevel > 1 &&
                    <span className="current_level_msg">Confira os benefícios para o seu nível. <Link to={Globals.paths.progresso} style={{ color: "#0A4B7F", textDecoration: "none" }}>Veja mais</Link></span>
                  }
                </div>
              </li>
            </ul>
          </Grid>
        </Grid>
        <Grid item xs={4}>
          <Grid container>
            <Box>
              <img className="current_level_icon_study img-fluid" alt={`Imagem do Nível ${this.state.userLevel}`} src={`https://pefmbddiag.blob.core.windows.net/cdn/images/icon/level_${+this.state.userLevel}.png`}></img>
            </Box>
          </Grid>
        </Grid>
      </>
    )
  }
}

export default watch((Profile), { user: '' });
