import React, { Component } from 'react';
import './ToolPlanning.scss';

import { Link } from 'react-router-dom';
import { Grid, Breadcrumbs } from '@material-ui/core';

import NumberFormat from 'react-number-format';

import { Helmet } from "react-helmet";

import AlertErrors from '../../components/alertErrors/alerts';

import api from '../../../services/api';

import Button from '../../components/button/Button';
import ToolList from '../../components/tool/ToolList'

const Globals = require("../../../Globals.json");

class ToolPlanning extends Component {

  state = {
    thumbnail: "https://pefmbddiag.blob.core.windows.net/cdn-pi/imagens/praia.jpg",
    title: "Simulador de viagens",
    subtitle: "Descubra quanto você precisa poupar por mês para chegar ao seu objetivo",
    dreamAmountRadio: '',
    dreamAmount: '',
    moneySaved: '',
    toSave: '',
    otherMoney: false,
    invalid: false,
    showResult: false,
    finalResult: '',
    alertError: '',
    optionsTosave: [],
    errorMsg: null,
  }

  testAgain () {
    this.setState({
      dreamAmountRadio: '',
      dreamAmount: '',
      moneySaved: '',
      toSave: '',
      otherMoney: false,
      invalid: false,
      showResult: false,
      finalResult: '',
      optionsTosave: []
    })
  }

  clean (str) {
    return str.replace(/[()-. ]/g, '');
  }

  maskValue (input, mask) {
    return input.value;//input.value.replace(',', '')

    let str = this.clean(input.value);
    let counter = 0;
    let ret = "";
    for (let i = 0; i < mask.length; i++) {
      if (counter >= str.length) break;

      let char = mask.charAt(i);
      if (char === "#") {
        ret += str.charAt(counter);
        counter++;
      }
      else {
        ret += char;
      }
    }

    return ret;

  }
  removeValidation (key) {
    const obj = { ...this.state.fieldsValidation };
    delete obj[key];
    this.setState({ fieldsValidation: obj });
  }

  savePerMonth (save) {

    const { dreamAmountRadio, dreamAmount, moneySaved } = this.state

    let dreamValue = dreamAmountRadio !== '' ? dreamAmountRadio : dreamAmount

    let acum = moneySaved;
    let mensal = save;
    let count_mes = 0
    while (acum < parseFloat(dreamValue)) {
      count_mes++;
      acum *= 1.005;
      acum += parseFloat(mensal);
    }

    return `${count_mes} meses, reservando R$ ${save} por mês.`
  }

  async send () {
    const { moneySaved, toSave } = this.state;
    let dreamAmount = this.state.dreamAmountRadio !== '' ? this.state.dreamAmountRadio : this.state.dreamAmount;

    this.setState({ invalid: false })

    if (moneySaved === '' || toSave === '' || dreamAmount === '') {
      this.setState({ invalid: true, errorMsg: "Para fazer o cálculo, preencha todos os campos." })
      return
    }

    if (parseFloat(moneySaved) >= parseFloat(dreamAmount)) {
      this.setState({ invalid: true, errorMsg: "Para o cálculo, o custo da viagem deve ser menor do que o valor já guardado." })
      return
    }

    let valuesTosave = []

    valuesTosave.push(this.savePerMonth(toSave))
    valuesTosave.push(this.savePerMonth(toSave * .75))
    valuesTosave.push(this.savePerMonth(toSave * .50))

    this.setState({
      optionsTosave: valuesTosave,
      showResult: true
    })

    let inputUserData = {
      dreamAmount: this.state.dreamAmountRadio !== '' ? this.state.dreamAmountRadio : this.state.dreamAmount,
      moneySaved: this.state.moneySaved,
      toSave: this.state.toSave,
    }

    const postData = {
      type_action_id: 26,
      data_modules_detail_id: 189,
      type_module_id: 21,
      simulationData: [inputUserData, valuesTosave]
    };

    try {
      await api.post(Globals.api.userActionEvent, postData);
    } catch (error) {
      this.setState({ alertError: error.response.status })
      setTimeout(() => {
        this.setState({ alertError: '' })
      }, 3000)
    }
  }

  handleChange (e) {
    this.setState({ dreamAmountRadio: e.target.value, otherMoney: false })
  }

  render () {

    return (
      <>
        <Grid >
          <Grid item container xs={12} sm={8} md={9} direction="column" className="tool_classContainer">
            <AlertErrors error={this.state.alertError} />
            <div className="tool_cardImage" style={{ backgroundImage: `url(${this.state.thumbnail})` }}>
              <div className="tool_shadon">

                <Breadcrumbs aria-label="breadcrumb" className="tool_planning_breadCrumb">
                  <Link color="inherit" to={Globals.paths.tools} className="tool_planning_breadCrumbLink">
                    Ferramentas Financeiras
                  </Link>
                </Breadcrumbs>

                <h1 className="tool_title"> {this.state.title} </h1>
              </div>
            </div>

            <Helmet>
              <title>Ferramentas | Meu bolso em dia</title>
            </Helmet>

            <div className="tool_subtitle"> {this.state.subtitle} </div>

            {!this.state.showResult ? (
              <div className="tool_container">
                <div className="tool_containerQuestions">

                  <span className="toolsQuestion">
                    1. Quanto vai custar sua viagem?
                  </span>

                  <div className="toolsAnswers">
                    <input
                      onChange={e => this.handleChange(e)}
                      type="radio"
                      name="alternative"
                      value="1000"
                    />
                    <span className="toolsTxtAnswers">R$ 1.000,00</span>
                  </div>

                  <div className="toolsAnswers">
                    <input
                      onChange={e => this.handleChange(e)}
                      type="radio"
                      name="alternative"
                      value="5000"
                    />
                    <span className="toolsTxtAnswers">R$ 5.000,00</span>
                  </div>

                  <div className="toolsAnswers">
                    <input
                      onChange={e => this.handleChange(e)}
                      type="radio"
                      name="alternative"
                      value="10000"
                    />
                    <span className="toolsTxtAnswers">R$ 10.000,00</span>
                  </div>

                  <div className="toolsAnswers">
                    <input
                      type="radio"
                      name="alternative"
                      onChange={() => this.setState({ dreamAmountRadio: '', otherMoney: true })}
                    />
                    <span className="toolsTxtAnswers">Outro: </span>
                    {this.state.dreamAmountRadio === '' && this.state.otherMoney &&
                      <NumberFormat
                        prefix="R$ "
                        decimalSeparator=","
                        thousandSeparator="."
                        decimalScale={2}
                        fixedDecimalScale
                        onValueChange={values => { this.setState({ dreamAmount: values.floatValue }) }}
                        type="text"
                        value={this.state.dreamAmount}
                        placeholder="R$"
                        className="tool_inputText"
                      />
                    }
                  </div>
                </div>

                <div className="tool_containerQuestions">
                  <span className="toolsQuestion">
                    2. Quanto dinheiro você já tem guardado?
                </span>
                  <div className="toolsAnswers">
                    <NumberFormat
                      prefix="R$ "
                      decimalSeparator=","
                      thousandSeparator="."
                      decimalScale={2}
                      fixedDecimalScale
                      onValueChange={values => { this.setState({ moneySaved: values.floatValue }) }}
                      type="text"
                      value={this.state.moneySaved}
                      placeholder="R$"
                      className="tool_inputText"
                    />
                  </div>
                </div>

                <div className="tool_containerQuestions">
                  <span className="toolsQuestion">
                    3. Quanto você pode se comprometer a economizar por mês?
                </span>
                  <div className="toolsAnswers">
                    <NumberFormat
                      prefix="R$ "
                      decimalSeparator=","
                      thousandSeparator="."
                      decimalScale={2}
                      fixedDecimalScale
                      onValueChange={values => { this.setState({ toSave: values.floatValue }) }}
                      type="text"
                      value={this.state.toSave}
                      placeholder="R$"
                      className="tool_inputText"
                    />
                  </div>
                </div>
                <div className="tool_contentButton">
                  <Button width="180px" type="darkBlue" onClick={() => this.send()}>Planejar</Button>

                </div>
                {this.state.errorMsg && <div style={{ textAlign: 'center', color: 'red', marginBottom: "10px", fontWeight: "bold" }}>{this.state.errorMsg}</div>}
              </div>

            ) : (
              <div className="tool_container">
                <div className="tool_containerQuestions">
                  <span className="toolsQuestion">
                    Você pode chegar no seu objetivo em:
                </span>

                  <ul className="tool_list">
                    {this.state.optionsTosave !== undefined && (
                      this.state.optionsTosave.map((item, index) => (
                        <li className="tool_listItem" key={index}>{item}</li>
                      ))
                    )}
                  </ul>

                  <div className="toolContainerButton">
                    <Button width="150px" textAlign="center" icon="none" onClick={() => this.testAgain()}>REFAZER</Button>
                  </div>
                </div>
              </div>
            )}
          </Grid>

        </Grid >

        <div className="tools">
          <h1 className="tool_titleTool"> Nossas Ferramentas </h1>
          <div className="content_tool_list">
            <ToolList />
          </div>
        </div>
      </>
    )
  }
}
export default ToolPlanning;
