import React, { Component } from 'react';
import './styles/privacity.scss';
import './styles/login.scss';

import { watch } from 'redux-easy/lib/redux-easy';
import { InputAdornment, TextField } from '@material-ui/core';
import { withRouter } from 'react-router-dom';

import VisibilityPasswordTextField from '../../../../../shared/components/VisibilityPasswordTextField';
import Mensagem from '../../../../../assets/mensagem.png';
import CircularProgress from '@material-ui/core/CircularProgress';

import DialogPdf from './DialogPdf';
import Button from '../../../button/Button';
import Dados from './Dados';
import api from '../../../../../services/api';
import { Alert } from '@material-ui/lab';
import moment from 'moment';

const Globals = require('../../../../../Globals.json');

class Privacity extends Component {
  state = {
    loading: false,
    email: '',
    password: '',
    desempenho: '',
    isfb: '',
    coin: '',
    certificate: '',
    trophies: '',
    pdf: false,
    msg: '',
    displayWarning: true,
    login: 0,
    mailId: '',
    username: '',
    userPhone: '',
    userCpf: '',
    userEmail: '',
    userAddress: '',
    userCep: '',
    userState: '',
    userCity: '',
    userGender: '',
    userBirthdate: '',
    userIncome: '',
    userEducation: '',
    open: false,
    data: '',
    failed: false,
    hours: '',
    indexData: {
      indexScore: 0,
    },
  };

  handleLogin = async () => {
    this.setState({ loading: true });
    if (!this.state.email || !this.state.password) {
      this.setState({ loading: false, msg: `Por favor, insira todos os dados.` });
    } else {
      const userId = this.props.match.params.userId;
      const postData = {
        email: this.state.email,
        password: this.state.password,
      };

      await api
        .post(Globals.api.userPrivacy + userId, postData)
        .then((response) => {
          let address;

          const { data } = response;

          this.setState({
            login: 2,
            desempenho: data.desempenho,
            isfb: data.indexData.indexScore,
            coin: data.progress.total_moedas,
            certificate: data.total_certificado,
            trophies: data.total_trofeis,
            userName: data.username,
            userCpf: data.cpf_cnpj || '',
            userEmail: data.email,
            userActive: typeof data.TypeUsersStatus === 'undefined' ? '' : data.TypeUsersStatus.id,
            userPhone: data.phone,
            userAddress: !data.CadUserAddressesOne ? '' : data.CadUserAddressesOne.landmark,
            userCep: !data.CadUserAddressesOne ? '' : data.CadUserAddressesOne.zip_code,
            userState: this.getState(data.CadUserAddressesOne),
            userCity: this.getCity(data.CadUserAddressesOne),
            userGender: this.getGender(data.type_gender_id),
            userBirthdate: data.birth_date ? this.formatDate(data.birth_date.replace('T00:00:00.000Z', 'T03:00:00.000Z')) : '',
            userEducation: this.getEducation(data),
            userIncome: this.getIncome(data),
            loading: false,
            indexData: data.indexData,
          });
        })
        .catch((err) => {          
          console.log(err);
          this.setState({
            loading: false,
            msg: `Dados inválidos. Por favor, verifique os dados informados.`,
          });
        });
    }
  };

  getState(data) {
    if (data && data.CadIbgeState) {
      return data.CadIbgeState.state;
    }

    return '';
  }

  getCity(data) {
    if (data && data.CadIbgeCounty) {
      return data.CadIbgeCounty.county;
    }

    return '';
  }

  getIncome(data) {
    if (data && data.type_income) {
      return data.type_income.description;
    }
    return '';
  }

  getEducation(data) {
    if (data && data.type_education) {
      return data.type_education.description;
    }
    return '';
  }

  formatDate(date) {
    if (date) return moment(date).format('DD/MM/YYYY');
    return '';
  }

  getGender (typeGender) {
    switch (typeGender) {
      case 1:
        return 'Masculino';
      case 2:
        return 'Feminino';
      case 3:
        return 'Prefiro não informar';
      default:
        return '';
    }
  }

  handleData = () => {
    let now = moment();

    this.setState({ data: now.format('DD/MM/YYYY'), hours: now.format('HH:mm') });
  };

  generatePdf = () => {
    const id = this.props.match.params.userId;
    api
      .post(Globals.api.backofficeGeneratePdf, {
        user_id: id,
      })
      .then((response) => {
        this.setState({ open: true, mailId: response.data.id });
      })
      .catch((err) => {
        this.setState({
          failed: true,
          errorMessage: 'Ocorreu um erro na comunicação com o servidor!',
        });
        setTimeout(() => {
          this.setState({ failed: false, errorMessage: '' });
        }, 3000);
      });
  };

  componentDidMount = () => {
    this.setState({ login: 0 });
    this.handleData();
  };

  render() {
    return (
      <div>
        {this.state.failed && (
          <Alert className="alertEditUser" severity="error">
            {this.state.errorMessage}
          </Alert>
        )}

        <div className="editHeaderForm">
          <div className="displayName">
            <strong>Privacidade:</strong>
            <span>{this.props.username}</span>
          </div>
        </div>
        <DialogPdf
          open={this.state.open}
          onClose={() => this.setState({ open: false })}
          id={this.state.mailId}
        />

        {this.state.login === 0 ? (
          <div className="login_user_container">
            <div className="login_user_card centerAlign">
              <strong style={{ color: '#004178' }}>Atenção</strong>
              <span>
                Esta ação será <b>registrada</b> e <b>informada</b> ao administrador
              </span>
              <Button
                left="170px"
                icon="none"
                height="36px"
                width="150px"
                type="darkBlue"
                textAlign="center"
                onClick={() => this.setState({ login: 1 })}
              >
                ACESSAR
              </Button>
            </div>
          </div>
        ) : this.state.login === 1 ? (
          <div className="login_user_container">
            <div className="login_user_card">
              <header>
                <strong>Dados carregados</strong>
                <span>Para visualizá-los insira seu login e senha de acesso:</span>
                {/* <span>Esta ação será registrada e informada ao administrador master.</span> */}
              </header>

              <label>E-mail</label>
              <TextField
                placeholder="Digite seu email"
                variant="outlined"
                value={this.state.email}
                onChange={(e) => this.setState({ email: e.target.value })}
                multiline
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <img src={Mensagem} alt="mensagem" />
                    </InputAdornment>
                  ),
                }}
              />

              <label>Senha</label>
              <VisibilityPasswordTextField
                className="inputField"
                variant="outlined"
                required
                fullWidth
                inputRef={this.state.showPassword}
                autoComplete="off"
                onChange={(e) => this.setState({ password: e.target.value })}
                isVisible={this.state.isPasswordVisible}
                onVisibilityChange={(val) => {
                  this.setState({ isPasswordVisible: val });
                }}
                placeholder="Digite sua senha"
              />
              <div
                className={`privacity_buttonPosition2 ${
                  this.state.loading && 'privacity_isLoading2'
                }`}
              >
                <Button
                  left="170px"
                  icon="none"
                  height="36px"
                  width="150px"
                  type="darkBlue"
                  text-align="center"
                  onClick={() => this.handleLogin()}
                >
                  {this.state.loading === true ? <CircularProgress /> : 'acessar'}
                </Button>
              </div>

              {this.state.loading === false && (
                <div className="msgPrivactiy">
                  {this.state.msg.substring(0, 1).toUpperCase().concat(this.state.msg.substring(1))}
                </div>
              )}
            </div>
          </div>
        ) : (
          this.state.login === 2 && (
            <>
              <div className="privacity_hrs">
                Atualizado {this.state.data} às {this.state.hours}
              </div>
              <div className="privacity_border">
                <Dados
                  name={this.state.userName}
                  email={this.state.userEmail}
                  phone={this.state.userPhone}
                  landmark={this.state.userAddress}
                  zip_code={this.state.userCep}
                  state={this.state.userState}
                  city={this.state.userCity}
                  cpf={this.state.userCpf}
                  gender={this.state.userGender}
                  birthDate={this.state.userBirthdate}
                  userEducation={this.state.userEducation}
                  userIncome={this.state.userIncome}
                  desempenho={this.state.desempenho}
                  isfb={this.state.isfb}
                  coin={this.state.coin}
                  certificate={this.state.certificate}
                  trophies={this.state.trophies}
                  pdf={this.state.pdf}
                  indexData={this.state.indexData}
                />
              </div>
            </>
          )
        )}

        {this.state.login === 2 && (
          <div className={`privacity_buttonPosition ${this.state.pdf && 'privacity_isLoading'}`}>
            <Button
              icon="none"
              height="36px"
              width="150px"
              type="darkBlue"
              text-align="center"
              onClick={() => this.generatePdf()}
            >
              {this.state.loading === true ? <CircularProgress /> : 'GERAR PDF'}
            </Button>
          </div>
        )}
      </div>
    );
  }
}

export default watch(withRouter(Privacity), { user: '' });
