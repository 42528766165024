import React from 'react';
import { withStyles, Grid, Button, CircularProgress, Hidden } from '@material-ui/core';
import api from '../../services/api';
import AlertErrors from '../../logged_in/components/alertErrors/alerts';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import './registerStepper2.scss';
import Globals from '../../Globals.json';
import { logout } from '../../services/auth';
import { watch } from 'redux-easy/lib/redux-easy';

const styles = theme => ({

  root: {
    flexGrow: 1,
    padding: "20px 50px 20px 50px",
    maxHeight: "60vh",
    overflow: "auto",
    "@media (max-width: 700px)": {

      overflow: "auto",
      height: "400px"
    }
  },
  button: {
    marginLeft: "5px",
    marginRight: "5px",
    borderRadius: "0px",
  },
  buttonDisabled: {
    marginLeft: "5px",
    marginRight: "5px",
    backgroundColor: "#999",
  },
  description:
  {
    fontSize: "20px",
    color: "#707070",
    textAlign: "center",
    marginTop: "-14px",
    marginBottom: "20px",
    "@media (max-width: 700px)": {
      marginTop: "0",
      fontSize: "13px",
      marginBottom: "10px"
    }
  },
  loading:
  {
    display: "flex",
    direction: "row",
    justifyContent: "center",
    alignItems: "center",
    height: "350px",
  },
  containerBtn: {
    display: "flex",
    flexDirection: "row-reverse",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "15px",
  }

});

class RegisterStep4 extends React.Component {

  state = {
    userProfile: {
      username: '',
    },
    personaData: null
  }

  componentDidMount = async () => {
    const userProfile = this.props.user.profile;
    const personaData = userProfile.personaData;

    this.setState({ userProfile, personaData });
  }

  goToBlog() {
    logout();
    window.setTimeout(() => window.location.href = Globals.paths.blogUrl, 10);
  }

  render() {

    const { classes, nextStep } = this.props;

    return (
      <div className={classes.root + " registerStepper2"}>
        <Grid container>
          <Grid item xs={12} className={classes.explanation + ' '}>
            <h1 className="welcome-title mb0">Olá {this.state.userProfile.username}!</h1>
            {this.state.personaData && <h4>Seu resultado foi:</h4>}
          </Grid>
          {this.state.personaData &&
            <Grid container className="profile-content">
              <Grid container item xs={12} justify="center" alignItems="center" className="">
                <Hidden smDown>
                  {
                    this.state.personaData.type > -1 &&
                    <img src={`/images/personas/${this.state.personaData.type}.svg`} width="40%" alt={`imagem da persona ${this.state.personaData.name}`} />
                  }
                </Hidden>
                <Hidden mdUp>
                  {
                    this.state.personaData.type > -1 &&
                    <img src={`/images/personas/${this.state.personaData.type}.svg`} width="40%" alt={`imagem da persona ${this.state.personaData.name}`} />
                  }
                </Hidden>
              </Grid>
              <Grid container item xs={12} justify="center" alignItems="center" className="">
                <h1>{this.state.personaData.name}</h1>
                <p className="persona-description">
                  {this.state.personaData.description}
                </p>
              </Grid>
            </Grid>
          }
          <Grid container item xs={12} className="">
            <h1 className="welcome-title mb0">Em breve estaremos juntos!</h1>
            <p><strong>Obrigado pelo seu interesse na plataforma de educação financeira da Febraban.</strong> 
             <br /><br />No momento estamos em fase beta, mas em breve você receberá um convite para acessar os conteúdos, ferramentas, serviços de educação financeira e viver estar experiência.
            </p>
          </Grid>
          <Grid container item xs={12} direction="row" justify="center" alignItems="center" className={classes.containerBtn}>
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              onClick={() => { this.goToBlog(); }}
            >IR PARA O BLOG
              <ArrowRightIcon />
            </Button>
          </Grid>
        </Grid>
      </div>
    )
  }

}

export default watch(withStyles(styles)(RegisterStep4), { user: '' });
