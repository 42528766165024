import React from 'react';
import './favorite.scss';

import { Grid, Breadcrumbs, Hidden } from '@material-ui/core';
import { Link } from 'react-router-dom'
import { Helmet } from "react-helmet";

import CurrentLevel from '../../components/sidebar/CurrentLevel';
import FeedItem from '../../components/feed/FeedItem';
import FeedLine from '../../components/feed/FeedLine';
import ListFavorites from '../../components/favorite/ListFavorites';
import PointsBalance from '../../components/sidebar/PointsBalance';
import ToolbarItem from '../../components/toolbar/ToolbarItem';

const Globals = require("../../../Globals.json");

class Favorite extends React.Component {

  render() {
    return (
      <>
        <Breadcrumbs aria-label="breadcrumb" className="favorite_breadCrumb">
          <Link color="inherit" to={Globals.paths.favorite} className="favorite_breadCrumbLink">
            Favoritos
          </Link>
        </Breadcrumbs>

        <Helmet>
          <title>Favoritos | Meu bolso em dia</title>
        </Helmet>

        <Grid container>
          <Grid item xs={12} sm={8} md={9} className="favoriteContainer" >
            <Grid container >

              <Grid item className="cardFavorite">
                <Grid item xs={12} className="favoriteTitleBar">
                  Meus Favoritos
                  </Grid>
              </Grid >

              <div className="favoriteContainerRecomendados">
                <FeedLine >
                  <FeedItem title="Cursos" styles={{ padding: "0px 20px 0px 20px" }}>
                    <ListFavorites favorite={1} />
                  </FeedItem>
                </FeedLine>
              </div>
              <br />


              {/* <Grid item className="favoriteCardContainer" />

              <div className="favoriteContainerRecomendados">
                <FeedItem title={"Quiz"}>
                  <ListFavorites favorite={3} endpoint={Globals.api.recommendedModule} payload={{ module_type: "1" }} />
                </FeedItem>
              </div>
              <br /> */}

              <Grid item className="favoriteCardContainer" />

              <div className="favoriteContainerRecomendados">
                <FeedItem title={"Artigos"}>
                  <ListFavorites favorite={2} />
                </FeedItem>
              </div>
              <br />

            </Grid>
          </Grid>

          <Hidden xsDown>
            <Grid item sm={4} md={3}>


              <ToolbarItem tooltip="
                O Nível representa o seu status de usuário na Plataforma. Ele aumenta conforme você vai usando e aprendendo com a Plataforma: cumprir os objetivos e missões da sua jornada de aprendizado 
                e consumir conteúdos e usar ferramentas te dão experiência. Essa experiência se acumula, e quando você atinge determinada quantidade, passa de nível. Níveis mais altos concedem benefícios e 
                vantagens exclusivas para os usuários." title="Seu Nível" color="#45AF98">
                <CurrentLevel />
              </ToolbarItem>

              <ToolbarItem title="Saldo de Pontos"
                tooltip="Os Pontos representam o reconhecimento do seu engajamento no uso da Plataforma. Praticamente qualquer atividade da Plataforma é 
                recompensada com uma quantidade de pontos ao ser completada. Junte os pontos e as troque por benefícios e conteúdos exclusivos da Plataforma." color="#45AF98">
                <PointsBalance coins={this.props.coins} />
              </ToolbarItem>
            </Grid>
          </Hidden>


        </Grid >

      </>
    )
  }

}

export default Favorite;
